.Modal{
    height:100%;
    width:100%;
    position: fixed;
    top:0;
    left:0;
    background-color: rgb(255, 255, 255);
    z-index: 30;
}

.PlaceAlbum{
    width:100vw;
    height:100%;
    overflow: hidden;
    display:flex;
    position: relative;
}


.ImagesScroller{}

.ImagesScroller::-webkit-scrollbar
{
  width: 0;
}


.LargeImage{
    max-width:90%;
    object-fit: contain;
}


@media screen and (min-width: 1024px) {
    .BorderLeft{
        border-left:1px solid rgb(220,220,220);
    }
    
    .BorderRight{
        border-right:1px solid rgb(220,220,220);
    }

    .SelectedImage{
        width:69%;
        height:82vh;
        padding: 5vh 0.5%;
        display:flex;
        align-items: center;
        justify-content: center;
        
    }
    
    
    .ImagesScrollSection{
        display:flex;
        flex-direction: column;
        align-items: center;
        position:relative;  
        padding: 1vh 1%;
        width:28%;  
    }
    
    .ImagesScroller{
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height:90vh;
       
    }

}


@media screen and (max-width: 767px){
    .PlaceAlbum{
        width:100%;
        height:100%;
        overflow: hidden;
        display:flex;
        position: relative;
        flex-direction: column-reverse;
        background-color: rgb(59, 59, 59);
    }

    .PlaceGallery{
        width:100%;
        height:90%;
        background-color: rgb(59, 59, 59);
    }

    .SelectedImage{
        width:100%;
        object-fit: contain;
        height:70%;
        display:flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        
    }
    

    .BorderTop{
        border-top:1px solid rgb(220,220,220);
    }

    .ImagesScrollSection{
        position:fixed;
        bottom:0;
        width:100%;
    }
    
    .ImagesScroller{
        display:flex;
        align-items: center;
        position:relative;  
        padding: 0;
        width:98%;  
        height:10vh;
        bottom:5vh;
        z-index: 10;
        overflow-y: hidden;
        overflow-x: auto;
    }
    .LargeImage{
        max-width: 100%;
    }
}