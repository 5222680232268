.Modal{
  position:fixed;
  z-index: 800;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

  border: 0.7px solid rgb(165, 165, 165);
  width: 30vw;
  left: 35vw;
  height: 80vh;
  top: 10vh;
  overflow-y: auto;
  padding: 0.5px;
  
}

.Body{
  width:90%;
  padding: 20px 5%;
  height:60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.User{
  display: flex;
  width:100%;
  align-items: flex-end;
  margin:10px 0;
  padding: 5px;
  border-bottom: 1px solid rgb(230,230,230);
}
.User:hover{
  cursor: pointer;
  background-color: rgb(245,245,245);
}

.UserDetails{
  display: flex;

}

.UserPhoto{
  width:50px;
  border-radius: 50%;;
  margin-left:20px;
}

.UserName{
  font-size:1.1em;
  color:rgb(88,88,88);
  font-weight:500;

}

.DeleteIcon{
  width:20px;
  height:20px;
}

.DeleteIcon{
  cursor: pointer;
}

@media(max-width:1023px){
  .Modal{
    position:fixed;
    z-index: 800;
    background-color: white;
    box-shadow: none;
  
    border:none;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    overflow:hidden;
    padding: 0.5px;
    
  }
  
  .Body{
    width:90%;
    padding: 5%;
    height:80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }
  .User{
    display: flex;
    width:100%;
    align-items: flex-end;
    margin:5px 0;
    padding: 5px;
  }
}