.Body{
    width:100%;
    height:120px;
    background-color: white;;
    border-bottom:1px solid rgb(223,223,223);
    direction: ltr;
}
.Body:hover{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 1px 2px rgba(240, 240, 240, 0.699);
    -moz-box-shadow: 0px 0px 1px 2px rgb(240, 240, 240);
    box-shadow: 0px 0px 1px 2px rgba(238, 238, 238, 0.76);
}

.Content{
    width:100%;
    height:100%;
    display:flex;
   align-items: center;
}   

.Ticket{
 
    border-radius:7px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:80%;
    padding:5px 0;
    height:110px;
    font-size: 0.9em;
}
.Ticket:hover{

}

.TicketPart{
    background-color: white;
    width:45%;
    padding:10px 2.5%;
    height:80%;
    font-size:1em;    
    position: relative;
    color:rgb(100,100,100);
   
}
.Time{
    position: absolute;
    bottom:1vh;
}
.TicketPartHover{
    background-color: white;
    width:45%;
    padding:0 2.5%;
    height:100%;
    font-size:1em;    
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(100,100,100);
   
}
.TicketBorder{
    width:0px;
    height:100%;
 
  
}

.Type{
 
    width:20%;
   
    display:flex;
    align-items: center;
    justify-content: center;
    height:90px;
    margin: 10px 0;
    border-right:1px solid rgb(223,223,223);
}
.TypeHover{
    border-right:1px solid rgb(223,223,223);
    width:20%;
    height:90px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

}

.LeftClass{
   /*  height:90px;
    margin: 10px 0;
    border-right:1px solid rgb(223,223,223); */
}

.RightClass{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
}

.TransportIcon{
    width:40px;
}

.Arrow{
    width:30px;
}

@media(max-width:600px){
    .Body{
        width:98%;
        padding:0 1%;
        height:100px;
        
    }
    .Ticket{
        font-size: 0.7em;;
        height:100px;
    }
    .Type{
        height:100px;
    }
}