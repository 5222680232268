.Body{
    position: relative;
}
.BodyRTL{
    direction: rtl;
    position: relative;
}

.Wall{
    min-width: 100%;
    position:relative;
    height:100%;
}

.PlacesWall{
    display: flex;
   position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(250,250,250);
  }
  .PlansWall{
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: rgb(250,250,250);
  }

  .Toolbar{
    background-color: white;
    display:flex;
    flex-direction: column;
    height:auto;
    width:90%;
    position:relative;;
    padding: 2vh 5%;
    color:rgb(100,100,100)
}

.SearchBarSection{
    display:flex;
    align-items: flex-start;
    position:relative;;
}

.SearchBarSectionRTL{
    display:flex;
    align-items: flex-start;
    direction: rtl;
    position:relative;;
  }

  .TagsList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
}

  
.PlansWall::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}

.ExperiencesWall{
    width: 80%;
    padding: 0 10%;
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: rgb(250,250,250);
  }

  .ExperiencesWall::-webkit-scrollbar
    {
    width: 0;
    background-color: #F5F5F5;
    }

    .EmptyWall{
      display:flex;
      flex-direction: column;
      align-items: center;
       background-color: rgb(250,250,250);
       height:auto;
       padding-bottom: 5vh;
    }

  .NoContentMessage{
  font-size:1.2em;
  color:#b22222;
  text-align: center;
}
  .CallToAction{
    position:relative;
    top:3vh;
    height:auto;
    padding:7px 15px;
    border-radius: 5px;
    border: 1px solid rgb(223,223,223);
    background-color: rgb(251,251,251);
  }

  .TripStagesSelection{
    display:flex;
    align-items: center;
    font-size:0.9em;
    color:rgb(100,100,100);
    width:80%;
    padding:1vh 10%;
  }

  .TripStage{
    display: flex;
    align-items: center;
    margin:0 7px;
  }

  
.CheckIcon{
  margin-right:12px;
  width:26px;
  height:26px;
}

.CheckIconRTL{
  margin-left:12px;
  width:26px;
  height:26px;
}


.UncheckBox{
  margin-right:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

.UncheckBoxRTL{
  margin-left:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}
  
@media screen and (min-width: 1024px) {
    .SearchBarSection{
        left:30%;
        width:40%;
        font-size: 0.9em;
    }
    .SearchBarSectionRTL{
        right:30%;
        width:40%;
        font-size: 0.9em;
    }
   
    .PlacesWall{
        width: 90%;
        padding: 0 5%;
        height: 83vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .PlansWall{
        width: 70%;
        padding: 0 15%;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .ExperiencesWall{
        width: 90%;
        padding: 0 5%;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .CallToAction:hover{
        cursor: pointer;
        background-color: white;
      }

      .CheckIcon:hover{
        cursor: pointer;
      }
      .CheckIconRTL:hover{
        cursor: pointer;
      }
      .UncheckBox:hover{
        cursor: pointer;
      }
      .UncheckBoxRTL:hover{
        cursor: pointer;
      }
      
    }

@media screen and (max-width: 767px) {
    .SearchBarSection{
        position:relative;
        right: -5%;;
        width:90%;
    }
    .SearchBarSectionRTL{
        position:relative;
       left:-5%;
        width:90%;
    }
    .Toolbar{
        width:90%;
        padding: 2vh 5%;
        padding-bottom: 0;
        display:flex;
        align-items: flex-start;
        top:0;
        font-size:0.8em;
    }
    .PlacesWall{
        width: 100%;
        padding: 0;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .PlansWall{
        width: 100%;
        padding: 0;
        height: 65vh;
        padding-bottom: 15vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .ExperiencesWall{
        width: 100%;
        padding: 0;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: rgb(250,250,250);
      }
    .TripStagesSelection{
      width:100%;
      padding:1vh 0;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .SearchBarSection{
        position:relative;
        left:15%;
        width:80%;
    }
    .SearchBarSectionRTL{
        position:relative;
        left:5%;
        width:80%;
    }
    .Toolbar{
        width:70%;
        padding: 1vh 15%;
        display:flex;
        align-items: center;
        top:0;
        font-size:0.8em;
    }
    .PlacesWall{
        width: 100%;
        padding: 0;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .PlansWall{
        width: 90%;
        padding: 0 5%;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .ExperiencesWall{
        width: 100%;
        padding: 0;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: rgb(250,250,250);
      }
}