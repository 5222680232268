.Wrapper{
    min-height:7vh;
    width:100%;
    margin:0;
}

.TopSection{
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    min-height:7vh;
    width:100%;
    background-color: rgb(255, 255, 255);
}

.TopSectionOpen{
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    min-height:7vh;
    width:100%;
    background-color: rgb(255, 255, 255);
}

.OpenBody{
    position: relative;
    width:100%;
    height:auto;
    z-index: 120;
    padding: 0;
   
    border-radius: 5px;
}

.ClosedBody{
background-color: red;
height:auto;
width:100%;
}



  .TitleSection{
    font-size:1.2em;
    padding:3px 5%;
   width:auto;
    height:100%;
    font-family: inherit;
    display: flex;
    align-items: center;
    color:rgb(100,100,100);
   
}

.TitleInput{
    width:90%;
    height:auto;
    color:rgb(100,100,100);
    font-size:1.1em;
   
    font-family: inherit;
    display: flex;
    border:none;
    background-color: rgba(255, 255, 255, 0);

}
.TitleInput:focus{
    outline: none;
}

.TextSection{
    width:100%;
    padding: 0;
    border-bottom: 1px solid rgb(230,230,230);
}

.TextSectionRTL{
    width:100%;
    padding: 0;
    border-bottom: 1px solid rgb(230,230,230);
    direction: rtl;
}

.InfoInput{
    padding:0 2.5%;
    width:95%;
}

.InfoInputRTL{
    padding:0 2.5%;
    width:95%;
    direction: rtl;
}

.NoteContent{
}
.ToolbarClassname{
    border:none;
}

.ToolbarClassnameRTL{
    border:none;
    direction: ltr;
}

.LinkButton{
    font-size: 1.1em;
    font-weight: 500;
    color:rgb(34,121,190);
  }

  .Links{
      width:95%;
      padding:5px 2.5%;
  }

  
.AttachIcon {
    height: 25px;
  }
  
  .AttachmentSection{
    width:95%;
    padding: 10px 2.5%;
    border-top:1px solid rgb(223,223,223);
  }
  
  .AttachmentMessage{
    font-size: 1.1em;
    font-weight: 500;
    color:rgb(34,121,190);
  }

  
.RemoveIcon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 100;
  }
  
  
  .AttachedFiles {
    margin-top: 10px;
    padding-bottom: 10px;
    color: rgb(80, 80, 80);
    font-size: 1.2em;
  }
  
  .SectionTitle {
    margin-bottom: 10px;
  }
  
  .Files {
    color: rgb(51, 51, 51);
    font-size: 1em;
  }
  .FileItem {
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 5px;
    border:5px;
  }
  .FileItem:hover {
   background-color:  rgb(248, 248, 248);
  }
  

   
.UploadSection {
    display: flex;
    align-items: center;
    height: 60px;
    width:auto;
    right:0;
    cursor: pointer;
    position: relative;
  }
  
  .FileInput {
    width: auto;
    height: 45px;
    cursor: pointer;
    position:absolute;
    right:0;
    top:20px;
  }
  
  .ImageUploadWrapper {
    cursor: pointer;
    max-width: 45px;
    max-height: 45px;
    margin-left: 5px;
  
    display: flex;
    justify-content: center;
    overflow: hidden;
  
    color: rgb(87, 87, 87);
  
  }
  
  .ImageUploadWrapper > input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .ImageUploadWrapper > .UploadButton {
    display: inline-block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
  }
  
  .UploadButton {
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    padding-top: 0;
  
  }

  .EditIcon{
      height:25px;
      margin:0 5px;
  }


@media screen and (min-width: 1024px) {  
    .TopSection:hover{
        cursor: pointer;
    }
    .LinkButton:hover{
        cursor: pointer;
      }
      .EditIcon:hover{
        cursor: pointer;
      }
   
}

@media screen and (max-width: 767px) {  
   
}

    
@media screen and (min-width: 768px) and (max-width:1023px){

}