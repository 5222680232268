.Body{
    border-bottom: 1px solid rgb(223,223,223);
    width:100%;
    background-color: white;
    position: relative;
}
.NavBar{
    width: 95%;
    padding: 0 2.5%;
    height:7vh;
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size:1em;
    color:rgb(100,100,100);
    position:relative
}

.Logo{
    height: 40px;
}

.Logo:hover{
    cursor: pointer;
}

.NavBarTools {
    position:relative;
    display: flex;
    align-items: center;
   /*  margin-right: 20px; */
    font-size: 1em;
}

.LanguageButton{
    position:relative;
    margin-right:0px;
}

.Tools{
    position:relative;
    z-index: 100;
  }

  .IconSection{
    position: relative;
    display: flex;
    margin-right: 15px;
    padding:  0;
    padding-right: 15px;
  }

  .NavBarIcon{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
  
  }
  
  .NavBarIconSelected{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-bottom:3px;
    border-bottom: 2px solid rgb(34,121,190);
  }

  
  .ProfilePicture {
   
    object-fit: cover;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    
  }

  .HamburgIcon{
    height:25px;
    width:25px;
    margin-right:6px;
  }
.ToolbarProfile{
  display:flex;
  align-items: center;
  border:1px solid rgb(220,220,220);
  border-radius: 20px;
  padding:1px 2px;
}
  .ToolbarProfileSelected{
    display:flex;
    align-items: center;
    border:1px solid rgba(34, 120, 190, 0.658);
    background-color: rgba(34, 120, 190, 0.758);
    border-radius: 20px;
    padding:1px 2px;
  }

  .HamburgOnly{
    margin-left:25px;
    display:flex;
    align-items: center;
  }
  
  .Notify{
    width: 15px;
    height: 15px;
    position: absolute;
    right: 5px;
  }

  .NotifyNumber{
    padding: 3px;
    height: 10px;
    width:10px;
    text-align: center;
    position: absolute;
    right: 5px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(160, 22, 22);
    color: white;
  }

  .SelectedContent{
    font-family: cursive;
    font-size:1.4em;
    font-weight: 600;
    position:absolute;
    width:20%;
    left:40%;
    text-align: center;
  }

  @media screen and (min-width: 1024px) {
    .HamburgIcon:hover{
      cursor: pointer;
    }
    .ProfilePicture:hover{
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    .Body{
      border-bottom: 1px solid rgb(194, 194, 194);
    }
    .ProfilePicture {
        width: 30px;
        height: 30px;        
      }
    
      .HamburgIcon{
        height:22px;
        width:22px;
        margin-right:6px;
      }
      .NavBarTools{
          margin:0;
      }

      .IconSection{
          padding-right: 10px;
          margin-right:10px;
      }
      
    .NavBarIcon{
        width: 26px;
        height: 26px;
    }
    .NavBarIconSelected{
        width: 26px;
        height: 26px;
    }
  }


  @media screen and (min-width: 768px) and (max-width:1023px){
    .ProfilePicture {
      width: 35px;
      height: 35px;        
    }
  
    .HamburgIcon{
      height:30px;
      width:30px;
      margin-right:6px;
    }
    .NavBarTools{
        margin:0;
    }

    .IconSection{
        padding-right: 15px;
        margin-right:15px;
    }
    
  .NavBarIcon{
      width: 35px;
      height: 35px;
  }
  .NavBarIconSelected{
      width: 35px;
      height: 35px;
  }
  }