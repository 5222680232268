.Body{
    width:100%;
}

.SearchBar{
    width:100%;
    border:1px solid rgb(223,223,223);
    border-radius: 30px;
    background-color: white;
}

.SearchInput{

}

.SearchInputWithSuggestions{

}

.InputText{
    color:rgb(100,100,100);
    position:relative;
    width:65%;
    padding:0 2.5%;
    left:5%;
    outline: none;
    border:none;
   
}

.InputTextRTL{
    position:relative;
    color:rgb(100,100,100);
    width:65%;
    padding:0 2.5%;
    outline: none;
    border:none;
}

.Suggestions{
    top:2px;
    position:relative;
    background-color:white;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 30;
    border-radius: 10px;
    border:1px solid rgb(223,223,223);
}

.SuggestionsRTL{
    top:2px;
    position:relative;
    background-color:white;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 30;
    border-radius: 10px;
    border:1px solid rgb(223,223,223);
}

.Suggestions::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .Suggestions::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  .Suggestions::-webkit-scrollbar-track-piece{
    background-color: #F5F5F5;
  }
  
  .Suggestions::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(255, 255, 255, 0.562);
  }

.SearchValue{
    padding:10px 0;
    color:rgb(100,100,100);
    display:flex;
    align-items: center;
}

.LocationTagIcon{
    height:16px;
    margin-right:10px;
}

.LocationTagIconRTL{
    height:16px;
    margin-left:10px;
}
.FullWidth{
    width:90%;
    left:2.5%;
    right:2.5%;
}

.InputFullWidth{
    width:80%;
}



@media screen and (min-width: 1024px) {  
    .SearchBar{
        height: 50px;
    }

    .Suggestions{
        width:95%;
        left:0;
        padding:10px 2.5%;
        max-height:300px;
        border:1px solid rgb(230,230,230);
    }
    .SuggestionsRTL{
        width:95%;
        padding:10px 2.5%;
        right:0;
        max-height:300px;
        border:1px solid rgb(230,230,230);
    }

    .FullWidth{
        width:90%;
        left:2.5%;
        right:2.5%;
    }

    .InputText{
        height:50px;
        left:5%;
        font-size:1.1em;
    }
    .InputTextRTL{
        height:50px;
        right:5%;
        font-size:1.1em;
    }
    .SearchValue:hover{
        cursor: pointer;
        background-color: rgb(248,248,248);
    }
    
}
    
@media screen and (max-width: 767px) {  
    .SearchBar{
        height: 50px;
        border-radius: 25px;
    }
    .InputText{
        height:50px;
        font-size:1.1em;
        left:7.5%;
    }
    .InputTextRTL{
        height:50px;
        right:7.5%;
        font-size:1.1em;
    }
    .Suggestions{
        width:95%;
        padding:10px 2.5%;
        left:0;
        max-height:300px;
    }
    .SuggestionsRTL{
        width:95%;
        padding:10px 2.5%;
        right:0;
        max-height:300px;
    }
    .SearchValue{
        background-color: rgb(248,248,248);
        margin:5px 0;
        padding:10px 5px;
    }
    .FullWidth{
        width:90%;
        left:2.5%;
        right:2.5%;
    }
    .LocationTagIcon{
        height:20px;
        margin-right:10px;
    }
    
    .LocationTagIconRTL{
        height:20px;
        margin-left:10px;
    }
}
    
@media screen and (min-width: 768px) and (max-width:1023px){
    .SearchBar{
        height: 50px;
        font-size:1.2em;
    }
    .InputText{
        height:50px;
        font-size:1.1em;
        left:7.5%;
    }
    .InputTextRTL{
        height:50px;
        right:7.5%;
        font-size:1.1em;
    }
    .Suggestions{
        width:70%;
        padding:10px 2.5%;
        max-height:300px;
    }
    .SuggestionsRTL{
        width:70%;
        padding:10px 2.5%;
        max-height:300px;
    }
    .SearchValue{
        background-color: rgb(248,248,248);
        margin:5px 0;
        padding:10px 5px;
    }
    .LocationTagIcon{
        height:20px;
        margin-right:10px;
    }
    
    .LocationTagIconRTL{
        height:20px;
        margin-left:10px;
    }
}