.RTL{
  direction: rtl;
}

  
.PlaceHolder{
  background: linear-gradient(-45deg, #bebebe, #fdfdfd, #dddddd, #f3f3f3);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.PreviewBody{
  background-color: white;

  position: relative;
  border: 1px solid rgb(220, 220, 220);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex:1;
  justify-content: flex-start;
  min-width: 90%;
  max-width: 90%;
  margin: 10px 5%;
  cursor: pointer;
  padding: 0;
  height: auto; 
  height:35vh;
  border-radius: 10px;
  padding: 0px;
}

.Content{
  padding: 0 2.5%;
  width:95%;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PhotoPreview {
  width: 100%;
  object-fit:cover;

}

.PhotoPreviewHeightPlus{
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  min-height: 22vh;
  max-height: 22vh;
  position: relative;
  top:-0.3vh;
}


.TopSection {
  padding: 0;
  width: 100%;

}
.TopSection:hover{
    cursor: pointer;
}

.Title {
  text-align: start;
  cursor: pointer;
  width: 100%;
  resize: none;
  height: auto;
  font-style: italic;
  border: none;
  outline: none;
  font-size: 1.1em;
  font-weight: 500;
  color: rgb(65, 65, 65);
}

.Category {
 
  text-align: start;
  font-size: 0.9em;
  font-weight: 450;
  color: rgb(80, 80, 80);
  white-space: nowrap;
 
}

.CategoryLTR{
  margin-right:7px;
  padding-right:7px;
  font-size: 0.9em;
  font-weight: 450;
  color: rgb(80, 80, 80);
  border-right:1px solid rgb(235,235,235);
}

.CategoryRTL{
  margin-left:7px;
  padding-left:7px;
  font-size: 0.9em;
  font-weight: 450;
  color: rgb(80, 80, 80);
  border-left:1px solid rgb(235,235,235);
}

.SelectedPlaceCategory{
  border-right:1px solid rgb(200,200,200);
}

.SelectedPlaceCategoryRTL{
  border-left:1px solid rgb(200,200,200);
}

.CategoryLast{
  text-align: start;
  white-space: nowrap;
  font-size: 0.9em;
  font-weight: 450;
  color: rgb(80, 80, 80);
}


.CategoryLastLTR{
  margin-right:7px;
  padding-right:7px;
}

.CategoryLastRTL{
  margin-left:7px;
  padding-left:7px;
}

.CategoriesList{
  display:flex;
  align-items: center;
  flex-wrap: wrap;
  padding:5px 0;
}

.LocationStats{
  display: flex;
  align-items: flex-end;
  width:100%;
  padding-bottom: 5px;
  font-size: 0.8em;
  color: rgb(122, 122, 122);
  max-height: 65px;
  position:absolute;
  bottom:3px;
}


.Owner{
  position:relative;
  text-align: center;
  z-index: 10;
  width:100%;
  background-color: white;
  border-top:1px solid rgb(200,200,200);
  border-bottom:1px solid rgb(200,200,200);
  top:-15vh;
}
.Tools{
  width:100%;
  height:auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  right:0;
  top:20px;
}
.ToolsRTL{
  width:100%;
  height:auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  left:0;
  top:20px;
}
.PlaceTools{
position:absolute;
width:94%;
display:flex;
justify-content: space-evenly;
align-items: center;
z-index:10;
top:5px;
padding:5px 2.5%;
min-height: 40px;
min-width: 50px;
background-color: rgba(255, 255, 255, 0.78);
}
.PlaceToolsRTL{
  position:absolute;
width:94%;
display:flex;
justify-content: space-evenly;
align-items: center;
z-index:10;
top:5px;
padding:5px 2.5%;
min-height: 40px;
min-width: 50px;
background-color: rgba(255, 255, 255, 0.78);
}

.ToolsWrapper{
  position: absolute;;
  top:0;
  width:90%;
  padding:0 5%;
  display:flex;
  align-items: center;
  justify-content: flex-end;
z-index:10;
}

.ClosedMessage{
  position: relative;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  z-index: 10;
  top:-20vh;
  color: red;
  padding:10px 10px;
border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.642);
}
.ToolsIcon{
  height: 22px;  
  border-radius: 50%;
  margin:0 7px;
}

.ExperienceIcon{
  height: 22px;  
  margin:0 7px;
}


.GreyedTools{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width:32%;
  min-width:25%;
}

.ToolsIconGreyed{
  height:30px;
  border-radius: 50%;

}


.ToolText{
  margin-top:3px;
  font-size:0.7em;
  text-align: center;
  white-space: normal;
  color:rgb(88, 88, 88);
}

.ToolTextFade{
  margin-top:3px;
  font-size:0.7em;
  text-align: center;
  white-space: normal;
  color:rgb(165, 165, 165);
}

.TabSeperator{
  border-left:1px solid rgb(202, 202, 202);
  height:40px;
}

.DurationIcon{
  height:20px;
}

.Duration{
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:0.7em;
  position:absolute;
  right:5px;
  bottom:0;
}

.DurationRTL{
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:0.7em;
  position:absolute;
  left:5px;
  bottom:0;
}

@media screen and (min-width: 1024px) {
    
  .Body{
    background-color: white;
      position: relative;
      border: 1px solid rgb(220, 220, 220);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 30%;
      max-width: 30%;
      margin: 1vh 1%;
      cursor: pointer;
      padding: 0;
      height:40vh;
      border-radius: 10px;
      padding: 2px;
  }
  
  .Body:hover{
    box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
      0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
     top:-2px;
     transition-delay:5s ;
     transition: ease 0.4s;
     height:40vh;
  
  }

  .SelectedBody{
    box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
      0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
     top:-2px;
     transition-delay:5s ;
     transition: ease 0.4s;
     background-color: rgb(255,119,0,0.2);
     border:1px solid rgb(255,119,0,0.8);
  }

  .Content{
    height:18vh;
  }

  .ToolsIcon{
    height:22px;  
    margin:0 8px;
  }
  .ToolsIconGreyed{
    height:22px;  
    margin:0 8px;
  }
  .GreyedTools{
    margin:0 8px;
  }
  .ExperienceIcon{
    height: 22px;  
    margin:0 8px;
  }
  .ExperienceIcon:hover{
    cursor: pointer;
  }
  .ToolsIcon:hover{
    cursor: pointer;
  }
  }
  
  @media screen and (max-width: 767px) {
      
    .Body{
      background-color: white;
        position: relative;
        border: 1px solid rgb(220, 220, 220);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex:1;
        justify-content: flex-start;
        min-width: 98%;
        max-width: 98%;
        margin: 1vh 1%;
        cursor: pointer;
        padding: 0;
        min-height:35vh;
        border-radius: 10px;
      
    }
    .MobileBodyWelcomeList{
      min-width: 75%;
        max-width: 75%;
        max-height:40vh;
        min-height:40vh;
        margin: 0.5vh 2%;
        border: 1px solid rgb(207, 207, 207);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        display:flex;
        flex-direction: column;
        background-color:white;
        position: relative;
        border-radius: 10px;
    }
    .PlaceTools{
      padding:2px 2.5%;
      width:95%;
    }
    .PlaceToolsRTL{
      padding:2px 2.5%;
      width:95%;
    }
    .Content{
      min-height:18vh;
    }

    .MapContent{
      padding: 0 2.5%;
      width:95%;
    
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height:16vh;
    }
    
  .ToolsIcon{
    height:22px;  
    margin:0 8px;
  }
  .ExperienceIcon{
    height:22px;  
    margin:0 8px;
  }
  .ToolsIconGreyed{
    height:22px;  
    margin:0 8px;
  }
 .GreyedTools{
    margin:0 8px;
  }
  .MobileMapBody{
    height:16vh;
    border-radius: 10px;
    min-width:80vw;
    max-width:80vw;
    margin:0 2.5vw;
    display:flex;
    background-color:white;
    font-size: 0.9em;
  }

  .MobileMapBodyRTL{
    height:16vh;
    border-radius: 10px;
    min-width:80vw;
    max-width:80vw;
    margin:0 2.5vw;
    display:flex;
    background-color:white;
    direction: rtl;
    font-size: 0.9em;
  }
  
  .FirstInMap{
    
    margin:0 2.5vw;
    margin-left:10vw;
  }
  
  .LastInMap{
    margin:0 2.5vw;
    margin-right:10vw;
  }
  .MobilePhotoPreviewMap{
    max-height:16vh;
    min-height:16vh;
    min-width:40%;
    max-width: 40%;
    position: relative;
    object-fit:cover;
  }
  .MapPhotoBorderLTR{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .MapPhotoBorderRTL{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .CategoryLTR{
  font-size:0.8em;
  }
  .CategoryRTL{
    font-size:0.8em;
  }
  .CategoryLast{
    font-size:0.8em;
  }

  .PlanToolkit{
    position: absolute;
    right:5px;
    top:5px;
  }
  .PlanToolkitRTL{
    position: absolute;
    left:5px;
    top:5px;
  }
.ToolkitIcon {
height: 25px;
}
.PlanTools{
  position:fixed;
  top:40%;
  width:45%;
  left:25%;

}
  }
  
  @media screen and (min-width: 768px) and (max-width:1023px){
      
  .Body{
    background-color: white;
        position: relative;
        border: 1px solid rgb(220, 220, 220);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex:1;
        justify-content: flex-start;
        min-width: 45.5%;
        max-width: 45.5%;
        margin: 1vh 2%;
        cursor: pointer;
        padding: 0;
        height:35vh;
        border-radius: 10px;
  }
  .MobileBodyWelcomeList{
    min-width: 60%;
      max-width: 60%;
      max-height:35vh;
      min-height:35vh;
      margin: 0.5vh 2%;
      border: 1px solid rgb(207, 207, 207);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display:flex;
      flex-direction: column;
      background-color:white;
      position: relative;
      border-radius: 10px;
  }
  .MapContent{
    padding: 0 2.5%;
    width:95%;
  
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height:16vh;
  }
  .Content{
    height:16vh;
  }
  .ToolsIcon{
    height: 30px;  
    margin:0 12px;
  }
  .ExperienceIcon{
    height: 30px;  
    margin:0 12px;
  }
  .ToolsIconGreyed{
    height: 30px;  
    margin:0 12px;
  }
  .GreyedTools{ 
    margin:0 12px;
  }
  .PlaceTools{
    padding:2px 2.5%;
    width:95%;
  }
  .PlaceToolsRTL{
    padding:2px 2.5%;
    width:95%;
  }
  .MobileMapBody{
    height:16vh;
    border-radius: 10px;
    min-width:80vw;
    max-width:80vw;
    margin:0 2.5vw;
    display:flex;
    background-color:white;
  }
  .MobileMapBodyRTL{
    height:16vh;
    border-radius: 10px;
    min-width:80vw;
    max-width:80vw;
    margin:0 2.5vw;
    display:flex;
    background-color:white;
    direction: rtl;
  }
  
  .FirstInMap{
    margin:0 2.5vw;
    padding-left:7.5vw;
  }
  
  .LastInMap{
    margin:0 2.5vw;
    margin-right:7.5vw;
  }
  .MobilePhotoPreviewMap{
    max-height:16vh;
    min-height:16vh;
    min-width:40%;
    max-width: 40%;
    position: relative;
    object-fit:cover;
  }
  .MapPhotoBorderLTR{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .MapPhotoBorderRTL{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .Tools{
    right:10px;
    top:25px;
  }
  .ToolsRTL{
    left:10px;
    top:25px;
  }
  }