.Body{
    padding:10px;
}

.Phone{
    padding:10px 0;
    width:100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.PhoneContainer{
    direction: ltr;
    width:400px;
    border: 1px solid rgb(235,235,235);
    border-radius: 7px;
    height:40px;
    width:400px;
    font-size: 1.05em;
    padding:0 10px;
    background-color: white;
}

.PhoneInput{
    border: 1px solid rgb(235,235,235);
    border-radius: 7px;
    height:40px;
    width:400px;
    font-size: 1.05em;
    padding:0 10px;
}

.ContactIcon{
    height:30px;
    margin-right:15px;
}

.ContactIconRTL{
    height:30px;
    margin-left:15px;
}

.Social{
    
    padding:10px 0;
    width:100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.SocialLinkInput{
    border: 1px solid rgb(235,235,235);
    border-radius: 7px;
    height:40px;
    width:400px;
    font-size: 1.05em;
    padding:0 10px;
}

.SocialLinkInput:focus{
    outline: none;
}


.PhoneOption{
    display:flex;
    align-self: center;
    color:rgb(88,88,88);
}
 
.CheckIcon{
    margin-right:12px;
    width:26px;
    height:26px;
  }
  
  .CheckIconRTL{
    margin-left:12px;
    width:26px;
    height:26px;
  }
  
  
  .UncheckBox{
    margin-right:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }
  
  .UncheckBoxRTL{
    margin-left:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }

  .CheckIconLhover{
    cursor: pointer;
  }
  .CheckIconRTL:hover{
    cursor: pointer;
    }

.UncheckBox:hover{
    cursor: pointer;
  }

.UncheckBoxRTL:hover{
    cursor: pointer;
  }

@media screen and (max-width: 767px) {
    .Body{
        width:95%;
        padding:5px 2.5%;
    }
    
    .Phone{
        padding:10px 0;
        width:100%;
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .ContactIcon{
        height:25px;
        margin-right:15px;
    }

    .ContactIconRTL{
        height:25px;
        margin-left:15px;
    }
    
    .Social{
        
        padding:10px 0;
        width:100%;
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .SocialLinkInput{
        border: 1px solid rgb(235,235,235);
        border-radius: 7px;
        height:35px;
        width:300px;
        font-size: 0.9em;
        padding:0 10px;
    }
    
    .SocialLinkInput:focus{
        outline: none;
    }
    
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Body{
        width:95%;
        padding:15px 2.5%;
    }
    
    .Phone{
        padding:10px 0;
        width:100%;
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .ContactIcon{
        height:30px;
        margin-right:15px;
    }

    .ContactIconRTL{
        height:30px;
        margin-left:15px;
    }
    
    .Social{
        
        padding:10px 0;
        width:100%;
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .SocialLinkInput{
        border: 1px solid rgb(235,235,235);
        border-radius: 7px;
        height:35px;
        width:300px;
        font-size: 0.9em;
        padding:0 10px;
    }
    
    .SocialLinkInput:focus{
        outline: none;
    }
}