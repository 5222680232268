.Body{
    width:100%;
}

.PlanWall{
    width:100%;
    display:flex;
    flex-wrap: wrap;
    position: relative;
    margin:1.5vh 0%; 
    height:70vh;
    overflow-y: auto;
    overflow-x: hidden;
   
}


.PlanWall::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.PlanWall::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}

.PlanWall::-webkit-scrollbar-track-piece{
  background-color: #F5F5F5;
}

.PlanWall::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgba(255, 255, 255, 0.562);
}


.AddButton{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}

.PlusIcon{
    width:30px;
}


.Tasks{

}

.NoTasksMessage{
    
}

@media screen and (min-width: 1024px) {  

    .AddButton:hover{
        cursor: pointer;
        background-color: rgba(247, 247, 247, 0.562);
    }
    
}

@media screen and (max-width: 767px) {  
    .Body{
        height:auto;
       
    }
    .PlanWall{
        width:100%;
        height:100%;
        overflow-y: hidden;
        overflow-x: hidden;
       
    }
    .TasksWall{
        width:100%;
        height:auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .Header{
        width:95%;
        height:30px;
        padding: 7px 2.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(255,119,0);
        font-size: 1.1em;
        color:white;
    }
    .NotSelectedHeader{
        width:45%;
        height:30px;
        padding: 7px 2.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(255,119,0,0.4);
        font-size: 1.1em;
        color:white;
    }

    
    .SelectedHeader{
        width:45%;
        height:30px;
        padding: 7px 2.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(255,119,0);
        font-size: 1.1em;
        color:white;
    }
    .PlusIcon{
        width:24px;
    }
    .DeleteSwipe{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        color:white;
        background-color: #b22222;
          font-weight:500;
        }
    
}


@media screen and (min-width: 768px) and (max-width:1023px){

}