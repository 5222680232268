.Body{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px 0;
/*     margin-top:10px; */
    padding-top: 25px;
   /*  border-top:1px solid rgb(224, 224, 224); */
}

.BodyRTL{
    direction: rtl;
}

.TextArea{
    width:auto;
    min-width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TextInput{
    font-size: 1.2em;
    color: rgb(100,100,100);
    border:none;
    min-width:90%;
    border-bottom:1px solid rgb(233,233,233);
    padding-bottom: 8px;
}

.TextInput:focus{
    outline: none;
}

.TagsList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
}

.PlusIcon{
    width:30px;
}

.PlusIcon:hover{
    cursor: pointer;
}
@media screen and (min-width: 1024px) {

    .PlusIcon{
        margin: 0 15px;
    }
    
    .PlusIcon:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px) {
    .TextInput{
        font-size:1em;
    }
}
  
  @media screen and (min-width: 768px) and (max-width:1023px){
    .TextInput{
        font-size:1.1em;
    }
  
  }
