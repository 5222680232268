.RTL{
    direction: rtl;
}

.Modal{
    z-index: 600;
    position:fixed;
    width:50vw;
    left:25vw;
    height:90vh;
    top:5vh;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgb(212, 212, 212);
    box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
    0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
  
  }
  
  .Body{
    width: 100%;
    height: 72vh;
    padding: 0;
    overflow-y: auto;
    margin-bottom: 1vh;
    overflow-x: hidden;
  }
  
  .Body::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }
    
    .Body::-webkit-scrollbar
    {
      width: 3px;
      background-color: #F5F5F5;
    }
    
    .Body::-webkit-scrollbar-thumb
    {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(168, 168, 168, 0.562);
    }
  
    .SelectionsSection{
        background-color: rgb(247,247,247);
      width:90%;
    position: relative;
    padding:5px 5%;
    border-bottom:1px solid rgb(223,223,223);
    }
  
    .PlaceTop{
        display:flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width:100%;
      
      border-bottom:1px solid rgb(223,223,223);
    }
  
  .PlaceSelection{
    padding:10px 10%;
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .CountrySelection{
    width:50%;
      padding: 10px 5%;
  }
  .ResetPlace{
      margin: 5px 0;
    width:auto;
    padding:5px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(248,248,248);
    border:1px solid rgb(230,230,230);
    font-size:1.1em;
    color:rgb(88,88,88);
  }
  
 
  .TopDetails{
    display:flex;
    align-items: center;
    justify-content: space-between;
    
  
  }
  
  .Date{
    border:1px solid rgb(225,225,225);
    padding:0 20px;
    position:relative;
  top:10px;
    direction: ltr;
    z-index: 20;
    font-size: 1.1em;
  }
  
  .EmptyDate{
    border:1px solid rgb(173, 0, 0);
    padding:0 20px;
    position:relative;
  top:10px;
    direction: ltr;
    z-index: 20;
  }
  
  .Rank{
     
      width:100%;
    display:flex;
    align-items: flex-end;
    height:50px;
  }
  
  .SelectedRankIcon{
    width:30px;
    height:30px;
    margin:0 15px;
    transform: scale(1.3);
  }
  .SelectedRankIcon:hover{
      cursor: pointer;
  }
  
  .RankIcon{
    width:30px;
    height:30px;
    margin:0 15px;
  }
  
  .RankIcon:hover{
      cursor: pointer;
  }
  
  .TitleSection{
    width:100%;
    padding:15px 0%;
  }
  
  .TitleInput{
    width:100%;
    font-size:1.5em;
    color:rgb(100,100,100);
    border:none;
    padding-bottom: 5px;
    border-bottom:1px solid rgb(235,235,235);
  }
  .TitleInput:focus{
      outline: none;
  }
  
  .ImageContent{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }
  
  .ImageButton{
    margin:0 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 200px;
    background-color: rgb(255,119,0);
    border-radius: 25px;
  }
  .ImageButton:hover{
    cursor: pointer;
  }
  
  
  .ImageIcon{
    height:25px;
    margin-left: 15px;
  }
  
  .ImageText{
  border:none;
  color:white;
  font-size:1.2em;
  width:auto;
  font-weight: 500;
  border:none;
  display:inline-block
  }
  .ImageText:focus{
  outline: none;
  }
  
  .ImageSection{
  
  }
  
  .Images{
  
  }
  
  
  .ImageUploadWrapper {
    cursor: pointer;
    min-width: 200px;
    max-width: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
  
    color: white;
  
  }
  
  .ImageUploadWrapper > input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .ImageUploadWrapper > .ImageButton {
    display: inline-block;
    width: 200px;
    height: 200px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .TextSection{
    width:95%;
    min-height:20vh;
    margin:15px 0%;
    color:rgb(100,100,100);
    font-size: 1.1em;
   border:1px solid rgb(230,230,230);
   padding: 0 2.5%;
  
  }
  
  .TextInput{
    line-height: 1.5em;
    min-width:95%;
    max-width:95%;
   
    min-height:20vh;
    max-height:  20vh;
    border:none;
    font-size:inherit;
    font-size:1.2em;
    color:inherit;
    padding:5px 2.5%;
    padding-bottom:10px;
    border-radius: 5px;
    border:1px solid rgb(230,230,230);
  }
  
  .TextInput:focus{
      outline: none;
  }

  .TextInputSection{
      width:90%;
      padding:0 5%;
  }
  
  .VideoInputSection{
      display:flex;
      align-items: center;
  }
 
  
  .ExperiencePrivacy{
    position:relative;
    width:162px;
    margin-top:10px;
  }
  
  .SelectedPrivacy{
    width: auto;
  padding: 5px 15px;
  border-radius: 5px;
  border:1px solid rgb(212, 212, 212);
  background-color: white;
  display:flex;
  align-items: center;
  white-space: nowrap;
  }
  .SelectedPrivacy:hover{
    cursor: pointer;
  }
  
  .PrivacyOptions{
    position: absolute;
    z-index: 300;
    width: 150px;
    border:1px solid rgb(223,223,223);
    border-radius: 6px;;
    padding: 5px;
    background-color: white;
    
  }
  
  .PrivacyOption{
    color:rgba(79, 95, 138);
    padding:3px 7px;
    margin: 2px 0;
    background-color: rgba(255, 255, 255, 0.062);
  }
  
  .PrivacyOption:hover{
    cursor: pointer;
    background-color: rgba(79, 95, 138, 0.120);
  }
  
  .PrivacyIcon{
    position: relative;
    right:7px;
    top:2px;
    width:25px;
  }

  .PrivacyIconRTL{
    position: relative;
    left:7px;
    top:2px;
    width:25px;
  }
  
  .InfoSection{
    border-bottom:1px solid rgb(212, 212, 212);
    margin-bottom:10px;
    padding-bottom: 10px;
    width:90%;
    position: relative;
    padding:10px 5%;
  }
  .DatesSection{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    flex-wrap: nowrap;
   
  }
  .Duration{
    font-size:1em;
  
    padding-top:15px;
    display:flex;
    align-items: center;
  }
  
  .DurationTitle{
  
  }
  
  
  .NumberInput{
    color:rgb(88,88,88);
    font-size:1.1em;
  width:120px;
  display:flex;
  align-items: center;
  height:35px;
  
  }
  
  .NumberInputButton{
    min-height:30px;
    max-height:30px;
    max-width:30px;
    min-width:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  background-color:rgb(52, 124, 219);
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.1em;
  }
  .NumberInputButton:hover{
    background-color:rgb(52, 124, 219,0.7);
  cursor: pointer;
  }
  
  .NumberValue{
  width:40px;
    border:none;
    text-align: center;
    height:28px;
    margin:0 10px;
  border:1px solid rgb(230,230,230);
  border-radius: 5px;
  }
  
  .AddTripButton{
    padding:5px 10px;
    border:1px solid rgb(230,230,230);
    border-radius: 5px;
    background-color: white;
    color:rgb(100,100,100);
    font-weight: 500;
  font-size: 1.1em;
  cursor: pointer;
  }
  .NewPlaceButton{
    padding:5px 10px;
    border:1px solid rgb(230,230,230);
    border-radius: 5px;
    background-color: white;
    color:rgb(100,100,100);
    font-weight: 500;
  font-size: 1.1em;
  cursor: pointer;
  }
  
  .PlanSelectionSection{
    padding:10px 10%;
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  
  .PlanSelectSection{
    background-color: white;
    width: 95%;
    padding: 0 2.5%;
    border: 0.6px solid rgb(221,221,221);
    border-radius: 25px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  
  .InputText {
    width: 100%;
    resize: none;
    height: 40px;
    font-style: italic;
    border: none;
    outline: none;
    font-size: 1.2em;
    font-weight: 500;
    color: rgb(119, 119, 119);
  }
  
  .Suggestions {
    background-color: white;
    margin-top: 0px;
    display: flex;
    position: relative;
    margin: 0 2.5%;
    width: 95%;
    flex-direction: column;
    border: 0.8px solid rgb(221, 221, 221);
    z-index: 150;
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
    margin-bottom: -422px;
    font-size:0.9em;
  }
  
  .Suggestions::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .Suggestions::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }
  
  .Suggestions::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(168, 168, 168, 0.562);
  }
  
.PlanTop{
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
  }
  
  .SelectedPlan{
    font-size:0.9em;
    padding:10px 10%;
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .PlansWall{
    height:45vh;
    width:95%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 2.5%;
    border:0.6px solid rgb(223,223,223);
  }
  
  .ResetPlace{
    margin-top: 20px;
  width:auto;
  padding:5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border:1px solid rgb(230,230,230);
  font-size:1.1em;
  color:rgb(88,88,88);
  }
  
  .ResetPlace:hover{
    cursor: pointer;
    background-color: white;
    
  }
  
  .public-DraftStyleDefault-block{
    margin: 0px;
    line-height: 25px;
  }
  
  
  .VideoIcon{
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right:20px;
    background-color: white;
  }

  .VideoIconRTL{
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left:20px;
    background-color: white;
  }
  
  .VideoSection{
    width: 90%;
    padding: 0 5%;
    margin-top:20px;
  }
  
  .VideoBox{
      max-width: 100%;
      min-width: 100%;
     padding: 10px 0; 
  }
  .VideoUrlInput{
    width: 100%;
    outline: none;
    border: none;
  
    font-size: 1.1em;
    font-weight: 500;
    color: rgb(85, 85, 85);
    margin:5px 0;
    padding:5px 0;
    border-bottom: 1px solid rgb(228, 228, 228);
    resize: none;
  }
  
  .VideoUrlInput:focus {
    outline: none;
  }
  
  .UrlNotValid{
      color: #b22222;
      font-size:0.8em;
  }

  .ExperiencePlace{
    display:flex;
    align-items: flex-start;
    padding-bottom: 10px;
    width:80%;
    padding: 10px 10%;
  }
  
  .PlaceName{
    color:rgb(34,121,190);
    font-weight: 500;
    font-size:1.2em;
    position:relative;
    top:5px;
  }

  .Icon{
     
    height:30px;
    margin-right:10px;
  }
  
  .IconRTL{
    height:30px;
    margin-left:10px;
  }

  .PlaceNotExist{
    color:rgb(255,119,0);
    width:90%;
    padding: 10px 5%;
  }

  .DropdownButton {
  
    width: 16px;
    height: 16px;
    margin-right: 10px;
    padding-left: 10px;
    border-left: 1px solid rgb(194, 194, 194);
  }
  .DropdownButtonRTL{
    
    width: 16px;
    height: 16px;
    margin-left: 10px;
    padding-right: 10px;
    border-right: 1px solid rgb(194, 194, 194);
  }
  
  .TaggedUsers{
    display:flex;
    align-items: flex-end;
    color:rgb(88,88,88);
    padding-top:10px;
    border-top:1px solid rgb(223,223,223);
  }
  .UsersIcon{
    height: 35px;  
    margin-right: 20px;
}
.UsersIconRTL{
    height: 35px;  
    margin-left: 20px;
}

.UserImage{
  width:50px;
    border-radius: 50%;;
    margin:0 -2px;
}

.TagMessage{
  margin-right:15px;
  margin-bottom:5px;
}

.TagMessageRTL{
  margin-left:15px;
  margin-bottom:5px;
}

.ConnectToTripButton{
  color:rgb(34,121,190);
  border:1px solid rgb(223,223,223);
  border-radius: 5px;
  padding:5px 10px;
  margin-top:10px;
}

.BottomSection{
  border-top: 1px solid rgb(197, 197, 197);
  min-height: 7vh; 
  max-height: 7vh;
  position: absolute;
  bottom:0.5vh;
  display: flex;
  align-items: center;
  margin: 0.5vh 2.5%;
  background-color: white;
  z-index: 1;
  width:95%;
}

.BottomIcon{
  height:4vh;
  cursor: pointer;
  margin:0 10px;
}

  
  @media(min-width:1024px){
    .PlaceNotExist:hover{
      cursor: pointer;
    }
    .SelectedPrivacy{
    }
    .ExperiencePrivacy{
      width: 200px;
    }
    .DropdownButton:hover{
      cursor: pointer;
    }
    .DropdownButtonRTL:hover{
      cursor: pointer;
    }
    .UsersIcon:hover{
      cursor: pointer;
  }

  .UsersIconRTL:hover{
      cursor: pointer;
  }
  .ResetPlace:hover{
    cursor: pointer;
    background-color: white;
    
}
.ConnectToTripButton:hover{
  cursor: pointer;
    background-color: white;
}

  }

  @media(max-width:767px){
    .Modal{
      width:100%;
      left:0vw;
      height:100%;
      top:0vh;
      background-color: white;
    border-radius: 0;
      border: none;
      box-shadow: none;
    
    }

    .Body{
      height:75%;
      padding-bottom: 10vh;
    }
    .BottomSection{
      bottom:0%;
    }
    
    .ResetPlace{
      margin-top: 15px;
      margin-left:5px;
    padding:5px 10px;
    font-size:0.9em;
    }
    .TopDetails{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:95%;
    position: relative;
    left: 0%;
    padding:5px 2.5%;
    }

    .Date{
      top:0;
    }
    
    .Rank{
    
    height:40px;
    }
    .SelectedRankIcon{
      
      width:30px;
      height:30px;
      margin:0 15px;
      transform: scale(1.3);
    }
    .SelectedRankIcon:hover{
      cursor: pointer;
    }
    .VideoUrlInput{
      font-size: 1em;;
    }
    
    .RankIcon{
    width:30px;
    height:30px;
    margin:0 15px;
    }
    
    .SelectedPrivacy{
      width: auto;
      white-space: nowrap;
    padding: 5px 5px;
    border-radius: 5px;
    border:1px solid rgb(212, 212, 212);
    background-color: white;
    display:flex;
    align-items: center;
    }
    
    .Duration{
    display:flex;
    font-size:0.8em;
    }
    .ExperiencePrivacy{
    width:150px;
    margin:0;
    }
    
    .PrivacyOptions{
      width:130px;
    border:1px solid rgb(223,223,223);
    
    }
    .NumberInput{
      margin-top:5px;
    }
    .ImageButton{
      font-size: 0.9em;
    }
    .TextSection{
      font-size:0.9em;
    }
    .SelectedPlan{
      font-size:0.9em;
      padding:10px 0%;
      width:100%;
    }
    .PlaceSelection{
      padding:10px 0%;
      width:100%;
    }
    .InputText{
      font-size:1em;
      height:30px;
    }
    .PlanSelectSection{
      height: 40px;
    }
    .PlanSelectionSection{
      padding:10px 0;
      width:100%;
      display:flex;
      flex-direction: column;
      align-items: center;
    }
    .InfoSection{
      padding:10px 2.5%;
      width:95%;
    }
    .TopDetails{
      padding:0;
      width:100%;
    }
    .AddTripButton{
      font-size:0.9em;
    }
    .NewPlaceButton{
      font-size:0.9em;
    }
    .ExperiencePlace{
      width:100%;
      padding: 10px 0;
    }
    .CountrySelection{
      width:100%;
      padding: 10px 0;
    }
    .UsersIcon{
      height: 30px;  
      margin-right: 20px;
  }
  .UsersIconRTL{
      height: 30px;  
      margin-left: 20px;
  }
}


@media screen and (min-width: 768px) and (max-width:1023px){

  .Modal{
    width:100vw;
    left:0vw;
    height:100vh;
    top:0vh;
    background-color: white;
  border-radius: 0;
    border: none;
    box-shadow: none;
  
  }

 
  .Body{
    height:70vh;
    padding-bottom: 10vh;
    font-size: 1.1em;
  }
  .BottomSection{
    bottom:0%;
  }
  
  .ResetPlace{
    margin-top: 15px;
    margin-left:5px;
  padding:5px 10px;
  font-size:0.9em;
  }
  .TopDetails{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:95%;
  position: relative;
  left: 0%;
  padding:5px 2.5%;
  }
  
  .Rank{
  
  height:40px;
  }
  .SelectedRankIcon{
    
    width:30px;
    height:30px;
    margin:0 15px;
    transform: scale(1.3);
  }
  .SelectedRankIcon:hover{
    cursor: pointer;
  }
  .VideoUrlInput{
    font-size: 1em;;
  }
  
  .RankIcon{
  width:30px;
  height:30px;
  margin:0 15px;
  }
  
  .SelectedPrivacy{
    width: auto;
    white-space: nowrap;
  padding: 5px 5px;
  border-radius: 5px;
  border:1px solid rgb(212, 212, 212);
  background-color: white;
  display:flex;
  align-items: center;
  }
  
  .Duration{
  display:flex;
  align-items: center;
  font-size:1em;
  }
  .ExperiencePrivacy{
  width:250px;
  }
  
  .PrivacyOptions{
    width:200px;
  border:1px solid rgb(223,223,223);
  
  }
  .NumberInput{
    margin-top:5px;
  }
  .ImageButton{
    font-size: 0.9em;
  }
  .TextSection{
    font-size:0.9em;
  }
  .SelectedPlan{
    font-size:0.9em;
    padding:10px 0%;
    width:100%;
  }
  .PlaceSelection{
    padding:10px 0%;
    width:100%;
  }
  .InputText{
    font-size:1em;
    height:30px;
  }
  .PlanSelectSection{
    height: 40px;
  }
  .PlanSelectionSection{
    padding:10px 0;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .InfoSection{
    padding:10px 5%;
  }
  .TopDetails{
    padding:0;
    width:100%;
  }
  .AddTripButton{
    font-size:0.9em;
  }
  .NewPlaceButton{
    font-size:0.9em;
  }
  .ExperiencePlace{
    width:100%;
    padding: 10px 0;
  }
  .CountrySelection{
    width:100%;
    padding: 10px 0;
  }
}