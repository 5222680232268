.Body {
    width: 100%;
  }
  .Suggestions {
    background-color: white;
    margin-top: 0px;
    display: flex;
    position: relative;
    margin: 0 2.5%;
    width: 95%;
    flex-direction: column;
    border: 0.8px solid rgb(221, 221, 221);
    z-index: 150;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    margin-bottom: -322px;
  }

  .Suggestions::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .Suggestions::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }
  
  .Suggestions::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(168, 168, 168, 0.562);
  }
  
  .SearchValue{
    padding:10px 0;
    color:rgb(100,100,100);
    border-bottom: 1px solid rgb(223,223,223);
}
  .SubjectInputSection {
  }
  
  .PlaceSection {
    width: 100%;
    position: relative;
  }
  

  .PlaceSelectSection{
    
    background-color: white;
    width: 95%;
    padding: 0 2.5%;
    border: 0.6px solid rgb(221,221,221);
    border-radius: 25px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  
  .InputText {
    width: 100%;
    resize: none;
    height: 40px;
    font-style: italic;
    border: none;
    outline: none;
    font-size: 1.2em;
    font-weight: 500;
    color: rgb(119, 119, 119);
  }

  .InputRequired{
    border:1px solid #b22222;
    color:  #b22222;
  }

  @media (max-width: 1023px) {
    .Suggestions {
      height: 220px;
   
    }
    .InputText {
      height: 30px;
      font-size: 1em;
    }
    .PlaceInputSection{
      height:40px;
    }.PlaceSelectSection{
      height:40px;
    }
    .Suggestions{
 
    }
  }
  