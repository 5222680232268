.Modal{
    position:fixed;
    z-index: 50;
    width:25%;
    top:50px;
    height:calc(100% - 50px);
    right:-100%;
    -webkit-animation: slideLeft 1s forwards;
    animation: slideLeft 1s forwards;
    background-color: white;
}

@-webkit-keyframes slideLeft {
    100% { right:0; }
}

@keyframes slideLeft {
    100% {  right:0; }
}

.HideModal{
    top:7vh;
    height:calc(100% - 50px);
    background-color: white;
    position:fixed;
    z-index: 50;
    width:25%;
    right:0;
    transform: translateX(100vw);
    transition:0.7s
}


.RTL{
    direction: rtl;
    right:0;
}

.Body{
height:95%;
width:90%;
padding: 2.5% 5%;
}


.ToolOption{
    white-space: nowrap;
    display:flex;
      padding:7px 12px;
      border-bottom: 1px solid rgb(230,230,230);
      align-items: center;
      height:50px;
  }
 
  
  .ToolOptionRTL{
    white-space: nowrap;
    display:flex;
    padding:7px 12px;
    border-bottom: 1px solid rgb(230,230,230);
    height:50px;
    align-items: center;
    direction: rtl;
  }
  
  
  
  .Icon{
    height:25px;
    margin-right:15px;
  }
  
  .IconRTL{
    height:25px;
    margin-left:15px;
  }

  @media screen and (min-width: 1024px) {
    .ToolOption:hover{
        cursor: pointer;
        background-color: rgb(248,248,248);
    }
    .ToolOptionRTL:hover{
        cursor: pointer;
        background-color: rgb(248,248,248);
      }
  }

  @media screen and (max-width: 767px) {
    .Modal{
        width:80%;
    }

    .HideModal{
        width:80%;
    }
  }


  @media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        width:50%;
    }

    .HideModal{
        width:50%;
    }
  }