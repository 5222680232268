
html, body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.App {
  position:fixed;
  overflow-y: hidden;
  min-height: 100%;
  max-height: 100%;
  min-width:100%;
  max-width: 100%;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  overscroll-behavior-x: none;
}



.Wrapper{
  overflow-y: hidden;
  min-height: 100%;
  max-height: 100%;
  min-width:100%;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
/*   overscroll-behavior-y: contain; */
  align-items: center;
  overscroll-behavior-y: contain;
  overscroll-behavior-x: none;
}


.LoadingSection{
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  min-width:100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LoadingTop{
  position: relative;
top: 2.3vh;
left:-0.7vw;
display: flex;
justify-content: center;
align-items: center;
max-height: 30vh;
width:auto;
animation: top 1.5s ease-out forwards;
animation-iteration-count:infinite;
}
.LoadingBottom{
  position: relative;
  bottom: 2.3vh;
  right:-0.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30vh;
  width:auto;
  animation: bottom 1.5s ease-out forwards;
  animation-iteration-count:infinite;
}
@keyframes top {
  0% { top: 2.2vh; }
  50% { top: -3vh; }
  100% { top: 2.2vh;  }
}
 @keyframes bottom {
  0% {  bottom: 2.2vh; }
  50% {  bottom: -3vh; }
  100% {   bottom: 2.2vh;  }
}


@media (prefers-reduced-motion: no-preference) {

}

@media screen and (max-width: 767px) {
    .App{
      top:0%;
      left:0%;
      overscroll-behavior-y: contain;
    }

}