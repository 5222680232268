
.ModalRTL{
  direction: rtl;
}


.SelectedExperience{
  padding:20px 15%;
  width:70%;
  border-bottom: 0.6px solid rgb(223,223,223);
  margin-bottom:20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}



.PlanSelectionSection{
  padding:10px 10%;
  width:80%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.PlanSelectSection{
  background-color: white;
  width: 95%;
  padding: 0 2.5%;
  border: 0.6px solid rgb(221,221,221);
  border-radius: 25px;
  height: 50px;
  display: flex;
  align-items: center;
}

.InputText {
  width: 100%;
  resize: none;
  height: 40px;
  font-style: italic;
  border: none;
  outline: none;
  font-size: 1em;
  font-weight: 400;
  color: rgb(119, 119, 119);
}

.Suggestions {
  background-color: white;
  margin-top: 0px;
  display: flex;
  position: relative;
  margin: 0 2.5%;
  width: 95%;
  flex-direction: column;
  border: 0.8px solid rgb(221, 221, 221);
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
  margin-bottom: -422px;
  font-size:0.9em;
}

.Suggestions::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.Suggestions::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}

.Suggestions::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgba(168, 168, 168, 0.562);
}

.PlanTop{
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width:100%;
}

.SelectedPlan{
  font-size:0.9em;
  padding:10px 5%;
  width:90%;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.PlansWall{
  height:45vh;
  width:95%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 2.5%;
  border:0.6px solid rgb(223,223,223);
}

.ResetPlace{
  margin-top: 20px;
width:auto;
padding:5px 15px;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
background-color: rgb(248,248,248);
border:1px solid rgb(230,230,230);
font-size:1.1em;
color:rgb(88,88,88);
}

.ResetPlace:hover{
  cursor: pointer;
  background-color: white;
  
}


.DeleteIcon{
  width:20px;
}
.DeleteIcon:hover{
  cursor: pointer;
}

.DaySelectionSection{

}

.ChooseHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:50px;
  padding: 5px 0;
}
.ChooseMessage{
  padding:10px 5%;
  font-size:1.2em;
  color:rgb(100,100,100);
}

.CreateNewPlan{
  height:35px;
  padding: 5px 10px;
  margin-bottom: 20px;
  border:1px solid rgb(235,235,235);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CreateNewPlan:hover{
  color: rgb(70,70,70);
  background-color: rgb(245,245,245);
  cursor: pointer;
}

.ExperienceDateMessage{
  display:flex;
  flex-direction: column;
  padding:10px 2.5%;
  width:95%;
}

.Message{
  font-size:1.1em;
  color: #b22222;
}

.Date{
  border:1px solid rgb(225,225,225);
  padding:0 20px;
  position:relative;
top:5px;}

.UpdateDate{
    padding: 5px 10px;
    height:30px;
    margin-left:15px;
    border-radius: 5px;
    border:1px solid rgb(230,230,230);
    display:flex;
    align-items: center;
    justify-content: center;
}

.UpdateDate:hover{
    cursor: pointer;
}

.UpdateDateFade{
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  height:30px;
  margin-left:15px;
  border-radius: 5px;
  border:1px solid rgb(230,230,230);
  color:rgb(200,200,200);
}

.DateSection{
    position: relative;
    justify-content: space-between;
    padding-top:10px;
  width:100%;
  display:flex;
  align-items: center;
}

.SelectedPlace{
  padding:10px 20%;
  width:60%;
  border-bottom: 1px solid rgb(223,223,223);
}

.Body::-webkit-scrollbar
{
width:0;
}

.DropdownButton {
  
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding-left: 10px;
  border-left: 1px solid rgb(194, 194, 194);
}
.DropdownButtonRTL{
  
  width: 16px;
  height: 16px;
  margin-left: 10px;
  padding-right: 10px;
  border-right: 1px solid rgb(194, 194, 194);
}



@media screen and (min-width: 1024px) {
  .Modal{
    position:fixed;
    z-index: 200;
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 1px rgb(172, 172, 172,0.75);
    -moz-box-shadow: 0px 0px 7px 1px rgb(172, 172, 172,0.75);
    box-shadow: 0px 0px 7px 1px rgb(172, 172, 172,0.75);
    border: 1px solid rgb(218, 218, 218);
    width: 50vw;
    right: 25%;
    height: 90vh;
    top: 5vh;
  
    padding: 0.5px;
    border-radius: 20px;
  }
  .Body{
    height:81vh;
    overflow-y: auto;
  }
  .DropdownButton:hover{
    cursor: pointer;
  }
  .DropdownButtonRTL:hover{
    cursor: pointer;
  }
}


@media screen and (max-width: 767px) {
  .Modal{
    position:fixed;
    z-index: 200;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .Body{
    width:96%;
    padding: 0 2%;
    height:80vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.SelectedExperience{
  padding:20px 0%;
  width:100%;
  border-bottom: 0.6px solid rgb(223,223,223);
  margin-bottom:20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}


.SelectedPlace{
  padding:10px 0;
  width:100%;
  border-bottom: 1px solid rgb(223,223,223);
}



.SelectedPlan{
    font-size:1.2em;
    
}

.DeleteIcon{
    width:16px;
}
.DeleteIcon:hover{
    cursor: pointer;
}

.DaySelectionSection{

}

.ChooseMessage{
    padding:10px 0;
    font-size:1.1em;
    font-weight: 500;
}
.CreateNewPlan{
    height:30px;
    padding: 3px 5px;
   font-size:0.9em;
}
  
.InputText {
width: 95%;
margin: 0 2.5%;
font-size: 1.1em;
font-weight: 400;
}
.ExperienceDateMessage{
flex-direction: column;
}
.Message{
font-size:1em;
color: #b22222;
}
.PlanTop{
display:flex;
align-items: center;
flex-direction: column;
justify-content: flex-start;
}
.SelectedPlan{
font-size:0.9em;
padding:10px 0%;
width:100%;
}
.PlanSelectionSection{
font-size:0.9em;
padding:10px 0%;
width:100%;
display:flex;
flex-direction: column;
align-items: center;
}
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position:fixed;
    z-index: 800;
    background-color: white;
    width: 100%;
    height: 100vh;
    top: 0;
  }
  .Body{
    width:96%;
    padding: 0 2%;
    height:80vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.SelectedExperience{
  padding:20px 10%;
  width:80%;
  border-bottom: 0.6px solid rgb(223,223,223);
  margin-bottom:20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}


.SelectedPlace{
  padding:10px 0;
  width:100%;
  border-bottom: 1px solid rgb(223,223,223);
}



.SelectedPlan{
    font-size:1.2em;
    
}

.DeleteIcon{
    width:16px;
}
.DeleteIcon:hover{
    cursor: pointer;
}

.DaySelectionSection{

}

.ChooseMessage{
    padding:10px 0;
    font-size:1.1em;
    font-weight: 500;
}
.CreateNewPlan{
    height:30px;
    padding: 3px 5px;
   font-size:0.9em;
}
  
.InputText {
width: 95%;
margin: 0 2.5%;
font-size: 1.1em;
font-weight: 400;
}
.ExperienceDateMessage{
flex-direction: column;
}
.Message{
font-size:1em;
color: #b22222;
}
.PlanTop{
display:flex;
align-items: center;
flex-direction: column;
justify-content: flex-start;
}
.SelectedPlan{
font-size:0.9em;
padding:10px 0%;
width:100%;
}
.PlanSelectionSection{
font-size:0.9em;
padding:10px 5%;
width:90%;
display:flex;
flex-direction: column;
align-items: center;
}
}

