.Body{
    position: relative;
}
.BodyRTL{
    direction: rtl;
    position: relative;
}

.Wall{
    min-width: 100%;
    position:relative;
    height:100%;
}

.PlacesWall{
    display: flex;
   position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(250,250,250);
  }

  .Toolbar{
    background-color: white;
    display:flex;
    flex-direction: column;
    height:auto;
    width:90%;
    position:relative;;
    padding: 2vh 5%;
    color:rgb(100,100,100)
}

.SearchBarSection{
    display:flex;
    align-items: flex-start;
    position:relative;;
}

.SearchBarSectionRTL{
    display:flex;
    align-items: flex-start;
    direction: rtl;
    position:relative;;
  }

  .TagsList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width:70%;
    padding: 10px 15%;
}

.NoContentMessage{
    text-align: center;
    font-size:1.2em;
    width:100%;
    color:rgb(150,150,150);
}

.PlansWall{
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: rgb(250,250,250);
  }


.PlansWall::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}



@media screen and (min-width: 1024px) {
   
    .SearchBarSection{
        left:30%;
        width:40%;
        font-size: 0.9em;
    }
    .SearchBarSectionRTL{
        right:30%;
        width:40%;
        font-size: 0.9em;
    }
   
    .PlacesWall{
        width: 90%;
        padding: 0 5%;
        height: 83vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .PlansWall{
        width: 70%;
        padding: 0 15%;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

@media screen and (max-width: 767px) {
    .SearchBarSection{
        position:relative;
        right: -5%;;
        width:90%;
    }
    .SearchBarSectionRTL{
        position:relative;
       left:-5%;
        width:90%;
    }
    .Toolbar{
        width:90%;
        padding: 1vh 5%;
        display:flex;
        align-items: flex-start;
        top:0;
        font-size:0.8em;
    }
    .PlacesWall{
        width: 100%;
        padding: 0;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
     
      .PlansWall{
        width: 100%;
        padding: 0;
        height: 65vh;
        padding-bottom: 15vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .SearchBarSection{
        position:relative;
        left:15%;
        width:80%;
    }
    .SearchBarSectionRTL{
        position:relative;
        left:5%;
        width:80%;
    }
    .Toolbar{
        width:70%;
        padding: 1vh 15%;
        display:flex;
        align-items: center;
        top:0;
        font-size:0.8em;
    }
    .PlacesWall{
        width: 100%;
        padding: 0;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .PlansWall{
        width: 90%;
        padding: 0 5%;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
}