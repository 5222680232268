.Modal{
    position: fixed;
    left: 20%;
    top: 10vh;
    width: 60%;
  
    border: 0.6px solid rgb(180, 180, 180);
    box-shadow: 5px 5px 10px rgb(172, 172, 172);
    border-radius: 20px;
    z-index: 880;
    background-color: rgb(255, 255, 255);
    display: flex;
  flex-direction: column;
  }
  
  .Body{
    width:80%;
    padding: 0 10%;
    margin: 2vh 0;
    height:70vh;
    overflow-x: hidden;
    overflow-y: auto;
    color: rgb(95, 95, 95);
  }

  .Body::-webkit-scrollbar
  {
    width: 0;
    background-color: #F5F5F5;
  }

  .PlanPrivacy{
    padding-bottom: 5vh;
  }

  .Title{
    font-size:1.2em;
    color:rgb(70,70,70);
  }

  .PrivacyHeader{}
  
  .PrivacySelection{
    position:relative;
    width:162px;
    margin-top:10px;
  }

  .Sperator{
    width:100%;
    height:2px;
    border-top:1px solid rgb(223,223,223);
    padding-bottom:5vh;
  }
  
  .SelectedPrivacy{
    width: auto;
  padding: 5px 15px;
  border-radius: 5px;
  border:1px solid rgb(212, 212, 212);
  background-color: white;
  display:flex;
  align-items: center;
  }
  .SelectedPrivacy:hover{
    cursor: pointer;
  }
  
  .PrivacyOptions{
    position: absolute;
    z-index: 300;
    width: 150px;
    border:1px solid rgb(223,223,223);
    border-radius: 6px;;
    padding: 5px;
    background-color: white;
    
  }
  
  .PrivacyOption{
    color:rgba(79, 95, 138);
    padding:3px 7px;
    margin: 2px 0;
    background-color: rgba(255, 255, 255, 0.062);
  }

  .PrivacyOptionDisabled{
    color:rgb(172, 172, 172);
    padding:3px 7px;
    margin: 2px 0;
    background-color: rgba(255, 255, 255, 0.062);
  }
  
  .PrivacyOption:hover{
    cursor: pointer;
    background-color: rgba(79, 95, 138, 0.120);
  }
  
  .PrivacyIcon{
    position: relative;
    right:7px;
    top:2px;
    width:25px;
  }

  .PrivacyIconRTL{
    position: relative;
    left:7px;
    top:2px;
    width:25px;
  }

  .PlanHasNoPlacesMessage{
    color:#b22222;
    padding: 5px 0;
  }
  
  .DescriptionSection{
    font-style: unset;
    width: 99%;
      margin-top: 5px;
    
      height: auto;
      padding: 0 0.5%;
      padding-bottom: 10px;
      padding-top: 15px;
      min-height: 100px;
      font-size:1.1em;
      color: rgb(100,100,100);
      font-weight: 450;
      direction: ltr;
      border-radius: 5px;
      border:1px solid rgb(223,223,223);
  }

  .DescriptionInputField{
    direction: ltr;
  }
  .DescriptionInputFieldRTL{
    direction: rtl;
  }

  .PublicPlanInfo{
    width:100%;
    margin:5vh 0;
  }
  @media screen and (min-width: 1024px) {  
    .Modal{
      min-height: 80vh;
      max-height: 80vh;
    }
}


@media(max-width:767px){
  .Modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
   border-radius: 0;;
    border:none;
}

.Body{
    width:96%;
    padding: 20px 2%;
    height:80vh;
    padding-bottom: 5vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.DescriptionSection{
  font-style: unset;
  width: 99%;
  margin-top: 5px;

  height: auto;
  padding: 0 0.5%;
  padding-bottom: 10px;
  padding-top: 15px;
  min-height: 100px;
  font-size:0.8em;
  color: rgb(100,100,100);
  font-weight: 400;
  direction: ltr;
}

}

    
@media screen and (min-width: 768px) and (max-width:1023px){
.Modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;;
  border:none;
}

.Body{
  width:90%;
  padding: 20px 5%;
  height:80vh;
  padding-bottom: 5vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.DescriptionSection{
  font-style: unset;
  width: 99%;
  margin-top: 5px;

  height: auto;
  padding: 0 0.5%;
  padding-bottom: 10px;
  padding-top: 15px;
  min-height: 100px;
  font-size:0.8em;
  color: rgb(100,100,100);
  font-weight: 400;
  direction: ltr;
}

}