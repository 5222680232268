.Body{
    position:fixed;
    z-index: 800;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    border-radius:20px;
    border: 1px solid rgb(211, 211, 211);
    width: 30vw;
    left: 35vw;
    height: 80vh;
    top: 10vh;
    overflow-y: auto;
    padding: 0.5px;
    
}

.Content{
    padding: 20px 20px;
    height: 67vh;
    position: relative;
}

.UsersSection{
    width: 95%;
    padding: 0 2.5%;
    margin-top: 15px;
}

.ContentSelections{
    padding-top:10px;
}

@media screen and (max-width: 767px){
    .Body{
         position:fixed;
    z-index: 800;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0;
    border: 1px solid rgb(211, 211, 211);
    width:100%;
    left: 0;
    height: 100%;
    top: 0;
    overflow-y: auto;
   
    }
    .Content{
        padding: 7px;
        height:80vh;
        padding: 0 5%;
        padding-bottom: 5vh;
        width: 90%;
    }
}


@media screen and (min-width: 768px) and (max-width:1023px){
    .Body{
        position:fixed;
        z-index: 800;
        background-color: white;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
          0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
        border-radius: 0;
        border: 1px solid rgb(211, 211, 211);
        width:100%;
        left: 0;
        height: 100%;
        top: 0;
        overflow-y: auto;
    }
    .Content{
        padding: 7px;
        height:80vh;
        padding: 0 10%;
        padding-bottom: 5vh;
        width: 80%;
    }
}