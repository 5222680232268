.Modal{
  position: fixed;
  height:60vh;
  width:30vw;
  left:35vw;
  top:15vh;
  z-index:900;
background-color: white;
  border:0.7px solid rgb(223,223,223);
  border-radius:20px;
}

.Body {
    width: 95%;
    padding: 0 2.5%;
    overflow-y: auto;
    height: 85vh;
   
  }
  
  .TopSection {
    width: 95%;
    padding: 5px 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(30, 38, 51);
    height: 40px;
  }
  
  
  .Header {
    color: rgb(255, 255, 255);
    font-size: 1.2em;
  }
  
  .SaveButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 30px;
    background-color: rgb(44, 186, 143);
    color: white;
    border-radius: 10px;
    font-size: 1.3em;
  }
  
  .InputSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    margin-top: 30px;
    font-size: 1.3em;
  }
  
  
  .LinkInputSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  
  .LinkUrlInput {
    border: none;
    font-size: 1.1em;
    width: 80%;
    margin-right: 15px;
    border-bottom: 0.8px solid rgb(201, 201, 201);
    color:  rgb(75, 75, 75);
  }
  .LinkUrlInput:focus {
    outline: none;
  }
  

  
  .InvalidUrl {
    color: #b22222;
    font-size: 0.9em;
    position: relative;
    top: -7px;
  }
  
  .AddButton {
    display:flex;
    justify-content: center;
    width:100%;
    margin:15px 0;
   }
   
  
 
  
  .LinkObject {
    font-size: 0.2em;
    color:  rgb(30, 38, 51);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 5px;
    border-bottom: 0.8px solid rgb(221, 221, 221);
  }
  
  .LinkObject:hover {
   
  }
  
  .LinksSection {
    margin-top: 30px;
  }
  
  @media (max-width: 500px) {
    .Body {
      height: 85vh;
    }
    .TopSection {
      height: 30px;
    }
    .SaveButton {
      width: 70px;
      height: 25px;
      font-size: 1.1em;
    }
    .InputSection {
      font-size: 1em;
    }

    .InvalidUrl {
      color: #b22222;
      font-size: 0.9em;
    }
  
    .LinkName {
      font-size: 1em;
    }

    .LinksSection {
      margin-top: 15px;
    }
    .LinkObject {
      font-size: 1em;
      height: 45px;
    }
    .LinkInputSection {
      margin-bottom: 10px;
    }
  }
  .Body {
    width: 95%;
    padding: 0 2.5%;
    overflow-y: auto;
    height: 80vh;
  }
  
  .TopSection {
    width: 95%;
    padding: 5px 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(30, 38, 51);
    height: 40px;
  }
  
  .Header {
    color: rgb(255, 255, 255);
    font-size: 1.2em;
  }
  
  .SaveButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 30px;
    background-color: rgb(44, 186, 143);
    color: white;
    border-radius: 10px;
    font-size: 1.3em;
  }
  
  .InputSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    margin-top: 30px;
    font-size: 1em;
  }

  .LinkTitle{
    color:rgb(100,100,100);
    width:80px;
  }
  
  .LinkNameInput {
    border: none;
    font-size: 1.1em;
    width: 80%;
    margin-right: 15px;
    color: rgb(90, 90, 90);
    border-bottom: 0.7px solid rgb(210,210,210);
  }
  .LinkNameInput:focus {
    outline: none;
  }
  
  .LinkInputSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  
  .LinkName {
      display: flex;
    font-size: 1.1em;
    width: 100%;
  }
  
  .InvalidUrl {
    color: #b22222;
    font-size: 0.9em;
  }
  

  

  
  .Icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 25px;
  }

  .IconRTL{
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-right: 25px;
  }
  
  .LinkObject {
    font-size: 1.2em;
    color: rgb(30, 38, 51);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    border-bottom: 1px solid rgb(221, 221, 221);
    margin-bottom: 10px;
  }
  
  .LinkObject:hover {
    cursor: pointer;
    background-color:rgb(250, 250, 250);
  }
  
  .LinksSection {
    margin-top: 30px;
  }
  
  @media (max-width: 767px) {
    .Modal{
      position: fixed;
      height:100vh;
      width:100vw;
      padding:0;
      left:0;
      top:0;
      z-index:900;
    background-color: white;
      border:0.7px solid rgb(223,223,223);
      border-radius:6px;
    }
    
    .Body {
      height: 85vh;
    }
    .TopSection {
      height: 30px;
    }
    .SaveButton {
      width: 70px;
      height: 25px;
      font-size: 1.1em;
    }
    .InputSection {
      font-size: 1em;
    }
    .LinkNameInput {
      font-size: 1em;
      width: 100%;
    }
    .LinkUrlInput {
      font-size: 1em;
    }
    .InvalidUrl {
      color: #b22222;
      font-size: 0.9em;
    }
  
    .LinkName {
      font-size: 1em;
    }
  
    .Icon {
      width: 15px;
      height: 15px;
      margin-left: 15px;
    }
    .LinksSection {
      margin-top: 15px;
    }
    .LinkObject {
      font-size: 1em;
      height: 45px;
    }
    .LinkInputSection {
      margin-bottom: 10px;
    }
    .LinkTitle{
      color:rgb(100,100,100);
      max-width:60px;
      min-width:60px;
    }
    
  }
  
  @media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
      position: fixed;
      height:70vh;
      width:60vw;
      padding:0;
      left:20vw;
      top:15vh;
      z-index:900;
    background-color: white;
      border:1px solid rgb(223,223,223);
      border-radius:10px;
    }
    
    .Body {
      height: 60vh;
    }
    .TopSection {
      height: 30px;
    }
    .SaveButton {
      width: 70px;
      height: 25px;
      font-size: 1.1em;
    }
    .LinkInputSection{
      height:40px;
    }
    .InputSection {
      font-size: 1.1em;
    }
    .LinkNameInput {
      font-size: 1.1em;
      width: 100%;
    }
    .LinkUrlInput {
      font-size: 1.1em;
    }
    .InvalidUrl {
      color: #b22222;
      font-size: 0.9em;
    }
  
    .LinkName {
      font-size: 1.1em;
    }
  
    .Icon {
      width: 15px;
      height: 15px;
      margin-left: 15px;
    }
    .LinksSection {
      margin-top: 15px;
    }
    .LinkObject {
      font-size: 1em;
      height: 45px;
    }
    .LinkInputSection {
      margin-bottom: 10px;
    }
    .LinkTitle{
      color:rgb(100,100,100);
      max-width:60px;
      min-width:60px;
    }
    
  }