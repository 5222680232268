.Modal{
    position: fixed;
    z-index: 700;
    left: 0;
    right:0;
    top:0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;;
    max-height: 100%;;
    background-color: rgba(255, 255, 255, 1);
}

.CloseIcon{
    width:30px;
    padding:10px;
    border:1px solid rgb(240,240,240);
    border-radius:50%;
    cursor: pointer;
    position: absolute;
    z-index:20;
    top: 5vh;
    right:3vw;
}


.ImageViewer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    max-width:90vw;
    min-width:90vw;
    max-height:90vh;
    min-height:90vh;
    padding: 5vh 5vw;
    top:0;
}

.ImagesSection{
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    max-width:90vw;
    min-width:90vw;
    max-height:90vh;
    min-height:90vh;
}


.ArrowsSection{
    min-width:95%;
    padding: 0 2.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:absolute;
    top: 45vh;
}


.Arrow{
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 1);

    box-shadow: 0 0 0 1px #c5c5c58e;
    width:40px;
    height:40px;
    padding:10px;
    position:relative;
    z-index: 10;
}

.RotateArrow{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}



.Image{
    max-width:80vw;
    max-height:80vh;
    border: solid 1.5em rgba(#000, .03);
    object-fit: contain;
    position: relative;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    z-index: 1;
  
}


.ImageBullets{
    position: absolute;
    bottom:5vh;
   height:25px;
   padding: 5px 0;
   width:100%;
   display: flex;
   align-items: center;
   justify-content: center;

}

.Bullet{
width:15px;
height:15px;
margin:0 5px;
}
.Bullet:hover{
    cursor: pointer;
}

.SelectedBullet{
    border-radius:50%;
    background-color: rgb(255,119,0);
    width:15px;
height:15px;
}

.UnselectedBullet{
    border-radius:50%;
    border:0.7px solid rgba(97, 97, 97, 0.527);
    background-color: white;
    width:15px;
height:15px;
}

@media screen and (min-width: 1024px) {
    .Arrow:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px) {
    .Modal{
        background-color: rgb(255, 255, 255);
    }
    .CloseIcon{
        width:20px;
        padding:10px;
        border:1px solid rgb(211, 211, 211);
        top: 5px;
        right:5px;
    }
    .ImagesSection{
        min-width: 100%;
        max-width: 100%;
        max-height:90%;
      
        min-height:90%;
    }
    .ImageViewer{
        min-width: 100%;
    max-width: 100%;
        max-height:90%;
      
        min-height:90%;
        padding: 0;
        top:0%;
    }
    .Image{
        min-width: 100%;
        max-width: 100%;
        max-height:90%;
    }
    .ArrowsSection{
        top:40vh;
    }
    .Arrow{
        width:30px;
        height:30px;
        box-shadow: none;
    }
    .ImageBullets{
        bottom:10vh;
        z-index: 10;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        background-color: rgb(255, 255, 255);
    }
    .CloseIcon{
        width:20px;
        padding:10px;
        border:1px solid rgb(211, 211, 211);
        top: 5px;
        right:5px;
    }
    .ImagesSection{
        max-width:100vw;
        min-width:100vw;
        max-height:90vh;
        min-height:90vh;
    }
    .ImageViewer{
        max-width:100%;
        min-width:100%;
        max-height:90%;
        min-height:90%;
        padding: 0;
        top:5%;
    }
    .Image{
        max-width:100%;
        max-height:90vh;
    }
    .Arrow{
        width:40px;
        height:40px;
        box-shadow: none;
        border:1px solid rgb(223,223,223);
    }
    .ImageBullets{
        bottom:10vh;
        z-index: 10;
    }
}

