.RTL{
    direction: rtl;
}

.Body{
    width:100%;
   
    margin-top:5vh;
    background-color: rgb(247, 247, 247);
    border-top:1px solid rgb(230,230,230);
 /*    box-shadow: 0px 4px 8px #888888; */
}

.ContentWall{
    padding: 0 7.5%;
    width:85%;
    height:90vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.ContentWall::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}


.Header{
    padding:10px 0;
    font-size:1.5em;
    color:rgb(88,88,88);
}

.Filters{
    display: flex;
    align-items: center;
    width:130%;
    right:15%;
    position: relative;
    padding:7px 15%;
    z-index: 1;
    background-color: white;
    border-top:1px solid rgb(211, 211, 211);
    border-bottom:1px solid rgb(211, 211, 211);
}

.FiltersFade{
    display: flex;
    align-items: center;
    width:130%;
    right:15%;
    position: relative;
    padding:7px 15%;
    z-index: 1;
    background-color: white;
    border-top:1px solid rgb(211, 211, 211);
    border-bottom:1px solid rgb(211, 211, 211);
    color:rgb(150,150,150);
}
.FiltersRTL{
    display: flex;
    align-items: center;
    width:130%;
    left:15%;
    position: relative;
    padding:7px 15%;
    z-index: 1;
    background-color: white;
    border-top:1px solid rgb(211, 211, 211);
    border-bottom:1px solid rgb(211, 211, 211);
}

.FiltersRTLFade{
    display: flex;
    align-items: center;
    width:130%;
    left:15%;
    position: relative;
    padding:7px 15%;
    z-index: 1;
    background-color: white;
    border-top:1px solid rgb(211, 211, 211);
    border-bottom:1px solid rgb(211, 211, 211);
    color:rgb(150,150,150);
}

.FriendsExperiences{
    display: flex;
    align-items: center;
    margin: 0 20px;
}


.CheckIcon{
    margin-right:12px;
    width:26px;
    height:26px;
    margin-right:15px;
  }

  .CheckIconRTL{
    margin-left:12px;
    width:26px;
    height:26px;
   margin-left: 15px;
  }

  
  .UncheckBox{
    margin-right:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
    margin-right:15px;
  }

  .UncheckBoxRTL{
    margin-left:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
    margin-left: 15px;
  }

  .UncheckBoxFade{
    margin-right:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(170, 170, 170);
    margin-right:15px;
  }

  .UncheckBoxRTLFade{
    margin-left:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(170, 170, 170);
    margin-left: 15px;
  }
  
@media screen and (min-width: 1024px) {  
    .CheckIcon:hover{
        cursor: pointer;
    }
    .CheckIconRTL:hover{
        cursor: pointer;
    }
    .UncheckBox:hover{
        cursor: pointer;
    }
    .UncheckBoxRTL:hover{
        cursor: pointer;
    }
}


@media screen and (max-width: 767px) {
    .Body{
        height:100%;
    }
    .ContentWall{
        padding: 0;
        width:100%;
        height:100%;
        min-height: 20vh;;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .Header{
        padding:10px 2.5%;
        font-size:1.5em;
        color:rgb(88,88,88);
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .ContentWall{
        padding: 0;
        width:100%;
        height:70vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .Header{
        padding:10px 2.5%;
        font-size:1.5em;
        color:rgb(88,88,88);
    }
}
