.RTL{
    direction: rtl;
}


.Modal{
    z-index: 60;
    position: fixed;
    background-color: white;
}

.Body{
    width: 100%;
    padding: 0;
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
}

.Body::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}


.UserProfileSection{
    z-index: 10;
    position: relative;
    -webkit-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
    -moz-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
    box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
}

.UserProfile{
    display:flex;
    align-items: flex-end;
    justify-content: space-between;
}

.ProfileInfo{
    display:flex;
    align-items: flex-end;
}

.ProfileImage{

}

.ProfileImageRTL{

}

.DisplayName{

}

.UserTools{
    display:flex;
    align-items: center;

}

.SelectionTabs{
    display:flex;
    align-items: center;
}

.WallContentSelection{
    display:flex;
    position: absolute;;
    bottom:1vh;
    width:100%;
    align-items: flex-start;
}

.Toolbar{
    background-color: white;
    display:flex;
    flex-direction: column;
    height:auto;
    width:35%;
    margin: 0 5%;
    color:rgb(100,100,100)
}

.SearchBarSection{
    display:flex;
    align-items: flex-start;
    position:relative;;
}

.SearchBarSectionRTL{
    display:flex;
    align-items: flex-start;
    direction: rtl;
    position:relative;;
  }

.Remove{
    border-radius: 5px;
    background-color: rgba(199, 199, 199, 0.185);
    padding: 5px 10px;
    color: white;
    color: rgb(41, 63, 109);
    margin:0 7px;
}

.Friend{
    color:rgb(8, 109, 8);
    margin:0 7px;
}


.AskRequest{
    border-radius: 5px;
    background-color: rgba(34, 120, 190, 0.185);
    padding: 5px 10px;
    color: white;
    color: rgb(41, 63, 109);
    margin:0 6px;
}

.AskRequest:hover{
    cursor: pointer;
  font-weight: 500;
}

.AskFollow{
    border-radius: 5px;
    background-color: rgba(44, 139, 57, 0.185);
    padding: 5px 10px;
    color: white;
    color: rgb(19, 110, 23);
    margin:0 6px;
}

.AskFollow:hover{
    cursor: pointer;
    font-weight: 500;
}

.Wall{
    padding-top:5vh;
    background-color: rgb(250,250,250);
}

.LeanToolbar{
    position:fixed;
    z-index: 10;
    background-color: white;
    -webkit-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
    -moz-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
    box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
}

.TagsList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;

}

.PlansWall{
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: rgb(250,250,250);
  }

  .ExperiencesWall{
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: rgb(250,250,250);
  }

  .Seperator{
    min-height:4vh;
    border-right:1px solid rgb(214, 214, 214);
    width:2px;
  }

@media screen and (min-width: 1024px) {  
    .Modal{
        height:100vh;
        width:100vw;
        z-index: 70;
    }
    .Body{
        height:90vh;
    }

  
    .UserProfileSection{
        width:70%;
        padding:2vh 15%;
    }

    .ProfileSectionHeight{
        height:26vh;
    }

    .ProfileSectionHeightExpand{
        height:32vh;
    }

    .ProfileImage{
        height:15vh;
        border-radius: 50%;
        margin-right:25px;
    }

    .ProfileImageRTL{
        height:15vh;
        border-radius: 50%;
        margin-left:25px;
    }

    .DisplayName{
        font-size: 1.3em;
        color:rgb(100,100,100);
    }

    .SearchBarSection{
        left:0%;
        width:100%;
        font-size: 0.9em;
    }
    .SearchBarSectionRTL{
        right:0%;
        width:100%;
        font-size: 0.9em;
    }
    .Wall{
        min-width: 100%;
        height:100%;
    }
    .Remove:hover{
        cursor: pointer;
      font-weight: 500;
    }
    .Accept:hover{
        cursor: pointer;
      font-weight: 500;
    }
    .ExperiencesWall{
        width:80%;
        padding:0 10%;
    }
    .PlansWall{
        width:70%;
        padding:0 15%;
    }

    .LeanToolbar{
        top:7vh;
        width:70%;
        height:8vh;
        padding: 0 15%;
    }
}


@media screen and (max-width: 767px) {
    .Modal{
        z-index: 60;
        position: fixed;
        background-color: white;
        height:100vh;
        width:100vw;
    }

    .Body{
        width: 100%;
        padding: 0;
        height:82vh;
        display: flex;
        position: relative;
         flex-wrap: wrap;
         overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .UserProfileSection{
        width:95%;
        padding:2vh 2.5%;
    }
    .UserProfile{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .ProfileInfo{
        width:100%;

    }
    .UserTools{
        margin-top:20px;
    }
    
    .ProfileImage{
        height:15vh;
        border-radius: 50%;
        margin-right:25px;
    }

    .ProfileImageRTL{
        height:15vh;
        border-radius: 50%;
        margin-left:25px;
    }

    .DisplayName{
        font-size: 1.3em;
        color:rgb(100,100,100);
    }
    .ProfileSectionHeight{
        height:40vh;
    }

    .ProfileSectionHeightExpand{
        height:50vh;
    }
    .WallContentSelection{
        flex-direction: column;

    }
    .SelectionTabs{
        display:flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .Toolbar{
        width:80%;
        margin: 0 5%;
    }
    .LeanToolbar{
        position:fixed;
        top:6.2vh;
        z-index: 20;
        background-color: white;
        width:95%;
        min-height:15vh;
        max-height:25vh;
        padding: 0 2.5%;
        padding-top:1vh;
        -webkit-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
        -moz-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
        box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
    }
  }

  @media screen and (min-width: 768px) and (max-width:1023px){

  }
  
