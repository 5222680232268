.Body{
    padding-bottom: 20vh;;
    width:100%;
    padding: 0%;
    background-color: rgb(255, 255, 255);
    position: relative;
}

@media screen and (min-width: 1024px) {
    .ViewsSelection{
        width:auto;
        display:flex;
        align-items: center;
        
    }   
    
    .TopSection{
        width:106%;
        position: relative;
        left:-3%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 5%;
        background-color: white;
    }
    
    .MapIconSection{
        position: absolute;
        left:20vw;
        top:10px;
        background-color: white;
        color:rgb(100,100,100);
        border:1px solid rgb(235,235,235);
        border-radius: 5px;
        padding:5px 15px;
        height:40px;
        display:flex;
        align-items: center;
        white-space: nowrap;
        z-index: 10;
      }
      .MapIconSection:hover{
        cursor: pointer;
        background-color: rgb(245,245,245);
      }
    
      .MapIcon{
        height:30px;
        margin-right:10px;
      }
    
      .CloseMapView{
        cursor: pointer;
        height:25px;
        padding:10px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top:10px;
        left:10px;
        z-index: 1;
      }
      
    
    .SelectAll{
        position: relative;
        left:50%;
        display: flex;
        align-items: center;
    }
    
    
    .SelectAll:hover{
        cursor: pointer;
    }
    
    
    .ViewSelector{
        display:flex;
        align-items: center;
        margin-right:25px;
        width:auto;
        
    }
    
    .CheckIcon{
        width:24px;
        margin-right:10px;
    }
    .CheckIcon:hover{
        cursor: pointer;
    }
    
    .SelectedContent{
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        width:100%;
       height:85vh;
        overflow:hidden;
     
    }
    
    .PlanDaysCalendar{
        min-width:62.5vw;
        height:80vh;
        background-color: white;
        overflow-y: hidden;
    }
    
    .PlanDaysCalendarWide{
        width:95vw;
        padding: 0 2.5vw;
        height:80vh;
        background-color: white;
        overflow-y: hidden;
    }
    
    .NarrowCalendarSection{
        width:35vw;
        height:72vh;
        background-color: white;
    }
    
    .MapSection{
        min-width:35vw;
        max-width: 35vw;
        margin-top:1.5vh;
        height:83vh;
        position:relative;
    }
    
    .HideDesktopMap{
        transform: translateY(-100vh);
      }
    
    .DiscoverSection{
        height:82vh;
        min-width:31.5vw;
        max-width:31.5vw;
        margin-right:1vw;
        margin-left:2.5vw;
        display: flex;
        align-items: flex-start;
        background-color: rgb(235, 235, 235);
        overflow: hidden;
    }
    
    .DiscoverResults{
        width: 95%;
        padding: 0 2.5%;
        overflow-y: hidden;
        overflow-x: hidden;
        height:82vh;
        background-color: white;
        position: relative;
    }
    
    .DiscoverResults::-webkit-scrollbar
    {
    width: 0;
    background-color: #F5F5F5;
    }
    
    .PlanDaysScroller{
        width:31.5vw;
        margin:0 1vw;
        background-color: white;
        height:80vh;
        padding:1vh 0;
        top:2vh;
        position:relative;
        display:flex;
        align-items: center;
        flex-direction: column;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .CurrentLocationIcon{
        cursor: pointer;
        position:absolute;
        right:15px;
        top:10px;
        z-index: 20;
        width:30px;
        height:30px;
        padding:10px;
        background-color: white;
        border-radius: 7px;
      }
}


@media screen and (max-width: 767px) {
    .SelectedContent{
        width:100%;
        height:75vh;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 10;
    }
    .Body{
        overflow-y: auto;
    }
    .DiscoverSection{
        height:82vh;
        min-width:100vw;
        max-width:100vw;
        margin:0;
        display: flex;
        background-color: rgb(235, 235, 235);
        overflow: hidden;
    }
    .DiscoverResults{
        width: 95%;
        padding: 0 2.5%;
        overflow-y: hidden;
        overflow-x: hidden;
        height:82vh;
        background-color: white;
        position: relative;
    }
    
    .DiscoverResults::-webkit-scrollbar
    {
    width: 0;
    background-color: #F5F5F5;
    }
    
    .MapSection{
        min-width:100vw;
        max-width:100vw;
        height:60vh;
        margin-top:0;
        position:relative;
    }

    .PlacesWall{
        direction: ltr;
        width:100%;
        min-height:18vh;
        max-height:18vh;
        padding: 0;
        z-index: 20;;
        top:-16vh;
        overflow-x: auto;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        overflow-y: hidden;
        background-color: transparent;
    }

    .HidePlacesWall{
        z-index: -20;;
    }
.PlanPlace{
    width:100%;
    min-height:18vh;
    max-height:18vh;
    padding: 0;
    bottom:0;
    overflow:hidden;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.411);
    border-bottom:1px solid rgb(99, 99, 99);
    z-index: 30;
    display: flex;
}
    
.Days{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    font-size:0.9em;
}
.DaysRTL{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    font-size:0.9em;
    direction: rtl;
}
.DaySelected{
    padding:7px 10px;
    margin:0 1px;
    color:white;
   /*  background-color: rgb(255,119,0); */
   min-width:40vw;
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    background-color: rgb(34,121,190);
   /*  background-image: linear-gradient(to right, rgba(255, 119, 0, 0.781) 0%, rgba(230, 107, 30, 0.712) 51%, rgba(235, 136, 75, 0.623) 100%) */
}
.Day{
    padding:7px 10px;
    margin:0 1px;
   /*  border:1px solid rgb(230,230,230); */
    min-width:40vw;
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    color:rgb(255, 255, 255);
    background-color: rgb(148, 148, 148);
  /*   color:rgb(90,90,90);
    background-image: linear-gradient(to right, rgba(194, 194, 194, 0.781) 0%, rgba(228, 228, 228, 0.712) 51%, rgba(231, 231, 231, 0.514) 100%) */
}
.CurrentLocationIcon{
    cursor: pointer;
    position:absolute;
    right:10px;
    top:15%;
    z-index: 20;
    width:30px;
    height:30px;
    padding:5px;
    background-color: white;
    border-radius: 5px;
  }
}


@media screen and (min-width: 768px) and (max-width:1023px){
    .SelectedContent{
        width:100%;
        height:75vh;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 10;
    }
    .Body{
        overflow-y: auto;
    }
    .DiscoverSection{
        height:82vh;
        min-width:100vw;
        max-width:100vw;
        margin:0;
        display: flex;
        background-color: rgb(235, 235, 235);
        overflow: hidden;
    }
    .DiscoverResults{
        width: 95%;
        padding: 0 2.5%;
        overflow-y: hidden;
        overflow-x: hidden;
        height:82vh;
        background-color: white;
        position: relative;
    }
    
    .DiscoverResults::-webkit-scrollbar
    {
    width: 0;
    background-color: #F5F5F5;
    }
    
    .MapSection{
        min-width:100vw;
        max-width:100vw;
        height:60vh;
        margin-top:0;
        position:relative;
    }

    .PlacesWall{
        direction: ltr;
        width:100%;
        min-height:18vh;
        max-height:18vh;
        padding: 0;
        z-index: 20;;
        top:-16vh;
        overflow-x: auto;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        overflow-y: hidden;
        background-color: transparent;
    }

    .HidePlacesWall{
        z-index: -20;;
    }
.PlanPlace{
    width:80%;
  
    min-height:18vh;
    max-height:18vh;
    padding: 0;
    padding:0 10%;
    bottom:0;
    overflow:hidden;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.411);
    border-bottom:1px solid rgb(99, 99, 99);
    z-index: 30;
    display: flex;
}
    
.Days{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    font-size:0.9em;
}
.DaysRTL{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    font-size:0.9em;
    direction: rtl;
}
.DaySelected{
    padding:7px 10px;
    margin:0 1px;
    color:white;
   /*  background-color: rgb(255,119,0); */
   min-width:40vw;
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    background-color: rgb(34,121,190);
  /*   background-image: linear-gradient(to right, rgba(255, 119, 0, 0.781) 0%, rgba(230, 107, 30, 0.712) 51%, rgba(235, 136, 75, 0.623) 100%) */
}
.Day{
    padding:7px 10px;
    margin:0 1px;
   /*  border:1px solid rgb(230,230,230); */
    min-width:40vw;
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    color:rgb(255, 255, 255);
    background-color: rgb(148, 148, 148);
   /*  color:rgb(90,90,90);
    background-image: linear-gradient(to right, rgba(194, 194, 194, 0.781) 0%, rgba(228, 228, 228, 0.712) 51%, rgba(231, 231, 231, 0.514) 100%) */
}
.CurrentLocationIcon{
    cursor: pointer;
    position:absolute;
    right:5%;
    top:15%;
    z-index: 20;
    width:35px;
    height:35px;
    padding:7px;
    background-color: white;
    border-radius: 5px;
  }
}