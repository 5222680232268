.Body{
    padding-bottom: 20vh;;
    width:100%;
    padding: 0;
    background-color: rgb(235, 235, 235);
    height:85vh;
    direction: ltr;
}


.TopSection{
    width:95%;
    padding: 5px 2.5%;
    background-color: white;
}


.Days{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    font-size:0.9em;
}
.DaysRTL{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    font-size:0.9em;
    direction: rtl;
}
.DaySelected{
    width:auto;
    min-width:40vw;
    height:auto;
    background-color: rgb(255,119,0);
    border:1px solid rgb(255,119,0);
    color:white;
    padding:5px 10px;
    margin:0 3px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.Day{
    width:auto;
    min-width:40vw;
    height:auto;
    border:1px solid rgb(231, 231, 231);
    padding:5px 10px;
    margin:0 3px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.MapSection{
    min-width:100%;
    max-width: 100%;
    height:75vh;
}

.PlacesWall{
    width:100%;
    min-height:18vh;
    max-height:18vh;
    padding: 0;
    top:-20vh;
    overflow-x: auto;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    overflow-y: hidden;
    background-color: transparent;
}

@media screen and (min-width: 768px) and (max-width:1023px){
.DaySelected{
    min-width:25vw;
}
.Day{
    min-width:25vw;
}
.Days{
    font-size: 1em;;

}
.DaysRTL{
    font-size: 1em;;
}
}