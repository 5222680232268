.RTL{
  direction: rtl;
}
.Modal{
  height:100%;
}


.Body {
    position: relative;
    width: 100%;
   
    overflow-y: auto;
    height: 75vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }

  .Content{
    width: 80%;
    padding: 0 10%;
  }



.Body::-webkit-scrollbar
{
	width: 0;
	background-color: #F5F5F5;
}

.BodyWide{
  width: 80%;
  padding: 0 10%;
  height: 72vh;
}

  .Autocomplete {
    width: 90%;
    margin: 20px 5%;
    position:relative;

  }
  
  .InputSection {
    height: 60px;
    width: 100%;
    font-weight: 400;
    align-items: center;
    display: flex;
    border-radius: 15px;
    border: 0.8px solid rgb(221, 221, 221);
  }

  .InputSectionWithSuggestions {
    height: 60px;
    width: 100%;
    color: rgb(88, 88, 88);
    align-items: center;
    border: 0.8px solid rgb(221, 221, 221);
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
  }
  
  
  .AutocompleteInput {
    color: rgb(88, 88, 88);
    border: none;
    margin: 0 1.5%;
    font-size: 1.1em;
    font-weight: normal;
    font-family: inherit;
    height: 90%;
    width: 95%;
  }
  .AutocompleteInput:focus {
    outline: none;
  }
  
  .CurrentLocation {
    font-size: 1.1em;
    color: rgb(88, 88, 88);
    border: 0.7px solid rgb(161, 161, 161);
    border-radius: 5px;
    height: 45px;
    width: 70%;
    margin-left: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .SuggestionsSection {
    
    display: flex;
    position: absolute;
    padding: 5px 2.5%;
    line-height: 2em;
    width: 95%;
    flex-direction: column;
    border: 0.8px solid rgb(221, 221, 221);
    z-index: 200;
    overflow-y: auto;
    height: 160px;
    margin-bottom: -180px;
    background-color: rgb(255, 255, 255);
    -webkit-border-bottom-left-radius: 15px;
    -webkit-border-bottom-right-radius: 15px;
    -moz-border-radius-bottomleft: 15px;
    -moz-border-radius-bottomright: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-bottom: 15px;
  }

  .LocationInstructions{
    top:10vh;
    position: relative;
    width:70%;
    padding: 0 15%;
    text-align: center;
  }



  .InputTypeBar{
    display:flex;
    align-items: center;
    padding: 10px 0;
    width:100%;
    border-top:1px solid rgb(223,223,223);
    border-bottom: 1px solid rgb(223,223,223);
  }

  .InputTypeSelected{
    margin:0 1%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(34, 120, 190, 0.79);
    color:white;
    padding: 7px 1%;
    width:100%;
    font-weight: 400;
    font-size:1.1em;
  }
  .InputTypeSelected:hover{
    cursor: pointer;
  }

  .InputTypeRegular{
    justify-content: center;
    margin:0 1%;
    display:flex;
    align-items: center;
    padding: 7px 1%;
    width:100%;
    color:rgb(88,88,88);
    font-weight: 400;
    font-size:1.1em;
  }

  .InputTypeRegular:hover{
    cursor: pointer;
    background-color: rgb(245,246,245);
  }

  .InputType{
    display:flex;
    flex-direction: column-reverse;
    align-items: center;
    width:80%;
  }

  .TypeIcon{
    margin-bottom: 7px;
    height:30px;
  }
  @media (min-width: 1024px) {
    .Modal{
      border-radius: 20px;
      height:100vh;
    }
  }


  @media (max-width: 767px) {
    .Modal{
    
    }
    .Body {
      width:100%;
      padding:0;
      min-height: 85vh;
      max-height: 85vh;
    }


    .InputSection {
      height: 45px;
      border-radius: 15px;
    }.InputSectionWithSuggestions{
      height: 45px;
    }
    .InputType{
      font-size:0.7em;
      height:100%;
      justify-content: space-between;
    }
    .InputTypeSelected{
      margin:0 5px;
      padding: 6px;
      width:100%;
      height: 7vh;
    }
    .InputTypeRegular{
      margin:0 5px;
      padding: 6px;
      width:100%;
      height: 7vh;
    }
    .TypeIcon{
      margin-bottom: 0px;
      height:25px;
    }
  

    .AutocompleteInput {
      margin: 0 3%;
      width:92%;
      height:90%;
      padding: 2px 1%;

      font-size: 1em;
    }
    .CurrentLocation {
      margin-top: 20px;
      font-size: 0.9em;
      height: 35px;
      width: 80%;
      margin-left: 10%;
    }
    .LocationInstructions{
      width:100%;
      left:0;
    }
    .SuggestionsSection{
      font-size:0.9em;
      padding:0 2%;
      width:96%;

    }
  
  

  }

  @media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
    }
    .Body {
  
      min-height: 85vh;
      max-height: 85vh;
    }

    
  .InputTypeBar{
    display:flex;
    align-items: center;
    padding: 10px 0%;
    width:100%;
  }

    .InputSection {
      height: 45px;
      border-radius: 15px;
    }.InputSectionWithSuggestions{
      height: 45px;
    }
    .InputType{
      font-size: 0.9em;
      height:100%;
      text-align: center;
      align-items: center;
      justify-content: space-between;
    }
    .InputTypeSelected{
      margin:0 5px;
      padding: 6px;
      width:100%;
      height: 8vh;
    }
    .InputTypeRegular{
      margin:0 5px;
      padding: 6px;
      width:100%;
      height: 8vh;
    }
    .TypeIcon{
      margin-bottom: 5px;
      height:30px;
    }
  
  
    .AutocompleteInput {
      margin: 0 3%;
      width:92%;
      height:90%;
      padding: 2px 1%;

      font-size: 1em;
    }
    .CurrentLocation {
      margin-top: 20px;
      font-size: 0.9em;
      height: 35px;
      width: 80%;
      margin-left: 10%;
    }
    .LocationInstructions{
      width:100%;
      left:0;
    }
    .SuggestionsSection{
      font-size:0.9em;
      padding:0 2%;
      width:96%;

    }
  

  }
  