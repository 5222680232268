

.PlacesWall{
    display: flex;
   position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(250,250,250);
  }

.SearchBarSection{
    display:flex;
    align-items: flex-start;
    position: relative;
}

.SearchBarSectionRTL{
    display:flex;
    align-items: flex-start;
    position: relative;
    direction: rtl;
  }

  .ToolbarButton{
    display: flex;
    align-items: center;
    border:1px solid rgb(223,223,223);
    border-radius: 5px;
  }

  .ToolbarIcon{

  }

  .ToolbarIconRTL{

  }

  .ExperiencesWall{
    overflow-y: auto;
     width:80%;
     height:80vh;
     padding: 0 10%;
     display:flex;
     position: absolute;
     flex-direction: column;
     align-items: center;
     padding-bottom: 10vh;
     background-color: rgb(250,250,250);
   }
   .ExperiencesWall::-webkit-scrollbar
   {
   width: 0;
   background-color: #F5F5F5;
   }

   .PlansWall{
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: rgb(250,250,250);
  }

  .PlansWall::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}

.NoContentMessage{
  text-align: center;
  font-size:1.2em;
  width:100%;
  color:rgb(117, 117, 117);
  padding: 20px 0;
}

.EmptyWall{
  display:flex;
  flex-direction: column;
  align-items: center;
   background-color: rgb(250,250,250);
   height: 70vh;
   padding-bottom: 5vh;

}


.CallToAction{
position:relative;
top:3vh;
height:auto;
padding:7px 15px;
margin: 0 10px;
border-radius: 5px;
border: 1px solid rgb(223,223,223);
background-color: rgb(255, 255, 255);
font-size: 1.2em;
color:rgb(66,66,66);
}



.SuggestedTripsToolbar{
  display:flex;
  align-items: center;;
}



.SuggestedTripAction{
  position:relative;
  height:auto;
  padding:7px 12px;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid rgb(223,223,223);
  background-color: rgb(255, 255, 255);
  font-size: 1em;
  color:rgb(66,66,66);
  }
  
  .MobileSeperator{
    min-height:4vh;
    border-right:1px solid rgb(150, 150, 150);
    width:2px;
  }

@media screen and (min-width: 1024px) {
    .Body{
    }
    .BodyRTL{
        direction: rtl;
    }
    .TripsSelectionToolbar{
      display:flex;
      justify-content: space-between;
      width:80%;
    
      padding:0 10%;
      margin:3vh 0;
      margin-bottom:1vh;
      align-items: center;       
    }
    .TripSelectionSelected{
      padding:10px 15px;
      color: rgb(34,121,190);
      font-weight: 500;
      font-size:1em;
      margin:0 20px;
      border-bottom:2px solid rgb(34,121,190);
    }
    
    .TripsSelection{
     
      padding:10px 15px;
      border-bottom:2px solid rgb(223,223,223);
      color:rgb(88,88,88);
      font-weight: 400;
      font-size:1em;
      margin:0 20px;
    }
    .RegularToolBar{
      position:relative;
      display:flex;
      align-items: center;
      width:80%;
      z-index: 10;
      padding: 0.5vh 5%;
      padding-bottom: 0;
      height:9vh;
      font-size:1em;
    }
    .Wall{
        width: 100%;
        position:fixed;
        height:100%;
        top:17vh;
    }
    .PlacesWall{
        width: 90%;
        padding: 0 5%;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .PlacesWallNarrow{
         max-width: 65%;
         padding: 0 ;
         height: 75vh;
         overflow-y: auto;
         overflow-x: hidden;
         padding-bottom: 10vh;
      }
      
      .PlacesMap{
        position:fixed;
        z-index: 1;
        top:17vh;
        right:0;
        width:35%;
        height:83vh;
        background-color: violet;
    }
    .PlacesMapRTL{
        position: fixed;
        z-index: 1;
        top:17vh;
        left:0;
        width:35%;
        height:83vh;
    }
    .DiscoverToolbar{
        background-color: white;
        position: absolute;
        display:flex;
        align-items: center;
        justify-content: space-between;
        height:10vh;
        width:90%;
        padding: 0 5%;
        color:rgb(100,100,100)
    }
    .SearchBarSection{
      position:absolute;
      left:32.5%;
      width:35%;
      font-size: 0.9em;
    }
    .SearchBarSectionRTL{
      position:absolute;
      right:32.5%;
      width:35%;
      font-size: 0.9em;
    }
    .ToolbarButton{
        padding: 5px 10px;
        border-radius: 5px;
    }
    .ToolbarButton:hover{
        cursor: pointer;
        background-color: rgb(250,250,250);
    }
    .ToolbarIcon{
        width:25px;
        margin-right:10px;
    }
  
    .ToolbarIconRTL{
        height:30px;
        margin-left:10px;
    }
    .CurrentLocationIcon{
        cursor: pointer;
        position:absolute;
        right:15px;
        top:10px;
        z-index: 20;
        width:30px;
        height:30px;
        padding:10px;
        background-color: white;
        border-radius: 7px;
      }
      .MapSearchButton{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top:50px;
        width:30%;
        left:35%;
        z-index: 1;
        background-color: white;
        padding: 10px 15px;
        border-radius: 25px;
        height:30px;
        color:rgb(88,88,88);
        font-size: 1.1em;;
      }
      .MapSearchButton:hover{
        cursor: pointer;
        background-color: rgb(245, 245, 245);
      }
      .HideDesktopMap{
        transform: translateY(-100vh);
      }
      .PlansWall{
        width: 80%;
        padding: 0 10%;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .FilterTags{
        padding: 0 5%;
        width:60%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }
      
      .Filters{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width:auto;
        font-size:1em;
        padding:5px 0;
      }

    
      .TripsViewSelection{
        display:flex;
        align-items: center;
        
      }
      
      .TripsSelection:hover{
        cursor: pointer;
      }
      .CallToAction:hover{
        cursor: pointer;
        box-shadow: 1px 2px 2px rgb(200,200,200);
      }
      .SuggestedTripAction:hover{
        cursor: pointer;
        box-shadow: 1px 2px 2px rgb(200,200,200);
      }
}

@media screen and (max-width: 767px) {
    .Body{
        overflow-y: auto;
        overflow-x: hidden;
        height:100%;
    }
    .BodyRTL{
        overflow-x: hidden;
        overflow-y: auto;
        height:100%;
    }
    .Wall{
      width: 100%;
      position:fixed;
      height:100%;
      top:7vh;
  }
    .TripsSelectionToolbar{
      width:100%;
    
      padding:0.5vh 0;
      margin:0;
      margin-bottom:1vh;    
    
    }

    .TripsViewSelection{
      display:flex;
      align-items: center;
      border-bottom: 1px solid rgb(223,223,223);
      padding:0.5vh 0;
    }
    .TripsSelection{
      padding:5px 10px;
      font-size: 1em;
      height:25px;
      margin: 0 5px;
      width:100%;
      color:rgb(88,88,88);
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
    }
    .TripSelectionSelected{
      padding:5px 10px;
      font-size: 1em;
      height:25px;
      margin: 0 5px;
      width:100%;
      color:rgb(34,121,190);
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
    }

    .SuggestedTripsToolbar{
      display:flex;
      width:95%;
      padding:0.5vh 2.5%;
      background-color: grey;
      justify-content: space-evenly;
    }
    .SuggestedTripAction{
      font-size:1em;
    }
    .SearchBarSection{
        position:relative;
        left:15%;
        width:80%;
    }
    .SearchBarSectionRTL{
        position:relative;
        left:5%;
        width:80%;
    }
    .PlacesMap{
      /*   overflow:hidden;
        height:80vh;
        width:100vw;
        right:0;
        position:relative; */
        position:relative;
        height:75vh;
    }
    .PlacesMapRTL{
        height:75vh;
        position:relative;
    }
    .ToolbarIcon{
        height:20px;
        margin-right:35px;
    }
  
    .ToolbarIconRTL{
        height:20px;
        margin-left:35px;
    }
    .CurrentLocationIcon{
        cursor: pointer;
        position:absolute;
        right:10px;
        top:15%;
        z-index: 20;
        width:30px;
        height:30px;
        padding:5px;
        background-color: white;
        border-radius: 5px;
      }
      .PlacesPreview{
        width:100%;
        min-height:18vh;
        max-height:18vh;
        padding: 0;
        top:-18vh;
        overflow-x: auto;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        overflow-y: hidden;
        background-color: transparent;
      } 
      .MapSearchButton{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top:15%;
        width:40%;
      
        left:30%;
        z-index: 1;
        background-color: white;
        padding: 2px 4px;
        border-radius: 20px;
        height:35px;
        color:rgb(88,88,88);
        font-size: 0.9em;;
      }
      .DiscoverToolbar{
          position:absolute;
          z-index: 10;
          width:90%;
          padding: 0 7.5%;
          left:-2.5%;
          display:flex;
          align-items: center;
          top:2%;
          font-size:0.9em;
      }
      .RegularToolBar{
        position:relative;
        width:90%;
        z-index: 0;
        padding: 1vh 5%;
        padding-bottom: 0;
        top:1%;
        height:9vh;
        font-size:0.9em;
        z-index: 10;
      }
      .MobileFiltersButton{
        position: absolute;
        border:1px solid rgb(230,230,230);
        background-color: white;
        left:10%;
        border-radius: 25px;
        height:50px;
        display: flex;
        align-items: center;
        justify-content: start;
        width:65px;
        padding-left:10px;
      }
      .MobileFiltersButtonRTL{
        position: absolute;
        border:1px solid rgb(230,230,230);
        background-color: white;
        right:10%;
        border-radius: 25px;
        height:50px;
        display: flex;
        align-items: center;
        justify-content: start;
        width:65px;
        padding-left:10px;
      }
      .OpenCloseButton{
        display:flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        height:7vh;
        width:50%;
        padding: 0 25%;
        border-bottom:3px solid rgb(87, 87, 87);
      }
      .OpenCloseButtonRTL{
      }
      .MobilePreviewPlacesNum{
        direction: ltr;
        font-size:1.1em;
        color:rgb(60,60,60);
        font-weight: 500;
      }
      .MobilePrevArrow{
        height:30px;
      
      }
      .MobileNextArrow{
        height:30px;
       
      }
      .ExperiencesWall{
        overflow-y: auto;
         width:100%;
         height:80vh;
         padding: 0;
         display:flex;
         position: absolute;
         flex-direction: column;
         align-items: center;
         padding-bottom: 10vh;
         background-color: rgb(248,248,248);
       }
       .PlansWall{
        width: 100%;
        padding: 0;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .MobileSeperator{
        height:4vh;
        border-right:1px solid rgb(200,200,200)
      }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Body{
        overflow-y: auto;
        height:100%;
    }
    .BodyRTL{
        overflow-y: auto;
        height:100%;
        direction: rtl;
    }
    .SearchBarSection{
      position:relative;
      left:15%;
      width:80%;
  }
  .SearchBarSectionRTL{
      position:relative;
      right:15%;
      width:80%;
  }
    .PlacesMap{
      /*   overflow:hidden;
        height:80vh;
        width:100vw;
        right:0;
        position:relative; */
        position:relative;
        height:75vh;
    }
    .PlacesMapRTL{
        height:75vh;
        position:relative;
    }
    .ToolbarIcon{
        height:20px;
        margin-left:5px;
    }
  
    .ToolbarIconRTL{
        height:20px;
        margin-right:5px;
    }
    .CurrentLocationIcon{
        cursor: pointer;
        position:absolute;
        right:5%;
        top:15%;
        z-index: 20;
        width:35px;
        height:35px;
        padding:7px;
        background-color: white;
        border-radius: 5px;
      }
      .PlacesPreview{
        width:100%;
        min-height:18vh;
        max-height:18vh;
        padding: 0;
        top:-23vh;
        overflow-x: auto;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        overflow-y: hidden;
        background-color: transparent;
      } 
      .MapSearchButton{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top:15%;
        width:30%;
      
        left:35%;
        z-index: 1;
        background-color: white;
        padding: 5px 10px;
        border-radius: 20px;
        height:35px;
        color:rgb(88,88,88);
        font-size: 1.1em;;
      }
      .DiscoverToolbar{
          position:absolute;
          z-index: 10;
          width:70%;
          padding: 0 15%;
          display:flex;
          align-items: center;
          top:2%;
          font-size:0.8em;
      }
      .RegularToolBar{
        position:relative;
        width:90%;
        z-index: 0;
        padding: 1vh 5%;
        padding-bottom: 0;
        top:1%;
        height:9vh;
        font-size:0.8em;
      }
      .MobileFiltersButton{
        position: absolute;
        border:1px solid rgb(230,230,230);
        background-color: white;
        left:20%;
        border-radius: 25px;
        height:50px;
        display: flex;
        align-items: center;
        justify-content: start;
        width:70px;
        padding-left:15px;
      }
      .MobileFiltersButtonRTL{
        position: absolute;
        border:1px solid rgb(230,230,230);
        background-color: white;
        right:20%;
        border-radius: 25px;
        height:50px;
        display: flex;
        align-items: center;
        justify-content: start;
        width:70px;
        padding-left:35px;
      }
      .OpenCloseButton{
        display:flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        height:7vh;
        width:50%;
        padding: 0 25%;
        border-bottom:3px solid rgb(87, 87, 87);
      }
      .OpenCloseButtonRTL{
        direction: ltr;
      }
      .MobilePreviewPlacesNum{
        direction: ltr;
        font-size:1.1em;
        color:rgb(60,60,60);
        font-weight: 500;
      }
      .MobilePrevArrow{
        height:30px;
      
      }
      .MobileNextArrow{
        height:30px;
       
      }
      .PlansWall{
        width: 90%;
        padding: 0 5%;
        height: 75vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .ExperiencesWall{
        width: 100%;
        padding: 0;
        height: 70vh;
        padding-bottom: 10vh;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: rgb(250,250,250);
      }

      .TripsSelectionToolbar{
        width:100%;
      
        padding:0.5vh 0;
        margin:0;
        margin-bottom:1vh;    
      
      }
  
      .TripsViewSelection{
        display:flex;
        align-items: center;
        border-bottom: 1px solid rgb(223,223,223);
        padding:0.5vh 0;
      }

      .TripSelectionSelected{
        padding:10px 15px;
        color: rgb(34,121,190);
        font-weight: 500;
        font-size:1.3em;
        margin:0 20px;
        border-bottom:2px solid rgb(34,121,190);
      }
      
      .TripsSelection{
       
        padding:10px 15px;
        border-bottom:2px solid rgb(223,223,223);
        color:rgb(88,88,88);
        font-weight: 400;
        font-size:1.3em;
        margin:0 20px;
      }
      .SuggestedTripsToolbar{
        padding:0.5vh 2.5%;
      background-color: grey;
      }
}