.RTL{
    direction: rtl;
}

.CheckIcon{
    margin-right:12px;
    width:26px;
    height:26px;
  }

  .CheckIconRTL{
    margin-left:12px;
    width:26px;
    height:26px;
  }

  
  .UncheckBox{
    margin-right:12px;
    min-width:24px;
    max-width:24px;
    min-height:24px;
    max-height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }

  .UncheckBoxRTL{
    margin-left:12px;
    min-width:24px;
    max-width:24px;
    min-height:24px;
    max-height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }


  .TitleSection{
    width:100%;
    border-bottom: 1px solid rgb(223,223,223);
    padding:10px 0;
}

.TitleInput{
    width: 90%;
    resize: none;
  
    font-style: italic;
    border: none;
    outline: none;
   
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.TitleInputRed{
  width: 90%;
  resize: none;

  font-style: italic;
  border: none;
  outline: none;
 
  font-weight: 500;
  color: rgb(156, 30, 30);
}
.TitleInputRed::-webkit-input-placeholder {
  color: rgb(156, 30, 30);
}


.SectionHeader{
    font-size:1.3em;
    font-weight: 500;
    padding-bottom:15px;
    color:rgb(100,100,100);
}

.ImageSection{
    position: relative;
    top:1vh;
    width:100%;
    max-height:40vh;
}

.DatesSection{
    padding-top:10px;
    position: relative;
    z-index: 20;
    width:100%;
    padding-bottom:15px;
    border-bottom:1px solid rgb(240,240,240);
}


.StartDateMessage{
    font-size:1.2em;
   
    padding-bottom: 10px;;
    margin-left:15px;
}

.RedMessage{
  color:rgb(204, 11, 11);
}


.PlanDuration{
    display:flex;
    align-items: center;
}

.SubHeader{
    margin-right:20px;
    font-size:1.1em;
    color:rgb(88,88,88);
}

.SubHeaderRTL{
  margin-left:20px;
    font-size:1.1em;
    color:rgb(88,88,88);
}


.PlanDurationInput{
    width:50px;
    height:30px;
    border:none;
    text-align: center;
    border-bottom: 1px solid rgb(230,230,230);
    font-size:1.1em;
    color:rgb(88,88,88);
}
.PlanDurationInput:focus{
    outline: none;
}

.NoDatesSection{
    width:80%;
    display:flex;
    align-items: center;
    padding-bottom:15px;
}
.NoDatesButton{
    font-size:1.1em;
    color:rgb(88,88,88);
}
.NoDatesButtonSelected{
    font-size:1.1em;
    color:rgb(34,121,190);
}

.ImageSection{

}

.ImageContent{
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.ImageButton{

  margin:0 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 200px;
}
.ImageButton:hover{
  cursor: pointer;
  background-color: rgb(240,240,240);
}

.ImageUploadWrapper {
  cursor: pointer;
  min-width: 200px;
  max-width: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;

  color: rgb(87, 87, 87);

}

.ImageUploadWrapper > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.ImageUploadWrapper > .ImageButton {
  display: inline-block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FileInput{
  width:100%;
  height:140%;
  position: absolute;
  bottom:20%;
  z-index: 10;
}
.FileInput:hover{
cursor: pointer;

}

.ImageIcon{
  height:25px;
  margin-right: 15px;
}
.ImageIconRTL{
    height:25px;
    margin-left: 15px;
  }

.ImageText{
border:none;
color:rgb(100,100,100);
font-size:16px;
width:auto;
border:none;
display:inline-block
}
.ImageText:focus{
outline: none;
}

.Images{

}

.ParticipantsSection{
  padding-top:10px;
  width:100%;
  padding-bottom:15px;
  border-bottom:1px solid rgb(240,240,240);
}

.Participants{
  display:flex;
  align-items: flex-start;
  flex-direction: column;
}

.Participant{
  display:flex;

  align-items: center;
  margin:3px 10px;
}

.ParticipantText{
  font-size:1.1em;
  color:rgb(100,100,100);
  text-align: start;
  min-width:120px;
}

.NumberValue{
  width:50px;
  border:none;
  text-align: center;
  height:30px;
  border: 0.7px solid rgb(210,210,210);

}
.NumberValue:focus{
  outline: none;
}

.NumberInput{
  display:flex;
  align-items: center;
  height:40px;
  margin:0 10px;
  padding:0 5px;
}

.NumberInputButton{
  height:35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  background-color: rgb(180, 180, 180);
  width:50px;
}
.NumberInputButton:hover{
  background-color:rgb(34,121,190,0.7);
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.DurationSection{
 /*  display: flex;
  align-items: center; */
  margin-bottom: 15px;
  color:rgb(88,88,88);
}

.DuraitonRadioButtons{
  display: flex;
  align-items: center;
}

.DurationMessage{
  font-size:1.1em;
 padding:5px 0;
  margin-right:25px;
}

.DurationMessageRTL{
  font-size:1.1em;
  margin-left:25px;
}

.DurationButton{
  display: flex;
  align-items: center;
  margin:0 10px;
}

.RadioButton{
  height:20px;
  margin-right:10px;
}

.RadioButtonRTL{
  height:20px;
  margin-left:10px;
}

.AnonymousUserMessage{
  position:fixed;
  z-index: 100;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.MessageButtonsSection{
  position:absolute;
  bottom:10%;
  color:rgb(100,100,100);
  display:flex;
  align-items: center;
}
.SignUpButton{
 
}

.ContinueButton{
  margin:0 10px;
}

.MessageContent{
  position: absolute;
  white-space: normal;
  text-align: center;
  top:20%;
  width:80%;
  padding: 0 10%;
}
  
@media screen and (min-width: 1024px) {  
    .Modal{
      position:fixed;
    width:50vw;
    left:25vw;
    height:90vh;
    top:5vh;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgb(214, 214, 214);
    box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
    0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
    z-index: 500;
      }

      .CreationForm{
        height:75vh;
        overflow-x: hidden;
        overflow-y: auto;
        width:90%;
        padding: 2vh 5%;
        position: relative;
    }

    .CreationForm::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    }

    .CreationForm::-webkit-scrollbar
    {
    width: 0px;
    background-color: #F5F5F5;
    }

    .CreationForm::-webkit-scrollbar-thumb
    {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(168, 168, 168, 0.562);
    }
        
    .TitleInput{
        height: 40px;
        font-size: 2em;
    }
    .TitleInputRed{
      height: 40px;
      font-size: 2em;
    }

    .CheckIconRTL:hover{
        cursor: pointer;
        }
    .CheckIcon:hover{
      cursor: pointer;
      }

    .UncheckBox:hover{
        cursor: pointer;
      }

    .UncheckBoxRTL:hover{
        cursor: pointer;
      }
     .RadioButton:hover{
       cursor: pointer;
     }
     .RadioButtonRTL:hover{
       cursor: pointer;
     }
     .AnonymousUserMessage{
      top:20vh;
      height:50vh;
      width:40%;
      left:30%;
      background-color: white;
      box-shadow: 0 2px 2px #dadada;
      border-radius: 10px;
      border:1px solid rgb(235,235,235);
  }
  .SignUpButton{
      border:1px solid rgb(223,223,223);
      border-radius: 5px;
      padding: 7px 15px;
      margin:0 10px;

  }
  .SignUpButton:hover{
      box-shadow: 0 2px 2px #ebebeb;
      cursor: pointer;
  }
  .ContinueButton:hover{
    cursor: pointer;
  }
  .MessageTitle{
      font-size:1.4em;
      color:rgb(88,88,88);
  }
}

@media screen and (max-width: 767px) {
    .Modal{
        z-index: 500;
      position:fixed;
      width:100%;
      left:0;
      height:100%;
      top:0;
      background-color: white;
      }
    .CreationForm{
      height:75%;
      padding:1vh 2.5%;
      padding-bottom:15vh;
      margin-bottom:15vh;
      width:95%;
      font-size:1em;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .TitleInput{
        height: 40px;
        font-size: 1.4em;
    }

    .TitleInputRed{
      height: 40px;
      font-size: 1.4em;
    }

    .ImageSection{
    
    }
  
    .ImageContent{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0;
    }
  
    .ImageButton{
      border:1px solid rgb(223,223,223);
      margin:5px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 200px;
      border-radius: 10px;
    }
  
    .ImageUploadWrapper {
      cursor: pointer;
      min-width: 200px;
      max-width: 200px;
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
    
      color: rgb(87, 87, 87);
    
    }
    
    .ImageUploadWrapper > input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
    
    .ImageUploadWrapper > .ImageButton {
      display: inline-block;
      width: 200px;
      height: 200px;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .FileInput{
      width:100%;
      height:140%;
      position: absolute;
      bottom:20%;
      z-index: 10;
    }
  
    .ImageIcon{
      height:20px;
      margin-right: 10px;
    }
    .ImageIconRTL{
      height:20px;
      margin-left: 10px;
    }
    
    .ImageText{
    border:none;
    color:rgb(100,100,100);
    font-size:16px;
    width:100%;
    border:none;
    display:inline-block
    }
    .ImageText:focus{
    outline: none;
    }
  
    .Images{
  
    }
    .Participants{
      display:flex;
     flex-direction: column;
     align-items: flex-start;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        z-index: 500;
      position:fixed;
      width:100vw;
      left:0;
      height:100vh;
      top:0;
      background-color: white;
      }
    .CreationForm{
        height:90vh;
        overflow-x: hidden;
        overflow-y: auto;
        height:auto;
        width:95%;
        padding: 2vh 2.5%;
        height:100%;
        display:flex;
        flex-direction: column;
    }

    .TitleInput{
        height: 40px;
        font-size: 1.5em;
    }
    
    .TitleInputRed{
      height: 40px;
      font-size: 1.5em;
    }
    
  .ImageSection{

  }

  .ImageContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
  }

  .ImageButton{
    border:1px solid rgb(223,223,223);
    margin:5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 200px;
   border-radius: 10px;
  }

  .ImageUploadWrapper {
    cursor: pointer;
    min-width: 200px;
    max-width: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
  
    color: rgb(87, 87, 87);
  
  }
  
  .ImageUploadWrapper > input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .ImageUploadWrapper > .ImageButton {
    display: inline-block;
    width: 200px;
    height: 200px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .FileInput{
    width:100%;
    height:140%;
    position: absolute;
    bottom:20%;
    z-index: 10;
  }

  .ImageIcon{
    height:30px;
    margin-right: 15px;
  }
  .ImageIconRTL{
    height:30px;
    margin-left: 15px;
  }
  
  .ImageText{
  border:none;
  color:rgb(100,100,100);
  font-size:16px;
  width:100%;
  border:none;
  display:inline-block
  }
  .ImageText:focus{
  outline: none;
  }

  .Images{

  }
    
}