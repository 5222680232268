.Modal{
    height:100%;
    width:100%;
    position: fixed;
    top:0;
    left:0;
    background-color: rgb(255, 255, 255);
    z-index: 30;
}

.Body{
    height:100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.PlaceAlbum{
    width:100vw;
    height:100%;
    overflow: hidden;
    display:flex;
    position: relative;
}

.AlbumImage{
    width:96%;
    height: 100%;
    margin:0 2%;
    background-color: black;
    object-fit: contain;
}

.Tools{
    position:fixed;
    right:5vw;
    top:1vh;
    z-index: 20;
    display: flex;
}

.ToolsRTL{
    position:fixed;
    left:5vw;
    top:1vh;
    display: flex;
    z-index: 20;
}

.ToolsOptions{
    display: flex;
}

.EditIcon{
    height:3vh;
    padding: 1vh 0;
    transition: 0.5s;
}

.UpdateButton{
    height:3vh;
    padding:1vh 20px;
    margin:0 20px;
    border-radius: 5px;
    border:1px solid white;
    color:white;
    transition: 0.6s;
}

.PlaceSection{
    margin:10px 1%;
    padding: 10px 1%;
    width:96%;
    background-color: white;
}


.PlaceExperiencesSection{
    width:90%;
    padding:5%;
    height:90%;

}

.ImagesGallery{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width:100%;
    direction: ltr;
    overflow-y: auto;
}


.MovingImage{
    object-fit: cover;
    height:35vh;
    width:32%;
    border:1px solid rgb(88,88,88);
    margin:0.5%;
}


.SelectedImageBullet{
    position:absolute;
    top:5px;
    right:5px;
    height:14px;
    width:14px;
    border: 3px solid white;
    background-color: rgb(34,121,190);
    border-radius: 50%;
}

.NotSelectedImageBullet{
    position:absolute;
    top:5px;
    right:5px;
    height:18px;
    width:18px;
    border: 1px solid rgb(117, 117, 117);
    background-color: white;
    border-radius: 50%;
}
.EditImageSection{
    object-fit: cover;
    height:30vh;
    width:24%;
    margin:0.5%;
    position:relative;
}


.ExpImage{
    object-fit: cover;
    height:35vh;
    width:32%;
    margin:0.5%;
}

.EditImage{
    object-fit: cover;
    height:35vh;
    height:100%;
    width:100%;
}

.SelectedImage{
    width:69%;
    height:82vh;
    padding: 5vh 0.5%;
    display:flex;
    align-items: center;
    justify-content: center;
    
}

.BorderLeft{
    border-left:1px solid rgb(220,220,220);
}

.BorderRight{
    border-right:1px solid rgb(220,220,220);
}

.ImagesScrollSection{
    display:flex;
    flex-direction: column;
    align-items: center;
    position:relative;  
    padding: 1vh 1%;
    width:28%;  
}

.ImagesScroller{
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height:80vh;
    padding-top:10vh;
   
}

.ImagesScroller::-webkit-scrollbar
{
  width: 0;
}

.SmallImage{
    transition: 0.3s;
    width:100%;
    object-fit: contain;
}

.LargeImage{
    max-width:90%;
    object-fit: contain;
}

.PlaceName{
    color:rgb(100,100,100);
    position: absolute;
    top:0vh;
   z-index: 10;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 3vh 0;
    height:4vh;
    font-size:1.5em;
    width:100%;
    background-color: white;
}

.PlaceIcon{
    height:25px;
    margin:0 15px;
}


@media screen and (min-width: 1024px) {
    .EditIcon:hover{
        cursor: pointer;
        transform: scale(1.1);
        transition: 0.5s;
    }
    .UpdateButton:hover{
        cursor: pointer;
        background-color: rgba(248, 248, 248, 0.329);
        transition: 0.3s;
    }
    .NotSelectedImageBullet:hover{
        cursor: pointer;
    }
    .SelectedImageBullet:hover{
        cursor: pointer;
    }
    .SmallImage:hover{
        cursor: pointer;
        opacity: 0.85;
        transition: 0.3s;
    }
}


@media screen and (max-width: 767px){
    .PlaceAlbum{
        width:100%;
        max-height:100%;
        min-height: 100%;
        overflow: hidden;
        display:flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(59, 59, 59);
    }

    .SelectedImage{
        width:100%;
        object-fit: contain;
        height:auto;
        display:flex;
        align-items: center;
        justify-content: center;
        position: unset;
    }
    

    .BorderTop{
        border-bottom:1px solid rgb(220,220,220);
    }

    .ImagesScrollSection{
       position:relative;
        width: auto;
        bottom:5vh;
        z-index:10;
    }

    
    .ImagesScroller{
        display:flex;
        align-items: center;
        position:relative;  
        padding: 2vh 1%;
        width:98%;  
        height:10vh;
     
        overflow-y: hidden;
        overflow-x: auto;
    }
    .LargeImage{
        max-width: 100%;
        max-height:65vh;
    }
    .PlaceName{
        width:100%;
        padding: 1vh 0;
        left:0;
        font-size: 0.7em;
        color:white;
        background-color: rgb(59, 59, 59);
    }
    .EditImageSection{
        width:49%;
        height:20vh;
    }
    .MovingImage{
        width:48%;
        height:20vh;
    }
    .PlaceExperiencesSection{
        width:100%;
        padding: 10px 0;
    }
    .SmallImage{
        transition: 0.3s;
        width:auto;
        height:100%;
        object-fit: contain;
    }
}