.Body{
  height:100vh;
  width:100vw;
  position:fixed;
  top:0vh;
  left:0;
  z-index:100;
  background-color: rgb(255, 255, 255);
  }   

  .RTLBody{
    direction: rtl;
  }
  
  .BodyContent{
  
   
    display:flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .TitleTopSection{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  
  .PlaceShortcuts{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    width:38%;
  }
  
  .Title {
    text-align: start;
    align-self: flex-start;
   
    font-style: italic;
    border: none;
    outline: none;
  
    color: rgb(65, 65, 65);
    font-weight: 500;
  }
  .PlaceLanguage{
    position:relative;
  
}

  .Tools{
    position:relative;
    z-index: 100;
    font-size: 0.9em;
  }
  .ToolsRTL{
    position:relative;
    z-index: 100;
    font-size: 0.9em;
  }


  .LanguageTools{
    position:relative;
    z-index: 100;
    font-size: 0.9em;
  }
  .LanguageToolsRTL{
    position:relative;
    z-index: 100;
    font-size: 0.9em;
  }
  .PlaceOverview{
    position:relative;

    height: auto;
    padding-bottom:2vh;
  }
  

  .Map{
    position:relative;

  }
  
  .LocationPath{
    display:flex;
    align-items: center;
    justify-content: space-between;
    color: rgb(102, 102, 102);
    font-size:1.1em;
    font-weight: 500;
    padding-bottom: 7px;
    }
    
    .ShowMap{
      margin-right:10px;
      padding: 5px;
      border-radius: 5px;
      color:rgb(34,121,190);
      text-align: end;
    }

    .LinksSection{
      display: flex;
      align-items: center;
      padding: 5px 0;
      height:50px;
  }
  .Links{
    display: flex;
    align-items: flex-start;
    text-align: start;

    
  }
  
  .Link {
    color: rgb(33, 33, 153);
    text-align: start;
    margin-right: 7px;
    padding-right: 7px;
    border-right: 0.7px solid rgb(197, 197, 197);
  
  }

  .LinkRTL{
    color: rgb(33, 33, 153);
    text-align: start;
    margin-left: 7px;
    padding-left: 7px;
    border-left: 1px solid rgb(218, 218, 218);
  }

  .Category {
 
    text-align: start;
    font-size: 0.9em;
    font-weight: 500;
    color: rgb(80, 80, 80);
    white-space: nowrap;
   
  }
  
  .CategoryLTR{
    margin-right:7px;
    padding-right:7px;
    border-right:1px solid rgb(221, 221, 221);
  }
  
  .CategoryRTL{
    margin-left:7px;
    padding-left:7px;
    border-left:1px solid rgb(221, 221, 221);
  }
  
  
  .CategoryLast{
    text-align: start;
    font-size: 0.9em;
    font-weight: 500;
    color: rgb(80, 80, 80);
    white-space: nowrap;
  }
  
  .NewCategory{
    text-align: start;
    font-size: 0.9em;
    font-weight: 500;
    color: rgb(255,119,0);
    white-space: nowrap;
  }
  
  .CategoryLastLTR{
    margin-right:7px;
    padding-right:7px;
  }
  
  .CategoryLastRTL{
    margin-left:7px;
    padding-left:7px;
  }
  
  .CategoryList{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
   }
   .CategoriesSection{
    margin:10px 0;
    text-align: start;
    font-size: 1.1em;
    font-weight: 500;
    color: rgb(88, 88, 88);
    display:flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .DescriptionRTL{
    direction: rtl;
  }

  .LikedSection{
    position:absolute;
    right:17.5vw;
    display: flex;
    direction: ltr;
    color:rgb(100,100,100);
    font-size:0.9em;
    align-items: flex-end;
  }

  .LikedSectionRTL{
    position:absolute;
    left:17.5vw;
    display: flex;
    direction: ltr;
    color:rgb(100,100,100);
    font-size:0.9em;
    align-items: flex-end;
  }

  .RankItem{
    display:flex;
    align-items: flex-end;
    margin:0 10px;
  }

  .LikedIcon{
    height:25px;
    margin-right:10px;
  }

  .Owner{
    display:flex;
    flex-direction: column;
  }

  .PlaceCharacteristcs{
    width:100%;
    background-color: rgb(248,248,248);
  }

  .PlaceTags{
    display: flex;
    align-items: center;
    padding:5px 0;
    flex-wrap: wrap;
    font-size: 0.9em;;
}

.ToolText{
  margin-top:3px;
  font-size:0.7em;
  text-align: center;
  white-space: nowrap;
  color:rgb(88, 88, 88);
}

.ToolTextFade{
  margin-top:3px;
  font-size:0.7em;
  text-align: center;
  white-space: nowrap;
  color:rgb(165, 165, 165);
}

.TabSeperator{
  border-left:1px solid rgb(219, 219, 219);
  height:40px;
}

.ToolsIcon{
  height: 22px;  
  border-radius: 50%;

}

.ExperienceIcon{
  height: 22px;  
}


.GreyedTools{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ToolsIconGreyed{
  height: 22px;  
  border-radius: 50%;

}

.AdditionalTools{
  margin:0 10px;
}

.GoogleRankIcon{
  height:18px;
  margin: 0 5px;
}

.GoogleRank{
  color:rgb(100,100,100);
  display:flex;
  align-items: center;
  direction: ltr;
}

.CheckIcon{
  margin-right:12px;
  width:26px;
  height:26px;
}

.CheckIconRTL{
  margin-left:12px;
  width:26px;
  height:26px;
}


.UncheckBox{
  margin-right:12px;
  min-width:24px;
  max-width:24px;
  min-height:24px;
  max-height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

.UncheckBoxRTL{
  margin-left:12px;
  min-width:24px;
  max-width:24px;
  min-height:24px;
  max-height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

  @media screen and (min-width: 1024px) {
    .TitleTopSection{
      width:80%;
      padding: 1vh 15%;
      margin-bottom:15px;
      min-height:5vh;
      align-items: flex-end;
      background-color: #F5F5F5;
    }
    .BodyContent{
      height:80vh;
      width:100%;
      padding: 0;
    }
    .BodyContent::-webkit-scrollbar
    {
    width: 0;
    background-color: #F5F5F5;
    }
    .PlaceOverview{
      width:50%;
      padding: 0 15%;
    }
    
   
    .Title{
      width: 58%;
      resize: none;
      min-height: 40px;
      font-size: 2em;
    }
    .PlaceShortcuts{
      min-width: 38%;
      height:40px;
    }
    .PlaceLanguage{
      margin:10px;
    }
    .PlaceLanguageRTL{
      position:relative;
      margin:10px;
    }
    .ToolsIcon{
      height: 22px;  
    }
    .ToolsIcon:hover{
        cursor: pointer;
    }
  
  .GreyedTools{
    margin:0 25px;
  }
    .Map{
      height:48vh;
      width:95%;
      margin-top: 20px;
      border-top: 1px solid rgb(223,223,223);
      padding: 20px 2.5% ;
      font-size: 0.9em;
    }

  .DescriptionSection {
    margin-top: 20px;
    border-top: 1px solid rgb(223,223,223);
    width:100%;
    font-size:1em;
    height: auto;
    padding: 0;
    padding: 20px 0 ;
  }
  .DescriptionInputField {
    width: 100%;
    outline: none;
    border: none;
  text-align: start;
    font-size: 1.1em;
    font-weight: 400;
    color: rgb(85, 85, 85);
    margin-top: 10px;
  
    margin-bottom: 10px;
  }
  .PlaceInfoSection{
    background-color: white;
    padding:1vh 0;
    height:auto;
    margin-top: 15px;
    border-top: 1px solid rgb(223,223,223);
    }



  .CategoryIcon{
      max-width: 30px;
      max-height: 30px;
      margin-right:20px;
  }
  .CategoryIconRTL{
    max-width: 30px;
    max-height: 30px;
    margin-left:20px;
}
  .ContactInfoIcon{
    width:35px;
    margin-right:20px;
    }
    .ContactInfoIconRTL{
      width:35px;
      margin-left:20px;
      }
    
    .ContactInfoIcon:hover{
    cursor: pointer;
    }
    .ContactInfoIconRTL:hover{
      cursor: pointer;
      }
    .LinkIcon{
      width:30px;
      margin-right:20px;
    }
    .LinkIconRTL{
      width:30px;
      margin-left:20px;
    }

    .ContactInfo{
      width:100%;
      height:100%;
      padding-top: 7px;
      border-top:1px solid rgb(235,235,235);
      }
      
      .ContactInfoHeader{
      color: rgb(88,88,88);
      font-size: 1.2em;
      font-weight: 500;
      }
      
      .QuickLinks{
      padding:7px 0;
      width:100%;
      display: flex;
      align-items: center;
      }
      .Phone{
        display:flex;
        align-items: center;
        padding:7px 0;
        }
      .ShowMap:hover{
        cursor: pointer;
      }
      .GreyedTools:hover{
        cursor: pointer;
      }
    
      .LikedSection{
        top:10vh;
      }
    
      .LikedSectionRTL{
        top:10vh;
      }
      .CheckIconRTL:hover{
        cursor: pointer;
        }
    .CheckIcon:hover{
      cursor: pointer;
      }

    .UncheckBox:hover{
        cursor: pointer;
      }

    .UncheckBoxRTL:hover{
        cursor: pointer;
      }
   
  }

  @media screen and (max-width: 767px) {
    .BodyContent{
      width:100%;
      overflow-y: auto;
      overflow-x: hidden;
      height:75vh;
      padding-bottom:5vh;
    }
    .TopSection{
      position: relative;
      z-index: 1;
    }
    .TitleTopSection{
      width:95%;
      padding: 1vh 2.5%;
      min-height:10vh;
      display: flex;
      flex-direction: column;
      background-color: #F5F5F5;
      transition: 0.3s;
    }
    .TitleTopSectionSlim{
      width:95%;
      padding: 1vh 2.5%;
      min-height:4vh;
      display: flex;
      flex-direction: column;
      background-color: #F5F5F5;
      transition: 0.3s;
    }
    .PlaceShortcuts{
      width: 70%;
      height:40px;
      z-index: 10;
      align-self: flex-end;
    }
    .PlaceShortcutsLTR{
      width:95%;
      padding: 0 2.5%;
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top:5px;
    }
    .PlaceShortcutsRTL{
      width:95%;
      padding: 0 2.5%;
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top:5px;
    }
    .PlaceLanguage{
      position:fixed;
      margin:7px;
      top:0;
      right:0;
    }
    .PlaceLanguageRTL{
      position:fixed;
      margin:7px;
      top:0;
      left:0;
    }
    .Tools{
      right:-45vw;
    }
    .ToolsRTL{
      left:-45vw;
    }
    .LanguageTools{
      position:relative;
      z-index: 100;
      font-size: 0.9em;
      right:45vw;
    }
    .LanguageToolsRTL{
      position:fixed;
      z-index: 100;
      font-size: 0.9em;
      left:45vw;

    }
    .ToolsIcon{
      height: 25px;  
    }
    .Title{
      width: 100%;
      resize: none;
      font-size: 1.3em;
    }
    .GoogleRank{
      font-size: 0.8em;
      width:100%;
      justify-content: flex-start;
    }
    .GoogleRankIcon{
      height:16px;
    }
    .GreyedTools{
      margin:0 10px;
      min-width:20%;
    }
    .Map{
      height:45vh;
      width:95%;
      margin-top: 20px;
      border-top: 1px solid rgb(223,223,223);
      padding: 20px 2.5% ;
      font-size: 0.9em;
    }

  .DescriptionSection {
    margin-top: 20px;
    border-top: 1px solid rgb(223,223,223);
    width:95%;
    font-size:0.9em;
    height: auto;
    padding: 0;
    padding: 20px 2.5% ;
  }
  .DescriptionInputField {
    width: 100%;
    outline: none;
    border: none;
  text-align: start;
    font-size: 1.1em;
    font-weight: 400;
    color: rgb(85, 85, 85);
    margin-top: 10px;
  
    margin-bottom: 10px;
  }
  .PlaceInfoSection{
    background-color: white;
    width:95%;
    padding:1vh 2.5%;
    height:auto;
    margin-top: 15px;
    border-top: 1px solid rgb(223,223,223);
    }

  .CategoriesSection{
    margin:10px 0;
    text-align: start;
    font-size: 1.1em;
    font-weight: 500;
    color: rgb(88, 88, 88);
    display:flex;
    align-items: center;
  }
    

  .CategoryList{
   display:flex;
   align-items: center;
  }

  .CategoryIcon{
      max-width: 35px;
      max-height: 35px;
      margin-right:15px;
  }
  .CategoryIconRTL{
    max-width: 35px;
    max-height: 35px;
    margin-left:15px;
}
  .ContactInfoIcon{
    width:30px;
    margin-right:15px;
    }
    .ContactInfoIconRTL{
      width:30px;
      margin-left:15px;
    }

    .LinkIcon{
      width:25px;
      margin-right:15px;
    }
    .LinkIconRTL{
      width:25px;
      margin-left:15px;
    }
    .ContactInfo{
      width:100%;
      height:100%;
      padding-top: 7px;
      border-top:1px solid rgb(235,235,235);
    }
      
    .ContactInfoHeader{
    color: rgb(88,88,88);
    font-size: 1.2em;
    font-weight: 500;
    }
    
    .QuickLinks{
    padding:7px 2.5%;
    width:95%;
    display: flex;
    align-items: center;
    }
    .Phone{
      display:flex;
      align-items: center;
      padding:7px 0;
    }
    .LikedSection{
      position: fixed;
      top:2vh;
      right:35vw;
      z-index: 100;
      color: white;
    }

    .LikedSectionRTL{
      position: fixed;
      top:2vh;
      left:35vw;
      color: white;
      z-index: 100;
    }
    .LikedIcon{
      height:20px;
    }
  }

  @media screen and (min-width: 768px) and (max-width:1023px){
    .BodyContent{
      width:100%;
      padding: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height:70vh;
    }
    .PlaceOverview{
      width:90%;
      padding: 0 5%;
    }
    .TopSection{
      position: relative;
      z-index: 1;
    }
    .TitleTopSection{
      width:95%;
      padding: 1vh 2.5%;
      min-height:8vh;
      display: flex;
      flex-direction: column;
      background-color: #F5F5F5;
      transition: 0.3s;
    }
    .TitleTopSectionSlim{
      width:95%;
      padding: 1vh 2.5%;
      min-height:4vh;
      display: flex;
      flex-direction: column;
      background-color: #F5F5F5;
      transition: 0.3s;
    }
    .PlaceShortcuts{
      width: 70%;
      height:40px;
      z-index: 10;
      align-self: flex-end;
      font-size: 1.1em;
    }
    .PlaceShortcutsLTR{
      width:50%;
      padding: 0;
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top:5px;
    }
    .PlaceShortcutsRTL{
      width:50%;
      padding: 0;
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top:5px;
    }
    .PlaceLanguage{
      position:fixed;
      margin:7px;
      top:0;
      right:0;
    }
    .PlaceLanguageRTL{
      position:fixed;
      margin:7px;
      top:0;
      left:0;
    }
    .Tools{
      right:-45vw;
    }
    .ToolsRTL{
      left:-45vw;
    }
    .LanguageTools{
      position:relative;
      z-index: 100;
      font-size: 0.9em;
      right:45vw;
    }
    .LanguageToolsRTL{
      position:fixed;
      z-index: 100;
      font-size: 0.9em;
      left:45vw;

    }
    .ToolsIcon{
      height: 25px;  
    }
    .Title{
      width: 100%;
      resize: none;
      font-size: 1.5em;
    }
    .GoogleRank{
      font-size: 0.8em;
      width:100%;
      justify-content: flex-start;
    }
    .GoogleRankIcon{
      height:16px;
    }

  .Map{
    height:45vh;
    width:95%;
    margin-top: 20px;
    border-top: 1px solid rgb(223,223,223);
    padding: 20px 2.5% ;
    font-size: 0.9em;
  }

  .DescriptionSection {
    margin-top: 20px;
    border-top: 1px solid rgb(223,223,223);
    width:95%;
    font-size:1em;
    height: auto;
    padding: 0;
    padding: 20px 2.5% ;
  }
  .DescriptionInputField {
    width: 100%;
    outline: none;
    border: none;
  text-align: start;
    font-size: 1em;
    font-weight: 400;
    color: rgb(85, 85, 85);
    margin-top: 10px;
  
    margin-bottom: 10px;
  }
  .PlaceInfoSection{
    background-color: white;
    width:95%;
    padding:1vh 2.5%;
    height:auto;
    margin-top: 15px;
    border-top: 1px solid rgb(223,223,223);
    }

  .CategoriesSection{
    margin:10px 0;
    text-align: start;
    font-size: 1.1em;
    font-weight: 500;
    color: rgb(88, 88, 88);
    display:flex;
    align-items: center;
  }
    

  .CategoryList{
   display:flex;
   align-items: center;
  }

  .CategoryIcon{
      max-width: 35px;
      max-height: 35px;
      margin-right:15px;
  }
  .CategoryIconRTL{
    max-width: 35px;
    max-height: 35px;
    margin-left:15px;
}
  .ContactInfoIcon{
    width:30px;
    margin-right:15px;
    }
    .ContactInfoIconRTL{
      width:30px;
      margin-left:15px;
      }
    
      .LinkIcon{
        width:25px;
        margin-right:15px;
      }
      .LinkIconRTL{
        width:25px;
        margin-left:15px;
      }
    .ContactInfo{
      width:100%;
      height:100%;
      padding-top: 7px;
      border-top:1px solid rgb(235,235,235);
      }
      
      .ContactInfoHeader{
      color: rgb(88,88,88);
      font-size: 1.2em;
      font-weight: 500;
      }
      
      .QuickLinks{
        padding:7px 2.5%;
        width:95%;
      display: flex;
      align-items: center;
      }
      .Phone{
        display:flex;
        align-items: center;
        padding:7px 0;
        }
        .LikedSection{
          position: absolute;
          bottom:-4vh;
          right:5vw;
          z-index: 100;
        }
    
        .LikedSectionRTL{
          position: absolute;
          bottom:-4vh;
          left:5vw;
          z-index: 100;
        }
        .LikedIcon{
          height:20px;
        }
  }