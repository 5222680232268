
.TasksWall{
    margin:10px 0;
    width:100%;
    height:70vh;;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.TasksWall::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: #F5F5F5;
}

.TasksWall::-webkit-scrollbar
{
width:3px;
background-color: #F5F5F5;
}

.TasksWall::-webkit-scrollbar-thumb
{
border-radius: 5px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: rgba(94, 94, 94, 0.562);
}


.AddButton{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}

.PlusIcon{
    width:30px;
}

.AddButton:hover{
    cursor: pointer;
    background-color: rgba(247, 247, 247, 0.562);
}

.Tasks{

}

.NoTasksMessage{
    color:rgb(100,100,100);
    font-size: 1.1em;
    text-align: center;
    padding:10px 0;
}
@media screen and (max-width: 767px) {  
.TasksWall{
    margin:10px 0;
    width:100%;
    height:100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.DeleteSwipe{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: #b22222;
      font-weight:500;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .TasksWall{
        margin:10px 0;
        width:100%;
        height:100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    
    .DeleteSwipe{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        color:white;
        background-color: #b22222;
          font-weight:500;
        }
    }