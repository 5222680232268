.Wrapper{
    width:100%;

    padding: 5px 0 ;
}

.Body{
    display: flex;
    align-items: center;
   
    flex-direction: column;
    justify-content: center;
    height: 6vh;
    padding: 1vh 10px;
    width:100%;
   
}

.BodyRTL{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 6vh;
    padding: 1vh 10px;
    width:100%;
}

.Selected{

}

.Text{
    color: rgb(110,110,110);
    text-align: center;
}

.TextSelected{
    font-weight: 500;
    color: rgb(80,80,80);
    text-align: center;
}

.Horizontal{
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
}

.HorizontalMargin{
    margin-right:10px;
}

.HorizontalMarginRTL{
    margin-left:10px;
}


@media screen and (min-width: 1024px) {
    .Wrapper:hover{
        cursor: pointer;
        background-color: rgb(241, 241, 241);
    }
    .Icon{
        height:40px;
    }
}

@media screen and (max-width: 767px) {
    .Icon{
        height:30px;
    }
    .Wrapper{
        font-size:0.9em;
        border:none;
    }
    .Body{
        height: 6vh;
        /* padding: 1vh 5px; */
        padding:0;
        max-width:100%;
        text-align: center;
        
    }
    .BodyRTL{
        height: 6vh;
    /*     padding: 1vh 5px; */
        max-width:100%;
        padding: 0;
        text-align: center;
    }
    
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Icon{
        height:40px;
        margin-bottom:7px;
    }
    .Body{
        height: 7vh;
        padding: 1vh 10px;
        width:100%;
    }
    .BodyRTL{
        height: 7vh;
        padding: 1vh 10px;
        width:100%;
    }
}