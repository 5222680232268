.Body{
    height:90%;
    padding-bottom: 0;
    width:95%;
    padding: 0 2.5%;
    background-color: rgb(255, 255, 255);
    position: relative;
}

.DaysSection{
    display:flex;
    align-items: center;
    margin:10px 0;
    width:90vw;
    overflow-x: auto;
}
.DaysSection::-webkit-scrollbar{
    height: 0;
}


.Day{
    padding:7px 10px;
    margin:0 2px;
    /* border:1px solid rgb(230,230,230); */
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    color:rgb(90,90,90);
    color:rgb(255, 255, 255);
    background-color: rgb(148, 148, 148);
    min-width: 30%;;
  /*   background-image: linear-gradient(to right, rgba(194, 194, 194, 0.781) 0%, rgba(228, 228, 228, 0.712) 51%, rgba(231, 231, 231, 0.514) 100%) */
}

.SelectedDay{
    padding:7px 10px;
    margin:0 2px;
    color:white;
   /*  background-color: rgb(255,119,0); */
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    /* background-image: linear-gradient(to right, rgba(255, 119, 0, 0.781) 0%, rgba(230, 107, 30, 0.712) 51%, rgba(235, 136, 75, 0.623) 100%) */
    background-color: rgb(34,121,190);
    min-width: 30%;;
}

.Wall{
    height:82vh;
    width:85%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5vh 5%;
}
 .Wall::-webkit-scrollbar{
    width: 0;
}

.Album{
    height:92.8%;
    position: relative;
    display:flex;
    align-items: center;
    width:100vw;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: whitesmoke;
    white-space: nowrap;
}


.AlbumNavigaition{
    position:fixed;
    right:5vw;
    top:1vh;
    z-index: 20;
    display: flex;
    align-items: center;
}

.AlbumNavigationRTL{
    position:fixed;
    left:5vw;
    top:1vh;
    display: flex;
    align-items: center;
    z-index: 20;
}

.AlbumDate{
    position: relative;;
}
.Button{
    height:3vh;
    padding:1vh 20px;
    border-radius: 5px;
    border:1px solid white;
    color:white;
    transition: 0.6s;
    margin: 0 15px;
}

.ButtonSelected{
    height:3vh;
    padding:1vh 20px;
    border-radius: 5px;
    border:1px solid white;
    color:white;
    background-color: rgba(248, 248, 248, 0.329);
    transition: 0.6s;
    margin: 0 15px;
}

.DatesOptions{
    position:absolute;
    top:5.5vh;
    right:10px;
}

.DatesOptionsRTL{
    position:absolute;
    top:5.5vh;
    left:10px;
}
.DatesSelection{
    
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    min-height:200px;
    max-height:300px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width:200px;
    padding: 10px;
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.Date{
    width:90%;
    height:3vh;
    padding:1vh 5%;
    margin:3px 0;
    display:flex;
    align-items: center;
    justify-content: center;
    color:rgb(100,100,100);
    background-color: rgb(255, 255, 255);
    border-top:1px solid white;
    border-bottom: 1px solid white;

}

.DateGrey{
    width:90%;
    height:3vh;
    padding:1vh 5%;
    margin:3px 0;
    display:flex;
    align-items: center;
    justify-content: center;
    color:rgb(168, 168, 168);
    background-color: rgb(255, 255, 255);
    border-top:1px solid white;
    border-bottom: 1px solid white;
}

.DateSelected{

}


.ArrowsSection{
    min-width:95%;
    padding: 0 2.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:absolute;
    top: 15vh;
}


.Arrow{
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 1);
    transition: 0.4s;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    width:40px;
    height:40px;
    padding:10px;
    position:relative;
    z-index: 10;
}



.RotateArrow{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.HideBack{
    transform: translateY(-100vh);
}

.HideForward{
    transform: translateY(-100vh);
}



@media screen and (min-width: 1024px) {
    .Modal{
        position:fixed;
        height:100vh;
        width:100%;
        z-index: 80;
        background-color: white;
    }
    .Day:hover{
        cursor: pointer;
    }
    .SelectedDay:hover{
        cursor: pointer;
    }
    .Button:hover{
        cursor: pointer;
        background-color: rgba(248, 248, 248, 0.329);
        transition: 0.3s;
    }
    .ButtonSelected:hover{
        cursor: pointer;
    }
    .Date:hover{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.555);
        box-shadow: 0 1px 1px rgba(161, 161, 161, 0.15), 0 2px 2px rgba(189, 189, 189, 0.15),
        0 4px 4px rgba(204, 204, 204, 0.055), 0 3px 3px rgba(224, 224, 224, 0.15);
    }
    .Arrow:hover{
        cursor: pointer;
        opacity: 0.9;
        transition: 0.4s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .AlbumPhotos{
        height:88vh;
        width:90%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 2vh 5%;
        display:flex;
        flex-wrap: wrap;
    }
     .AlbumPhotos::-webkit-scrollbar{
        width: 0;
    }
    
    
}

@media screen and (max-width: 767px){
    .Body{
        width:100%;
        padding:0;
    }
    .Wall{
        height:80vh;
        width:100%;
        padding: 2% 0;

    }
    .Album{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        position: relative;
        margin:1.5vh 0%; 
        height:80vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .DaysSection{
        margin:0;
        width:100vw;
    }
    .Day{
        min-width: 45%;
    }
    .SelectedDay{
        min-width: 45%;
    }
    .Button{
        height:3vh;
        padding:0.5vh 10px;
        border-radius: 5px;
        border:1px solid white;
        color:white;
        transition: 0.6s;
        margin: 0 5px;
        font-size: 0.8em;;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    
    .ButtonSelected{
        height:3vh;
        padding:0.5vh 10px;
        border-radius: 5px;
        border:1px solid white;
        color:white;
        background-color: rgba(248, 248, 248, 0.329);
        transition: 0.6s;
        margin: 0 5px;
        font-size: 0.8em;;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .AlbumPhotos{
        height:85vh;
        width:98%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 1vh 1%;

       
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Body{
        width:100%;
        padding:0;
    }
    .Wall{
        height:70vh;

    }
    .DaysSection{
        margin:0;
        width:100vw;
    }
}