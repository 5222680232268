.Body{
    width:100%;
    height:100%;
}
.Saving {
    position: fixed;
    top: 40%;
    width: 20%;
    left: 40%;
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SavingGif {
    width: 30%;
    animation: rotate 1s ease;
    animation-iteration-count:infinite;
    transition: ease-out 1s;
  }

  @keyframes rotate {
    0% { transform: rotateZ(0deg); }
    0% { transform: rotateZ(-180deg); }
    100% {  transform: rotateZ(0deg);   }
  }

  .ProgressBar{
    position: fixed;
    top: 60%;
    width: 40%;
    left: 30%;
    z-index: 800;
    display: flex;
    align-items: center;
    height:30px;
    border-radius: 10px;
    background-color: rgb(245,245,245);
  }

  .Progress{
    height:100%;
    background-color: rgb(255,119,0);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }



  @media(max-width:767px){
    .Saving {
      position: fixed;
      top: 40%;
      width: 40%;
      left: 30%;
      z-index: 900;
    }
    
    .SavingGif {
      width: 60%;
      animation: rotate 1s ease;
      animation-iteration-count:infinite;
      transition: ease-out 1s;
    }

    .ProgressBar{
      width: 80%;
      left: 15%;
    }
  }