.Wall{
    overflow-y: auto;
    height:100%;
    width:100%;
    display:flex;
    flex-wrap: wrap;
    position: relative;
}

  
.Wall::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}


.MobileWall{
    overflow-y: auto;
    overflow-x: hidden;
    height:100%;
    width:100%;
    display:flex;
    flex-wrap: wrap;
    position: relative;
   
}

.GettingData{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:10vh;
    font-size:1.2em;
    color:rgb(100,100,100);
}

.NextBackButtons{
    height:15vh;
    display:flex;
    align-items: center;
    justify-content: center;
    min-width:100%;
    font-size: 1.3em;
    padding-bottom: 2vh;
}

.PagesButtonGreyed{
    margin:0 20px;
    padding:10px;
    color:rgb(150,150,150);
}


.PagesButton{
    margin:0 20px;
    padding:10px;
}

.PagesButton:hover{
    border-radius: 7px;
    cursor: pointer;
    background-color: rgb(240,240,240);
}

.MobileNextBackButtons{
    direction: rtl;
    height:10vh;
    display:flex;
    align-items: center;
    justify-content: space-between;
    min-width:70%;
    margin:0 15%;
    font-size: 1.3em;
    margin-bottom:5vh;
}

.MobilePagesButtonGreyed{
    margin:0 20px;
    padding:10px;
    color:rgb(150,150,150);
}


.MobilePagesButton{
    height:50px;
    width:50px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
