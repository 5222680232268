.Wrapper{
    min-height:7vh;
    width:100%;
    margin:5px 0;
}

.TopSection{
    display: flex;
    padding: 5px 0;
    justify-content: space-between;
    align-items: center;
    min-height:7vh;
    width:100%;
    background-color: rgb(248,248,248);
}

.TopSectionOpen{
    display: flex;
    padding: 5px 0;
    justify-content: space-between;
    align-items: center;
    min-height:7vh;
    width:100%;
    background-color: rgb(248,248,248);
}

.OpenBody{
    position: relative;
    width:98%;
    height:auto;
    z-index: 120;
    padding: 0 0.5%;
   
    border-radius: 5px;
}

.ClosedBody{
background-color: red;
height:auto;
width:100%;
}


.CheckIcon{
    margin-right:15px;
    width:26px;
    height:26px;
  }

  .CheckIconRTL{
    margin-left:15px;
    width:26px;
    height:26px;
  }

  
  .UncheckBox{
    margin-right:15px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  
  }

  .UncheckBoxRTL{
    margin-left:15px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }

  .TitleSection{
    font-size:1.2em;
    padding:3px 5%;
   width:auto;
    height:100%;
    font-family: inherit;
    display: flex;
    align-items: center;
    color:rgb(100,100,100);
   
}

.CompletedTitle{
    text-decoration: line-through;
}

.TitleInput{
    width:90%;
    height:auto;
    color:rgb(100,100,100);
    font-size:1.1em;
   
    font-family: inherit;
    display: flex;
    border:none;
    background-color: rgba(255, 255, 255, 0);

}
.TitleInput:focus{
    outline: none;
}

.Tools{
    display:flex;
    align-items: center;
    position:relative;
    left:0;
}

.ToolsRTL{
    display:flex;
    align-items: center;
    position:relative;
    right:0;
}

.TextSection{
    direction: ltr;
    width:95%;
    padding: 0 2.5%;
    border-bottom: 1px solid rgb(230,230,230);
}

.InfoInputRTL{
    direction: rtl;
}
.AddTaskSection{
    width:100%;
    margin:5px 0;
    display: flex;
    align-items: center;
    color:rgb(100,100,100);
    justify-content: center;
}

.SubTask{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width:95%;
    border-top:1px solid rgb(230,230,230);
    padding:5px;
    margin: 10px 2.5%;
    min-height:50px;
}

.TaskBody{
    width:100%;
}

.TaskContent{
    border: 1px solid rgb(235, 235, 235);
}
.ToolbarClassname{
    border:none;
}

.TaskTools{
    width:30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.SubTaskTools{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.SubTaskToolkit{
    display:flex;
    align-items: center;
}

.TaskText{
    color: rgb(88,88,88);
    width:95%;
    padding: 7px 0;
   
}
.TaskTextInput{
    min-width:100%;
    max-width: 100%;;
    border:none;
    font-size:inherit;
    font-size:1.2em;
    color:inherit;
    padding: 5px;
    padding-bottom:10px;
    border-bottom:1px solid rgb(230,230,230);
}


.TaskTextInput:focus{
    outline: none;
}


.Owner{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    width:auto;
}

.OwnerRTL{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 20px;
    width:auto;
}

.OwnerName{
    font-size:0.8em;
}

.OwnerImage{
    width:50px;
    border-radius: 50%;;
}


.RemoveIcon{
    height:20px;
    margin-right:25px;
}
  
.RemoveIconRTL{
    height:20px;
    margin-left:25px;
}
@media screen and (min-width: 1024px) {  
    .TopSection:hover{
        cursor: pointer;
    }
    .DetailIcon:hover{
        cursor: pointer;
    }
    .DetailIconRTL:hover{
        cursor: pointer;
    }

    .DetailIconWhite:hover{
        cursor: pointer;
    }
    .DetailIconWhiteRTL:hover{
        cursor: pointer;
    }
    .CheckIcon:hover{
        cursor: pointer;
    }
    .CheckIconRTL:hover{
        cursor: pointer;
    }

    .UncheckBox:hover{
        cursor: pointer;
    }
    .UncheckBoxRTL:hover{
        cursor: pointer;
    }
    .AddTask:hover{
        cursor: pointer;
        background-color: rgb(245,245,245);
    }
    .Owner:hover{
        cursor: pointer;
    }
    .OwnerRTL:hover{
        cursor: pointer;
    }
    .RemoveIcon:hover{
        cursor: pointer;
    }
    .RemoveIconRTL:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px) {  
    .DeleteSwipe{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        color:white;
        background-color: #b22222;
      font-weight:500;
    }
    .SubTask{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width:95%;
        border-top:1px solid rgb(230,230,230);
        padding:5px;
        margin: 0 2.5%;
        min-height:40px;
    }
}

    
@media screen and (min-width: 768px) and (max-width:1023px){

}