.Body{
    height:85vh;
    padding-bottom: 20vh;;
    width:90%;
    padding: 0 5%;
    background-color: rgb(255, 255, 255);
    position: relative;
}

.DaysSection{
    display:flex;
    align-items: center;
    margin:10px 0;
    width:90vw;
    overflow-x: auto;
}
.DaysSection::-webkit-scrollbar{
    height: 0;
}


.Day{
    padding:7px 10px;
    margin:0 2px;
    /* border:1px solid rgb(230,230,230); */
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    color:rgb(90,90,90);
    color:rgb(255, 255, 255);
    background-color: rgb(148, 148, 148);
    min-width: 30%;;
  /*   background-image: linear-gradient(to right, rgba(194, 194, 194, 0.781) 0%, rgba(228, 228, 228, 0.712) 51%, rgba(231, 231, 231, 0.514) 100%) */
}

.SelectedDay{
    padding:7px 10px;
    margin:0 2px;
    color:white;
   /*  background-color: rgb(255,119,0); */
    border-radius: 0;
    white-space: nowrap;
    font-size: 1.2em;
    /* background-image: linear-gradient(to right, rgba(255, 119, 0, 0.781) 0%, rgba(230, 107, 30, 0.712) 51%, rgba(235, 136, 75, 0.623) 100%) */
    background-color: rgb(34,121,190);
    min-width: 30%;;
}

.Wall{
    height:78vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10vh;
}
 .Wall::-webkit-scrollbar{
    width: 0;
}

@media screen and (min-width: 1024px) {
    .Day:hover{
        cursor: pointer;
    }
    .SelectedDay:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px){
    .Body{
        width:100%;
        padding:0;
    }
    .Wall{
        height:70vh;

    }
    .DaysSection{
        margin:0;
        width:100vw;
    }
    .Day{
        min-width: 45%;
    }
    .SelectedDay{
        min-width: 45%;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Body{
        width:100%;
        padding:0;
    }
    .Wall{
        height:70vh;

    }
    .DaysSection{
        margin:0;
        width:100vw;
    }
}