.RTL{
  direction: rtl;
}

.OpenHoursPreview{
  width:100%;
  height:auto;
  font-size:1.1em;
  position: relative;
}
.OpenHoursPreviewEditMode{
  width:100%;
/*   padding: 10px 0; */
  height:auto;
  font-size:1.1em;
  position: relative;
/*   border-bottom: 1px solid rgb(223,223,223); */
}

.HoursIcon{
  height: 35px;
  margin-right: 15px;
}
.HoursIconRTL{
  height: 35px;
  margin-left: 15px;
}

.DetailIcon{
  z-index: 10;
  position: absolute;
  width: 16px;
  right:0;
  top:20px;
}

.DetailIconRTL{
  z-index: 10;
  position: absolute;
  width: 16px;
  left:0;
  top:20px;
}

.DetailIconMoved{
  width: 18px;
  margin-left:5px;
}

.DetailIconMovedRTL{
  width: 18px;
  margin-right:5px;
}

.DetailIcon:hover{
  cursor: pointer;
}
.DetailIconMoved:hover{
  cursor: pointer;
}

.DaysHoursList{
  width: 98%;
  height: auto;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 1%;
  top:10px;
  margin-bottom:20px;
  color:rgb(90,90,90);
}

.DaysHoursListEditMode{
  width: 98%;
  height: auto;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 1%;
  top:10px;
  margin-bottom:20px;
  color:rgb(90,90,90);
}


.TodayHours{
  display: flex;
  align-items: center;
  padding: 5px 0;
  height:40px;
  width:100%;
}
.TodayHoursEditMode{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
 height:50px;
 padding:5px 0;
}

.TodayHoursEditMode:hover{
  cursor: pointer;
}

.TodayName{
  display: flex;
  align-items: center;
  width:auto;
 color: rgb(34,121,190);
 font-weight: 500;
}

.WeekDay{
  width:95%;
  height:100%;
  margin: 3px 0;
  padding: 5px;
  border-bottom:0.7px solid rgb(241, 241, 241);
}

.DayOfWeek{
  display: flex;
  align-items: flex-start;
}

.DayOfWeekToday{
  display: flex;
  align-items: flex-start;
  color: rgb(34,121,190);
 font-weight: 500;
}

.DayName{
  width:100px;
}

.OpenHours{
  display: flex;
}

.OpenHoursToday{
  display: flex;
  color: rgb(34,121,190);
  font-weight: 500;
  width:auto;
  margin:0 10px ;
}

.HoursRow{
  display: flex;
  width:100%;
  height:100%;
}

.HoursRange{
  display: flex;
  align-items: flex-start;
}

.RangeSeperator{
  width:15px;
}

.Clickable:hover{
  cursor: pointer;
}

.Closed{
  padding: 5px 2.5%;
  width:95%;
  background-color: #b22222;
  color:white;
  font-weight: 500;
}

@media(max-width:767px){
  .DaysHoursList{
    width:100%;
    padding: 10px 0;
    font-size:0.9em;
  }
  .OpenHoursPreviewEditMode{
    width:100%;
    padding: 10px 0;
    font-size:0.9em;
  }
  .DetailIcon{
    z-index: 15;
    right:5px;
  }
  .DetailIconRTL{
    z-index: 15;
    left:5px;
  }
  .DetailIconMoved{
    width: 12px;
      margin-right:10px;
  }
  .DetailIconMovedRTL{
    width: 12px;
      margin-left:10px;
  }
  .TodayName{
    width:auto;
  }
  .HoursIcon{
    height: 25px;
    margin-right: 15px;
  }
  
  .HoursIconRTL{
    height: 25px;
    margin-left: 15px;
  }
  .OpenHoursPreviewEditMode{
    border-bottom: 1px solid rgb(240, 240, 240);
  }
  .TodayHoursEditMode{
    padding: 0;
  }
  
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .DaysHoursList{
    width:100%;
    padding: 10px 0;
    font-size:0.9em;
  }
  .OpenHoursPreviewEditMode{
    width:100%;
    padding: 10px 0;
    font-size:0.9em;
  }
  .DetailIcon{
    z-index: 15;
    right:5px;
  }
  .DetailIconRTL{
    z-index: 15;
    left:5px;
  }
  .DetailIconMoved{
    width: 16px;
      margin-right:10px;
  }
  .DetailIconMovedRTL{
    width: 16px;
      margin-left:10px;
  }
  .TodayName{
    width:auto;
  }
  .HoursIcon{
    height: 35px;
    margin-right: 20px;
  }
  
  .HoursIconRTL{
    height: 35px;
    margin-left: 20px;
  }
  
}