.RTL{
  direction: rtl;
}

.Body{
  position:relative;
}
.ClearIcon{
  height: 15px;
  cursor: pointer;
  top:20px;
}
.Icon {
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.Header {
  color: rgb(255, 255, 255);
  font-size: 1.2em;
}

.SaveButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
  background-color: rgb(44, 186, 143);
  color: white;
  border-radius: 20px;
  font-size: 1.3em;
}

.Autocomplete {
  width: 90%;
  margin: 20px 5%;
  position:relative;

}

.InputSection {
  height: 50px;
  width: 100%;
  font-weight: 400;
  align-items: center;
  display:flex;
  border-radius:20px;
  border: 0.8px solid rgb(221, 221, 221);
}

.InputSectionWithSuggestions {
  height: 50px;
  width: 100%;
  color: rgb(88, 88, 88);
  align-items: center;
  border: 0.8px solid rgb(221, 221, 221);
  -webkit-border-top-left-radius: 20px;;
  -webkit-border-top-right-radius: 20px;;
  -moz-border-radius-topleft: 20px;;
  -moz-border-radius-topright: 20px;;
  border-top-left-radius: 20px;;
  border-top-right-radius: 20px;;
  position: relative;
}


.AutocompleteInput {
  color: rgb(88, 88, 88);
  border: none;
  margin: 0 5%;
  font-size: 1.1em;
  font-weight: normal;
  font-family: inherit;
  height: 90%;
  width: 80%;
}
.AutocompleteInput:focus {
  outline: none;
}


.SuggestionsSection {
  
  display: flex;
  position: absolute;
  padding: 5px 2.5%;
  line-height: 2em;
  width: 95%;
  flex-direction: column;
  border: 0.8px solid rgb(221, 221, 221);
  z-index: 200;
  overflow-y: auto;
  height: 160px;
  margin-bottom: -180px;
  background-color: rgb(255, 255, 255);
  -webkit-border-bottom-left-radius: 15px;
  -webkit-border-bottom-right-radius: 15px;
  -moz-border-radius-bottomleft: 15px;
  -moz-border-radius-bottomright: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-bottom: 15px;
}


@media (max-width: 500px) {
  .Modal{
  }
  .Body {
    min-height: 93;
    max-height: 93;
  }
  .TopSection {
    height: 30px;
  }
  .Icon {
    margin-right: 10px;
  }
  .SaveButton {
    width: 70px;
    height: 25px;
    font-size: 1.1em;
  }
  .InputSection {
    height: 35px;
    border-radius: 15px;
  }.InputSectionWithSuggestions{
    height: 35px;
  }
  .InputType{
    font-size:0.8em;
    height:100%;
    justify-content: space-between;
  }
  .InputTypeSelected{
    margin:0 5px;
    padding: 6px;
    border-radius: 7px;
    width:30%;
    height: 12vh;
  }
  .InputTypeRegular{
    margin:0 5px;
    padding: 6px;
    border-radius: 7px;
    width:30%;
    height: 12vh;
  }
  
.CheckedButton{
  height:14px;
  margin-right:5px;
}

.ClearButton{
  height:14px;
  margin-right:5px;
}
  .AutocompleteInput {
    margin: 3px 5%;
    width:90%;
    height:90%;
    padding: 1px 1%;

    font-size: 1em;
  }
  .CurrentLocation {
    margin-top: 20px;
    font-size: 0.9em;
    height: 35px;
    width: 80%;
    margin-left: 10%;
  }
  .LocationInstructions{
    width:100%;
    left:0;
  }
  .SuggestionsSection{
    font-size:0.9em;
    padding:0 2%;
    width:96%;

  }
  .CountryRequired{
   
    width: 80%;
    left: 5%;
  }
  .LocationPath{
    padding: 6px 0;
    padding-bottom: 15px;
    font-size:1em;
  }

  .AddAddressComponent{
    width:12px;
    height:12px;
    padding:7px;
    font-size:1.1em;

  }
  .AddAddressComponent:hover{
    cursor: pointer;
    border:1px solid rgba(34, 120, 190, 0.322);

    font-weight: 600;
  }

  .ComponentInput{
    display: flex;
    position:relative;
    width:auto;
    height: auto;
    z-index: 300;
    align-items: flex-start;
    left:10px;
    padding-bottom: 20px;
  }

  .AddressInput{
    height:30px;
    width:200px;
    font-size: 1em;
  }
  .AddressInput:focus{
    outline: none;
  }

  .AddButton{
    margin-left:10px;
    padding: 5px 10px;
    border: 0.7px solid rgb(200,200,200);
    border-radius: 5px;
  }
  .MessageHeader{
    font-size: 1em;
  }
  .LocationSuggestions {
    position: absolute;
    top: 30px;
    width: 203px;
    height: 200px;
    background-color: white;
    border: 1px solid rgb(233, 227, 227);
    z-index: 200;
    overflow-y: auto;
  }
  .RemoveIcon{
    position: absolute;
    right:20px;
    top:8px;
    height:14px;
    width:14px;
    cursor: pointer;
  }
  .DiscardButton{

  }
  
}
