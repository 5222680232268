.RegularTabV1 {
  position: relative;
  border: none;
  color: rgb(85, 85, 85);
  outline: none;
  cursor: pointer;
  font-weight: 500;
  padding:10px 20px;
  font-size: 1.1em;
  border-bottom:  2px solid  rgb(185, 185, 185);
  margin: 0 10px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
}

.RegularTabV1:focus {
  outline: 0;
}

.SelectedTabV1 {
  border-style: none;
  border-bottom: 2px solid  rgb(34, 121, 190);
  padding:10px 20px;
  font-weight: 500;
  font-size: 1.1em;
  color: rgb(34, 121, 190);
  font-weight: 600;
  margin: 0 10px;
  width:auto;
  overflow: hidden;
  white-space: nowrap;
}



.SelectedTabV1:focus {
  outline: 0;
}


.SelectedTabV2 {
  border-style: none;
  height:30px;
  background-color: rgb(34, 121, 190);
  font-weight: 600;
  color: white;
padding : 5px 25px;
  width: auto;
  font-size: 1.1em;
  border-radius:3px;

  -webkit-box-shadow: 0px -5px 10px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -5px 10px -5px rgba(0,0,0,0.75);
  box-shadow: 0px -5px 10px -5px rgba(0,0,0,0.75);
  margin: 0 10px;
  position: relative;
}



.SelectedTabV2:focus {
  outline: 0;
}


.RegularTabV2 {
  position: relative;
  height:30px;
  border-radius:3px;
  -webkit-box-shadow: 0px -5px 10px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -5px 10px -5px rgba(0,0,0,0.75);
  box-shadow: 0px -5px 10px -5px rgba(182, 182, 182, 0.75);
  padding : 5px 25px;
  border: none;
  color:  #2e2525ef;
  background-color: rgba(225, 225, 225, 0.452);
  outline: none;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 400;
  margin: 0 10px;
  width: auto;
}

.RegularTabV2:focus {
  outline: 0;
}

.RegularMobileWide{
  padding:5px 10px;
  font-size: 1.1em;
  height:35px;
  margin: 0 5px;
  width:100%;
  color:rgb(88,88,88);
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.SelectedMobileWide{
  padding:5px 10px;
  font-size: 1.1em;
  height:35px;
  margin: 0 5px;
  width:100%;
  color:rgb(34,121,190);
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}


@media screen and (min-width: 1024px) {
  .RegularTabV1:hover {
    color:rgb(34, 121, 190);
    cursor: pointer;
  }
  .SelectedTabV1:hover {
    cursor: pointer;
  }
  .SelectedTabV2:hover {
    cursor: pointer;
  }
  .RegularMobileWide{
    min-width:150px;
  }
  .SelectedMobileWide{
    min-width:150px;
  }
  .RegularTabV2:hover {
    color: rgb(34, 121, 190);
    font-weight:500;
    cursor: pointer;
  }
  .RegularMobileWide:hover{
    color: rgb(34, 121, 190);
    font-weight:500;
    background-color: rgb(250,250,250);
    cursor: pointer;
  }
  
}

@media screen and (max-width: 767px) {
  .RegularTabV1 {
    padding:5px 10px;
    font-size: 1em;
    height:25px;
    margin: 0 5px;
  }
  .SelectedTabV1 {
    padding:5px 10px;
    font-size: 1em;
    height:25px;
    margin: 0 5px;
  }
  .SelectedTabV2 {
    padding:5px 10px;
    font-size: 1em;
    height:25px;
    margin: 0 5px;
  }
  .RegularTabV2 {
    padding:5px 10px;
    font-size: 1em;
    height:25px;
    margin: 0 5px;
  }
  .RegularMobileWide{
    padding:5px 10px;
    font-size: 1em;
    height:25px;
    margin: 0 5px;
    width:100%;
    color:rgb(88,88,88);
    font-weight: 400;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
  }
  .SelectedMobileWide{
    padding:5px 10px;
    font-size: 1em;
    height:25px;
    margin: 0 5px;
    width:100%;
    color:rgb(34,121,190);
    font-weight: 500;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .RegularTabV1 {
    padding:5px 10px;
    font-size: 1.1em;
  }
  .SelectedTabV1 {
    padding:5px 10px;
    font-size: 1.1em;
  }
  .SelectedTabV2 {
    padding:5px 10px;
    font-size: 1.1em;
  }
  .RegularTabV2 {
    padding:5px 10px;
    font-size: 1.1em;
  }
  .RegularMobileWide{
    padding:5px 10px;
    font-size: 1.1em;
    height:25px;
    margin: 0 5px;
    width:100%;
    color:rgb(88,88,88);
    font-weight: 400;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
  }
  .SelectedMobileWide{
    padding:5px 10px;
    font-size: 1.1em;
    height:25px;
    margin: 0 5px;
    width:100%;
    color:rgb(34,121,190);
    font-weight: 500;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
  }
}