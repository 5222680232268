.SearchInput {
    background-color: white;
    position: relative;
    height: 60px;
    width: 98%;
    padding: 0 1%;
    display: flex;
    font-weight: 400;
    align-items: center;
    border-radius: 20px;
    border: 0.8px solid rgb(221, 221, 221);
  }

    
  .InputText {
    color: rgb(88, 88, 88);
    border: none;
    padding: 0 1.5%;
    font-size: 1.2em;
    font-weight: normal;
    font-family: inherit;
    width: 97%;
  }
  .InputText:focus {
    outline: none;
  }

  @media screen and (max-width: 767px) {
    .SearchInput{
      height:45px;
      padding: 0 2%;
      width:96%;
      margin:10px 0;
    }

    .InputText{
      font-size:1em;
    }
  }

  @media screen and (min-width: 768px) and (max-width:1023px){
    .SearchInput{
      height:45px;
      padding: 0 2%;
      margin:10px 0;
    }

    .InputText{
      font-size:1em;
    }
  }
  