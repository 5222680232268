.Modal{
    animation: scale 0.5s;
}
@keyframes scale {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
.RTL{
    direction: rtl;
}

.Body{
    width:90%;
    padding: 10px 5%;
}


.LogoSection{
    padding: 5px 0;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Logo{
    height:8vh;
}

.Title{
    padding:1vh 0;
    width:100%;
    margin:0;
    display: flex;
    align-items: center; 
    justify-content: center;
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
    color: #e25b0c;
}

.ExternalIcon{
    height:35px;
    margin-right: 15px;
}

.ExternalIconRTL{
    height:35px;
    margin-left: 15px;
}

.ExternalLoginBox{
    border: 1px solid rgb(233, 233, 233);
    height: 60px;
    width: 80%;
   display: flex;
   align-items: center;
   font-size: 1.2em;
   color :rgb(90, 90, 90);
   box-shadow: 2px 2px 2px #e6e6e6;
   margin: 10px 5%;
   padding: 0 5%;
   font-weight: 500;
}


.EmailOption{
    width: 100%;
    position:relative;
    margin-top: 20px;
    padding-top: 10px;
    border-top:  1px solid rgb(223, 223, 223);
}
.EyeIcon{
    display:none;
    cursor: pointer;
    height: 20px;
    position: relative;
    right:10px;
    z-index: 10;
}

.InputField{
    border-bottom: 1px solid rgba(170, 170, 170, 0.39);
    height: 40px;
    width: 95%;
    display: flex;
    align-items: center;
   /*  flex-direction: column; */
    font-size: 1em;
   color :rgb(90, 90, 90);
   margin: 10px 0;
   padding: 0 2.5%;
   font-weight: 300;
}
.InputText{
    border:none;
    font-size:1em;
    width:100%;
    color :rgb(90, 90, 90);
}
.InputText:focus{
    outline: none;
}

.ErrorMessage{
    font-size:0.9em;
    color:#b22222;
}
.Login{
    background-color: rgb(255,119,0);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 50%;
    margin: 10px 25%;
    height: 50px;
   border-radius: 7px;
   font-size: 1.1em;
  
}

.LoginFade{
    background-color: rgb(255,119,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 20px;
    width: 50%;
    margin: 20px 25%;
    height: 50px;
   border-radius: 7px;
   font-size: 1.1em;
  
}

.Register{
    display:flex;
}


.RegisterButton{
    margin-right: 10px;
    font-weight: 600;
    color:rgb(30, 38, 51); 
    cursor: pointer;
}

.ResetPasswordSection{
padding:5px 0;
}

.ResetPassword{
    font-size:1em;
    width:100%;
    color :rgb(90, 90, 90);
}

@media screen and (min-width: 1024px) {
    .Modal{
        position: fixed;
        height:80vh;
        top:10vh;
        width:35vw;
        left:32.5%;
        z-index: 700;
        background-color: white;
        box-shadow: 0 2px 2px #6e6e6e;
        border: 1px solid rgb(216, 216, 216);
        box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
        0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
        padding:0;
    }
    .ExternalLoginBox:hover{
        cursor: pointer;
        background-color: rgb(250,250,250);
    }
    

.Login:hover{
    cursor: pointer;
    background-color: rgb(255,119,0,0.8);
}
.ResetPassword:hover{
    cursor: pointer;
}
.Content{
    width:80%;
    padding: 0 10%;
}

}

@media screen and (max-width: 767px) {
    .Modal{
        position: fixed;
        height:100%;
        top:0;
        width:100%;
        z-index: 700;
        background-color: white;
        padding: 0;
    }
    
    .Body{
        width:90%;
        padding: 10px 5%;
        height:85%;
        padding-bottom:10%;
        overflow-y: auto;
        overflow-x: hidden;
    }

}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        position: fixed;
        height:100vh;
        top:0;
        width:100vw;
        z-index: 700;
        background-color: white;
        padding:0;
    }

    .Body{
        width:90%;
        padding: 10px 5%;
    }
}