.Body {
    width: 100%;
    padding: 0;
    overflow-y: auto;
    height: auto;
    position: relative;
  }
  
  .TopSection {
    width: 95%;
    padding: 5px 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(30, 38, 51);
    height: 40px;
  }
  
  .Icon {
    height: 25px;
    cursor: pointer;
  }
  
  .Header {
    color: rgb(255, 255, 255);
    font-size: 1.2em;
  }
  
  .SaveButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 35px;
    background-color: rgb(44, 186, 143);
    color: white;
    border-radius: 10px;
    font-size: 1.3em;
  }
  
  .SaveButtonGreyed {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 35px;
    background-color: rgb(120, 212, 185);
    color: white;
    border-radius: 10px;
    font-size: 1.3em;
  }
  
  .UploadSection {
    display: flex;
    position: absolute;
    top:70px;
    z-index:20;
    left: 50%;
    justify-content: center;
    align-items: center;
    height: 140px;
  }
  
  .ImageUploadWrapper {
    width: 250px;
    height: 70px;
    overflow: hidden;
    position: relative;
    margin-bottom: 5px;
    border: 0.6px solid rgb(182, 182, 182);
    padding: 1px;
  }

  .ImageUploadWrapper:hover{
    cursor: pointer;
  }
  

  .ImageUploadWrapper > input[type="file"] {
    font-size: 220px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
  
  .ImageUploadWrapper > .UploadButton {
    display: inline-block;
    width: 245px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
  }
  
  .UploadButton {
    background-color: white;
  
    border-radius: 5px;
    margin-bottom: 20px;
    color: rgb(87, 87, 87);
  }
  
  .Icon {
    cursor: pointer;
    height: 30px;
    margin-right: 20px;
  }
  
  .ImagesSection {
    direction: ltr;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    height:auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .Image{
    height:200px;
    width:200px;
    display:grid;
    overflow-y: none;
  }


  .Saving {
    position: fixed;
    top: 40%;
    width: 20%;
    left: 40%;
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SavingGif {
    width: 30%;
    animation: rotate 1s ease;
    animation-iteration-count:infinite;
    transition: ease-out 1s;
  }
  .Message{
    padding: 5px 0;
    color: rgb(35, 35, 167);
    font-size:0.9em;
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .PasteButtonDesktop{
    color: rgb(80,80,80);
    font-size:1.1em;
    padding: 7px 12px;
    border: 0.6px solid rgb(210,210,210);
    border-radius: 5px;
    margin-left:25px;
  }
  .PasteButtonDesktop:hover{
    cursor: pointer;
    font-size:1.2em;
    padding: 6px 10px;
  }
  .PasteButtonMobile{
    display:none;
  }
  @keyframes rotate {
    0% { transform: rotateZ(0deg); }
    0% { transform: rotateZ(-180deg); }
    100% {  transform: rotateZ(0deg);   }
  }

  .SingleImage{
    direction: ltr;
    width: 50%;
    display:flex;
    flex-wrap: wrap;
    height:auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  @media (max-width: 767px) {
    .Body {
        height: auto;
        width:100%;
        padding : 0;
    }
    .TopSection {
      height: 30px;
    }
    .SaveButton {
      width: 70px;
      height: 30px;
      font-size: 1.2em;
    }
    .SaveButtonGreyed {
      width: 70px;
      height: 30px;
      font-size: 1.2em;
    }
    .UploadSection {
      height: 120px;
    }
    .ImageUploadWrapper {
      width: 200px;
      height: 50px;
    }
    .ImageUploadWrapper > .UploadButton {
      width: 195px;
      height: 45px;
      font-size: 1.2em;
    }
    .Icon {
      height: 20px;
      margin-right: 15px;
    }
    .PasteButtonDesktop{
      display: none;
    }
    .PasteButtonMobile{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      color: rgb(80,80,80);
      font-size:0.9em;
      padding: 7px 12px;
      border: 0.6px solid rgb(210,210,210);
      border-radius: 5px;
      margin-left:25px;
      
    } 
    .PastInput{
      width: 50px;
      border:none;
      text-align: center;
      color: rgb(80,80,80);
      font-size:0.9em;
      
    }
    .PastInput:focus{

  font-size: 16px;
}
    .PastInput:focus{
      outline: none;
    }
    .Saving {
      position: fixed;
      top: 40%;
      width: 40%;
      left: 30%;
      z-index: 900;
    }
    
    .SavingGif {
      width: 60%;
      animation: rotate 1s ease;
      animation-iteration-count:infinite;
      transition: ease-out 1s;
    }
    .SingleImage{
      direction: ltr;
      width: 100%;
      justify-content: center;
    }
    
  }
  

  @media screen and (min-width: 768px) and (max-width:1023px){
    .Body {
      height: auto;
      width:100%;
      padding : 0;
  }
  .TopSection {
    height: 30px;
  }
  .SaveButton {
    width: 70px;
    height: 30px;
    font-size: 1.2em;
  }
  .SaveButtonGreyed {
    width: 70px;
    height: 30px;
    font-size: 1.2em;
  }
  .UploadSection {
    height: 120px;
  }
  .ImageUploadWrapper {
    width: 200px;
    height: 50px;
  }
  .ImageUploadWrapper > .UploadButton {
    width: 195px;
    height: 45px;
    font-size: 1.2em;
  }
  .Icon {
    height: 20px;
    margin-right: 15px;
  }
  .PasteButtonDesktop{
    display: none;
  }
  .PasteButtonMobile{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    color: rgb(80,80,80);
    font-size:0.9em;
    padding: 7px 12px;
    border: 0.6px solid rgb(210,210,210);
    border-radius: 5px;
    margin-left:25px;
    
  } 
  .PastInput{
    width: 50px;
    border:none;
    text-align: center;
    color: rgb(80,80,80);
    font-size:0.9em;
    
  }
  .PastInput:focus{

font-size: 16px;
}
  .PastInput:focus{
    outline: none;
  }
  .Saving {
    position: fixed;
    top: 40%;
    width: 40%;
    left: 30%;
    z-index: 900;
  }
  
  .SavingGif {
    width: 60%;
    animation: rotate 1s ease;
    animation-iteration-count:infinite;
    transition: ease-out 1s;
  }
  .SingleImage{
    direction: ltr;
    width: 60%;
    padding: 0 20%;
    justify-content: center;
  }
  }