
.TopSection{
    width:95%;
    height:7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 2.5%;
    border-bottom:1px solid rgb(223,223,223);
    background-color: white;
    position: relative;
  }
  
  .Title{
    left:40%;
    font-size:1.3em;
    color:rgb(68, 68, 68);
    font-weight: 500;
  }
  
  .CloseIcon{
    position:absolute;
    right:15px;
    height:22px;
  }

  .CloseIconRTL{
    height:22px;
    position:absolute;
    left:15px;
  }

  
  

  .FilterSection{
    width:100%;
    padding: 10px 0;
    border-top: 1px solid rgb(223,223,223);
  }

  .SectionHeader{
    height: 4vh;
    padding: 1vh 0;
    width:100%;
    font-size:1.2em;
    font-weight: 500;
    color: rgb(100,100,100);
  }

  .ShowLessFilters{

  }

  .ShowMoreFilters{

  }

  .FilterTags{
    padding:15px 0;
  }
  
  .Filters{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
    font-size:1em;
    padding:5px 0;
  }
  
  .ShowMoreFilters{
    cursor: pointer;
    padding:5px 0;
    font-weight: 500;
  color:rgb(34,121,190);
  }
  
  .ShowLessFilters{
    cursor: pointer;
    padding:5px 0;
    font-weight: 500;
    color:black;
  }

  .MainCategories{
    display:flex;
    flex-wrap: wrap;
    width:100%;
    align-items: center;
  }

  .Category{
   /*  padding:10px 2%; */
    width:auto;
    width:32%;
    font-size:0.8em;
}

.DaysList{
  width: 100%;
  padding: 15px 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Day{
  width:50px;
    height:50px;
    border-radius: 50%;
    border:0.7px solid rgb(223,223,223);
    font-size: 1em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color:rgb(90,90,90);
}

.DaySelected{
  width:50px;
  height:50px;
  border-radius: 50%;
  background-color: rgb(34,121,190,0.85);
  font-size: 1em;
  font-weight: 500;
  text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  color:white;
}

.SelectionSection{
  width:80%;
}

.DurationNumbers{
  display: flex;
  align-items: center;
  margin-left:10px;
  color:rgb(100,100,100);
  font-size:1.1em;

}

.DurationNumber{
  height:30px;
  margin:0 10px;
  width:50px;
  text-align: center;
  border:1px solid rgb(223,223,223);
  border-radius: 5px;
}

.DurationFilter{
  padding:5px 0;
  display: flex;
  align-items: center;

  width:auto;
  margin-right:20px;
  padding-right:20px;
  border-right:1px solid rgb(230,230,230);
}

.RangeSection{
  position: relative;
  margin:10px 0;
  width:80%;
  padding: 0 10%;
  direction: ltr;
}

.Participants{
  display: flex;
  align-items: center;
}


.CheckIcon{
  margin-right:12px;
  width:26px;
  height:26px;
}

.CheckIconRTL{
  margin-left:12px;
  width:26px;
  height:26px;
}


.UncheckBox{
  margin-right:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

.UncheckBoxRTL{
  margin-left:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}


.NoDatesSection{
  width:80%;
  display:flex;
  align-items: center;
  padding-bottom:15px;
}
.NoDatesButton{
  font-size:1.1em;
  color:rgb(88,88,88);
}
.NoDatesButtonSelected{
  font-size:1.1em;
  color:rgb(34,121,190);
}

.LocationsMessage{
  padding:5px 0;
  color: rgb(100,100,100);
}

@media screen and (min-width: 1024px) {  
    .ModalLTR{
    position: fixed;
    height:80vh;
    padding:10px 0;
    width:30vw;
    
    top:16vh;
    z-index: 25;
    border: 1px solid rgb(210,210,210);
    background-color: white;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
    direction: ltr;
    
    left:-100%;
    -webkit-animation: slideRight 0.7s forwards;
    animation: slideRight 0.7s forwards;
    }

@-webkit-keyframes slideRight {
    100% {left:2.5vw; }
}

@keyframes slideRight {
    100% { left:2.5vw; }
}

  .HideModalLTR{
    position: fixed;
    height:80vh;
    padding:10px 0;
    width:30vw;
    
    top:16vh;
    z-index: 25;
    border: 1px solid rgb(210,210,210);
    background-color: white;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
    direction: ltr;
    
    transform: translateX(-100%);
    transition:0.7s
  }


  .ModalRTL{
    position: fixed;
    height:80vh;
    padding:10px 0;
    width:30vw;
    
    top:16vh;
    z-index: 25;
    border: 1px solid rgb(210,210,210);
    background-color: white;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
    direction: rtl;
    right:-100%;
    -webkit-animation: slideLeft 0.7s forwards;
    animation: slideLeft 0.7s forwards;
  }

@-webkit-keyframes slideLeft {
    100% { right: 2.5vw; }
}

@keyframes slideLeft {
    100% { right: 2.5vw; }
}
  .HideModalRTL{
    position: fixed;
    height:80vh;
    padding:10px 0;
    width:30vw;
    
    top:16vh;
    z-index: 25;
    border: 1px solid rgb(210,210,210);
    background-color: white;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
    direction: ltr;
    transform: translateX(100%);
    transition:0.7s
  }

    .Body{
    height:67vh;
    padding:1vh 2.5%;
    padding-top:0;
    padding-bottom:5vh;
    width:95%;
    margin-top:1px;
    font-size:1em;
    overflow-x: hidden;
    overflow-y: auto;
    }
    
    .Body::-webkit-scrollbar-track-piece {
    background-color: white;
    }
    
    .Body::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    }
    
    .Body::-webkit-scrollbar
    {
    width: 5px;
    background-color: #F5F5F5;
    }
    
    .Body::-webkit-scrollbar-thumb
    {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(231, 231, 231, 0.562);
    }
      
  .CloseIcon:hover{
    cursor: pointer;
  }
  .CloseIconRTL:hover{
    cursor: pointer;
  }
  .Day:hover{
    background-color: rgba(241, 241, 241, 0.65);
    color:rgb(80,80,80);
    cursor: pointer;
  }

  .DaySelected:hover{
    background-color: rgb(34,121,190,0.65);
    cursor: pointer;
  }
  .CheckIconRTL:hover{
    cursor: pointer;
    }

.UncheckBox:hover{
    cursor: pointer;
  }

.UncheckBoxRTL:hover{
    cursor: pointer;
  }
 .RadioButton:hover{
   cursor: pointer;
 }
 .RadioButtonRTL:hover{
   cursor: pointer;
 }
    
}

@media screen and (max-width: 767px) {  
    .ModalLTR{
        position: fixed;
        display:flex;
        flex-direction: column;
        align-items: center;
        top:-100vh;
        height: 100%;
        padding:0;
        left:0;
        width:100vw;
        z-index: 50;
        background-color: white;
        direction: ltr;
        -webkit-animation: slideTop 0.5s forwards;
        animation: slideTop 0.5s forwards;
        }
      @-webkit-keyframes slideTop {
          0% { top: -100vh; }
          100% {top:0;}
      }
      
      @keyframes slideTop {
          0% { top: -100vh; }
          100% {top:0;}
      }

      .HideModalLTR{
        position: fixed;
        display:flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding:0;
        width:100vw;
        z-index: 35;
        background-color: white;
        direction: ltr;
        
        transform: translateY(-200vh);
        transition:1s
      }
    
      
    .RTL{
        direction: rtl;
        }
    .Body{
        height:75%;
        background-color: rgb(248,248,248);
        padding:1% 2.5%;
        padding-bottom:10%;
        width:95%;
        font-size:1em;
        overflow-x: hidden;
        overflow-y: auto;
        }

    .BottomSection{
      position:absolute;
      display:flex;
      justify-content: center;
      align-items: center;
      bottom:0%;
      z-index: 60;
      padding:3vh 0 ;
      padding-top:2vh;
      width:100%;
      height:5%;
      background-color: white;
    }
    .ShowResults{
      width:30%;
      padding:0 35%;
      border-radius: 5px;
      color:white;
      font-weight: 500;
      background-color: rgb(255,119,0);
      padding:10px 10px;
      text-align: center;
    }
   
    
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .ModalLTR{
    position: fixed;
    display:flex;
    flex-direction: column;
    align-items: center;
    top:-100vh;
    height: 95%;
    padding:0;
    left:0;
    width:100vw;
    z-index: 50;
    background-color: white;
    direction: ltr;
    -webkit-animation: slideTop 0.5s forwards;
    animation: slideTop 0.5s forwards;
    }
  @-webkit-keyframes slideTop {
      100% { top: 0; }
  }
  
  @keyframes slideTop {
      100% { top: 0; }
  }

  .HideModalLTR{
    position: fixed;
    display:flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding:0;
    width:100vw;
    z-index: 35;
    background-color: white;
    direction: ltr;
    
    transform: translateY(-200vh);
    transition:1s
  }

  
.RTL{
    direction: rtl;
    }
    .Body{
        height:70vh;
        padding:1vh 2.5%;
        padding-top:0;
        padding-bottom:5vh;
        width:95%;
        margin-top:1px;
        font-size:1em;
        overflow-x: hidden;
        overflow-y: auto;
        }
        .BottomSection{
          position:absolute;
          display:flex;
          justify-content: center;
          align-items: center;
          bottom:0%;
          z-index: 60;
          padding:3vh 0 ;
          padding-top:2vh;
          width:100%;
          background-color: white;
        }
        .ShowResults{
          width:30%;
          padding:0 35%;
          border-radius: 5px;
          color:white;
          font-weight: 500;
          background-color: rgb(255,119,0);
          padding:10px 10px;
          text-align: center;
        }
       
}