.Modal{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.RTL{
  direction: rtl;
}

.Body {
  width: 90%;
  position: relative;
  left:0;
  padding: 5vh 5%;
  overflow-y: auto;
  height: 65vh;
}

.Body::-webkit-scrollbar
{
width:0px;
}


.Row{
  width:100%;
  margin:15px 0;
  border-bottom: 0.6px solid rgb(225,225,225);
}

.RowContent{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.RowTopSection{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-bottom:20px;
}

.RowOptions{
  width:auto;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.TimeRage{
  display: flex;
  width: 60%;
  padding: 10px 5%;
  font-size:1.2em;
}

.DaysList{
  width: 90%;
  padding: 30px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Day{
  width:60px;
    height:60px;
    border-radius: 50%;
    border:0.7px solid rgb(223,223,223);
    font-size: 1.1em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color:rgb(90,90,90);
}
.Day:hover{
  background-color: rgba(241, 241, 241, 0.65);
  color:rgb(80,80,80);
  cursor: pointer;
}
.DaySelected{
  width:60px;
  height:60px;
  border-radius: 50%;
  background-color: rgb(34,121,190,0.85);
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  color:white;
}
.DaySelected:hover{
  background-color: rgb(34,121,190,0.65);
  cursor: pointer;
}


.TimeTitle{
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 400;

}

.SubRow{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width:90%;
  padding:10px 5%;
  border-bottom:1px solid rgb(230,230,230);
}

.SubRowHours{
  padding:5px 0;
  display: flex;
  align-items: center;
}

.CheckIcon{
  margin-right:12px;
  width:26px;
  height:26px;
}
.CheckIcon:hover{
cursor: pointer;
}
.CheckIconRTL{
  margin-left:12px;
  width:26px;
  height:26px;
}
.CheckIconRTL:hover{
  cursor: pointer;
  }

.UncheckBox{
  margin-right:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}
.UncheckBox:hover{
  cursor: pointer;
}
.UncheckBoxRTL{
  margin-left:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}
.UncheckBoxRTL:hover{
  cursor: pointer;
}


.NewButtonSection{
  width:100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  top: 20px;
}

.NewButton{

  border-radius: 25px;
  width:auto;
  padding:5px 20px;
  height:50px;
  background-color: rgba(255, 119, 0, 0.816);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color:white;
  font-size:1.2em;

}

.NewButton:hover{
cursor: pointer;
font-weight: 600;
background-color: rgba(255, 119, 0, 0.616);
}


.RemoveButton{
  position : relative;
  padding: 5px 10px ;
  border-radius: 3px;
  background-color:#b22222b9;
  width:auto;
  text-align: center;
  font-size:1em;
  color:white;
}
.RemoveButton:hover{
  cursor: pointer;
  background-color:#b222228e;
  
 }

 .RemoveButtonRTL{
  position : relative;
  padding: 5px 10px ;
  border-radius: 3px;
  background-color:#b22222b9;
  width:auto;
  text-align: center;
  font-size:1em;
  color:white;
 }

 .RemoveButtonRTL:hover{
  cursor: pointer;
  background-color:#b222228e;
 }
  
.TimeBody{
  width:140px;
  height:40px;
  overflow:hidden;
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:1.2em;
  color:rgb(80,80,80);
  border-radius:15px;
  padding:0 10px;
  margin-left:15px;
  margin-right:25px;
}
  
  .AddHoursButton{
    color: rgb(34,121,190);
    font-size:600;
    font-size:1.2em;
    width:80%;
    padding: 10px 5%;
    cursor: pointer;
  }

  .SpecialCheckboxes{
    display:flex;
    align-items: center;
    padding-left:5%;
    width:80%;
  }

  .SpecialCheck{
    width:100%;
    flex-wrap: nowrap;
    display:flex;
    align-items: center;
    margin-right:50px;
  }
  .CheckboxText{
    white-space: nowrap;
    font-size:1.2em;
    color: rgb(88,88,88);
  }

  .Icon{
    width:20px;
    cursor: pointer;
  }

  .SpecialDatesButton{
    white-space: nowrap;
    font-size:1.1em;
    padding:5px 8px ;
    border:1px solid rgb(230,230,230);
    border-radius:4px;
  }
  .SpecialDatesButton:hover{
    cursor: pointer;
    background-color: rgb(247,247,247);
  }

  .SpecialDatesButtonSelected{
    white-space: nowrap;
    font-size:1.1em;
    padding:5px 8px ;
    color:white;
    background-color: rgb(34,121,190,0.85);
    border-radius:4px;
  }

  .SpecialDatesButtonSelected:hover{
    cursor: pointer;
    background-color: rgb(34,121,190,0.75);
  }

  .PlaceClosedSection{
    height:6vh;
    display:flex;
    align-items:center;
    padding: 1vh 5%;
    width:90%;
    background-color: rgb(223,223,223);
  }

  .ClosedSubSection{
    display:flex;
    align-items:center;
    margin-right:30px;
  }

  .ClosedNoteInput{
    margin-left:15px;
    height:35px;
    font-size:1em;
    color:rgb(100,100,100);
    padding: 0 5px;
    border:none;
    border-bottom:1px solid rgb(230,230,230);
  }

  .ClosedNoteInput:focus{
    outline: none;
  }

.ClosedDateButton{
margin-left:20px;
height:30px;
padding:5px 10px;
border:1px solid rgb(230,230,230);
background-color: rgba(243, 243, 243, 0.856);
display: flex;
align-items: center;
justify-content: center;
border-radius: 6px;
}
.ClosedDateButton:hover{
  cursor: pointer;
  background-color: rgba(248, 248, 248, 0.956);
}
  .SelectDate{
    z-index: 50;
    background-color: white;
    border:1px solid rgb(210,210,210);
    border-radius: 5px;;
    position:fixed;
    height:60vh;
    width:25vw;
    top:20vh;
    left:37.5vw;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    direction: ltr;
  }
  .SetDate{
    height:30px;
    width:100px;
    padding:5px 10px;
    color:white;
    border:1px solid rgb(230,230,230);
    background-color: rgb(69, 155, 47);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: relative;
    z-index:10;
  }
  .SetDate:hover{
    cursor: pointer;
    background-color: rgba(43, 143, 18, 0.708);
  }


.DiscardCloseDate{
  height:20px;
  position: absolute;
  top:10px;
  left:10px;
  cursor: pointer;
}

.DiscardCloseDateRTL{
  height:20px;
  position: absolute;
  top:10px;
  right:10px;
  cursor: pointer;
}


@media screen and (max-width: 767px) {
  .Body {
    width:95%;
    padding: 10px 2.5%;
    height: 80vh;
    font-size:0.9em;
  }
  .Row{
    padding: 5px 0;
    margin:0;
  }
  .DaysList{
    width: 95%;
    padding: 15px 2.5%;
    flex-wrap: wrap;
  }
  .DayTitle{
    width: 150px;
  font-size: 0.9em;
  font-weight: 400;
  }
  .TimeTitle{
    font-size: 0.7em;
    font-weight: 400;

  }

  .Day{
    width:35px;
      height:35px;
      font-size: 0.9em;
      font-weight: 500;
      color:rgb(90,90,90);
  }
  .DaySelected{
    width:35px;
    height:35px;
    font-size: 0.9em;
    font-weight: 500;
    color:white;
  }
.NewButton{

  border-radius: 25px;
  width:auto;
  padding:5px 15px;
  height:30px;
  font-size:1em;
  font-weight:500;
}
.AddHoursButton{
  color: rgb(34,121,190);
  font-weight:500;
  font-size:1.1em;
  width:80%;
  padding: 10px 5%;
  cursor: pointer;
}
.SubRowHours{
  font-size:1.1em;
  flex-wrap: wrap;
  flex-direction: column;
}
.SubRowTime{
  display:flex;
  align-items: center;
}
.Icon{
  width:16px;
}
.RowTopSection{
  position: relative;
  width:95%;
  padding:0 2.5%;
  font-size: 0.8em;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom:20px;
  height:10vh;
}
.RowOptions{
  width:100%;
  align-items: flex-start;
  flex-direction: column;
}
.SpecialCheckboxes{
  width:100%;
  padding:0;
}
.SpecialDatesButton{
  position:absolute;
  right:5px;
  top:40px;

}
.SubRow{
  border-bottom:1px solid rgb(230,230,230);
  font-size:1.2em;
  width:100%;
  padding:10px 0;
  align-items: center;
}
.TimeTitle{
  width:100px;

}
.SpecialCheck{
  margin-bottom:10px;
  margin-right:10px;
}
.RemoveButton{
  position : absolute;
  top: 0px;
  right:0px;
  padding: 5px 10px ;
  border-radius: 3px;
  background-color:#b22222b9;
  width:auto;
  text-align: center;
  font-size:1em;
  color:white;
}

.RemoveButtonRTL{
  position : absolute;
  top: 0px;
  left:0px;
  padding: 5px 10px ;
  border-radius: 3px;
  background-color:#b22222b9;
  width:auto;
  text-align: center;
  font-size:1em;
  color:white;
}

.UncheckBox{
  margin-right:7px;
  width:18px;
  height:18px;
  border-radius:4px;
  border:1px solid rgb(40,40,40);
}


}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Body {
    width: 90%;
    position: relative;
    left:0;
    padding: 5vh 5%;
    overflow-y: auto;
    height: 80vh;
  }
}