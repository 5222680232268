
  .ContentTypeSeclectionClosed{
    border:1px solid rgb(223,223,223);
    position: absolute;
    width:120px;
    background-color: rgb(240,240,240);
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: bottom 1s ease;
    font-size: 0.9em;
}

.BodyWrapper{
    right:-1px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.BodyWrapperRTL{
    left:-1px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}


.ContentTypeSeclectionOpen{
  border-top:1px solid rgb(223,223,223);
  border-bottom: 1px solid rgb(223,223,223);;
  background-color: rgb(240,240,240);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height:50px;
  position: absolute;
  border-radius: 25px;
  width: 120px;
  z-index: 1;
  animation: show 0.4s;
   font-size: 0.9em;
}@keyframes show{
  0%   {   width: 120px; }
  100% {   width: 100%; }
}
.ContentTypeSeclectionOpen:hover{
  width: 100%;
}
.ContentTypeSeclectionAlwaysOpen{
  width: 100%;
}

@media screen and (min-width: 1024px) {
    .UserCountryOption{
        margin-top: 50px;
        width:60%;
        height:50px;
        background-color: rgb(255, 124, 36);
        transition: ease 1s;
      }
      
    .UserCountryOption:hover{
        cursor:pointer;
        transform: scale(1.1);
        transition: ease 1s;
        background-color: rgba(255, 102, 0,1);
    }
}

@media screen and (max-width: 767px) {
    .ContentTypeSeclectionClosed{
        height:50px;
        width:70px;
    }
    .ContentTypeSeclectionOpen{
        height:50px;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .ContentTypeSeclectionClosed{
    height:50px;
}
.ContentTypeSeclectionOpen{
    height:50px;
}
}