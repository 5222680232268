.LTR{
  left:10%;
}
.RTL{
  left:10%;
direction: rtl;
}
.Modal{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.TopSection{
    width:95%;
    min-height:8%;
    max-height:8%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 2.5%;
  
    background-color: rgb(255,119,0,0.8);;
    border-bottom:1px solid rgb(255,119,0,0.8);
    position: relative;
  }

  
  .Title{
    left:40%;
    font-size:1.3em;
    color:rgb(255, 255, 255);
    font-weight: 500;
  }
  
  .CloseIcon{
    position:absolute;
    right:15px;
    height:22px;
  }

  .CloseIconRTL{
    height:22px;
    position:absolute;
    left:15px;
  }

  .PreferencesForm{
 
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  .PreferencesForm::-webkit-scrollbar-track-piece {
    background-color: white;
    }
    
    .PreferencesForm::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    }
    
    .PreferencesForm::-webkit-scrollbar
    {
    width: 5px;
    background-color: #F5F5F5;
    }
    
    .PreferencesForm::-webkit-scrollbar-thumb
    {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(231, 231, 231, 0.562);
    }

  .CreateButton{
      bottom:2vh;
      position: absolute;
      background-color: rgb(255,119,0);
      color:white;
      border-radius: 5px;
      font-size:1.2em;
      padding: 10px 25px;
  }

  .CreateButtonFade{
    cursor:default;
    bottom:2vh;
    position: absolute;
    background-color: rgb(255,119,0,0.6);
    color:white;
    border-radius: 5px;
    font-size:1.2em;
    padding: 10px 25px;
  }

  .Body{
 
  }

  .SearchSection{
    width:60%;
    padding: 0 20%;
  
  }

  .InfoSection{
    width:100%;
    padding: 2vh 0;
    border-bottom: 1px solid rgb(235,235,235);
  }

  .SectionHeader{
    display: flex;
    align-items: center;
    font-size:1.2em;
    font-weight: 500;
    padding:10px 0;
  }

  .SectionSubHeader{
    font-size:1em;
    padding:5px 0;
    color:rgb(80,80,80);
  }

  .InfoRow{
    display:flex;
    align-items: center;
    padding: 1vh 0;
  }
  .SelectedDestination{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:80%;
  }

  .ResetIcon{
    height:20px;
    margin:0 25px;
  }
  .DestinationValue{
    color:rgb(34,121,190);
    font-size:1.2em;
    font-weight: 400;
    display:flex;
    align-items: center;
  }

  .CheckMarkSection{
    display:flex;
    align-items: center;
    white-space: nowrap;
    color:rgb(80,80,80);
    padding: 1vh 0;
  }

  .SelectDifferentDestination{
    
    margin:1vh 0;
    width:100%;
  }
  
  .SubCategoriesSection{
    width:100%;
    padding: 2vh 0;
  }

  .CategorySelection{
    width:50%;
  }

  .MainCategoryFocus{
    font-size:0.9em;
  }

  .CategoriesTags{
    padding:1vh 0;
    display:flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .Category{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .MainCateogryFocus{
    display:flex;
    align-items: center;
  }

  
.CheckIcon{
  margin-right:12px;
  width:26px;
  height:26px;
}

.CheckIconRTL{
  margin-left:12px;
  width:26px;
  height:26px;
}


.UncheckBox{
  margin-right:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

.UncheckBoxRTL{
  margin-left:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

  .Message{
    font-size: 0.9em;
    color:#b22222;
  }

  .MapModal{
 
    background-color: white;
  }

  .DiscardMap{
    height:7vh;
    display:flex;
    align-items: center;
    width:90%;
    padding: 0 5%;
  }

  .LocationContent{
    display:flex;
  }

  .MapButton{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin:5px 0;
    border-radius: 5px;
    border:1px solid rgb(223,223,223);
    width:auto;
  }

  .MapIcon{
    height:35px;
    margin-right: 15px;
  }
  .MapIconRTL{
      height:35px;
      margin-left: 15px;
    }

    .MapRadiusSection{
      display:flex;
      align-items: center;
      justify-content: center;
      width:100%;
      position:absolute;
      top:5vh;
      z-index: 10;
    }
  
    .MapRadius{
      display:flex;
      align-items: center;
      background-color: white;
      padding: 5px 10px;
      border-radius: 5px;
    } 

    .MapRadiusText{
      margin-right:15px;
    }

    .MapRadiusTextRTL{
      margin-left: 15px;
    }
  
.NumberInput{
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height:30px;
  width:50px;
  padding:0 5px;
  border:1px solid rgb(180, 180, 180);
  border-radius:5px;
  margin:0 15px;
}
.NumberInput:focus{
  outline: none;
}

.NumberInputFade{
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height:30px;
  width:50px;
  padding:0 5px;
  border:1px solid rgb(180, 180, 180);
  border-radius:5px;
  color:rgb(129, 129, 129);
  margin:0 15px;
}
.NumberInputFade:focus{
  outline: none;
}


.CloseIcon{
  position:absolute;
  right:15px;
  height:22px;
  transition: 0.4s;
}

.CloseIconRTL{
  height:22px;
  position:absolute;
  left:15px;
  transition: 0.4s;
}

.IssueMessageModal{
  position:absolute;
  z-index: 30;
  width:40%;
  height:30%;
  top:15%;
  right:30%;
  border:1px solid rgb(223,223,223);
  border-radius:5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 5vh 5%;
}

.IssueMessageText{
text-align: center;
font-size:1.4em;
font-weight: 500;
padding:10px 0;
}

.IssueMessageSubText{

}

.IssueMessageOptions{
  display:flex;
  width:100%;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 0;
} 

.MessageOption{
  padding: 10px 20px;
  border-radius: 5px;
  border:1px solid rgb(223,223,223);
  margin:0 10px ;
  min-width:35%;
text-align: center;
}

.SetAcoommodation{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  background-color: white;
  z-index:100;
  border-radius: 20px;
}

.ClickedPlace{
  position:absolute;
  z-index: 10;
  bottom:1vh;
  width:70%;
}

.InitialScreen{
  display: flex;
  height:100%;
  width:95%;
  padding: 0 2.5%;
}

.InitialScreenMessage{
  padding:1vh 0%;
  height:100%;
}

.InitialScreenHeader{
  font-size:1.4em;
  font-weight: 600;
  padding: 2vh 0;
}

.MessageText{
  padding:1vh 0;
  width:90%;
  font-size:1.1em;
  font-weight: 450;
  color:rgb(88,88,88);
}

.DisclaimarText{
  font-size:0.9em;
  padding-top:2vh;
  font-weight: 500;
  color:rgb(59, 59, 59);
}

.DestinationSection{
  padding:2vh 2.5%;
  width:50%;
  height:100%;
  position:relative;
  z-index: 100;
}
.SearchSection{
  padding:2vh 0;
}

.AccommodationSelection{
  width: 60%;;
  font-size: 0.9em;
}

.PlaceNotExist{
  color:rgb(255,119,0);
  width:90%;
  padding: 10px 0;
}
.ResetPlace{
  margin: 5px 0;
width:auto;
padding:5px 15px;
border-radius: 5px;
display: inline-block;
vertical-align: middle;
text-align: center;
background-color: rgb(248,248,248);
border:1px solid rgb(230,230,230);
font-size:1.1em;
color:rgb(88,88,88);
}

.AccommodationCategories{
  padding: 10px 0;
  width:100%;
}

.SelectedCategories{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 0;
}


.CategoriesSelectionList{
  width:60%;
}


.ShowExtraInfo{
  position:relative;
  width:110%;
  right:5%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh 0;
  padding: 1vh 0;
  transition: 0.6s;
}
.ShowExtraInfoRTL{
  position:relative;
  width:110%;
  left:5%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh 0;
  padding: 1vh 0;
  transition: 0.6s;
}

.WhiteBackground{
  background-color: white;

}

.ExtraInfoText{
color:rgb(100,100,100);
font-size:1em;
margin-bottom:2px;

}
.Arrow{
  -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    background-color: rgba(255, 255, 255, 0.75);
   /*  box-shadow: 0 0 0 1px #c5c5c58e; */
    width:35px;
    height:35px;
  /*   padding:10px; */
    z-index: 10;
    transition: 0.4s;
    border-radius: 50%;;
}

.UpArrow{
  -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: rgba(255, 255, 255, 0.75);
  /*   box-shadow: 0 0 0 1px #c5c5c58e; */
    width:35px;
    height:35px;
  /*   padding:10px; */
    z-index: 10;
    transition: 0.4s;
    border-radius: 50%;;
}


.DurationSection{
  display: flex;
  align-items: center;
  padding: 5px 0;
  color:rgb(88,88,88);
}

.DurationMessage{
  font-size:1.1em;
 
  margin-right:25px;
}

.DurationMessageRTL{
  font-size:1.1em;
  margin-left:25px;
}

.DurationButton{
  display: flex;
  align-items: center;
  margin:0 10px;
}

.RadioButton{
  height:20px;
  margin-right:10px;
}

.RadioButtonRTL{
  height:20px;
  margin-left:10px;
}

.PlanDuration{
  display:flex;
  align-items: center;
}

.PlanDurationInput{
  width:50px;
  height:30px;
  border:none;
  border-bottom: 1px solid rgb(230,230,230);
  font-size:1.1em;
  color:rgb(88,88,88);
}
.PlanDurationInput:focus{
  outline: none;
}
.WarningMessage{
  font-size:0.9em;
  color:#b22222;
}

.Filters{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width:auto;
  font-size:1em;
  padding:5px 0;
}

.InfoColumnRow{
  margin:5px 0;
}

.TripIntensityOptions{
  display:flex;
  align-items: center;
  padding: 7px 0;
}

.IntensityOption{
  border:1px solid rgb(223,223,223);
  border-radius: 5px;
  color:rgb(100,100,100);
}

.IntensityOptionSelected{
  border:1px solid rgb(94, 94, 94);
  background-color: rgb(140,140,140);
  color:white;
  border-radius: 5px;
}

  @media screen and (min-width: 1024px) {  
    .Modal{
    height:85vh;
    
    padding:0;
    width:80vw;
   
    top:8vh;
    z-index: 25;
  /*   border: 1px solid rgb(210,210,210); */
    background-color: white;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
    }

    

    .TopSection{
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

 .PreferencesForm{
  width:95%;
  padding:5vh 2.5%;
  height:55vh;
 }
    
    .Body{
      border-bottom: 1px solid rgb(223,223,223);
    position: relative;
    display:flex;
    align-items: flex-start;
    flex-direction: row;
    height:68vh;
    padding:1vh 0;
    padding-top:0;
  
    width:100%;
    margin-top:1px;
    font-size:1em;
    overflow: hidden;
    }

    .PreferencesForm{
      width:50%;
      height:48vh;
      padding-top:1vh;
      padding-bottom: 20vh;
    }

    .MapModal{
      position:relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      top:0;
      height:100%;
      width:50%;
      border-radius: 20px;
    }

    .InitialScreenMessage{
      padding:1vh 2.5%;
      width:45%;
      height:100%;
      border-right: 1px solid rgb(223,223,223);
    }
    .InitialScreenMessageRTL{
      padding:1vh 2.5%;
      width:45%;
      height:100%;
      border-left: 1px solid rgb(223,223,223);
    }
    
   .Category{
     width:60%;
   }
      
  .CloseIcon:hover{
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.4s;
  }
  .CloseIconRTL:hover{
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.4s;
  }
.CheckIcon:hover{
  cursor: pointer;
}
  .CheckIconRTL:hover{
    cursor: pointer;
    }

.UncheckBox:hover{
    cursor: pointer;
  }

.UncheckBoxRTL:hover{
    cursor: pointer;
  }
 .RadioButton:hover{
   cursor: pointer;
 }
 .RadioButtonRTL:hover{
   cursor: pointer;
 }
 .CreateButton:hover{
   cursor: pointer;
   background-color: rgb(255,119,0,0.95);
 }

 .MapButton:hover{
  cursor: pointer;
  background-color: rgb(248,248,248);
}
.CloseIcon:hover{
  cursor: pointer;
}
.CloseIconRTL:hover{
  cursor: pointer;
}
.MessageOption:hover{
  cursor: pointer;
}
.PlaceNotExist:hover{
  cursor: pointer;
}
.ResetPlace:hover{
  cursor: pointer;
  background-color: white;
  
}
.Arrow:hover{
  cursor: pointer;
  background-color: white;
}

.UpArrow:hover{
  cursor: pointer;
  background-color: white;
}

.ShowExtraInfo:hover{
  background-color: rgb(241, 241, 241);
  transition: 0.5s;
  cursor: pointer;
}
.ShowExtraInfoRTL:hover{
  background-color: rgb(241, 241, 241);
  transition: 0.5s;
  cursor: pointer;
}
.RadioButton:hover{
  cursor: pointer;
}
.RadioButtonRTL:hover{
  cursor: pointer;
}

.ResetIcon:hover{
  cursor: pointer;
  transform: rotate(-359deg);
  transition: 2.5s;
}
.TripIntensityOptions{

}

.IntensityOption{
  padding: 7px 20px;
  margin:0 20px;
}
.IntensityOption:hover{
  cursor: pointer;
  background-color: rgb(247,247,247);
}

.IntensityOptionSelected{
  padding: 7px 20px;
  margin:0 20px;
}

}

@media(max-width:767px){
  .Modal{
    z-index: 200;
    width:100vw;
    left:0;
    height:100%;
    top:0;
    background-color: white;
  }
  .Body{
    display: block;
    min-height:90%;
    max-height:90%;
    overflow:hidden;
    width:100%;
    position:relative;
  /*   border-bottom:1px solid rgb(223,223,223); */
    background-color: rgba(255, 255, 255);
  }
  .TopSection{
    height:8%;
  }
  .ViewMode{
    display: flex;
    align-items: center;
    padding: 0.5vh 2.5%;
    width:95%;
    border-bottom: 1px solid rgb(223,223,223);
    font-size:1.1em;
  }
  .MapModal{
    position:absolute;
    width:100%;
    height:80%;
    z-index:10;
    top:10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .HideMap{
    position:absolute;
    z-index: 0;
  }
  .PreferencesForm{
    width:95%;
    padding:0 2.5%;
    position:absolute;
    margin-top: 1vh;
    height:80%;
  /*   padding-bottom:20%; */
    z-index:20;
    background-color: white;
    border-bottom:1px solid rgb(223,223,223);
  }
  .HidePreferencesForm{
    position:absolute;
    z-index: 0;
    transform: translateY(-100vh);
    display: none;
  }
  .InitialScreen{
   flex-direction: column;
   align-items: center;
    height:100%;
   width: 100%;
    padding:0;
    overflow-y: auto;
  }
  
  .InitialScreenMessage{
    padding:1vh 0;
    color:rgb(80, 80, 80);
    background-color: rgb(255, 255, 255);
    width:90%;
    padding: 1vh 2.5%;
    height:auto;
    border-radius: 5px;
    margin:2vh 2.5%;
    border:1px solid rgb(223,223,223);
  }
  .InitialScreenHeader{
    font-size:1.2em;
    font-weight: 600;
    padding: 1vh 0;
    text-align: center;
    border-bottom:1px solid rgb(223,223,223);
  }
  .SelectedDestination{
    display:flex;
    align-items: flex-start;
    flex-direction: column;
    width:100%;
  }
  .ShowOnMap{
    margin:5px 0;
  }
  .MessageText{
    width:95%;
    padding: 1vh 2.5%;
    font-size: 1em;
   
  }
  .DisclaimarText{
    font-weight: 400;
    font-size:0.8em;
  }
  .DisclaimarSection{
    position: relative;
    bottom:0%;
    width:95%;
    padding:1vh 2.5%;
    background-color: rgb(238, 238, 238);
    border-top:1px solid rgb(223,223,223);
  }
  .DestinationSection{
    color:rgb(80, 80, 80);
    width:90%;
    padding: 1vh 2.5%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    margin:2vh 2.5%;
    border:1px solid rgb(223,223,223);
  }
  .CreateButton{
    bottom:2%;
    z-index: 20;
   
  }
  .CreateButtonFade{
    bottom:2%;
    z-index: 20;
  }
  .SearchSection{
    padding:2vh 5%;
    width:90%;
 
  }

  .ClickedPlace{
    width:90%;
    bottom:3vh;
  }
  .AccommodationSelection{
    width:90%;
  }
  .MobileSeperator{
    height:4vh;
    width:2px;
    border-right:1px solid rgb(200,200,200)
  }
  .CategorySelection{
    width:100%;
    padding: 5px 0;
  }
  .IntensityOption{
    padding: 5px 15px;
    margin:0 15px;
  }
  
  .IntensityOptionSelected{
    padding: 5px 15px;
    margin:0 15px;
  }
  
.IssueMessageModal{
  position:absolute;
  z-index: 30;
  width:70%;
  height:40%;
  top:15%;
  right:10%;
  border:1px solid rgb(223,223,223);
  border-radius:5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 5vh 5%;
}

.IssueMessageText{
  text-align: center;
  font-size:1.2em;
  font-weight: 500;
  padding:10px 0;
  }
  
}


@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    z-index: 200;
    width:100vw;
    left:0;
    height:100vh;
    top:0;
    background-color: white;
  }
  .Body{
    display: block;
    min-height:72vh;
    max-height:77vh;
    overflow:hidden;
    width:90%;
    padding:0 5%;
    position:relative;
    border-bottom:1px solid rgb(223,223,223);
  }
  .ViewMode{
    display: flex;
    align-items: center;
    padding: 0.5vh 2.5%;
    width:95%;
    border-bottom: 1px solid rgb(223,223,223);
    font-size:1.1em;
  }
  .MapModal{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:72vh;
    z-index:10;
    top:0;
  }
  .HideMap{
    position:absolute;
    z-index: 0;
    transform: translateY(-100vh);
  }
  .PreferencesForm{
    width:95%;
    padding:0 2.5%;
    position:relative;
    margin-top: 1vh;
    height:75vh;
    z-index:20;
    background-color: white;
  }
  .HidePreferencesForm{
    position:absolute;
    z-index: 0;
    transform: translateY(-100vh);
  }
  .InitialScreen{
   flex-direction: column;
   align-items: center;
    height:100%;
    width:95%;
    padding: 0 2.5%;
  }
  
  .InitialScreenMessage{
    padding:1vh 0;

    width:100%;
    height:100%;
    border-bottom: 1px solid rgb(223,223,223);
   
  }
  .InitialScreenHeader{
    font-size:1.2em;
    font-weight: 600;
    padding: 1vh 0;
    text-align: center;
  }
  .MessageText{
    width:100%;
    font-size: 1em;
  }
  .DisclaimarText{
    font-size:0.8em;
  }
  .DestinationSection{
    width:100%;
  }
  .CreateButton{
    bottom:2%;
   
  }
  .CreateButtonFade{
    bottom:2%;
  }
  .SearchSection{
    padding:2vh 5%;
    width:90%;
  }

  .AccommodationSelection{
    width:90%;
  }
  .MobileSeperator{
    height:4vh;
    width:2px;
    border-right:1px solid rgb(200,200,200)
  }
  .CategorySelection{
    width:100%;
    padding: 5px 0;
  }
  .IssueMessageModal{
    position:absolute;
    z-index: 30;
    width:70%;
    height:40%;
    top:15%;
    right:10%;
    border:1px solid rgb(223,223,223);
    border-radius:5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 5vh 5%;
  }
  
  .IssueMessageText{
    text-align: center;
    font-size:1.2em;
    font-weight: 500;
    padding:10px 0;
    }
}