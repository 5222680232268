.Body{
    padding:0 20px;
    margin: 0 20px;
    height:100%;
    color:rgb(88,88,88);
  }


.BodyRTL{
    padding:0 20px;
    margin: 0 20px;
    height:100%;
    color:rgb(88,88,88);
}

.Selected{
    color:rgb(34,121,190);
    font-weight: 500;
}

.Style1{
    height:46px;
    width:100px;
    padding: 2px 0;
    border-radius: 5px;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.4s;
}
.Style2{
    height:50px;
    border-radius: 5px;
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.4s;
}

.Icon{
    height:20px;
    margin:0 10px;
}

.IconRTL{
    height:20px;
     margin:0 10px;

}
@media screen and (min-width: 1024px) {
    .Body:hover{
        cursor: pointer;
       
    }
    .Style1{
        height:45px;
        
        padding-top:3px;
        padding-bottom: 3px;
        border-radius: 5px;
        font-size: 0.9em;
    }
    .Style1:hover{
        background-color: rgb(250,250,250);
        transition: 0.4s;
    }
    .Style2:hover{
        cursor: pointer;
        background-color: rgb(250,250,250);
        transition: 0.4s;
    }
    .Icon{
        height:25px;
    }
    .IconRTL{
        height: 25px;
    }
}

@media screen and (max-width: 767px) {
    .Style1{
        width:50px;
        height:46px;
        padding-top:2px;
        padding-bottom: 2px;
        border-radius: 5px;
        font-size:0.9em;
    }
    .Icon{
        height:25px;
    }
    .Body{
        padding:0 10px;
        margin: 0 10px;
        height:100%;
      }
    .BodyRTL{
    padding:0 10px;
    margin: 0 10px;
    height:100%;
    }
   
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Style1{
        height:50px;
        border-radius: 5px;
    }
    .Icon{
        height:25px;
    }
    .Body{
        padding:0 10px;
        margin: 0 10px;
        height:100%;
      }
    .BodyRTL{
    padding:0 10px;
    margin: 0 10px;
    height:100%;
    }
}