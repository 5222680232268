.Modal{
    position: fixed;
    left: 25%;
    top: 5%;
    width: 50%;
    min-height: 85vh;
    max-height: 85vh;
    border: 0.6px solid rgb(180, 180, 180);
    box-shadow: 5px 5px 10px rgb(172, 172, 172);
    border-radius: 15px;
    z-index: 880;
    background-color: rgb(255, 255, 255);
    display: flex;
  flex-direction: column;
  }
  .RTL{
    direction: rtl;
  }
  .Body{
    width:96%;
    padding: 20px 2%;
    height:76vh;
    overflow-x: hidden;
    overflow-y: auto;
    color: rgb(95, 95, 95);
  }
  
  .TransportType{
    width:100%;
    display:flex;
    align-items: center;
    height:12vh;
    padding: 1vh 0;
    border-bottom: 0.7px solid rgb(240, 240, 240);
    margin-bottom:2vh;
  }
  
  .TransportHeader{
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom:10px;
  }
  
  .TransportOptions{
    display: flex;
    align-items: center;
  }
  
  .IconSection{
    width:15%;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    border-right: 1px solid rgb(223,223,223);
  }
  
  .TransportIcon{
    cursor: pointer;
    width:50px;
    margin:0 10px;
  }
  
  .TransportSelection{
  
  }
  
  .OriginDestination{
    display:flex;
    align-items: center;
    width:100%;
    padding:10px 0;
  }
  
  .DepartureArrivalSection{
    width:100%;
    padding:10px 0;
  }
  .DepartureArrival{
    display:flex;
    align-items: center;
    width:100%;
    padding:10px 0;
  }
  .CheckIcon{
    width:16px;
    margin-right:10px;
  }
  .CheckIcon:hover{
    cursor: pointer;
  }
  
  .Origin{
    width:45%;
    margin:0 2.5%;
  }
  
  .Destination{
    width:45%;
    margin:0 2.5%;
  }
  
  .DepArrSection{
    width:45%;
    margin:0 2.5%;
    display:flex;
    align-items: flex-start;
    flex-direction: column;
   
  }
  
  .Departure{
  width:95%;
  border:0.7px solid rgb(223,223,223);
  border-radius:20px;
  padding: 0 2.5%;
  }   
  
  .Arrival{
    width:95%;
  border:0.7px solid rgb(223,223,223);
  border-radius:20px;
  padding: 0 2.5%;
  }
  
  .DepArrHeader{
    font-size:1.1em;
    align-self: flex-start;
  }
  
  .customDatePickerWidth{
    
    color: rgb(100, 100, 100);
    height: 45px;
    width: 88%;
    margin: 0 5%;
    font-size: 1em;
    display: flex;
    align-items: center;
    border:none;
   
  }
  .customDatePickerWidth:focus{
    outline: none;
  }
  
  .NoteSection {
    font-style: unset;
    width: 95%;
      margin: 15px 2.5%;
      height: auto;
      padding: 0 0.5%;
      padding-bottom: 10px;
      border: 0.7px solid rgb(230, 230, 230);
      font-size:1.1em;
      min-height:100px;
      background-color: rgb(248,248,248);
      color: rgb(85, 85, 85);
          font-weight: 450;
  
    }
    .NoteInputField {
      width: 95%;
      outline: none;
      border: none;
      
      font-style: oblique;
      font-size: 1.2em;
      font-weight: 500;
      color: rgb(85, 85, 85);
  
      margin-bottom: 10px;
      resize: none;
    }
    
    .NoteInputField:focus {
      outline: none;
    }
  
    
.AttachIcon {
  height: 25px;
}

.AttachmentSection{
  width:95%;
  padding: 10px 2.5%;
  border-top:1px solid rgb(223,223,223);
}

.AttachmentMessage{
  font-size: 1.1em;
  font-weight: 500;
  color:rgb(34,121,190);
}


.RemoveIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 100;
}


.AttachedFiles {
  margin-top: 10px;
  padding-bottom: 10px;
  color: rgb(80, 80, 80);
  font-size: 1.2em;
}

.SectionTitle {
  margin-bottom: 10px;
}

.Files {
  color: rgb(51, 51, 51);
  font-size: 1em;
}
.FileItem {
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 5px;
  border:5px;
}
.FileItem:hover {
 background-color:  rgb(248, 248, 248);
}


 
.UploadSection {
  display: flex;
  align-items: center;
  height: 60px;
  width:auto;
  right:0;
  cursor: pointer;
  position: relative;
}

.FileInput {
  width: auto;
  height: 45px;
  cursor: pointer;
  position:absolute;
  right:0;
  top:20px;
}

.ImageUploadWrapper {
  cursor: pointer;
  max-width: 45px;
  max-height: 45px;
  margin-left: 5px;

  display: flex;
  justify-content: center;
  overflow: hidden;

  color: rgb(87, 87, 87);

}

.ImageUploadWrapper > input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.ImageUploadWrapper > .UploadButton {
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

.UploadButton {
  cursor: pointer;
  border-radius: 50%;
  padding: 3px;
  padding-top: 0;

}
  
  .OtherInput{
    height:30px;
    padding: 5px;
    width:200px;
    color:rgb(88,88,88);
    font-size:1.1em;
    border:none;
    border-bottom:1px solid rgb(230,230,230);
  }
  .OtherInput:focus{
    outline: none;
  }

  
.CheckIcon{
    margin-right:15px;
    width:26px;
    height:26px;
  }

  .CheckIconRTL{
    margin-left:15px;
    width:26px;
    height:26px;
  }

  
  .UncheckBox{
    margin-right:15px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  
  }

  .UncheckBoxRTL{
    margin-left:15px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }

  @media screen and (min-width: 1024px) {  
    .CheckIcon:hover{
        cursor: pointer;
    }
    .CheckIconRTL:hover{
        cursor: pointer;
    }

    .UncheckBox:hover{
        cursor: pointer;
    }
    .UncheckBoxRTL:hover{
        cursor: pointer;
    }
  }
  
  
  @media(max-width:767px){
    .Modal{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 100vh;
      max-height: 100vh;
      border:none;
      border-radius: 0;
  }
  
  .Body{
      width:96%;
      padding: 20px 2%;
      height:76vh;
      overflow-x: hidden;
      overflow-y: auto;
  }
  .TransportType{
    height:auto;
  }
  .OriginDestination{
    display:flex;
    align-items: center;
    flex-direction: column;
    width:100%;
    padding:10px 0;
  }
  .customDatePickerWidth{
    height:30px;
  }
  .Origin{
    width:95%;
    margin:10px 2.5%;
  }
  
  .Destination{
    width:95%;
    margin:10px 2.5%;
  }
  .TransportIcon{
  width:30px;
  }
  .DepartureArrival{
    display:flex;
    align-items: center;
    width:100%;
    padding:10px 0;
    font-size:0.9em;
  }
  .DepArrSection{
    width:45%;
    margin:0 2.5%;
    display:flex;
    align-items: flex-start;
    flex-direction: column;
   
  }
  .Departure{
   font-size:0.9em;
   padding:2px 2.5%;
    }   
    
    .Arrival{
      font-size:0.9em;
      padding:2px 2.5%;
    }
    .TransportOptions{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  
      
@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    border:none;
}

.Body{
    width:90%;
    padding: 20px 5%;
    height:76vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.Origin{
  width:45%;
  margin:0 2.5%;
}

.Destination{
  width:45%;
  margin:0 2.5%;
}
.TransportIcon{
width:45px;
}
.DepartureArrival{
  display:flex;
  align-items: center;
  width:100%;
  padding:10px 0;
  font-size:0.9em;
}
.DepArrSection{
  width:45%;
  margin:0 2.5%;
  display:flex;
  align-items: flex-start;
  flex-direction: column;
 
}
.Departure{
 font-size:0.9em;
 padding:2px 2.5%;
  }   
  
  .Arrival{
    font-size:0.9em;
    padding:2px 2.5%;
  }

}