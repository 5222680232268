.Wrapper{
    position: relative;
}
.SectionContent{
    display: flex;
    width:auto;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 5px 0;
    border-top: 1px solid rgb(223,223,223);
    border-bottom: 1px solid rgb(223,223,223);
    border:none;
  }
  .SectionContent::-webkit-scrollbar
  {
   height:0;
   background-color: #d1d1d1;
  }
  .SectionContent::-webkit-scrollbar-thumb
  {
   border-radius: 5px;
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
   background-color: rgba(94, 94, 94, 0.562);
  }

  
.Arrow{
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 0 1px #c5c5c58e;
    width:40px;
    height:40px;
    padding:10px;
    position:absolute;
    z-index: 10;
    top:15vh;
  }
  
  .ArrowLeft{
    left:0;
  }
  
  .ArrowLeftRTL{
    right:0;
  }
  
  .ArrowRight{
    right:0;
  }
  
  .ArrowRightRTL{
    left:0;
  }
  
  .RotateArrow{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  @media screen and (min-width: 1024px) {
    .Arrow:hover{
        cursor: pointer;
    }
  }

  @media screen and (max-width: 1023px){
    .Arrow{
      display: none;
    }
  }