.RTL{
    direction: rtl;
}
.TitleInput{
    width: 90%;
    resize: none;
  
    font-style: italic;
    border: none;
    outline: none;
   
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.TitleInputRed{
  width: 90%;
  resize: none;

  font-style: italic;
  border: none;
  outline: none;
 
  font-weight: 500;
  color: rgb(156, 30, 30);
}
.TitleInputRed::-webkit-input-placeholder {
  color: rgb(156, 30, 30);
}

.SelectedCategories{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
}

.LanguageSection{
  padding: 10px 0;
  border-bottom: 1px solid rgb(223,223,223);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LanguageHeader{
  color:rgb(100,100,100);
  font-size:1.1em;
  margin-right:25px;
  width:30%;
}
.LanguageHeaderRTL{
  color:rgb(100,100,100);
  font-size:1.1em;
  margin-left:25px;
  width:30%;
}


.ShowExtraInfo{
  position:relative;
  width:110%;
  right:5%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh 0;

}
.ShowExtraInfoRTL{
  position:relative;
  width:110%;
  left:5%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh 0;
}

.WhiteBackground{
  background-color: white;
  -webkit-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
  -moz-box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
  box-shadow: 0px 1px 11px 0px rgba(214,214,214,1);
}

.ExtraInfoText{
color:rgb(100,100,100);
font-size:1em;


}
.Arrow{
  -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    background-color: rgba(255, 255, 255, 0.75);
   /*  box-shadow: 0 0 0 1px #c5c5c58e; */
    width:35px;
    height:35px;
  /*   padding:10px; */
    z-index: 10;
    transition: 0.4s;
    border-radius: 50%;;
}

.UpArrow{
  -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: rgba(255, 255, 255, 0.75);
  /*   box-shadow: 0 0 0 1px #c5c5c58e; */
    width:35px;
    height:35px;
   /*  padding:10px; */
    z-index: 10;
    transition: 0.4s;
    border-radius: 50%;;
}

  @media screen and (min-width: 1024px) {

.SlimModal{
    position:fixed;
    width:50vw;
    left:25vw;
    height:90vh;
    top:5vh;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgb(214, 214, 214);
    box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
    0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
    z-index: 650;
  }

  .Modal{
    position:fixed;
    height:100vh;
    width:100vw;
    z-index: 500;
    background-color: white;
  }

  .Body{
    width:95%;
    height:80vh;
    padding:0 2.5%;
    background-color: rgb(250,250,250);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20vh;
  }

  .Body::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .Body::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  .Body::-webkit-scrollbar-track-piece{
    background-color: #F5F5F5;
  }
  
  .Body::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(255, 255, 255, 0.562);
  }

  .SlimBody{
    width:95%;
    height:80vh;
    padding:0 2.5%;
    background-color: rgb(250,250,250);
  }

  .Content{
    height:100%;
    width:100%;
   
  }

  .SlimContent{
    height:100%;
    width:100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20vh;
  }

  .SlimContent::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  .LeftContent::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .LeftContent::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  .LeftContent::-webkit-scrollbar-track-piece{
    background-color: #F5F5F5;
  }
  
  .LeftContent::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(255, 255, 255, 0.562);
  }

  .LeftContent{
    background-color: white;
    width:40%;
    margin:0 1.5%;
    padding:10px 1%;
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom:10vh;
  }

  .RightContent::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .RightContent::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  .RightContent::-webkit-scrollbar-track-piece{
    background-color: #F5F5F5;
  }
  
  .RightContent::-webkit-scrollbar-thumb
  {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(255, 255, 255, 0.562);
  }

  .RightContent{
    background-color: white;
    width:50%;
    margin:0 1.5%;
    padding:10px 1%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .CreationMessage{
    width: 95%;
    font-size: 1.1em;
    color: #b22222;
    text-align: center;
    padding: 10px 2.5%;
  }

  .PlaceTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Section{
    background-color: white;
    width:95%;
    padding: 10px 2.5%;
    border-bottom: 0.7px solid rgb(223,223,223);
}

    .AddToFavorites{

    }

    .FavoriteIcon{
        cursor: pointer;
        height:35px;
    }
    .TitleInput{
        height: 40px;
        font-size: 2em;
    }
    .TitleInputRed{
      height: 40px;
      font-size: 2em;
    }
    .SectionHeader{
      
    }
  
    .SectionHeaderTitle{
      display: flex;
      align-items: center;
      font-size:1.2em;
      font-weight: 500;
      padding:10px 0;
    }
  
    .HeaderGrey{
      display: flex;
      align-items: center;
    }
  
    .Icon{
      height:35px;
      margin-right:15px;
    }
    .IconRTL{
        height:35px;
        margin-left:15px;
      }
    .ImageSection{

    }
  
    .ImageContent{
      display: flex;
      align-items: center;
      padding: 15px 0;
    }
  
    .ImageButton{
 
      margin:0 20px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 200px;
    }
    .ImageButton:hover{
      cursor: pointer;
      background-color: rgb(240,240,240);
    }
  
    .ImageUploadWrapper {
      cursor: pointer;
      min-width: 200px;
      max-width: 200px;
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
    
      color: rgb(87, 87, 87);
    
    }
    
    .ImageUploadWrapper > input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
    
    .ImageUploadWrapper > .ImageButton {
      display: inline-block;
      width: 200px;
      height: 200px;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .FileInput{
      width:100%;
      height:140%;
      position: absolute;
      bottom:20%;
      z-index: 10;
    }
    .FileInput:hover{
    cursor: pointer;
    
    }
    
    .ImageIcon{
      height:25px;
      margin-right: 15px;
    }
    .ImageIconRTL{
        height:25px;
        margin-left: 15px;
      }
    
    .ImageText{
    border:none;
    color:rgb(100,100,100);
    font-size:16px;
    width:auto;
    border:none;
    display:inline-block
    }
    .ImageText:focus{
    outline: none;
    }
  
    .Images{
  
    }
  
    .DescriptionSection{
      font-style: unset;
      width: 99%;
        margin-top: 5px;
      
        height: auto;
        padding: 0 0.5%;
        padding-bottom: 10px;
        padding-top: 15px;
        min-height: 100px;
        font-size:1.1em;
        color: rgb(100,100,100);
        font-weight: 450;
        direction: ltr;
    }
  
    .DescriptionInputField{
      direction: ltr;
      background-color: white;
    }
    .DescriptionInputFieldRTL{
      direction: rtl;
      background-color: white;
    }
  
    .LocationContent{
      padding:10px 0;
    }
    .LocationRow{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .LocationPath{
      display:flex;
      align-items: center;
      justify-content: space-between;
      color: rgb(102, 102, 102);
      font-size:1.1em;
      font-weight: 500;
      padding-bottom: 7px;

      }
  
    .ButtonsSection{
      display: flex;
      align-items: center;
      padding: 15px 0;
    }
  
    .PlaceAddress{
      color: rgb(34,121,190);
      font-size:1em;
    }
  
    .LocationButton{
      margin:0 20px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 30%;
      color:rgb(95, 95, 95);
      font-size:1.2em;
      }
      .LocationButton:hover{
      cursor: pointer;
      background-color: rgba(240, 240, 240);
      }
      .LocationButtonRed{
      margin:0 20px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: auto;
      color: #b22222;
      }
      .LocationButtonRed:hover{
      cursor: pointer;
      }
  
      .Categories{
        padding: 10px 0;
        width:100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    
    .Category{
      margin:5px 0.5%;
      width:32%;
    }
  
    .CategorySection{
      width:100%;
     /*  display: flex;
      align-items: flex-end; */
    }
  
    .CategoriesSelectionList{
      width:60%;
    }
  
    .NewCategotry{
      width:50%;
      height:30px;
      border:none;
      font-size: 1.1em;
      font-weight: 400;
      color:rgb(100,100,100);
      border-bottom: 1px solid rgb(210,210,210);
      padding:7px 0;
      }
      
      .NewCategotry:focus{
      outline: none;
      }
  
      .LinkButton{
        font-size: 1.1em;
        font-weight: 500;
        color:rgb(34,121,190);
      }
  
      .LinkButton:hover{
        cursor: pointer;
      }
  
      .ExistingPlaces{
        align-items: center;
    
        flex-direction: column;
        margin-bottom: 10px;
        margin-top:5px;
        display: flex;
        position:fixed;
        top:15vh;
        width:25vw;
        left:37.5vw;
        background-color: white;
        z-index: 200;
        height: 65vh;
        border:0.7px solid rgb(233, 233, 233);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
      }
  
      .PlaceView{
        width: 95%;
        padding: 0 2.5%;
        height: 45vh;
        display: flex;
        justify-content: center;
      }
      
      .Message{
        color: #b22222;
        font-size: 1.2em;
        max-width: 90%;
        text-align: start;
        height:6vh;
        padding: 10px 0;
      }
    
      .MessageOptions{
        height: 90%;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      .EditLanguage{
        position: relative;
        top:25px;
        padding: 7px 12px;
        background-color: green;
        color: white;
        border-radius: 4px;
      }
      .EditLanguage:hover{
        cursor: pointer;
      }  

      .Arrow:hover{
        cursor: pointer;
        background-color: white;
      }

      .UpArrow:hover{
        cursor: pointer;
        background-color: white;
      }

      .ShowExtraInfo:hover{
        cursor: pointer;
        background-color: rgba(245, 245, 245, 0.664);
      }

      .ShowExtraInfoRTL:hover{
        cursor: pointer;
        background-color: rgba(245, 245, 245, 0.63);
      }
      .LocationPath:hover{
        cursor: pointer;
        background-color: rgb(245,245,245);
      }
}

@media screen and (max-width: 767px) {

    .Modal{
        z-index: 500;
      position:fixed;
      width:100vw;
      left:0;
      height:100vh;
      top:0;
      background-color: white;
      }

      .SlimModal{
        z-index: 650;
        position:fixed;
        width:100vw;
        left:0;
        height:100vh;
        top:0;
        background-color: white;
      }
    
      .Body{
        height:100%;
        width:100%;
       
      }

      .SlimBody{
        height:80vh;
        width:100%;
       
        overflow-y: auto;
      }
    
      .Content{
        overflow-y: auto;
        height:75vh;
        font-style: inherit;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        width:95%;
        padding: 0 2.5%;
        padding-bottom:5vh;
      }

      .SlimContent{
      
        height:100%;
        font-style: inherit;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        width:95%;
        padding: 0 2.5%;
        padding-bottom:20vh;
      }
    
      .CreationMessage{
        color: #b22222;
        text-align: center;
        font-size: 0.9em;
        width:90%;
        padding: 0 5%;
        padding-top: 5px;
      }
      .Section{
        padding: 10px 0;
        border-bottom: 1px solid rgb(240, 240, 240);
      }
    
        
      .PlaceTitle{
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .AddToFavorites{
        margin-right:10px;
        font-size:0.9em;
        color:rgb(100, 100, 100);
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    
      .FavoriteIcon{
        height:30px;
      }
      .TitleInput{
        height: 40px;
        font-size: 1.2em;
    }

    .TitleInputRed{
      height: 40px;
      font-size: 1.2em;
    }
    
      .SectionHeaderTitle{
        display: flex;
        align-items: center;
        font-size:1.1em;
        font-weight: 500;
      
      }
      .SectionHeader{
       display: flex;
       align-items: center;
       justify-content: space-between;
       min-width:100%;
       height:40px;
       padding:5px 0%;
      }
    
      .HeaderGrey{
        font-size: 1em;
        color: rgb(80,80,80);
      }
    
      .Icon{
        height:25px;
        margin-right: 15px;
      }
      .IconRTL{
        height:25px;
        margin-left: 15px;
      }
    
      .ImageSection{
    
      }
    
      .ImageContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 0;
      }
    
      .ImageButton{
        border:1px solid rgb(223,223,223);
        margin:5px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        width: 200px;
        border-radius: 10px;
      }
    
      .ImageUploadWrapper {
        cursor: pointer;
        min-width: 200px;
        max-width: 200px;
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
      
        color: rgb(87, 87, 87);
      
      }
      
      .ImageUploadWrapper > input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
      }
      
      .ImageUploadWrapper > .ImageButton {
        display: inline-block;
        width: 200px;
        height: 200px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .FileInput{
        width:100%;
        height:140%;
        position: absolute;
        bottom:20%;
        z-index: 10;
      }
    
      .ImageIcon{
        height:20px;
        margin-right: 10px;
      }
      .ImageIconRTL{
        height:20px;
        margin-left: 10px;
      }
      
      .ImageText{
      border:none;
      color:rgb(100,100,100);
      font-size:16px;
      width:100%;
      border:none;
      display:inline-block
      }
      .ImageText:focus{
      outline: none;
      }
    
      .Images{
    
      }
    
      .DescriptionSection{
        font-style: unset;
        width: 99%;
        margin-top: 5px;
      
        height: auto;
        padding: 0 0.5%;
        padding-bottom: 10px;
        padding-top: 15px;
        min-height: 100px;
        font-size:0.8em;
        color: rgb(100,100,100);
        font-weight: 400;
        direction: ltr;
      }
    
      .DescriptionInputField{

      }
    
      .LocationContent{
        padding:10px 0;
      }

      .LocationPath{
      padding: 3px 7px;
      width:50%;
      white-space: nowrap;
      display:block;
      }
     
    
      .ButtonsSection{
        display: flex;
        align-items: center;
        padding: 10px 0;
      }
    
      .PlaceAddress{
        color: rgb(34,121,190);
        font-size:1em;
      }
    
      .LocationButton{
        border:1px solid rgb(223,223,223); 
        display: flex;
        align-items: center;
        color:rgb(95, 95, 95);
        width:40%;
        margin:0 10px;
        padding: 5px 10px;
        font-size:0.9em;
        }
    
        .LocationButtonRed{
        border:1px solid #b2222273;
        display: flex;
        align-items: center;
        color: #b22222;
        width:40%;
        margin:0 10px;
        padding: 5px 10px;
        font-size:0.9em;
        }
    
        .Categories{
          padding: 10px 0;
          font-size:0.9em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      
      .Category{
        margin:5px 0.5%;
        width:32%;
      }
    
      .CategorySection{
        width:100%;
       /*  display: flex;
        align-items: flex-end; */
      }
    
      .CategoriesSelectionList{
        width:100%;
      }
    
      .NewCategotry{
        width:50%;
        height:30px;
        border:none;
        font-size: 1em;
        font-weight: 400;
        color:rgb(100,100,100);
        border-bottom: 1px solid rgb(210,210,210);
        padding-bottom:5px;
        }
        
        .NewCategotry:focus{
        outline: none;
        }
    
        .LinksList{
          padding: 5px 0;
        }
    
        .LinkButton{
          font-size: 0.9em;
          font-weight: 500;
          margin-left: 2.5%;
        }
    
        .DetailIcon{
          width: 12px;
          margin-right:10px;
      }
      .DetailIconRTL{
        width: 12px;
        margin-left:10px;
    }
      
  
      .ExistingPlaces{
        align-items: center;
        flex-direction: column;
        display: flex;
        position:fixed;
        top:0;
        width:100vw;
        left:0;
        background-color: white;
        z-index: 200;
        height: 100vh;
      }
    
      .PlaceView{
        width: 95%;
        padding: 0 2.5%;
        height: 55vh;
        display: flex;
        justify-content: center;
      }
      
      .Message{
        color: #b22222;
        font-size: 1.2em;
        max-width: 90%;
        text-align: start;
        height:6vh;
        padding: 10px 0;
      }
    
      .MessageOptions{
        height: 50%;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
      .EditLanguage{
        position: relative;
        top:25px;
        padding: 7px 12px;
        background-color: green;
        color: white;
        border-radius: 4px;
      }

      .LanguageHeader{
        color:rgb(100,100,100);
        font-size:0.9em;
        margin-right:10px;
        width:auto;
        white-space: nowrap;
      }
      .LanguageHeaderRTL{
        color:rgb(100,100,100);
        font-size:0.9em;
        margin-left:10px;
        width:auto;
        white-space: nowrap;
      }
      
    
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    z-index: 500;
  position:fixed;
  width:100vw;
  left:0;
  height:100vh;
  top:0;
  background-color: white;
  }

  .SlimModal{
    z-index: 650;
    position:fixed;
    width:100vw;
    left:0;
    height:100vh;
    top:0;
    background-color: white;
  }

  .Body{
    height:100%;
    width:100%;
   
  }

  .SlimBody{
    height:80vh;
    width:100%;
   
    overflow-y: auto;
  }

  .Content{
    overflow-y: auto;
    height:75vh;
    font-style: inherit;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width:95%;
    padding: 0 2.5%;
    padding-bottom:5vh;
  }

  .SlimContent{
  
    height:100%;
    font-style: inherit;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width:95%;
    padding: 0 2.5%;
    padding-bottom:20vh;
  }


  .CreationMessage{
    color: #b22222;
    text-align: center;
    font-size: 0.9em;
    width:90%;
    padding: 0 5%;
    padding-top: 5px;
  }
  .Section{
    padding: 10px 0;
    border-bottom: 1px solid rgb(223,223,223);
  }

    
  .PlaceTitle{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .AddToFavorites{
    margin-right:10px;
    font-size:0.9em;
    color:rgb(100, 100, 100);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FavoriteIcon{
    height:30px;
  }
  .TitleInput{
    height: 40px;
    font-size: 1.2em;
}

.TitleInputRed{
  height: 40px;
  font-size: 1.2em;
}

  .SectionHeaderTitle{
    display: flex;
    align-items: center;
    font-size:1.1em;
    font-weight: 500;
  
  }
  .SectionHeader{
   display: flex;
   align-items: center;
   justify-content: space-between;
   min-width:100%;
   height:40px;
   padding:5px 0%;
  }

  .HeaderGrey{
    font-size: 1em;
    color: rgb(80,80,80);
  }

  .Icon{
    height:35px;
    margin-right: 20px;
  }
  .IconRTL{
    height:35px;
    margin-left: 20px;
  }

  .ImageSection{

  }

  .ImageContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
  }

  .ImageButton{
    border:1px solid rgb(223,223,223);
    margin:5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 200px;
  }

  .ImageUploadWrapper {
    cursor: pointer;
    min-width: 200px;
    max-width: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
  
    color: rgb(87, 87, 87);
  
  }
  
  .ImageUploadWrapper > input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .ImageUploadWrapper > .ImageButton {
    display: inline-block;
    width: 200px;
    height: 200px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .FileInput{
    width:100%;
    height:140%;
    position: absolute;
    bottom:20%;
    z-index: 10;
  }

  .ImageIcon{
    height:30px;
    margin-right: 15px;
  }
  .ImageIconRTL{
    height:30px;
    margin-left: 15px;
  }
  
  .ImageText{
  border:none;
  color:rgb(100,100,100);
  font-size:16px;
  width:100%;
  border:none;
  display:inline-block
  }
  .ImageText:focus{
  outline: none;
  }

  .Images{

  }

  .DescriptionSection{
    font-style: unset;
    width: 99%;
    margin-top: 5px;
  
    height: auto;
    padding: 0 0.5%;
    padding-bottom: 10px;
    padding-top: 15px;
    min-height: 100px;
    font-size:0.8em;
    color: rgb(100,100,100);
    font-weight: 400;
    direction: ltr;
  }

  .DescriptionInputField{

  }

  .LocationContent{
    padding:10px 0;
  }
  .LocationPath{
    padding: 3px 7px;
    width:50%;
    white-space: nowrap;
    display:block;
    }

  .ButtonsSection{
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  .PlaceAddress{
    color: rgb(34,121,190);
    font-size:1em;
  }

  .LocationButton{
    border:1px solid rgb(223,223,223); 
    display: flex;
    align-items: center;
    color:rgb(95, 95, 95);
    width:40%;
    margin:0 10px;
    padding: 5px 10px;
    font-size:0.9em;
    }

    .LocationButtonRed{
    border:1px solid #b2222273;
    display: flex;
    align-items: center;
    color: #b22222;
    width:40%;
    margin:0 10px;
    padding: 5px 10px;
    font-size:0.9em;
    }

    .Categories{
      padding: 10px 0;
      font-size:0.9em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .Category{
    margin:5px 0.5%;
    width:32%;
  }

  .CategorySection{
    width:100%;
   /*  display: flex;
    align-items: flex-end; */
  }

  .CategoriesSelectionList{
    width:100%;
  }

  .NewCategotry{
    width:50%;
    height:30px;
    border:none;
    font-size: 1em;
    font-weight: 400;
    color:rgb(100,100,100);
    border-bottom: 1px solid rgb(210,210,210);
    padding-bottom:5px;
    }
    
    .NewCategotry:focus{
    outline: none;
    }

    .Links{

    }

    .LinkButton{
      font-size: 0.9em;
      font-weight: 500;
      margin-left: 2.5%;
    }

    .DetailIcon{
      width: 16px;
      margin-right:10px;
  }
  .DetailIconRTL{
    width: 16px;
    margin-left:10px;
}
  
  .ExistingPlaces{
    align-items: center;
    flex-direction: column;
    display: flex;
    position:fixed;
    top:0;
    width:100vw;
    left:0;
    background-color: white;
    z-index: 200;
    height: 100vh;
  }

  .PlaceView{
    width: 95%;
    padding: 0 2.5%;
    height: 55vh;
    display: flex;
    justify-content: center;
  }
  
  .Message{
    color: #b22222;
    font-size: 1.2em;
    max-width: 90%;
    text-align: start;
    height:6vh;
    padding: 10px 0;
  }

  .MessageOptions{
    height: 50%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }
  .EditLanguage{
    position: relative;
    top:25px;
    padding: 7px 12px;
    background-color: green;
    color: white;
    border-radius: 4px;
  }
}