.Modal{
    position:fixed;
    background-color: white;
    height:70vh;
    top:20vh;
    width:35vw;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  
    border: 1px solid rgb(165, 165, 165);
    left:32.5vw;
    overflow-x: hidden;
    z-index:200;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
}

.Header{
  width:80%;
  padding:20px 10%;
  font-size:1.5em;
  color:rgb(65, 65, 65);
  text-align: center;
}

.DatesSection{
  padding: 5px 30px;
  border:1px solid rgb(223,223,223);
  border-radius: 5px;
}

.ButtonsSection{
  position: absolute;
  bottom:10vh;
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    width:80%;
    padding:0 10%;
}

.DiscardCloseDate{
  height:20px;
  position: absolute;
  top:10px;
  left:10px;
  cursor: pointer;
}

.DiscardCloseDateRTL{
  height:20px;
  position: absolute;
  top:10px;
  right:10px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .Modal{
    position:fixed;
    background-color: white;
    height:100vh;
    top:0vh;
    width:100vw;
    padding:10vh 0;
    left:0vw;
    overflow-x: hidden;
    z-index:200;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Header{
  width:90%;
  padding: 15px 5%;
  font-size: 1.2em;
}
.DatesSection{
  margin-bottom:30vh;
}

}


@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position:fixed;
    background-color: white;
    height:70vh;
    top:10vh;
    width:60vw;
    padding:5vh 0;
    left:20vw;
    overflow-x: hidden;
    z-index:200;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.DatesSection{
  margin-bottom:30vh;
}

}