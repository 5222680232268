.RTL{
    direction: rtl;
}
.WelcomeBody{
    background-color: white;
    box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(94, 94, 94, 0.15),
      0 4px 4px rgba(136, 136, 136, 0.15), 0 8px 8px rgba(80, 80, 80, 0.15);
  
    border: 1px solid rgb(219, 219, 219);
    margin: 1%;
    display: flex;
    align-items: flex-start;
    flex:1;
    justify-content: flex-start;
    min-width: 60vw;
    max-width:60vw;
    padding: 0%;
    max-height: 40vh; 
    min-height:40vh;

}

.DiscoverBody{
    background-color: white;
    box-shadow: 0px 15px 10px -15px #111; 
  
     /*  border: 1px solid rgb(219, 219, 219); */
    margin: 5vh 0;
    display: flex;
    align-items: flex-start;
    flex:1;
    justify-content: flex-start;
    min-width: 100%;
    max-width:100%;
    padding: 0%;
    max-height: 40vh; 
    min-height:40vh;
    position:relative;
    /* border-radius: 10px; */
}


.AddToPlanBody{
    background-color: white;
    box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(94, 94, 94, 0.15),
      0 4px 4px rgba(136, 136, 136, 0.15), 0 8px 8px rgba(80, 80, 80, 0.15);
  
    border: 0.7px solid rgb(165, 165, 165);
    margin: 1%;
    display: flex;
    align-items: flex-start;
    flex:1;
    justify-content: flex-start;
    min-width: 40vw;
    max-width:40vw;
    padding: 0%;
    max-height: 25vh; 
    min-height:25vh;
    font-size: 0.9em;
    border-radius: 10px;
}


.SelectPlanBody{
    background-color: white;
    box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(94, 94, 94, 0.15),
      0 4px 4px rgba(136, 136, 136, 0.15), 0 8px 8px rgba(80, 80, 80, 0.15);
  
    border: 0.7px solid rgb(165, 165, 165);
    margin: 1%;
    display: flex;
    align-items: flex-start;
    flex:1;
    justify-content: flex-start;
    min-width: 98%;
    max-width:98%;
    padding: 0%;
    max-height: 20vh; 
    min-height:20vh;
    font-size: 0.9em;
    border-radius: 10px;
}


.BodyContent{
    position:relative;
    width: 100%;
     transition: all 0.4s ease-out;
     opacity: 1;
     max-height: 100%; 
     min-height:100%;
     display:flex;
     align-items: flex-start;
     justify-content: space-between;
}


.InfoContent{
    width:97%;
    height:100%;
    margin:1%;
    margin-right:2%;
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

}

.TopContent{
    display:flex;
    flex-direction: column;
}

.PlanEditors{
    display:flex;
    align-items: center;
    bottom:2vh;
    position:absolute;
}

.EditorImage{
    width:50px;
    border-radius: 50%;;
    margin:0 -2px;
}

.ImageSection{
    min-width: 50%;
    max-width: 50%;;
    object-fit:cover;
}

.SelectPlanImageSection{
    min-width: 50%;
    max-width: 50%;;
    object-fit: cover;
}


.PlanName{
    color:rgb(70,70,70);
    font-weight: 500;
    font-size:1.3em;
}

.PlanDurationInfo{
    display:flex;
    align-items: flex-end;
    white-space: nowrap;
    font-size:1.1em;
}

.PlanDuration{
    color:rgb(100,100,100);
  
    margin-right:15px;
}

.PlanDurationRTL{
    color:rgb(100,100,100);
    margin-left:15px;
}
.PlanDates{
    color:rgb(100,100,100);
    white-space: nowrap;
}


.PhotoPreview {
    /* border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; */
    width:100%;
    min-height:40vh;
    max-height:40vh;
   object-fit: cover;
  }
  .PhotoPreviewRTL {
  /*   border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
    width:100%;
    min-height:40vh;
    max-height:40vh;
   object-fit: cover;
  }

  .AddToPlanPhotoPreview{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width:100%;
    min-height:25vh;
    max-height:25vh;
   object-fit: cover;
  }

  .AddToPlanPhotoPreviewRTL{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width:100%;
    min-height:25vh;
    max-height:25vh;
   object-fit: cover;
  }

  .SelectPlanPhotoPreview{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width:100%;
    max-width:100%;
    min-height:20vh;
    max-height:20vh;
   object-fit: cover;
  }
  .SelectPlanPhotoPreviewRTL{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    min-width:100%;
    max-width:100%;
    min-height:20vh;
    max-height:20vh;
   object-fit: cover;
  }

  .PlanTools{
    position:absolute;
    width:auto;
    display:flex;
    align-items: center;
    justify-content: space-between;
    z-index:10;
    top:5px;
    padding:5px 2.5%;
    min-height: 40px;
    min-width: 50px;
    background-color: rgba(255, 255, 255, 0.78);
    }

    .PlanToolsRTL{
      position:absolute;
      width:auto;
      display:flex;
      align-items: center;
      justify-content: space-between;
      z-index:10;
      top:5px;
      padding:5px 2.5%;
      min-height: 40px;
      min-width: 50px;
      background-color: rgba(255, 255, 255, 0.78);
    }
    

  .Tools{
    width:100%;
    height:auto;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    right:0;
    top:20px;
  }
  .ToolsRTL{
    width:100%;
    height:auto;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    left:0;
    top:20px;
  }

  .OptionsIcon{
    height: 30px;  
    border-radius: 50%;
    margin:5px 7px;
    padding:10px;
  }

  .PublicInfo{

  }

  .LocationTags{
    display:flex;
    align-items: center;
    padding: 15px 0;
    flex-wrap: wrap;
  }

  .StyleAndParticipants{
    display:flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 7px 0;
  }

  .ParticipantIcon{
    height:50px;
  }

  .TripStyle{
    display:flex;
    align-items: center;
  }
  .Style{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;
  }

  .StyleIcon{
    height:35px;
    width: 35px;
  }

  .OptionsIcon{
    height: 22px;  
    border-radius: 50%;
    margin:0 7px;
  }

  
.GreyedToolsRTL{
  /* transform: rotateY(180deg); */
}

.ToolsIconGreyed{
  height:30px;
  border-radius: 50%;
}

  
.DislikedModal{
  position:fixed;
  display:flex;
  flex-direction: column;
  align-items: center;
  cursor:default;
}

.DislikeModalBody{
  width:90%;
  padding: 0 5%;
  overflow-y: auto;
  overflow-x: hidden;
}
.DislikeMessage{
  text-align: center;
  padding:2vh 0;
  font-size:1.2em;
  color:rgb(88,88,88);
}
.DislikeInputSection{
  padding: 2vh 0;
}

.DislikeInput{
  min-height: 20vh;
  width:100%;
  border:1px solid rgb(223,223,223);
  color:rgb(100,100,100);
  font-size:1.2em;
}
.DislikeInput:focus{
  outline: none;
}

.ButtonSection{
 
}


.GreyedTools{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width:25%;
}

.ToolText{
  margin-top:3px;
  font-size:0.7em;
  text-align: center;
  white-space:  nowrap;
  color:rgb(88, 88, 88);
}

.ToolTextFade{
  margin-top:3px;
  font-size:0.7em;
  text-align: center;
  white-space: nowrap;
  color:rgb(165, 165, 165);
}

  @media screen and (min-width: 1024px) {
    .WelcomeBody:hover{
        cursor: pointer;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    }
    .DiscoverBody:hover{
        cursor: pointer;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    }
    .AddToPlanBody:hover{
        cursor: pointer;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    }
    .SelectPlanBody:hover{
        cursor: pointer;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    }
    .PlanTools{
      top:10px;
      right:10px;
    }

    .PlanToolsRTL{
      top:10px;
      left:10px;
    }

    .ToolsIcon{
      height:30px;  
      margin:0 7px;
    }
    .DislikeIcon{
      height:30px;
      margin:0 25px;
    }
    .ToolsIconGreyed{
      height:30px;  
      margin:0 7px;
    }
    .GreyedTools{
      height:35px;  
      margin:0 8px;
    }
    .DislikedModal{
      height:70vh;
      top:15vh;
      width:40%;
      left:30%;
      background-color: white;
      z-index: 100;
      border-radius: 20px;
      box-shadow:  0px 11px 8px -10px #ccc;
    }
    .DislikeModalBody{
      height:50vh;
    }

    .GreyedTools{
      margin:0 12px;
    }
  }

  @media screen and (max-width: 767px) {
    .WelcomeBody{
        min-width: 97vw;
        max-width:97vw;
        padding: 0%;
        max-height: auto; 
        min-height:40vh;
        margin:0;
        flex-direction: column;
        border-radius:10px;
        box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(155, 155, 155, 0.15),
        0 2px 2px rgba(167, 167, 167, 0.15), 0 5px 5px rgba(165, 165, 165, 0.15);
    }
    .DiscoverBody{
      min-width: 99vw;
      max-width:99vw;
      border-radius: 10px;
      margin:2vh 0;
      padding: 0%;
     max-height:60vh;
      min-height:40vh;
      flex-direction: column;
        box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(155, 155, 155, 0.15),
        0 2px 2px rgba(167, 167, 167, 0.15), 0 5px 5px rgba(165, 165, 165, 0.15);
    }
    
    .AddToPlanBody{
        min-width: 90vw;
        max-width:90vw;
        padding: 0%;
        max-height: 20vh; 
        min-height:20vh;
        box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(155, 155, 155, 0.15),
        0 2px 2px rgba(167, 167, 167, 0.15), 0 5px 5px rgba(165, 165, 165, 0.15);
    }
    .BodyContent{
      height:100%;
      min-height:40vh;
         width:100%;
         padding:0;
         flex-direction: column;
         justify-content: flex-start;
    }
    .BodyContentFlex{
      position:relative;
       transition: all 0.4s ease-out;
       opacity: 1;
       display:flex;
       align-items: flex-start;
       justify-content: space-between;
      max-height: 100%; 
      min-height:100%;
      width:100%;
      padding:0;
    }
    .InfoContent{
      height:100%;
    }
    .PlanDurationInfo{
      flex-wrap: wrap;
      display: flex;
    }
    .ImageSection{
        min-width:100%;
        max-width:100%;
       height:20vh;
        object-fit:cover;
    }
    
    .PhotoPreview {
       border-radius: 0;
       border-top-left-radius: 10px;
       border-top-right-radius: 10px;
        width:100%;
        min-height:20vh;
        max-height:20vh;
       object-fit: cover;
      }
      .PhotoPreviewRTL{
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width:100%;
        min-height:20vh;
        max-height:20vh;
       object-fit: cover; 
      }
    
      .AddToPlanPhotoPreview{
        width:100%;
        min-height:20vh;
        max-height:20vh;
       object-fit: cover;
      }
      .AddToPlanPhotoPreviewRTL{
        width:100%;
        min-height:20vh;
        max-height:20vh;
       object-fit: cover;
      }
      .SelectPlanPhotoPreview{
        width:100%;
        min-height:20vh;
        max-height:20vh;
       object-fit: cover;
      }
      .SelectPlanPhotoPreviewRTL{
        width:100%;
        min-height:20vh;
        max-height:20vh;
       object-fit: cover;
      }
    .PlanDurationInfo{
        font-size:1em;
    }
    .PlanName{
        font-size:1.2em;
    }
    .PlanEditors{
      position:absolute;
      bottom:1vh;
  }
  .EditorImage{
      width:40px;
  }
     
  .ToolsIcon{
    height:22px;  
    margin:0 10px;
  }
  .DislikeIcon{
    height:25px;
    margin:0 20px;
  }
  .PlanTools{
    top:5px;
    right:5px;
    padding:3px 2%;
  }
  .PlanToolsRTL{
    top:5px;
    left:5px;
    padding:3px 2%;
  }
  .GreyedTools{
    max-width:40%;
  }
    .LocationTags{
      padding: 7px 0;
    }

    .DislikedModal{
      height:70vh;
      top:10vh;
      width:90%;
      left:5%;
      background-color: white;
      z-index: 100;
      border-radius: 20px;
      box-shadow:  0px 11px 8px -10px #ccc;
    }
    .DislikeModalBody{
      height:50vh;
    }
    .DislikeMessage{
      font-size:1.1em;
    }
    .ButtonSection{
      width:50%;
  }
  .GreyedTools{
    margin:0 8px;
  }
  }


  @media screen and (min-width: 768px) and (max-width:1023px){
    .WelcomeBody{
      min-width: 90vw;
      max-width:90vw;
      padding: 0%;
      margin:0;
      max-height: 40vh; 
      min-height:40vh;
      flex-direction: column;
      border-radius:10px;
      box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(155, 155, 155, 0.15),
      0 2px 2px rgba(167, 167, 167, 0.15), 0 5px 5px rgba(165, 165, 165, 0.15);
  }
  .DiscoverBody{
    min-width: 98%;
    max-width:98%;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0%;
    max-height: 40vh; 
    min-height:40vh;
    flex-direction: column;
      box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(155, 155, 155, 0.15),
      0 2px 2px rgba(167, 167, 167, 0.15), 0 5px 5px rgba(165, 165, 165, 0.15);
  }
  
  .AddToPlanBody{
      min-width: 70vw;
      max-width:70vw;
      padding: 0%;
      max-height: 20vh; 
      min-height:20vh;
      box-shadow: 0 1px 1px rgba(90, 90, 90, 0.15), 0 2px 2px rgba(155, 155, 155, 0.15),
      0 2px 2px rgba(167, 167, 167, 0.15), 0 5px 5px rgba(165, 165, 165, 0.15);
  }
  .BodyContent{
    max-height: 40vh; 
    min-height:40vh;
       width:100%;
       padding:0;
       flex-direction: column;
       justify-content: flex-start;
  }
  .BodyContentFlex{
    position:relative;
     transition: all 0.4s ease-out;
     opacity: 1;
     display:flex;
     align-items: flex-start;
     justify-content: space-between;
    max-height: 100%; 
    min-height:100%;
    width:100%;
    padding:0;
    font-size: 1.2em;
  }
  .PlanDurationInfo{
    flex-wrap: wrap;
    display: flex;
  }
  .ImageSection{
      min-width:100%;
      max-width:100%;
     height:20vh;
      object-fit:cover;
  }
  
  .PhotoPreview {
     border-radius: 0;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
      min-width:100%;
      min-height:20vh;
      max-height:20vh;
     object-fit: cover;
    }
    .PhotoPreviewRTL{
      border-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      min-width:100%;
      min-height:20vh;
      max-height:20vh;
     object-fit: cover; 
    }
  
    .AddToPlanPhotoPreview{
      width:100%;
      min-height:20vh;
      max-height:20vh;
     object-fit: cover;
    }
    .AddToPlanPhotoPreviewRTL{
      width:100%;
      min-height:20vh;
      max-height:20vh;
     object-fit: cover;
    }
    .SelectPlanPhotoPreview{
      width:100%;
      min-height:20vh;
      max-height:20vh;
     object-fit: cover;
    }
    .SelectPlanPhotoPreviewRTL{
      width:100%;
      min-height:20vh;
      max-height:20vh;
     object-fit: cover;
    }
  .PlanDurationInfo{
      font-size:1em;
  }
  .PlanName{
      font-size:1.2em;
  }
  .PlanEditors{
      position:absolute;
      bottom:1vh;
  }
  .EditorImage{
      width:50px;
  }
     
.ToolsIcon{
  height:22px;  
  margin:0 10px;
}
.DislikeIcon{
  height:25px;
  margin:0 20px;
}
.PlanTools{
  top:10px;
  right:10px;
  padding:3px 2%;
}
.PlanToolsRTL{
  top:10px;
  left:10px;
  padding:3px 2%;
}
.GreyedTools{
  max-width:40%;
}
.DislikedModal{
  height:70vh;
  top:10vh;
  width:90%;
  left:5%;
  background-color: white;
  z-index: 100;
  border-radius: 20px;
  box-shadow:  0px 11px 8px -10px #ccc;
}
.DislikeModalBody{
  height:50vh;
}
.DislikeMessage{
  font-size:1.1em;
}
.ButtonSection{
  width:50%;
}
.GreyedTools{ 
  margin:0 12px;
}
  
  }