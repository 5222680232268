.RTL{
  direction: rtl;
}

.Body{
  width:100%;
  height:auto;
  margin: 10px 0;
}

.CollapsedBody{
  display:flex;
  align-items: center;
  height:40vh;
  width:100%;
}

.SelectPlanBody{
  width:100%;
  height:20vh;
  display:flex;
  align-items: center;
  margin: 10px 0;
}

.Content{
  border: 1px solid rgb(235,235,235);
  border-top-right-radius: 10px;
  border-left: 0;
  min-width:30%;
  max-width:30%;
  height:38vh;
  padding:1vh 2.5%;
  margin-right:5px;
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.CollapsedContent{
  border-radius:10px;
  border: 1px solid rgb(235,235,235);
}

.ContentRTL{
  margin:0;
  margin-left:5px;
}

.SelectPlanContent{
  border: 1px solid rgb(235,235,235);
  border-radius:10px;
  min-width:40%;
  max-width:40%;
  height:18vh;
  padding:1vh 1%;
  margin:0 5px;
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.InfoSection{
  height:auto;
  margin-bottom:1vh;
}

.ImagesGallery{
  height:40vh;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  border-radius:10px;
  overflow-x: auto;
  width:100%;
  background-color: white;
}

.ImagesGallery::-webkit-scrollbar
{
  height: 0px;
  background-color: #F5F5F5;
}

.ImagesWrapper{
  display: flex;
  
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
overflow:none;
  width:70%;
}



.Arrow{
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 1px #c5c5c58e;
  width:40px;
  height:40px;
  padding:10px;
  position:absolute;
  z-index: 10;
  top:15vh;
}

.ArrowLeft{
  left:0;
}

.ArrowLeftRTL{
  right:0;
}

.ArrowRight{
  right:0;
}

.ArrowRightRTL{
  left:0;
}

.RotateArrow{
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.SelectPlanImageGallery{
  height:20vh;
  max-width:60%;

  border-radius:10px;
}

.Image{
  height:100%;
  object-fit: contain;
  margin:0 2px;
  position:relative;
}

.SelectPlanImage{
  height:100%;
  margin:0 2px;
  min-width:100%;
  max-width: 100%;
  position: relative;
  object-fit: cover;
}

.Tools{
  position:absolute;
  right:1vw;
}

.ToolsRTL{
  position:absolute;
  left:1vw;
}

.RankIcon{
  position:absolute;
  right:1vw;
  top:50px;
  height:35px;
}

.RankIconRTL{
  position:absolute;
  left:1vw;
  top:50px;
  height:35px;
}

.ToolsIcon{
  height:25px;
  margin-left: 10px;
  padding:5px;
}

.ToolsIconRTL{
  height:25px;
  margin-right: 10px;
  padding:5px;
}

.public-DraftStyleDefault-block{
  margin: 0px;
  line-height: 15px;
  max-height:20vh;
  
}

.ExpandIcon{
  position: absolute;
}
.ExpandIconRTL{
  position: absolute;
}

.SelectPlanText{
  font-size:0.9em;
  position: relative;
  color:rgb(90,90,90);
  max-height: 16vh;
  min-height: 16vh;
  overflow-y: hidden;
  top:-1vh;
}

.Owner{
  display:flex;
  align-items: center;
  padding: 7px 0;
  font-size:1.1em;
}

.OwnerImage{
  border-radius: 50%;
  height:50px;
  margin-right:15px;
}

.OwnerImageRTL{
  border-radius: 50%;
  height:50px;
  margin-left:15px;
}

.ExperienceInfo{
  color:rgb(100,100,100);
  font-size:0.9em;
  height:46px;
  padding: 2px 0;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.OwnerName{

}

.ExperienceDate{

}

.ExperiencePlace{
  display:flex;
  align-items: flex-start;
  padding-bottom: 10px;
  width: 90%;;
}
.ExperiencePlan{
  display:flex;
  align-items: flex-start;
  width: 90%;;
}

.PlaceName{
  color:rgb(34,121,190);
  font-weight: 500;
}

.PlanName{
  color:rgb(87, 87, 87);
  font-weight: 500;
}

.Icon{
  height:20px;
  margin-right:10px;
  margin-bottom:3px;
}

.IconRTL{
  height:20px;
  margin-left:10px;
  margin-bottom:3px;
}

.SeeMoreButton{
  position: absolute;
  bottom:2vh;
  z-index: 10;
  color:rgb(90,90,90);
  width:100%;
  text-align: center;
  height:4vh;
  right:0%;
  }
  

  .TaggedUsers{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .User{
    margin:0 10px;
    color:rgb(47, 105, 153);
    font-weight: 500;
  }


@media screen and (min-width: 1024px) {
  
.ExperienceText{
  position: relative;
  color:rgb(90,90,90);
  max-height: 20vh;
  min-height: 20vh;
  overflow-y: hidden;
  top:-1vh;
  
}
.User:hover{
  cursor: pointer;
}


.ExpandedExperienceText{
  color:rgb(90,90,90);
  height:auto;
  overflow-y: hidden;
  padding:5px 2.5%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(235,235,235);
  margin-top:0;
  border-top:0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
}
.ExpandedExperienceTextWidth{
  width:95%;
}
.ExpandedExperienceTextWidthNoImage{
width:30%;
}
  .ToolsIcon:hover{
    cursor: pointer;
  }
  .ToolsIconRTL:hover{
    cursor: pointer;
  }
  .Image:hover{
    cursor: pointer;
  }
  .PlaceName:hover{
    cursor: pointer;
  }
  .PlanName:hover{
    cursor: pointer;
  }
  .SeeMoreButton:hover{
    cursor: pointer;
    background-color: rgba(245, 245, 245, 0.649);
  }
  .ExpandIcon:hover{
    cursor: pointer;
  }
  .ExpandIconRTL:hover{
    cursor: pointer;
  }
  .Arrow:hover{
    cursor: pointer;
  }
  .Clickable:hover{
    cursor: pointer;
  }
  .ExpandIcon{
    height:20px;
    left:17.5%;
    top:5px;
  }
  .ExpandIconRTL{
    height:20px;
    right:17.5%;
    top:5px;
  }
}

@media screen and (max-width: 767px) {

  .Body{
    width:100%;
    height:auto;
    margin: 10px 0;
    border-bottom: 1px solid rgb(235,235,235);
  }

  .CollapsedBody{
    width:100%;
    flex-direction: column;
    height:auto;
  }
  .CollapsedPlanBody{
    font-size:0.8em;
    display: flex;
    align-items: center;
    height:20vh;
    width:100%;
  }

  .SelectPlanText{
    font-size:0.9em;
    position: relative;
    color:rgb(90,90,90);
    max-height: 10vh;
    min-height: 10vh;
    overflow-y: hidden;
    top:-1vh;
  }
  

  .Content{
    border: 1px solid rgb(235,235,235);
    border-radius:0;
    min-width:95%;
    max-width:95%;
    height:auto;
    padding:1vh 2.5%;
    margin-right:5px;
    position:relative;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  
  .ContentRTL{
    margin:0;
  }

  .ImagesWrapper{
    width:100%;
    padding-bottom: 5px;
    max-height:30vh;
  }
  .ImagesGallery{
    height:30vh;
    background-color: black;
    border-radius: 0;
  }
  .Image{
    /* min-width:100vw;
    max-width:100vw; */
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 30vh;
    object-position: center center;
  }
  .image-gallery {
    width: 40%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}
  .Arrow{
    top:12vh;
    width:35px;
    padding:5px;
    height:35px;
  }
  .ExperienceText{
    position: relative;
    color:rgb(90,90,90);
    height:auto;
    max-height:20vh;
    overflow-y: hidden;
  }
  .ExpandedExperienceText{
    color:rgb(90,90,90);
    height:auto;
    overflow-y: hidden;
    padding:5px 0%;
    width:100%;
    background-color: white;
    border-radius: 0;
    border: none;
    margin-top:0;
    border-top:0;
    position: relative;
  }
  .ExpandIcon{
    height:20px;
    left:48%;
    bottom:0;
  }
  .ExpandIconRTL{
    height:20px;
    right:48%;
    bottom:0;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){

  .Body{
    width:100%;
    height:auto;
    margin: 10px 0;
    border-bottom: 1px solid rgb(235,235,235);
  }

  .CollapsedBody{
    width:100%;
    flex-direction: column;
    height:auto;
  }

  .CollapsedPlanBody{
    display: flex;
    align-items: center;
    height:20vh;
    width:100%;
    padding: 0;
  }

  .SelectPlanText{
    font-size:0.9em;
    position: relative;
    color:rgb(90,90,90);
    max-height: 12vh;
    min-height: 12vh;
    overflow-y: hidden;
    top:-1vh;
  }
  .SelectPlanContent{
    border: 1px solid rgb(235,235,235);
    border-radius:10px;
    min-width:50%;
    max-width:50%;
    height:18vh;
    padding:1vh 1%;
    margin:0 5px;
    position:relative;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }

  .Content{
    border: 1px solid rgb(235,235,235);
    border-radius:0;
    min-width:95%;
    max-width:95%;
    height:auto;
    padding:1vh 2.5%;
    margin-right:5px;
    position:relative;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  
  .ContentRTL{
    margin:0;
  }

  .ImagesWrapper{
    width:100%;
    padding-bottom: 5px;
  }
  .ImagesGallery{
    height:30vh;

    border-radius: 0;
  }
  .Arrow{
    top:12vh;
    width:35px;
    padding:5px;
    height:35px;
  }
  .ExperienceText{
    position: relative;
    color:rgb(90,90,90);
    height:auto;
    min-height: auto;
    max-height: auto;
    overflow-y: hidden;
    top:-4vh;
  }
  .ExpandedExperienceText{
    color:rgb(90,90,90);
    height:auto;
    overflow-y: hidden;
    padding:5px 0%;
    width:100%;
    background-color: white;
    border-radius: 0;
    border: none;
    margin-top:0;
    border-top:0;
    position: relative;
    top:-4vh;
  }
  .ExpandIcon{
    height:20px;
    left:48%;
    bottom:0;
  }
  .ExpandIconRTL{
    height:20px;
    right:48%;
    bottom:0;
  }
  .OwnerImage{
    height:60px;
  }
  .OwnerImageRTL{
    height:60px;
  }

}