 
.DaySection{
    background-color: rgb(247,247,247);
    height:auto;
    padding:5px 0;
    margin:5px 0;
}

.DayTitle{
    font-size:1.3em;
    color:rgb(49, 49, 49);
    padding: 5px 2.5%;
}

.PlaceSection{
    margin:10px 1%;
    padding: 10px 1%;
    width:96%;
    background-color: white;
}

.PlaceExperiencesSection{
    width:100%;
    padding:7px 0;
}

.PlaceTopSection{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
}

.PlaceTools{
    display:flex;
    align-items: center;
}

.PlaceNameSection{
    display:flex;
    align-items: flex-start;
    padding-bottom: 10px;
  }
  
  .PlaceName{
    color:rgb(34,121,190);
    font-weight: 500;
  }
  
  
  .Icon{
    height:20px;
    margin-right:10px;
    margin-bottom:3px;
  }
  
  .IconRTL{
    height:20px;
    margin-left:10px;
    margin-bottom:3px;
  }
  
  .ToolsIcon{
      height:25px;
      margin:0 10px;
  }

.ImagesGallery{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-width:100%;
    direction: ltr;
}


.SelectedImageBullet{
    position:absolute;
    top:5px;
    right:5px;
    height:14px;
    width:14px;
    border: 3px solid white;
    background-color: rgb(34,121,190);
    border-radius: 50%;
}

.NotSelectedImageBullet{
    position:absolute;
    top:5px;
    right:5px;
    height:18px;
    width:18px;
    border: 1px solid rgb(117, 117, 117);
    background-color: white;
    border-radius: 50%;
}
.EditImageSection{
    object-fit: cover;
    height:30vh;
    width:24%;
    margin:0.5%;
    position:relative;
}


.ExpImage{
    object-fit: cover;
    height:35vh;
    width:32%;
    margin:0.5%;
}

.EditImage{
    object-fit: cover;
    height:35vh;
    height:100%;
    width:100%;
}

.MovingImage{
    object-fit: cover;
    height:35vh;
    width:32%;
    border:1px solid rgb(88,88,88);
    margin:0.5%;
}

.UpdateButton{
    border-radius: 5px;
    border: 1px solid rgb(223,223,223);
    padding: 5px 10px;
    background-color: white;
}

@media screen and (min-width: 1024px) {
    .PlaceName:hover{
        cursor: pointer;
    }
    .ToolsIcon:hover{
        cursor: pointer;
    }
    .EditImageSection:hover{
        cursor: pointer;
    }
    .UpdateButton:hover{
        background-color: rgb(240,240,240);
        cursor: pointer;
    }
}

@media screen and (max-width: 767px){
    .DaySection{
        margin:0;
    }
    .EditImageSection{
        height:18vh;
        width:48%;
        margin:0.5%;
        position:relative;
    }
    
    
    .ExpImage{
        height:20vh;
        width:48%;
        margin:0.5%;
    }
    
    .EditImage{
        height:100%;
        width:100%;
    }
    
    .MovingImage{
        height:20vh;
        width:46%;
        border:2px solid rgb(88,88,88);
        margin:0.5%;
    }
    
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .EditImageSection{
        height:20vh;
        width:48%;
        margin:0.5%;
        position:relative;
    }
    
    
    .ExpImage{
        height:20vh;
        width:48%;
        margin:0.5%;
    }
    
    .EditImage{
        height:100%;
        width:100%;
        object-fit: cover;
    }
    
    .MovingImage{
        height:20vh;
        width:48%;
        border:2px solid rgb(88,88,88);
        margin:0.5%;
    }
    .ToolsIcon{
        height:25px;
        margin:0 15px;
    }
  
    
}