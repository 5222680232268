.Body{
    display: flex;
    align-items: flex-start;
    position:relative;
    height:100%;
    padding-top:10px;
}

.PlanOverview{
    padding: 0;
    width:55vw;
    height:80vh;
    position:relative;
    display:flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    
}
.PlanOverview::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: #F5F5F5;
}

.PlanOverview::-webkit-scrollbar
{
width: 3px;
background-color: #F5F5F5;
}

.PlanOverview::-webkit-scrollbar-thumb
{
border-radius: 5px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: rgba(168, 168, 168, 0.562);
}
.OverviewBox{
    position:relative;
    height:50vh;
    width:40vw;
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 55px;
}



.InfoBox{
    top:5vh;
    height:auto;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    width:100%;
    position:absolute;
    z-index:1;
}
.PlanName{
    font-size: 2.5em;
    font-weight: 500;
    width:90%;
    padding: 10px 5%;
    color: rgba(255, 255, 255);
    border: 1px solid white;
    background-color: rgba(255, 115, 0, 0.412);
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.PlanImage{
    max-width:100%;
   

}

.PlanImagePlaceholder{
    max-width:100%;
    min-width: 100%;
    height:50vh;
    border:1px solid rgba(255, 122, 34, 0.171);
    background-color: white;
}

.ImagePreview{
    object-fit: cover;
    width:40vw;
    height:50vh;
}

.PlanDates{
    width:100%;
    padding:10px 0;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.641);
    color: rgb(66, 66, 66);
    font-size: 2em;
    font-weight: 500;
}

.DateSection{
    display:flex;
}
.DurationSection{
    margin-top:25px;
    display:flex;
    justify-content: center;
}

.PlanDuration{
    color: rgb(255, 119, 0);
    background-color: rgba(255, 255, 255, 1);
    /* background:radial-gradient(circle at center, rgba(255, 255, 255, 0.432) 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0.16)); 
     */
    height: 100px;
    width: 100px;
    font-size: 1.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
    border: 1px solid rgb(255, 119, 0);
    box-shadow: none;
    margin: 0 40px;
}


.CustomDatePickerWidth{
    color: rgb(255, 119, 0);
    background-color: rgba(255, 255, 255, 1);
    /* background:radial-gradient(circle at center, rgba(255, 255, 255, 0.432) 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0.16)); 
     */
    height: 70px;
    width: 250px;
    font-size: 1.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    border-radius: 45px;
    font-weight: 500;
    border: 1px solid rgb(255, 119, 0);
    box-shadow: none;
    margin: 0 40px;
   /*  box-shadow:
          0 0 5px 2px rgba(255, 255, 255, 0.349), */  /* inner white */
        /*   0 0 6px 4px rgba(224, 224, 224, 0.418), */ /* middle magenta */
        /*   0 0 8px 5px rgba(223, 223, 223, 0.295);  *//* outer cyan */
  }

  .WallSection{
    margin: 0 2.5%;
    width:40%;
    height:80vh;
    position:relative;
    display:flex;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .PlanDaysScroller{
    width:100%;
  }

.DaysLeft{
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    width:70%;
    padding:10px 5%;;
    margin:0 10%;
}

.DaysCalendar{
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.CalendarIcon{
    height:150px;
}

.CalendarDays{
    position:absolute;
    font-size:2.5em;
    bottom:35px;
    color:rgb(100,100,100);
}

.DaysMessage{
    width:240px;
    height:150px;
    font-size: 2.5em;
    display:flex;
    align-items: flex-end;
    position: relative;
    bottom:10px;
 /*    border-bottom:2px solid rgb(255,119,0); */
    color:rgb(255,119,0);
    
}
.DreamMessage{
    width:100%;
    height:100px;
    font-size: 2em;
    display:flex;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
    position: relative;
    bottom:10px;
   /*  border-bottom:2px solid rgb(255,119,0); */
    color:rgb(255,119,0);
  
}
.Selection{
    width:95%;
    min-height:5vh;
    padding: 1vh 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px solid rgb(223,223,223);
}

.TabSeperator{
    height:5vh;
    width:2px;
    border-right:1px solid rgb(197, 197, 197);
}

.PlanDescription{
    width:80%;
    padding: 2vh 10%;
    height:100%;
}

.TextInput{

}

.PlanEditors{
    position: absolute;
    bottom:-50px;
    width:100%;
    z-index: 10;
}

.EditorImage{
    width:60px;
    border-radius: 50%;;
    margin:0 -2px;
}

.ManageEditorsIcon{
    width:30px;
    padding: 15px;
    border:1px solid rgb(230,230,230);
    background-color: white;
    border-radius: 50%;;
    margin:0 -2px;
    transition: 0.5s;
}

.EditIcon{
    position:absolute;
    top:5px;
    left:5px;
    height:25px;
    width:25px;
    padding:10px;
    background-color: rgba(255, 255, 255, 0.329); 
    z-index: 10;
    transition: 0.5s;
}

.EditIconRTL{
    position:absolute;
    top:5px;
    right:5px;
    height:25px;
    width:25px;
    padding:10px;
    background-color: rgba(255, 255, 255, 0.333); 
    z-index: 10;
    transition: 0.5s;
}


@media screen and (min-width: 1024px) {  
    .ManageEditorsIcon:hover{
        cursor: pointer;
        background-color: rgb(240,240,240);
        transform: scale(1.1);
        transition: 0.5s;
        position: relative;
        z-index: 10;
    }
    .EditIconRTL:hover{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.35); 
        transition: 0.5s;
    }
    .EditIcon:hover{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.35); 
        transition: 0.5s;
    }
}


@media screen and (max-width: 767px) {
    .Body{
        display: block;
       align-items: center;
       flex-direction: column;
       width:100%;
       padding: 0;
       height: 75vh;
       padding-bottom: 5vh;
       overflow-x: hidden;
       overflow-y: auto;
    }
    .PlanOverview{
        width:100%;
        position:relative;
        display:flex;
        align-items: center;
        flex-direction: column;
        height:auto;
        overflow:hidden;
    }
    
    .PlanDescription{
        width:95%;
        padding: 0 2.5%;
        height:100%;
    }

    .PlanName{
        width:100%;
        background-color: rgba(255, 115, 0, 0.412);
        top:4vh;
        height:7vh;
        justify-content: center;
        padding: 10px 2.5%;
        font-size: 1.6em;
        font-weight: 500;
    }

    .OverviewBox{
        width:100%;
        height:50vh;
        margin-bottom: 55px;
    }

    .PlanImage{
        max-width:100%;
    

    }

    .PlanEditors{
        bottom:-55px;
    }

    .PlanImagePlaceholder{
        max-width:100%;
        min-width:100%;
        height:50vh;
        background-color: white;
    }

    .ImagePreview{
        object-fit: cover;
        width:100%;
        height:50vh;
    }

    .InfoBox{
        height:45vh;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .PlanDates{
        bottom:0vh;
        position:relative;
        width:90%;
      padding:10px 5%;
      font-size:1.3em;
    }

    
.DateSection{
    max-width:100%;
    display:flex;
    justify-content: space-evenly;
}

.PlanDuration{
    color: rgb(255, 119, 0);
    background-color: rgba(255, 255, 255, 1);
    /* background:radial-gradient(circle at center, rgba(255, 255, 255, 0.432) 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0.16)); 
     */
    height: 80px;
    width:80px;
    font-size: 1em;
    border-radius: 50%;
    margin: 0 15px;
}
.CustomDatePickerWidth{
    color: rgb(255, 119, 0);
    background-color: rgba(255, 255, 255, 1);
    /* background:radial-gradient(circle at center, rgba(255, 255, 255, 0.432) 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0.16)); 
     */
    height: 50px;
    min-width: 40%;
    max-width:40%;
    font-size: 1em;
    padding-right: 5px;
    border-radius: 25px;
    margin: 0 15px;
  }
  .DaysLeft{
    position: relative;
    bottom:0;
    width:90%;
    padding:10px 5%;
    background-color: rgb(150, 150, 150);
    margin:0;
    height:auto;
}

.DaysCalendar{
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.CalendarIcon{
    height:90px;
}

.CalendarDays{
    position:absolute;
    font-size:2em;
    bottom:15px;
    color:rgb(100,100,100);
}

.DaysMessage{
    width:auto;
    height:85px;
    font-size: 1.5em;
    display:flex;
    align-items: flex-end;
    position: relative;
    bottom:0;
    color:rgb(255, 255, 255);
}
.DreamMessage{
    width:auto;
    height:auto;
    font-size: 1.3em;
    display:flex;
    align-items: flex-end;
    position: relative;
    color:rgb(255, 255, 255);
   bottom:0;
}
.PlanDaysScroller{
    margin: 0%;
  
    width:100%;
    height:auto;
  }
  .WallSection{
    margin:0;
    width:100%;
    font-size: 1.1em;
    height:auto;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Body{
        display: block;
       align-items: center;
       flex-direction: column;
       width:100%;
       padding: 0;
       height: 70vh;
       overflow-x: hidden;
       overflow-y: auto;
    }
    .PlanOverview{
        width:100%;
        position:relative;
        display:flex;
        align-items: center;
        flex-direction: column;
        height:auto;
        overflow:hidden;
    }
    
    .PlanDescription{
        width:95%;
        padding: 0 2.5%;
        height:100%;
    }

    

    .PlanName{
        width:100%;
        background-color: rgba(255, 115, 0, 0.412);
        top:4vh;
        height:7vh;
        justify-content: center;
        padding: 10px 2.5%;
        font-size: 1.6em;
        font-weight: 500;
    }

    .OverviewBox{
        width:100%;
        height:40vh;
    }

    .PlanImage{
        max-width:100%;
    

    }

    .PlanEditors{
        bottom:-55px;
    }

    .PlanImage{
        max-width:100%;
        min-width: 100%;

    }

    .PlanImagePlaceholder{
        max-width:100%;
        min-width:100%;
        height:40vh;
        background-color: white;
    }

    .ImagePreview{
        object-fit: cover;
        width:100%;
        height:40vh;
    }

    .InfoBox{
        height:35vh;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .PlanDates{
        bottom:0vh;
        position:relative;
        width:90%;
      padding:10px 5%;
      font-size:1.5em;
    }

    
.DateSection{
    max-width:100%;
    display:flex;
    justify-content: space-evenly;
}

.PlanDuration{
    color: rgb(255, 119, 0);
    background-color: rgba(255, 255, 255, 1);
    /* background:radial-gradient(circle at center, rgba(255, 255, 255, 0.432) 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0.16)); 
     */
    height: 80px;
    width:80px;
    font-size: 1em;
    border-radius: 50%;
    margin: 0 15px;
}
.CustomDatePickerWidth{
    color: rgb(255, 119, 0);
    background-color: rgba(255, 255, 255, 1);
    /* background:radial-gradient(circle at center, rgba(255, 255, 255, 0.432) 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0.16)); 
     */
    height: 50px;
    min-width: 40%;
    max-width:40%;
    font-size: 1em;
    padding-right: 5px;
    border-radius: 25px;
    margin: 0 15px;
  }
  .DaysLeft{
    position: relative;
    bottom:0;
    width:90%;
    padding:10px 5%;
    background-color: rgb(156, 156, 156);
    margin:0;
    height:auto;
}

.DaysCalendar{
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.CalendarIcon{
    height:90px;
}

.CalendarDays{
    position:absolute;
    font-size:2em;
    bottom:15px;
    color:rgb(100,100,100);
}

.DaysMessage{
    width:auto;
    height:85px;
    font-size: 2em;
    display:flex;
    align-items: flex-end;
    position: relative;
    bottom:0;
    color:rgb(255, 255, 255);
}
.DreamMessage{
    width:auto;
    height:auto;
    font-size: 1.7em;
    position: relative;
    bottom:0;
    color:rgb(255, 255, 255);
   
}
.PlanDaysScroller{
    margin: 0%;
  
    width:100%;
    height:auto;
  }
  .WallSection{
    margin: 0 2.5%;
    margin-top:20px;
    width:95%;
   
    height:auto;
  }
  .Selection{
    width:60%;
    padding: 10px 20%;
    font-size:1.2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

}