
.AlbumDay{
    height:100%;
    margin:0 2px;
    display:flex;
    align-items: center;
    min-width:100%;
    overflow-y: hidden;
    overflow-x: hidden;
    
    
}

.DayPlace{
    width:49.5vw;
    border-right:1px solid rgb(210,210,210);
    background-color: white;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.PlaceTitleSection{
    position: relative;
    top:4vh;
    color:rgb(100,100,100);
    display:flex;
    flex-direction: column;
    align-items: center;
}

.DateName{
    font-size:1.1em;
    padding:2vh 0;
}

.PlaceName{
    display:flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 0;
    font-size:1.5em;
}

.PlaceContent{
    position:relative;
    top:10%;
    height:60%;
    width:90%;
    margin: 0 5%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border:1px solid rgb(230, 230, 230);
}

.ShareNewExperience{
    height:100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    white-space: normal;
    text-align: center;
    color:rgb(194, 194, 194);
    font-size:3em;
    transition: 0.5s;
    border-radius: 10px;
    opacity: 0.7;
}

.ExperiencesFirstImage{
    height:100%;
    width:100%;
    object-fit: cover;
    transition: 0.8s;
    border-radius: 10px;
}

.PlaceIcon{
    height:25px;
    margin:0 15px;
}

.ExperienceMessage{
    position:fixed;
    z-index: 100;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.MessageButton{
    position:absolute;
    bottom:10%;
    color:rgb(100,100,100);
}

.MessageContent{
    position: absolute;
    white-space: normal;
    text-align: center;
    top:20%;
    width:80%;
    padding: 0 10%;
}

.ExperienceIcon{
    position:absolute;
    top:20px;
    right:20px;
   height:35px;
}

.ExperienceIconRTL{
    position:absolute;
    top:20px;
    left:20px;
    height:25px;
}

@media screen and (min-width: 1024px) {
    .ShareNewExperience:hover{
        cursor: pointer;
        color:rgb(156, 156, 156);
        transition: 0.5s;
        opacity: 1;
        border-radius: 10px;
    }
    .ExperiencesFirstImage:hover{
        cursor: pointer;
        opacity: 0.85;
        transition: 0.6s;
    }
    .PlaceName:hover{
        cursor: pointer;
    }
    .ExperienceMessage{
        top:20vh;
        height:40vh;
        width:30%;
        left:35%;
        background-color: white;
        box-shadow: 0 2px 2px #dadada;
        border-radius: 10px;
    }
    .MessageButton{
        border:1px solid rgb(223,223,223);
        border-radius: 5px;
        padding: 7px 15px;

    }
    .MessageButton:hover{
        box-shadow: 0 2px 2px #ebebeb;
        cursor: pointer;
    }
    .MessageContent{
        font-size:1.4em;
        color:rgb(88,88,88);
    }
    .ExperienceIcon:hover{
        cursor: pointer;
    }
    .ExperienceIconRTL:hover{
        cursor: pointer;
    }
    .PlaceContent:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px){
    .AlbumDay{
        display: block;
        overflow-y: hidden;
        overflow-x: hidden;
        position: relative;
        height:100%;
        height:auto;
    }
    .DayPlace{
        width:100%;
        margin:1vh 0;
    /*     min-height: 80vh; */
    }
    .PlaceTitleSection{
        top:2vh;
        width:100%;
    }
    .PlaceContent{
        top:2vh;
        width:100%;
        min-height:35vh;
    }   
    .ShareNewExperience{
        min-height:35vh;
        font-size:1.5em;
    }
    .ExperienceIcon{
        top:5px;
        right:10px;
        z-index: 15;
    }
    .ExperienceIconRTL{
        top:5px;
        left:10px;
        z-index: 15;
    }
    .PlaceTitleSection{
        height:20%;
        position: relative;
    /*     background-color: rgba(255, 255, 255, 0.829); */
        z-index: 10;
        margin: 2vh 0;
    }
    .PlaceName{
        display:flex;
        align-items: center;
        flex-direction: row;
        padding: 5px 0;
        font-size:1em;
    }
    .DateName{
        font-size:0.8em;
        padding:0.5vh 0;
    }
    .PlaceContent{
        position: unset;
    }
    .ExperiencesFirstImage{
        max-height:35vh;
        width:100%;
        object-fit: cover;
        transition: 0.8s;
        border-radius: 10px;
    }
    .PlaceIcon{
        height:20px;
        margin: 0 10px;
    }
}