.Body{
    width:100%;
    height:100%;
    overflow-y: auto;
    overflow-x: hidden;
}

  
.Body::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}


.RTL{
    direction: rtl;
}

.Wall{
    width:95vw;
    padding: 0 2.5vw;
    height:auto;
    background-color: rgb(250,250,250);
  position: relative;
  z-index: 5;
}

.TopSection{
    height:60vh;
    width:100%;
    padding: 0;
    background-color: rgba(255, 255, 255, 0);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(110deg,#b7d9ff 20%,   #2b97c9 70%, rgba(255, 152, 83, 0.829) 70%); */
}



.WelcomeImage{
    position:absolute;
    width:100%;
    object-fit: cover;
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FWelcomeWallImageDesktop.jpg?alt=media&token=a6ae07dc-acee-4998-b7ca-23e2ad29a8a0);
  background-size: cover;
  }

  .SearchSection{
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100%;
}
    
  .UserCountryOption{
    position: relative;
    color:white;
    font-size:1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:25px;
  }

  .SearchBarSection{
      display:flex;
      align-items: flex-start;
      position: relative;
  }

  .SearchBarSectionRTL{
    display:flex;
    align-items: flex-start;
    position: relative;
    direction: rtl;
  }

  .WelcomeMessage{
    color:white;
    width:100%;
    text-align: center;
  }

  .WelcomeMainMessage{
    font-weight: 700;
  }
  
  .WelcomeSubMessage{
  }


  .Section{
    margin-bottom:40px;
    width:95%;
    padding: 10px 2.5%;
    background-color: white;
  }

  .SectionTop{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .SubSection{
    width:100%;
  }
  
.SectionContent{
  display: flex;
  width:auto;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 5px 0;
  border-top: 1px solid rgb(223,223,223);
  border-bottom: 1px solid rgb(223,223,223);
  border:none;
}
.SectionContent::-webkit-scrollbar
{
 height:0;
 background-color: #d1d1d1;
}
.SectionContent::-webkit-scrollbar-thumb
{
 border-radius: 5px;
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
 background-color: rgba(94, 94, 94, 0.562);
}
.SeeAllButton{
  font-size:1em;
  color: rgb(34,121,190);
  position:absolute;
  right:0;
  bottom:2vh;
}
.SeeAllButtonRTL{
  font-size:1em;
  color: rgb(34,121,190);
  position:absolute;
  left:0;
  bottom:2vh;
}
.SeeAllButtonDesktop{
  font-size:1em;
  color: rgb(34,121,190);
}

.SeeAllButtonMobile{
  display:none;
}
.SectionHeader{
  font-weight: 500;
  padding-top:10px;
  font-size:1.5em;
  color:rgb(65, 65, 65);
}
.SubSectionHeader{
  font-weight: 400;
  padding-top:10px;
  font-size:1.1em;
  color:rgb(34,121,190);
}
.SuggestionHeader{
  cursor: pointer;
  color:rgb(34,121,190);
  padding: 0 2.5%;
}

.ExperiencesHeader{
  padding:15px 0;
  position: relative;
  width:100%;
}

.ExperiencesWall{
 overflow-y: auto;
  width:80%;
  height:auto;
  padding: 0 10%;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;
}
.ExperiencesWall::-webkit-scrollbar
{
width: 0;
background-color: #F5F5F5;
}

.EmptyWall{
  display:flex;
  flex-direction: column;
  align-items: center;
   background-color: rgb(250,250,250);
   height:auto;
   padding-bottom: 5vh;
}

.ExperiencesFeedButton{
  min-height:6vh;
  max-height: 6vh;
  width:220px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(255,119,0,0.8);
  color:white;
  font-size:1.1em;
  font-weight: 500;
  border-radius:15px;
  margin-top:25px;
  transition: 0.3s;
}

.NoExperiencesMessage{
  font-size:1.2em;
  color:#464646;
  text-align: center;
}

.CallToAction{
  position:relative;
  top:3vh;
  padding:7px 15px;
  border-radius: 5px;
  font-size:1.3em;
  border: 1px solid rgb(223,223,223);
  background-color: rgb(251,251,251);
}

.ContentTypeSelection{
  display:flex;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
}

.TabSeperator{
  border-left:1px solid rgb(197, 197, 197);
  height:100%;
}

@media screen and (min-width: 1024px) {
.CallToAction:hover{
  cursor: pointer;
  background-color: white;
}
.SearchSection{
    width:50%;
    padding: 5vh 25%;
}

.WelcomeImage{
  height:60vh;
}


.UserCountryOption{
    margin-top: 50px;
    width:50%;
    height:60px;
    background-color: rgb(255, 124, 36);
    transition: ease 1s;
  }
  
.UserCountryOption:hover{
    cursor:pointer;
    transform: scale(1.1);
    transition: ease 1s;
    background-color: rgba(255, 119, 0,1);
    }
.SearchBarSection{
    width:60%;
    padding: 2vh 5%;
}
.SearchBarSectionRTL{
  width:60%;
  padding: 2vh 5%;
}
.WelcomeMainMessage{
    font-size:3em;
  }
  
  .WelcomeSubMessage{
    font-size:1.7em;
  }

  .Wall{
    padding-bottom: 5vh;
  }
  .SeeAllButtonDesktop{
    font-size:1em;
    color: rgb(34,121,190);
  }
  .SeeAllButtonDesktop:hover{
    cursor: pointer;
  }
  .SeeAllButtonMobile{
    display:none;
  }
  .SeeAllButton:hover{
    cursor: pointer;
  }
  .SeeAllButtonRTL:hover{
    cursor: pointer;
  }
  .ExperiencesFeedButton:hover{
    cursor: pointer;
    background-color: rgb(255,110,0);
    transition: 0.3s;
  }

}


@media screen and (max-width: 767px) {
    .TopSection{
        height:50vh;
    }
    .SearchSection{
        width:90%;
        padding: 5vh 5%;
        padding-top:10vh;
    }
    .SearchBarSectionRTL{
      position:relative;
        left:0%;
        width:90%;
        padding: 2vh 5%;
    }
    .SearchBarSection{
      position:relative;
      width:90%;
        padding: 2vh 5%;
    }
    .Wall{
      width:100vw;
      padding: 0;  
      padding-bottom:15vh;
  }
  .WelcomeImage{
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FWelcomeWallImageMobile.jpg?alt=media&token=b5c086f6-84e7-4d06-b14c-0b4d77ae38e6);
  background-size: cover;
    height:50vh;
  }
    .UserCountryOption{
        top:2vh;
        width:80%;
        min-height:50px;
        max-height:50px;
        background-color: rgb(255, 124, 36);
      }
    .WelcomeMainMessage{
    font-size:1.7em;
    font-weight: 600;
    }
    
    .WelcomeSubMessage{
    font-size:1.1em;
    }
   /*  .SearchBarSection{
      position:relative;
      left:-5%;
      width:90%;
    } */
    .NewButton{
      position:fixed;
      top:10vh;
      left:10px;
      z-index:25;
     
    }
    
    .CreateIcon {
      position: relative; 
     
      cursor: pointer;
      color: rgb(34,121,190);
      background-color: rgba(255, 255, 255);
      font-size: 1.5em;
      font-weight: 600;
      padding: 10px;
      min-height: 25px;
      min-width:  25px;
      max-height:  25px;
      max-width:  25px;
      border-radius: 50%;
      border:1px solid rgb(223,223,223);
    }

    .SeeAllButtonMobile{
      display:block;
      font-size:0.9em;
      color: rgb(34,121,190);
      width:100%;
      text-align: end;
  }
  .SeeAllButtonDesktop{
      display:none;
  }
  .ExperiencesWall{
    width:99%;
    padding:0 0.5%;
  }
  .SectionHeader{
    width:95%;
    padding: 0 2.5%;
    font-size: 1.3em;
  }
  .SeeAllButton{
    right:2vw;
  }
  .SeeAllButtonRTL{
    left:2vw;
  }
  .Section{
    margin-bottom:40px;
    width:98%;
    padding: 10px 1%;
    background-color: white;
  }
  .NoExperiencesMessage{
    font-size:1.3em;
    color:#4d4d4d;
  }
  .ContentTypeSelection{
    display:flex;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    padding:2px 10px;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .TopSection{
        height:50vh;
    }
    .SearchSection{
        width:70%;
        padding: 5vh 15%;
    }
    .SearchBarSection{
      padding: 2vh 5%;
        width:70%;
    }
    .SearchBarSectionRTL{
      width:70%;
        padding: 2vh 5%;
    }
    .Wall{
      width:98vw;
      padding: 0 1vw;  
  }
    .WelcomeImage{
      background: 
      linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url(https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FWelcomeWallImageMobile.jpg?alt=media&token=b5c086f6-84e7-4d06-b14c-0b4d77ae38e6);
    background-size: cover;
      height:50vh;
    }
    .UserCountryOption{
      top:10vh;
        width:50%;
        height:50px;
        background-color: rgb(255, 124, 36);
    }
    .WelcomeMainMessage{
    font-size:2.2em;
    font-weight: 600;
    }
    
    .WelcomeSubMessage{
    font-size:1.5em;
    }
   

    .SeeAllButtonMobile{
      display:block;
      font-size:0.9em;
      color: rgb(34,121,190);
      width:100%;
      text-align: end;
  }
  .SeeAllButtonDesktop{
      display:none;
  }
  .ExperiencesWall{
    width:100%;
    padding:0;
  }
  
}