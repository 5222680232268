.Modal{
    position:fixed;
    z-index: 50;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  
 /*    border: 1px solid rgb(224, 224, 224); */
    width: 40%;
    left: 30%;
    height: 80vh;
    top: 10vh;
      border-radius: 20px;
    
}
.RTL{
  direction: rtl;
}
.Body{
    padding: 20px 20px;
    height: 67vh;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.Body::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(114, 114, 114, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.Body::-webkit-scrollbar
{
  height:90%;
  height: 5px;
  width:3px;
  background-color: rgb(160, 160, 160);
}

.Body::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgba(211, 211, 211, 0.562);
}
.Body::-webkit-scrollbar-track-piece{
  background-color: #F5F5F5;

}
.UsersSection{
    width: 95%;
    padding: 0 2.5%;
    margin-top: 15px;
}

.ContentSelections{
    display: flex;
}

.TabSeperator{
  min-height:3vh;
  border-right:1px solid rgb(194, 194, 194);
  width:2px;
}

@media screen and (max-width: 767px) {
    .Modal{
      width: 100vw;
      left: 0;
      height: 100%;
      top: 0;
      border:none;
      box-shadow: none;
      border-radius: 0;
      
    }
    .Body{
      width:95%;
      padding: 5% 2.5%;
      height:80%
    }
    .Content{
        padding: 7px;
        height:80vh;
    }
    .UsersSection{
      width: 100%;
      padding: 0;
      margin-top: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    width: 100vw;
    left: 0;
    height: 100%;
    top: 0;
    border:none;
    box-shadow: none;
    border-radius: 0;
    
  }
  .Body{
    width:80%;
    padding: 20px 10%;
    height: 75vh;
    position: relative;
    overflow-y: auto;
}
  .Content{
      padding: 7px;
      height:80vh;
  }
}