.Body{
    width:100%;
    padding-bottom:25px;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom:4px solid rgb(240,240,240);
    margin:0vh 0;
    background-color: white;
   /*  border-radius:15px; */
}

.RTL{
    direction: rtl;
}

.Title{
    position: relative;
    height:5vh;
    width:95%;
    padding:1vh 2.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.3em;
    font-weight: 400;
    color:rgb(100, 100, 100);
   /*  background-color: rgb(172, 172, 172); */
    border-top:1px solid rgb(150, 150, 150);
    border-bottom: 1px solid rgb(151, 151, 151);
   /*  border-top-right-radius: 15px;
    border-top-left-radius: 15px; */
}
.OngoingTitle{
    position: relative;
    height:5vh;
    width:95%;
    padding:1vh 2.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.3em;
    font-weight: 350;
    background-color: rgb(55, 145, 218);
    color:white;
   /*  border-top-right-radius: 15px;
    border-top-left-radius: 15px; */
}
.TitleHover:hover{
    cursor: pointer;
}

.Name{

}

.DayContent{
    padding:5px 5%;
    width:90%;
    height:100%;
}

.CalendarDayContent{
/*     border-top:5px solid rgb(223,223,223);
    border-bottom:5px solid rgb(223,223,223);
    border-left:5px solid rgb(223,223,223);
    border-right:5px solid rgb(223,223,223); */
    padding-top:1vh;
    width:90%;
    min-height:0vh;
    max-height:64vh;
    overflow-y: auto;
    overflow-x: hidden;
}


.CalendarDayContent::-webkit-scrollbar
{
width:0;
background-color: #F5F5F5;
}


.AddButton{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    color:rgb(100,100,100);
    font-size: 1.1em;;
}

.PlusIcon{
    width:30px;
}

.CheckIcon{
    margin-right:12px;
    width:26px;
    height:26px;
    position:absolute;
    left:25px;
  }

  .CheckIconRTL{
    margin-left:12px;
    width:26px;
    height:26px;
    position:absolute;
    right:25px;
  }

  
  .UncheckBox{
    margin-right:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
    position:absolute;
    left:25px;
    background-color: white;
  }

  .UncheckBoxRTL{
    margin-left:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
    position:absolute;
    right:25px;
    background-color: white;
  }
.AddButton:hover{
    cursor: pointer;
    background-color: rgba(247, 247, 247, 0.562);
}

.AddMenu{
    position:fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5%;
    font-size: 1.2em;
    color:rgb(90,90,90);
    top:40vh;
    left:30%;
    width:30%;
    z-index: 125;
    border-radius: 7px;;
    background-color: rgba(150, 150, 150, 0.837);
}

.MenuItem{
    padding: 7px 15px;
    border:0.6px solid rgb(223,223,223);
    background-color: rgba(255, 255, 255, 0.952);
    margin: 3px 10px;
    height:25px;
    width:100px;
    z-index:10;
    border-radius: 5px;
   
    display:flex;
    align-items: center;
    justify-content: center;
}
.MenuItem:hover{
    cursor: pointer;
    color:white;
    background-color: rgba(93, 162, 219, 0.95);
}

.DetailIcon{
    display:flex;
    align-items: center;
    white-space: nowrap;
    width:20px;
    position:absolute;
    right:25px;
    color:white;
}

.DetailIconRTL{
    display:flex;
    align-items: center;
    white-space: nowrap;
    width:20px;
    position:absolute;
    left:25px;
    color:white;
}


.DetailIconWhite{
    display:flex;
    align-items: center;
    white-space: nowrap;
    width:20px;
    position:absolute;
    right:25px;
    color:white;
}
.DetailIconWhiteRTL{
    display:flex;
    align-items: center;
    white-space: nowrap;
    width:20px;
    position:absolute;
    left:25px;
    color:white;
}

@media screen and (min-width: 1024px) {  
    .DetailIcon:hover{
        cursor: pointer;
    }
    .DetailIconRTL:hover{
        cursor: pointer;
    }

    .DetailIconWhite:hover{
        cursor: pointer;
    }
    .DetailIconWhiteRTL:hover{
        cursor: pointer;
    }
    .CheckIcon:hover{
        cursor: pointer;
    }
    .CheckIconRTL:hover{
        cursor: pointer;
    }
    .UncheckBox:hover{
        cursor: pointer;
    }
    .UncheckBoxRTL:hover{
        cursor: pointer;
    }
    
}

@media screen and (max-width: 767px) {  
    .Body{
        width:100%;
        margin: 0;
        padding-bottom:10px;
        border-radius: 0;
    }
    .Title{
        position: relative;
        height:4vh;
        width:95%;
        padding:5px 2.5%;
        font-size:1.1em;
        font-weight: 350;
        border-radius: 0;
    }
    .OngoingTitle{
        height:4vh;
        width:95%;
        padding:5px 2.5%;
        font-size:1.1em;
        border-radius: 0;
    }
    .PlusIcon{
        width:20px;
    }
    
    .CheckIcon{
        width:16px;
        position:absolute;
        left:5px;
    }
    
    .DetailIcon{
        width:16px;
        right:15px;
        color:rgb(140,140,140);
    }
    .AddMenu{
        font-size: 1em;
        top:40vh;
        width:50%;
        left:25%;
    }
    .MenuItem{
        padding: 5px 10px;
        border:1px solid rgb(224, 224, 224);
        box-shadow:1px 2px 3px rgb(230, 230, 230);
        background-color: rgba(255, 255, 255, 1);
        margin: 3px 5%;
        height:25px;
        width:90%;
        z-index:10;
        border-radius: 5px;
       
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .DayContent{
        padding:5px 0%;
        width:100%;
        height:100%;
        overflow-x: hidden;
    }
    
    
}

@media screen and (min-width: 768px) and (max-width:1023px){

}