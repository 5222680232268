.MarkerWrapper{
  position:absolute;
  top:0;
  z-index: 1;
}
.MarkerWrapper:hover{
  z-index:10;
  transform: scale(1.5);
}

.MarkerWrapperHighlight{
  position:absolute;
  z-index: 5;
  top:50px;
}
