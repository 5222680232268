.SelectedFilter{
    padding:7px 15px;
    height:25px;
    display:flex;
    align-items: center;
    justify-content: center;
    
    border:1px solid rgba(34, 120, 190, 0.54);
   
    color:white;
    background-color: rgb(34,121,190,0.5);
    font-weight:400; 
    margin: 5px 7px;
    min-width: 30px;
    border-radius:5px;
}
.SelectedFilter:hover{
    cursor: pointer;
}
.GreyedFilter{
    height:25px;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:7px 15px;
    border:1px solid rgba(34, 120, 190, 0.54);
    margin: 5px 7px;
    color: rgb(34,121,190,0.8);
    min-width: 30px;
    border-radius:5px;
}

.GreyedFilter:hover{
    cursor: pointer;
    color: rgb(34,121,190,0.8);
    background-color: rgba(34, 120, 190, 0.15);
}

