.RTL{
  direction:rtl;
}
.MapView{
  max-height:17vh;
  margin:0;
  width:100%;
  display:flex;
  background-color: white;
}

.MapViewSelected{
  max-height:17vh;
  margin: 0;
  display:flex;
  box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
    0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
   top:-2px;
   transition-delay:5s ;
   transition: ease 0.4s;
  /*  background-color: rgb(240,240,240); */
   background-color: rgb(255,119,0,0.2);
   border:1px solid rgb(255,119,0,0.8);
   border-radius: 10px;
}

.LocationStats{
  width:70%;
  text-align: start;
  font-size: 0.8em;
  font-weight: 400;
  color: rgb(90, 90, 90);
  margin-bottom: 5px;
}


.RegularWidth{
  width: 100%;

}
.WideWidth{
  width:47.5%;
  margin: 0.5vh 1%;

}

.JourneyPlace{
  max-height:22vh;
  margin:2px;
  width:70%;
  display:flex;
  background-color: white;
  border-left:1px solid rgb(230,230,230);
}


.PlanDiscover{
  max-height:17vh;
  margin:1%;
  width:98%;
  display:flex;
  background-color: white;
  border:1px solid rgb(230,230,230);
  border-radius: 10px;
}


.SlimBody{
height:auto;
width:95%;
padding:1vh 2.5%;

}

.SlimBody:hover{
  cursor: pointer;
  background-color: rgb(245,245,245);
}

.PhotoPreview{
  height:17vh;
  padding-right:0.5vw;
  min-width:40%;
  max-width:40%;
  object-fit: cover;
}

.PhotoPreviewRTL{
  height:17vh;
  padding-left:0.5vw;
  min-width:40%;
  max-width:40%;
  object-fit: cover;
}

.JourneyPhoto{
  height:21vh;
  padding:0.5vh;
  min-width:40%;
  max-width:40%;
  object-fit: cover;
}

.DiscoverPhotoPreview{
  height:17vh;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width:40%;
  max-width:40%;
  padding-right:0.5vw;
  object-fit: cover;
}
.DiscoverPhotoPreviewRTL{
  height:17vh;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-width:40%;
  max-width:40%;
  padding-left:0.5vw;
  object-fit: cover;
}
.Content{
  padding: 0;
  min-width:55%;
  max-width:55%;
  height:17vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.SlimContent{
width:100%;
}

.TopSection {
  padding: 5px 0;
  width: 100%;
}


.TitleSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Title {
  text-align: start;
  cursor: pointer;
  width: 90%;
  resize: none;
  height: auto;
  font-style: italic;
  border: none;
  outline: none;
  font-size: 1em;
  font-weight: 500;
  color: rgb(65, 65, 65);
}

.Category {
 
  text-align: start;
  font-size: 0.9em;
  font-weight: 450;
  color: rgb(80, 80, 80);
  white-space: nowrap;
 
}

.CategoryLTR{
  margin-right:7px;
  padding-right:7px;
  font-size: 0.9em;
  font-weight: 450;
  color: rgb(80, 80, 80);
  border-right:1px solid rgb(235,235,235);
}

.CategoryRTL{
  margin-left:7px;
  padding-left:7px;
  font-size: 0.8em;
  font-weight: 450;
  color: rgb(80, 80, 80);
  border-left:1px solid rgb(235,235,235);
}

.SelectedPlaceCategory{
  border-right:1px solid rgb(200,200,200);
}

.SelectedPlaceCategoryRTL{
  border-left:1px solid rgb(200,200,200);
}

.CategoryLast{
  text-align: start;
  white-space: nowrap;
  font-size: 0.8em;
  font-weight: 450;
  color: rgb(80, 80, 80);
}


.CategoryLastLTR{
  margin-right:7px;
  padding-right:7px;
}

.CategoryLastRTL{
  margin-left:7px;
  padding-left:7px;
}

.CategoriesList{
  display:flex;
  align-items: center;
  flex-wrap: wrap;
  padding:5px 0;
}
.RemoveIcon{
  width:16px;
  height:16px;
  right:7px;
  top:7px;
  position:absolute;
}

  .Toolkit{
    position: absolute;
    right:0;
    top:5px;
  }
  .ToolkitRTL{
    position: absolute;
    left:0;
    top:5px;
  }
.ToolkitIcon {
height: 25px;
}


.Tools{
  width:100%;
  height:auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  right:0;
  top:5px;
}
.ToolsRTL{
  width:100%;
  height:auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  left:0;
  top:5px;
}

.FloatingTools{
  width:100%;
  height:auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  bottom:10vh;
  right:0;
}

.FloatingToolsRTL{
  width:100%;
  height:auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  bottom:10vh;
 
  left:0;
}

.DurationIcon{
  height:20px;
}

.Duration{
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:0.7em;
  position:absolute;
  right:5px;
  bottom:0;
}

.DurationRTL{
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:0.7em;
  position:absolute;
  left:5px;
  bottom:0;
}

@media screen and (min-width: 1024px) {  
  .MapView:hover{
    cursor:pointer;
    box-shadow: 0 1px 1px rgba(97, 97, 97, 0.15), 0 2px 2px rgba(83, 83, 83, 0.15),
    0 4px 4px rgba(88, 88, 88, 0.15), 0 6px 6px rgba(90, 90, 90, 0.15);
  }
  
  .JourneyPlace:hover{
    cursor: pointer;
    -webkit-box-shadow: 1px 0px 10px 4px #cecece; 
    box-shadow: 1px 0px 10px 4px #c5c5c5;
  }
  
  .PlanDiscover:hover{
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(83, 83, 83, 0.15), 0 5px 5px rgba(121, 121, 121, 0.15),
      0 15px 15px rgba(148, 148, 148, 0.055), 0 8px 8px rgba(160, 160, 160, 0.15);
      background-color: rgb(255,119,0,0.2);
      border:1px solid rgb(255,119,0,0.8);
      border-radius: 10px;
  }
  .TopSection:hover{
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {  
.Content{
  font-size:0.9em;
}
.TopSection{
  padding:0;
}
.JourneyPlace{
  width:100%;

}
.JourneyPhoto{
  min-width:50%;
  max-width:50%;
  object-fit: cover;
}

}


@media screen and (min-width: 768px) and (max-width:1023px){
  .Content{
    font-size:1.2em;
  }
}