.Button{
    display: inline-block;
}


.ButtonRTL{
    direction: rtl;
    display: inline-block;
}


.Style1{
    padding:5px 10px;
    border-radius:3px;
    background-color: white;
    border:1px solid rgb(223,223,223);
}
.Style1:hover{
    cursor: pointer;
    background-color: rgb(248,248,248);
}

.Style2{
    padding:5px 10px;
    color:rgb(34,121,190);
    border: 1px solid rgb(34,121,190);
    border-radius: 5px;
}

.Style2:hover{
    cursor: pointer;
    background-color: rgb(34,121,190,0.4);
}

.SaveButton{
    text-align: center;
    width: auto;
    height: 35px;
    background-color: white;
    color: rgb(44, 57, 77);
    font-size: 1.1em;
    font-weight: 500;
    border: 1px solid rgb(38, 41, 52);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    border-radius:5px;
    margin:0 5px;
}

.SaveButtonGreyed{
    align-self: center;
    text-align: center;
    width: auto;
    height: 35px;
    cursor:default;
    background-color: white;
    color: rgba(102, 122, 151, 0.342);
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(102, 122, 151, 0.329);
    align-items: center;
    padding: 0 20px;
    border-radius:5px;
    margin:0 5px;
}

.PositiveButton{
    text-align: center;
    width: auto;
    min-height: 35px;
    max-height: 35px;
    color: white;
    font-size: 1.1em;
    font-weight: 400;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 15px;
    margin:0 5px;
}

.PositiveButton2{
    text-align: center;
    width: auto;
    min-height: 35px;
    max-height: 35px;
    border-radius: 4px;
    font-size: 1.1em;
    font-weight: 400;
   color: green;
   border:1px solid rgba(0, 128, 0, 0.507);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 15px;
    margin:0 5px;
}

.DeleteButton{
    text-align: center;
    width: auto;
    min-height: 35px;
    max-height: 35px;
    color: white;
    font-size: 1.1em;
    font-weight: 400;
    background-color: #b22222;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 15px;
    margin:0 5px;
}
.DiscardButton{
    position: relative;
    text-align: center;
    width: auto;
   
    color: rgb(81, 91, 107);
    font-size: 1.1em;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgb(220, 220, 220);
    cursor: pointer;
    padding: 7px 15px;
    margin:0 5px;
}


.RemoveButton2{
    text-align: center;
    width: auto;
    min-height: 35px;
    max-height: 35px;
    color: white;
    font-size: 1.1em;
    font-weight: 400;
    background-color: #b22222b0;
    display: flex;
    border: 1px solid rgb(220, 220, 220);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 35px;
    border-radius: 5px;
    margin:0 5px;
}
.RemoveButton2:hover{
    background-color: #b22222;
}
.SetButton{
    text-align: center;
    width: auto;
    min-height: 35px;
    max-height: 35px;
    font-size: 1.1em;
    font-weight: 500;
    border: 1px solid rgb(38, 41, 52);
    display: flex;
    border: 1px solid rgb(220, 220, 220);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 35px;
    border-radius: 5px;
    margin:0 5px;
}
.OrangeButton{
    text-align: center;
    width: auto;
    min-height: 35px;
    max-height: 35px;
    font-size: 1.1em;
    font-weight: 500;
    display: flex;
    background-color: rgb(255,119,0);
    color:white;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 35px;
    border-radius: 10px;
    margin:0 5px;
}
@media screen and (min-width: 1024px) {
    .DiscardButton{
        min-height: 25px;
    max-height: 25px;
    }
    .PositiveButton2:hover{
        background-color: rgba(0, 128, 0, 0.07);
    }
}
@media screen and (max-width: 767px) {
.DiscardButton{
    font-size: 1em;
    padding:5px 10px; 
    height:auto;
}
.DeleteButton{
    font-size: 1em;
    min-height: 30px;
    max-height: 30px;
}
.PositiveButton{
    font-size: 1em;
    min-height: 30px;
    max-height: 30px;
}
}

@media screen and (min-width: 768px) and (max-width:1023px){

}