.TopSection {
    width: 97%;
    padding: 0 1.5%;
    display: flex;
    position:relative;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 119, 0, 0.8);
    height: 7vh;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.377); */

  }
  .DarkTop{
    background-color: rgb(59, 59, 59);
  }

  .RTLBody{
    direction: rtl;
  }

  .ModalView{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .Icon {
    width: 20px;
    cursor: pointer;
  }
  .Header {
    color: rgb(255, 255, 255);
    font-size: 1.3em;
  }
  .SmallIcon {
    height: 25px;
    cursor: pointer;
  }
  
  @media (max-width: 767px) {
    .TopSection {
      height: 5vh;
      width: 95%;
      padding: 5px 2.5%;
    }
    .Header {
      color:  rgb(255, 255, 255);
      font-size: 1.1em;
    }
    .Icon {
      width: 20px;
    }
    .SmallIcon {
      height: 15px;
      cursor: pointer;
    }
    .ModalView{
      border-radius: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width:1023px){
    .TopSection {
      height: 5vh;
      width: 95%;
      padding: 5px 2.5%;
    }
    .Header {
      color:  rgb(255, 255, 255);
      font-size: 1.1em;
    }
    .Icon {
      width: 20px;
    }
    .SmallIcon {
      height: 15px;
      cursor: pointer;
    }
    .ModalView{
      border-radius: 0;
    }
  }
  