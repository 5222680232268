.Body{
    padding-top:10px;
}

.Characteristics{
    padding-bottom: 10px;
    border-top: 1px solid rgb(223,223,223);
}

.CharacteristicsTitle{
    display: flex;
    align-items: center;
    font-size:1.2em;
    font-weight: 500;
    padding:10px 0;
}

.Section{
    margin: 10px 0 ;
    padding:0 1%;
    width:98%;
    background-color: rgb(248,248,248);
    border-radius:5px;
}

.SectionHeader{
    display: flex;
    align-items: center;
    font-size:1em;
    font-weight: 500;
    color: rgb(66, 66, 66);
    padding:10px 0;
}

.Filters{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
}

.Icon{
    height:35px;
    margin-right:20px;
}

.IconRTL{
    height:35px;
    margin-left:20px;
}

.SubHeader{
    width:auto;
    margin-right:20px;
    color: rgb(100,100,100);
}

.SubHeaderRTL{
    width:auto;
    margin-left:20px;
    color: rgb(100,100,100);
}


.DistanceValue{
    width:50px;
    border:none;
    text-align: center;
    height:30px;
    border: 0.7px solid rgb(210,210,210);

}
.DistanceValue:focus{
    outline: none;
}

.NumberValue{
    width:50px;
    border:none;
    text-align: center;
    height:30px;
    border: 0.7px solid rgb(210,210,210);
  
  }
  .NumberValue:focus{
    outline: none;
  }
  
  .NumberInput{
    display:flex;
    align-items: center;
    height:40px;
    margin:0 10px;
    padding:0 5px;
  }
  
  .NumberInputButton{
    height:35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: rgb(180, 180, 180);
    width:50px;
  }
  .NumberInputButton:hover{
    background-color:rgb(34,121,190,0.7);
    cursor: pointer;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  }
.Options{
    display:flex;
    align-items: center;
    margin:0 7px;
}

.OptionSelection{
    padding-bottom:10px;
}

@media screen and (min-width: 1024px) {  
    .Icon:hover{
        cursor: pointer;
    }
    .IconRTL:hover{
        cursor: pointer;
    }
    .OptionSelection{
        width:60%;
    }
}

@media screen and (max-width: 767px) {  
    .CharacteristicsTitle{
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .CharacteristicsTitle{
        display: none;
    }
}