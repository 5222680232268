.LTR{
}
.RTL{
direction: rtl;
}
.Modal{
  position: fixed;  
  background-color: rgb(255, 255, 255);
/*   font-family: cursive; */
  z-index: 25;
  left:0;
}

.Body{
    height:95%;
    width:80%;
    padding: 2.5% 10%;
    position:relative;
    overflow-y: auto;
 /*    display:flex;
    align-self: center;
    justify-content: center; */
}

.Body::-webkit-scrollbar
{
  width: 0;
  background-color: #F5F5F5;
}

.SkipButton{
    position: fixed;
  }
  
  .SkipButtonRTL{
    position: fixed;
  }
  

  .Options{

  }
  .GetPersonalizedTrips{
    background-color: rgb(255,119,0);
    color:white;
    
  }

  .DiscoverNewPlaces{
      background-color: rgb(34,121,190);
      color:white;
  }

  .TopLogoSection{
    height:8%;
    padding-top:2%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .TopLogo{
    height:80%;
  }

  .MainMessage{
    text-align: center;
    color: rgb(100,100,100);
    
  }

@media screen and (min-width: 1024px) {  
    .Modal{
        height:100%;
        width:100vw;
    }

    .SkipButton{
        position: fixed;
        top:5vh;
        background-color: rgba(255, 255, 255, 0.429);
        right:5vw;
        z-index: 30;
        padding:10px 20px;
       cursor: pointer; 
      }
      
      .SkipButtonRTL{
        position: fixed;
        top:5vh;
        background-color: rgba(255, 255, 255, 0.429);
        left:5vw;
        z-index: 30;
        padding:10px 20px;
        cursor: pointer;
      }

      .MessageContent{
        display:flex;
        width:100%;
        justify-content: space-between;
      }

      .Message{
          padding: 5vh 0;
          border-bottom:1px solid rgb(223,223,223);
      }

      .MessageHeader{
        /*   height: 10vh;; */
      }

      .ScreenshotImageLeft{
        max-width:60%;
        min-width:60%;
        position:relative;
        left:0%;
        object-fit: contain;
    }
    
    .ScreenshotImageRight{
        max-width:60%;
        min-width:60%;
        position:relative;
        left:0%;
        object-fit: contain;
    }
    .MessageText{
        position: relative;
        max-width:35%;
        min-width:35%;
        font-size:1.2em;
    }

    .MessageTextPart1{
        color:rgb(255,119,0);
        font-weight: 500;
        padding-bottom:20px;
        font-size:1.2em;
        text-align: center;
        display:flex;
        align-items: flex-end;
    }

    .MessageTextPart2{
        color:rgb(34,121,190);
    }

    .Number{
        color:white;
        background-color: rgb(255,119,0);
        padding:10px;
        height:30px;
        width: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 50%;
    }

    .Options{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:80%;
        padding:2vh 10%;
        padding-bottom: 10vh;;
    }
    .GetPersonalizedTrips{
        padding: 10px 50px;
      }
    
      .DiscoverNewPlaces{
        padding: 10px 50px;
      }
      .GetPersonalizedTrips:hover{
       background-color: rgb(255,119,0,0.8);
       cursor: pointer;
      }
    
      .DiscoverNewPlaces:hover{
       background-color: rgb(34,121,190,0.8);
       cursor: pointer;
      }

      .DirectMeToHomePage{
          color:rgb(100,100,100);
          cursor: pointer;
      }
      .MainMessage{
        font-size: 1.5em;
        padding: 3vh 0;
      }
}


@media(max-width:767px){
    .Modal{
      z-index: 200;
      width:100%;
      left:0;
      height:100%;
      top:0;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .Body{
      flex-direction: column;
      width:100%;
      padding: 0;
      overflow-x: hidden;
    }
    .SkipButton{
        position: fixed;
        top:2vh;
        background-color: rgba(255, 255, 255, 0.429);
        right:10px;
        z-index: 30;
        padding:10px 20px;
        
      }
      
      .SkipButtonRTL{
        position: fixed;
        top:2vh;
        background-color: rgba(255, 255, 255, 0.429);
        left:10px;
        z-index: 30;
        padding:10px 20px;
        
      }

      
      .MessageContent{
          position: relative;
        display:flex;
        flex-direction: column;
   /*      max-width:95%;
        min-width:95%;
        padding: 0 2.5%; */
        width:100%;
        height:70vh;
        /* align-items: center; */
      }

      .Message{
       
          padding: 5vh 0;
          border-bottom:1px solid rgb(223,223,223);
      }

      .MessageHeader{
        height: 10vh;
        position: absolute;
        
      }

      .ScreenshotImageLeft{
        max-width:80%;
        position:relative;
        align-self: flex-end;
        left:5%;
        height: 60vh;
        object-fit: contain;
    }
    .ScreenshotImageRight{
        max-width:80%;
        position:relative;
        align-self: flex-start;
        right:5%;
        height: 60vh;
        object-fit: contain;

    }
    .MessageText{
        position: relative;
        max-width:90%;
        min-width:90%;
        padding: 0 5%;
        font-size:1em;
        padding-bottom:10px;
    }

    .MessageTextPart1{
        color:rgb(255,119,0);
        font-weight: 500;
        padding-bottom:15px;
        font-size:1.2em;
        text-align: center;
        display:flex;
        align-items: flex-end;
    }

    .MessageTextPart2{
        color:rgb(34,121,190);
    }

    .Number{
        color:white;
        background-color: rgb(255,119,0);
        padding:7px;
        height:20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 50%;
    }

    .Options{
        display: flex;
        align-items: center;
        flex-direction: column;
        width:80%;
        padding:2vh 10%;
        padding-bottom: 10vh;;
    }
    .GetPersonalizedTrips{
        padding: 10px 50px;
        width:80%;
        text-align: center;
        margin:5px 0;
      }
    
      .DiscoverNewPlaces{
        padding: 10px 50px;
        width:80%;
        text-align: center;
        margin:5px 0;
      }

      .DirectMeToHomePage{
          color:rgb(100,100,100);
          cursor: pointer;
      }

      .MainMessage{
        font-size: 1.2em;
        padding: 3vh 5%;
      }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
      z-index: 200;
      width:100%;
      left:0;
      height:100%;
      top:0;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .Body{
      flex-direction: column;
      padding:0;
    }
    .SkipButton{
        font-size: 1.1em;;
        position: fixed;
        top:2vh;
        background-color: rgba(255, 255, 255, 0.429);
        right:20px;
        z-index: 30;
        padding:10px 20px;
        
      }
      
      .SkipButtonRTL{
        font-size: 1.1em;;
        position: fixed;
        top:2vh;
        background-color: rgba(255, 255, 255, 0.429);
        left:20px;
        z-index: 30;
        padding:10px 20px;
        
      }
       
      .MessageContent{
        position: relative;
      display:flex;
      flex-direction: column;
 /*      max-width:95%;
      min-width:95%;
      padding: 0 2.5%; */
      width:100%;
      /* align-items: center; */
    }

    .Message{
        padding: 3vh 0;
        border-bottom:1px solid rgb(223,223,223);
    }

    .MessageHeader{
      /*   height: 10vh;; */
    }

    .ScreenshotImageLeft{
      max-width:100%;
      min-width:100%;
      position:relative;
      left:0%;
      object-fit: contain;
  }
  .ScreenshotImageRight{
      max-width:100%;
      min-width:100%;
      position:relative;
      align-self: flex-start;
      right:0%;
      object-fit: contain;

  }
  .MessageText{
      position: relative;
      max-width:100%;
      min-width:100%;
      padding: 0;
      font-size:1.4em;
      padding-bottom:10px;
  }

  .MessageTextPart1{
      color:rgb(255,119,0);
      font-weight: 500;
      padding-bottom:15px;
      font-size:1.2em;
      text-align: center;
      display:flex;
      align-items: flex-end;
  }

  .MessageTextPart2{
      color:rgb(34,121,190);
  }

  .Number{
      color:white;
      background-color: rgb(255,119,0);
      padding:7px;
      height:20px;
      width: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 50%;
  }

  .Options{
      display: flex;
      align-items: center;
      flex-direction: column;
      width:80%;
      padding:2vh 10%;
      padding-bottom: 10vh;;
  }
  .GetPersonalizedTrips{
      padding: 10px 50px;
      width:80%;
      text-align: center;
      margin:5px 0;
    }
  
    .DiscoverNewPlaces{
      padding: 10px 50px;
      width:80%;
      text-align: center;
      margin:5px 0;
    }

    .DirectMeToHomePage{
        color:rgb(100,100,100);
        cursor: pointer;
    }
    .MainMessage{
      font-size: 1.5em;
      padding: 3vh 5%;
    }
}