

  .Body{
   /*  overflow-x: hidden;
    overflow-y: auto;
    height:65vh;
    max-height:65vh;
    width: 90%;
    padding: 0 5%;
    padding-bottom:20vh;
    top:0vh;
    display: flex;
    position: relative;
    flex-wrap: wrap;
     justify-content: flex-start;
     margin-bottom:30vh;*/
     position: relative;;
     top:0;
  } 



.DiscoverSource{
    display: flex;
    width:auto;
    align-items: center;
}

.SearchBar{
  display:flex;
  align-items: center;
}

.TopSection{
    display: flex;
    height:auto;
    width:95%;
    padding:10px 2.5%;
    align-items: center;
    justify-content: space-between;
}

.FiltersSection{
  display:flex;
  align-items: center;
}

.Filters{
    color:rgb(100,100,100);
    border:1px solid rgb(235,235,235);
    border-radius: 5px;
    padding:5px 15px;
    height:30px;
    display:flex;
    align-items: center;
    white-space: nowrap;
  }
  .Filters:hover{
    cursor: pointer;
    background-color: rgb(245,245,245);
  }

  .FiltersSelected{
    color:rgb(34,121,190);
    border:1px solid rgb(235,235,235);
    border-radius: 5px;
    padding:5px 15px;
    height:40px;
    display:flex;
    align-items: center;
    white-space: nowrap;
  }
  .FiltersSelected:hover{
    cursor: pointer;
    background-color: rgb(245,245,245);
  }

.FilterIcon{
    width:25px;
  /*   margin-right:10px; */
  }

.FilterIconRTL{
    width:25px;
  /*   margin-left:10px; */
}

.DiscoverBody{
  position: relative;;
  top:0;
  height:100%;
}


.Results{
    overflow-x: hidden;
    overflow-y: auto;
    height:50vh;
    width: 90%;
    padding: 0 5%;
    padding-bottom:10vh;
    margin-top:15px;
    display: flex;
    position: absolute;
    flex-wrap: wrap;
     justify-content: flex-start;
     margin-bottom:10vh;
}

.Results::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.Results::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}

.Results::-webkit-scrollbar-track-piece{
  background-color: #F5F5F5;
}

.Results::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgba(255, 255, 255, 0.562);
}


.Body::-webkit-scrollbar
{
width:0;
background-color: #F5F5F5;
}


.GettingData{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:10vh;
    font-size:1.2em;
    color:rgb(100,100,100);
}


.NextBackButtons{
    height:15vh;
    display:flex;
    align-items: center;
    justify-content: center;
    min-width:100%;
    font-size: 1.3em;
}

.PagesButtonGreyed{
    margin:0 20px;
    padding:10px;
    color:rgb(150,150,150);
}


.PagesButton{
    margin:0 20px;
    padding:10px;
}

.PagesButton:hover{
    border-radius: 7px;
    cursor: pointer;
    background-color: rgb(240,240,240);
}
.TagsList{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 3px 0;
}

.MapBoundariesSelection{
  padding: 5px 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color:rgb(88,88,88);
}


.CheckIcon{
  margin-right:12px;
  width:26px;
  height:26px;
}

.CheckIconRTL{
  margin-left:12px;
  width:26px;
  height:26px;
}


.UncheckBox{
  margin-right:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

.UncheckBoxRTL{
  margin-left:12px;
  width:24px;
  height:24px;
  border-radius:5px;
  border:1px solid rgb(40,40,40);
}

.CheckIcon:hover{
  cursor: pointer;
}
.CheckIconRTL:hover{
  cursor: pointer;
}
.UncheckBox:hover{
  cursor: pointer;
}
.UncheckBoxRTL:hover{
  cursor: pointer;
}

.Message{
  font-size:1.3em;
  padding:10px;
  font-weight: 500;
  color:rgb(88,88,88);
}

.DiscoverMapTools{
  display:flex;
  align-items: center;
}

.MobileSeperator{
  min-height:4vh;
  border-right:1px solid rgb(150, 150, 150);
  width:2px;
}

@media screen and (max-width: 767px) {

  .Body{
    position:absolute;
    height:100%;
    top:0;
    left:0;
    right:0;
    width:95%;
    padding: 0 2.5%;
    z-index:10;
    background-color: white;
  }
  .Results{
    overflow-x: hidden;
    overflow-y: auto;
    height:50vh;
    width: 100%;
    padding: 0;
    padding-bottom:10vh;
    margin-top:10px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
     justify-content: flex-start;
     margin-bottom:10vh;
}
.Message{
  font-size: 1em;
}
.DiscoverSource{
  width:100%;
  height:5vh;
}
.FilterIcon{
  position:relative;
  width:25px;
 /*  margin-right:10px; */
  top:0;
  right:30px;
  z-index: 10;
}

.FilterIconRTL{
  position:relative;
  width:25px;
  /* margin-left:10px; */
  top:0;
  left:30px;
  z-index: 10;
}
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Body{
    position:absolute;
    height:100%;
    top:0;
    left:0;
    right:0;
    width:95%;
    padding: 0 2.5%;
    z-index:10;
    background-color: white;
  }

  .CountrySelection{
    width:50%;
  }
  .Results{
    overflow-x: hidden;
    overflow-y: auto;
    height:50vh;
    width: 100%;
    padding: 0;
    padding-bottom:10vh;
    margin-top:10px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
     justify-content: flex-start;
     margin-bottom:10vh;
}
.Message{
  font-size: 1em;
}
.DiscoverSource{
  width:100%;
  height:5vh;
}
.FilterIcon{
  position:relative;
  width:25px;
  margin-right:10px;
  top:7vh;
  z-index: 10;
}

.FilterIconRTL{
  position:relative;
  width:25px;
  margin-left:10px;
  top:7vh;
  z-index: 10;
}
}