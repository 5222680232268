

.ModalRTL{
  direction: rtl;
}

.Content{
  width:90%;
  padding: 20px 5%;
  overflow-y: auto;
  overflow-x: hidden;
}
.TagItems {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.ExistingTags{
  padding:20px 0;
}
.SubHeader{
  font-size: 1.1em;
  font-weight: 400;

  color:rgb(83, 83, 83);
  text-align: start;
  display: flex;
  justify-content: start;
  margin: 5px 0;
  width: 100%;
}

.Title{
  display: flex;
  align-items: center; 
  text-align: center;
  font-size: 1.3em;
  font-weight: 400;
}

@media screen and (min-width: 1024px) {
  .Modal{
    position:fixed;
    z-index: 200;
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 1px rgb(172, 172, 172,0.75);
    -moz-box-shadow: 0px 0px 7px 1px rgb(172, 172, 172,0.75);
    box-shadow: 0px 0px 7px 1px rgb(172, 172, 172,0.75);
    border: 1px solid rgb(218, 218, 218);
    width: 30%;
    right: 35%;
    height: 80vh;
    top: 10vh;
    overflow-y: auto;
    padding: 0.5px;
    border-radius: 20px;
  }
  .Content{
    height:60vh;
  }
}

@media screen and (max-width: 767px) {
  .Modal{
    position:fixed;
    z-index: 200;
    background-color: white;
    width: 100%;
    height: 100vh;
    top: 0;
  }
  .Content{
    height:80vh;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position:fixed;
    z-index: 800;
    background-color: white;
    width: 100%;
    height: 100vh;
    top: 0;
  }
  .Content{
    height:80vh;
  }
}
