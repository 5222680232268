
.Modal{
    position: fixed;
    top: 15vh;
    width:25vw;
    left:37.5vw;
    height: 70vh;
   border:0.6px solid rgb(220,220,220);
   background-color: white;
   z-index:700;
  border-radius: 6px;
}
.Body{
  
    height:90%;
    width:90%;
    padding:5%;
}

.Inline{
    width:100%;
    height:100%;
}

.TopSection{
display: flex;
align-items: center;
justify-content: space-between;
width:100%;
min-height: 5vh;
border-bottom: 0.7px solid rgb(223,223,223);
margin-bottom: 10px;
width:90%;
}

.Title{
    font-size:1.2em;
    color: rgb(120,120,120);
    display: flex;
align-items: center;
flex-wrap: wrap;
}
.PlaceName
{
    color:rgb(80,80,80);
    font-weight: 500;
    padding:0 10px;
}

.CloseIcon{
    width:20px;
    position: absolute;
    right:15px;
    top:15px;
}
.CloseIconRTL{
    width:20px;
    position: absolute;
    left:15px;
    top:15px;
}
.CloseIcon:hover{
    cursor: pointer;
}
.CloseIconRTL:hover{
    cursor: pointer;
}
.BottomSection{
    position: absolute;
    bottom: 2vh;
    padding-top:15px;
    display: flex;
    justify-content: center;
    width: 90%;
    background-color: white;
}
.ResultsButton{
    padding: 7px 20px;
    border-radius: 5px;
    background-color:rgba(24, 141, 40, 1); ;
    color: white;
}
.ResultsButton:hover{
    cursor: pointer;
}

.ResultsButtonFade{
    padding: 7px 20px;
    border-radius: 5px;
    background-color:rgba(24, 141, 40, 0.4); ;
    color: white;
}

.Message{
    padding-top: 10px;
    font-size: 0.9em;
    color: #b22222;
}

.Content{
    padding: 25px 2.5%;

}

.Section{
    padding: 20px 0;
    border-bottom: 0.7px solid rgb(223,223,223);
    display:flex;
   justify-content: space-between;
    align-items: center;
}

.NoBorderSection{
    padding: 20px 0;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.RegionSection{
    padding: 10px 0;
    display:flex;
    justify-content: space-between;
    align-items: center;
}


.SectionName{
    max-width:40%;
    min-width:30%;
    font-size: 1em;
}

.ContentValue{
    height:30px;
    min-width:60%;
    max-width:60%;
    border: 0.7px solid rgb(160,160,160);
    font-size:1.1em;
    padding: 0 7px;
    color: rgb(110,110,110);
    font-weight: 400;
}

.ContentValue:focus{
    outline: none;
}

.Region{
    width:100%;
    padding: 20px 0;
    border-bottom: 0.7px solid rgb(223,223,223);
}

.AddIcon{
    width:25px;
}

.AddIcon{
    cursor:pointer;
}

@media(max-width: 767px){
 
.Modal{
    position: fixed;
    top: 0vh;
    width:100vw;
    left:0vw;
    height: 100vh;
   border:none;
   background-color: white;
   z-index:700;
  border-radius: 6px;
}
.Body{
  
    height:90%;
    width:90%;
    padding:5%;
}
.BottomSection{
    position: absolute;
    bottom: 12vh;
    padding-top:15px;
    display: flex;
    justify-content: center;
    width: 90%;
    background-color: white;
    border-top: 0.7px solid rgb(223,223,223);
}
    
.Section{
    padding: 15px 0;
    align-items: center;
}
.Content{
    padding-top:0;
}
.SectionName{
    display: flex;
    flex-wrap: wrap;
    max-width:30%;
    min-width:30%;
    font-size: 0.9em;
    margin-right:10px;
}
}


@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        position: fixed;
        top: 15vh;
        width:60vw;
        left:20vw;
        height: 70vh;
       border:none;
       background-color: white;
       z-index:700;
      border-radius: 6px;
    }
    .Body{
      
        height:90%;
        width:90%;
        padding:5%;
    }
    .BottomSection{
        position: absolute;
        bottom: 12vh;
        padding-top:15px;
        display: flex;
        justify-content: center;
        width: 90%;
        background-color: white;
        border-top: 0.7px solid rgb(223,223,223);
    }
        
    .Section{
        padding: 15px 0;
        align-items: center;
    }
    .Content{
        padding-top:0;
    }
    .SectionName{
        display: flex;
        flex-wrap: wrap;
        max-width:30%;
        min-width:30%;
        font-size: 0.9em;
        margin-right:10px;
    }
}