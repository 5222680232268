.RTL{
    direction: rtl;
}

.ToolsSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:90%;
    padding:5px 5%;
    background-color: #f5f5f5d2;
    position:relative;
}

.PlanPlanning{
    position:relative;
    height:80vh;
    width:100%;
}

.PlanMode{
    display: flex;
    align-items: center;
   width:auto;
   position: relative;
}

.Tools{
    position: absolute;
    left:50%;
}

.ToolsIcon{
    height: 30px;  
}
.ToolsIconRTL{
    height: 30px;  
}

.MoveCloneQuestion{
    position: fixed;
    top:40vh;
    width:20vw;
    padding: 20px 1vw;
    left:41vw;
    z-index: 20;
    border-radius: 5px;
    border:0.6px solid rgb(223,223,223);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MoveButton{
    width:40%;
    background-color: rgb(255,119,0);
    color:white;
    font-size:1.2em;
    font-weight: 400;
    padding: 7px 10px;
    text-align: center;
}


.CloneButton{
    width:40%;
    background-color: rgb(34,121,190);
    color:white;
    font-size:1.2em;
    font-weight: 400;
    padding: 7px 10px;
    text-align: center;
}

.PotentialPlacesSection{
    width:80%;
    padding: 10px 10%;
    position:relative;
    left:0;
    direction: ltr;
}

.PotentialPlacesMessage{
    font-weight: 500;
    font-size:1.2em;    
}

.CloneMessageModal{
    position:fixed;
  display:flex;
  flex-direction: column;
  align-items: center;
  cursor:default;

}
.CloneMessage{
    padding:2vh 5%;
    width:90%;
    font-size:1.2em;
    color:rgb(88,88,88);
  }

  .ButtonSection{
    position:absolute;
    bottom:5vh;
    display:flex;
    width:80%;
    align-items: center;
    justify-content: space-evenly;
}


.ModalTopSection{
    width:95%;
    height:7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 2.5%;
    border-bottom:1px solid rgb(223,223,223);
    position: relative;
  }

  .CloseIcon{
    position:absolute;
    right:15px;
    height:22px;
  }

  .CloseIconRTL{
    height:22px;
    position:absolute;
    left:15px;
  }

  .ToolsObject{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width:25%;
  }
  

  .AdditionalTools{
    display:flex;
   align-items: center;
}

.ToolText{
  margin-top:3px;
  font-size:0.8em;
  text-align: center;
  white-space:  nowrap;
  color:rgb(88, 88, 88);
}

.ToolTextFade{
  margin-top:3px;
  font-size:0.8em;
  text-align: center;
  white-space: nowrap;
  color:rgb(165, 165, 165);
}

.MobileSeperator{
  min-height:4vh;
  border-right:1px solid rgb(150, 150, 150);
  width:2px;
}

@media screen and (min-width: 1024px) {  
    .CloneMessageModal{
        height:50vh;
        top:15vh;
        width:30%;
        left:35%;
        background-color: white;
        z-index: 100;
        border-radius: 20px;
        box-shadow:  0px 11px 8px -10px #ccc;
      }
    .Modal{
        position:fixed;
        height:100vh;
        width:100vw;
        z-index: 80;
        background-color: white;
    }
    .ToolsIcon:hover{
        cursor: pointer;
    }

    .ToolsIconRTL:hover{
        cursor: pointer;
    }

    .MapIconSection{
        background-color: white;
        color:rgb(100,100,100);
        border:1px solid rgb(235,235,235);
        border-radius: 5px;
        padding:5px 15px;
        height:40px;
        display:flex;
        align-items: center;
        white-space: nowrap;
        z-index: 10;
        margin:0 20px;
      }
      .MapIconSection:hover{
        cursor: pointer;
        background-color: rgb(250, 250, 250);
      }
    
      .MapIcon{
        height:30px;
        margin-right:15px;
      }

      .MapIconRTL{
        height:30px;
        margin-left:15px;
      }
      .MoveButton:hover{
        cursor: pointer;
        background-color: rgb(255,119,0,0.8);
    }
    .CloneButton:hover{
        cursor: pointer;
        background-color: rgb(34,121,190,0.8);
    }
    .CloseIcon:hover{
        cursor: pointer;
      }
      .CloseIconRTL:hover{
        cursor: pointer;
      }
       
      .ToolsObject{
        margin:0 15px;
      }

}

@media screen and (max-width: 767px) {
    .Modal{
        z-index: 80;
        position:fixed;
        width:100%;
        left:0;
        height:100%;
        top:0;
        background-color: white;
      }

      .Body{
        position: relative;
        z-index: 90;
      }
     
      .MoveCloneQuestion{
        position: fixed;
        top:40vh;
        width:80vw;
        padding: 20px 5vw;
        left:5vw;
       
    }

    .ToolsSection{
       display: flex;
       flex-direction: column-reverse;
       align-items: flex-start;
       width:100%;
       padding: 0.5vh 0;
    }
    .AdditionalTools{
        width:100%;
        display:flex;
        justify-content: flex-end;
        padding-bottom: 12px;
    }
    .ToolsIcon{
        height: 30px;  
    }
    .ToolsIconRTL{
        height: 30px;  
    }
    .MobileTopBar{
      position: fixed;
      z-index: 30;
      width:95%;
      margin: 0 15%;
      top:1%;
      display: flex;
      align-items: center;
    }

    .PlanTitle{
        width:80%;
        font-size: 1.2em;
        font-weight: 500;
        text-align: center;
        height:4vh;
        color:rgb(255, 255, 255);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .MobileToolsIcon{
      height:20px;
    }

    .MobileTools{
      position: relative;
    }

    .MobileToolsOptions{
      position: absolute;
      top:10px;
      left:10%;
      z-index: 100;
     
    }

    .MobileToolsOptionsRTL{
      position: absolute;
      top:10px;
      left:10%;
      z-index: 100;
    }
    .MobileSeperator{
      min-height:4vh;
      border-right:1px solid rgb(150, 150, 150);
      width:2px;
    }

    .PlanMode{
        padding: 0 2.5%;
        width:95%;
        font-size:1.1em;
        flex-wrap: nowrap;
        max-width:95%;
    }
    
      .CloneMessageModal{
        height:50vh;
        top:15vh;
        width:90%;
        left:5%;
        background-color: white;
        z-index: 100;
        border-radius: 20px;
        box-shadow:  0px 11px 8px -10px #ccc;
      }
      .ButtonSection{
          width:95%;
      }
      .ToolsObject{
        margin:0 8px;
      }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        z-index: 80;
        position:fixed;
        width:100vw;
        left:0;
        height:100vh;
        top:0;
        background-color: white;
      }
      .MobileTopBar{
        position: fixed;
        z-index: 30;
        width:95%;
        margin: 0 15%;
        top:2%;
        display: flex;
        align-items: center;
      }
  
      .PlanTitle{
          width:80%;
          font-size: 1.2em;
          font-weight: 500;
          text-align: center;
          height:4vh;
          color:rgb(255, 255, 255);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
      }
  
      .MobileToolsIcon{
        height:30px;
      }
  
      .MobileTools{
        position: relative;
      }
  
      .MobileToolsOptions{
        position: absolute;
        top:10px;
        left:10%;
       
      }
  
      .MobileToolsOptionsRTL{
        position: absolute;
        top:10px;
        left:10%;
      }
      .MobileSeperator{
        min-height:4vh;
        border-right:1px solid rgb(150, 150, 150);
        width:2px;
      }
    .CloneMessageModal{
        height:40vh;
        top:15vh;
        width:60%;
        left:20%;
        background-color: white;
        z-index: 100;
        border-radius: 20px;
        box-shadow:  0px 11px 8px -10px #ccc;
      }
      .ButtonSection{
          width:80%;
      }
      .ToolsObject{
        margin:0 12px;
      }
      .PlanMode{
        padding: 0 2.5%;
        width:45%;
        font-size:1.1em;
        flex-wrap: nowrap;
        align-items: center;
    }
}