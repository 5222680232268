.Modal {
  position: fixed;
  width:35vw;
  left:32.5vw;
  top: 30vh;
z-index:900;
background-color: white;
box-shadow: 0 1px 1px rgba(129, 129, 129, 0.15), 0 2px 2px rgba(105, 105, 105, 0.15),
0 4px 4px rgba(104, 104, 104, 0.15), 0 8px 8px rgba(95, 94, 94, 0.15);
border-radius: 5px;
}

.Body{
  width:100%;
}

.BodyRTL{
  width:100%;
  direction: rtl;
}

.Title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  font-weight: 400;
  height: 50px;
  background-color:#cc3014;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.Message {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid rgba(219, 219, 219, 0.842);
  font-size: 1.1em;
  color: rgb(30, 38, 51);
  padding:5px;

}

.Buttons {
  height: 50px;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  padding:15px;
}

.ButtonsSeperator {
  width: 30px;
}

@media screen and (min-width: 1024px) {
  

}

@media screen and (max-width: 767px) {
  .Modal {
    position: fixed;
    width:90vw;
    left:5vw;
    top: 30vh;
  }

  .Title {
    font-size: 1.1em;
  }
  
  .Message {
    font-size: 1em; 
  }
  
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal {
    position: fixed;
    width:60vw;
    left:20vw;
    top: 30vh;
  }


  .Title {
    font-size: 1.2em;
  }
  
  .Message {
    font-size: 1em; 
  }
  
  
}