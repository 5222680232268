.Wrapper{
    min-height: 100%;
    max-height: 100%;
    min-width:100%;
    max-width: 100%;
    overflow: hidden;
   
}

.SuggestedTripsModal{
    position:absolute;
    z-index:10;
    top:5vh;
    height:90vh;
    overflow-y: auto;
    width:80vw;
    left:10vw;

}

.CloneMessageModal{
    position:fixed;
  display:flex;
  flex-direction: column;
  align-items: center;
  cursor:default;
  
}
.CloneMessage{
    padding:2vh 5%;
    width:90%;
    font-size:1.2em;
    color:rgb(88,88,88);
  }

  .ButtonSection{
    position:absolute;
    bottom:5vh;
    display:flex;
    width:80%;
    align-items: center;
    justify-content: space-evenly;
}
.RTL{
    direction: rtl;
}


.ModalTopSection{
    width:95%;
    height:7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 2.5%;
    border-bottom:1px solid rgb(223,223,223);
    position: relative;
  }

  .CloseIcon{
    position:absolute;
    right:15px;
    height:22px;
  }

  .CloseIconRTL{
    height:22px;
    position:absolute;
    left:15px;
  }

  .Saving {
    position: fixed;
    top: 40%;
    width: 20%;
    left: 40%;
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SavingGif {
    width: 30%;
    animation: rotate 1s ease;
    animation-iteration-count:infinite;
    transition: ease-out 1s;
  }

  @keyframes rotate {
    0% { transform: rotateZ(0deg); }
    0% { transform: rotateZ(-180deg); }
    100% {  transform: rotateZ(0deg);   }
  }

  .ProgressBar{
    position: fixed;
    top: 60%;
    width: 40%;
    left: 30%;
    z-index: 800;
    display: flex;
    align-items: center;
    height:30px;
    border-radius: 10px;
    background-color: rgb(245,245,245);
  }

  .Progress{
    height:100%;
    background-color: rgb(255,119,0);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }



@media screen and (min-width: 1024px) {
    
.Body{
    height:93vh;

    width:100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.CloneMessageModal{
    height:50vh;
    top:15vh;
    width:30%;
    left:35%;
    background-color: white;
    z-index: 100;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
  }
  .CloseIcon:hover{
    cursor: pointer;
  }
  .CloseIconRTL:hover{
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
    
.Body{
    height:85vh;
    padding-bottom:5vh;
    width:100%;
    overscroll-behavior-y: contain;
    overflow-y: hidden;
    overflow-x: hidden;
}
.CloneMessageModal{
    height:50vh;
    top:15vh;
    width:90%;
    left:5%;
    background-color: white;
    z-index: 100;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
  }
  .ButtonSection{
      width:95%;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    
.Body{
    height:85vh;
    width:100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.CloneMessageModal{
    height:40vh;
    top:15vh;
    width:60%;
    left:20%;
    background-color: white;
    z-index: 100;
    border-radius: 20px;
    box-shadow:  0px 11px 8px -10px #ccc;
  }
  .ButtonSection{
      width:80%;
  }
}