.LTR{
  }
  .RTL{
  direction: rtl;
  }
  .Modal{
    position: fixed;  
    background-color: rgb(255, 255, 255);
  /*   font-family: cursive; */
    z-index: 25;
    left:0;
  }

  .Body{
      height:100%;
      width:100%;
      position:relative;
      display:flex;
      align-self: center;
      justify-content: center;
  }

  

  

  .ScreenshotScreen{
    position: relative;
    width:100%;
    background-color: white;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.ImagineMessageBottom{
  text-align: center;
  font-size:1.3em;
  color:rgb(90, 90, 90);
  padding: 2vh 5%;
  font-weight: 500;
}
.ScreenshotText{
  text-align: center;
  font-size:1.5em;
  color:rgb(124, 124, 124);
  padding: 2vh 5%;
  font-weight: 500;
}
 
.SkipButton{
  position: fixed;
}

.SkipButtonRTL{
  position: fixed;
}

@media screen and (min-width: 1024px) {  
    .Modal{
        height:100%;
        width:100vw;
        background-color: black;
    }

    .DreamImage{
      min-height:110vh;
      max-height: 110vh;
      left:0;
      object-fit: cover;
   z-index: 10;
   opacity: 0.4;
   transition: 0.4s;
   position:absolute;
    }

    .DreamImageRTL{
      transform: rotateY(180deg);
        min-height:110vh;
        max-height: 110vh;
        right:0;
        object-fit: cover;
     z-index: 10;
     opacity: 0.4;
     transition: 0.4s;
     position:absolute;
    }


  

    .PlanImage{
      position:absolute;
        max-height:50vh;
        min-height:50vh;
        position:absolute;
        opacity: 0.4;
        top:-5vh;
        transform: scale(1.2);
        right:2.5vw;
        object-fit: cover;
        z-index: 11;
        transition: 0.4s;
    }

    .PlanImageRTL{
      transform: rotateY(180deg);
      position:absolute;
        max-height:50vh;
        min-height:50vh;
        position:absolute;
        opacity: 0.4;
        top:-5vh;
        transform: scale(1.2)  rotateY(180deg);
        left:2.5vw;
        object-fit: cover;
        z-index: 11;
        transition: 0.4s;
    }

    .ExperienceImage{
      position:absolute;
        max-height:50vh;
        min-height:50vh;
        right:4vw;
        top:55vh;
        opacity: 0.4;
        object-fit: cover;
        transform: scale(1.2);
        z-index: 12;
        transition: 0.4s;
    }

    .ExperienceImageRTL{
      transform: rotateY(180deg);
      position:absolute;
        max-height:50vh;
        min-height:50vh;
        left:4vw;
        top:55vh;
        opacity: 0.4;
        object-fit: cover;
        transform: scale(1.2)  rotateY(180deg);
        z-index: 12;
        transition: 0.4s;
    }

    .DreamImageOpacity{   
        opacity: 1;
        transition: 0.4s;
    }
    .PlanImageOpacity{
        opacity: 1;
        transition: 0.4s;
    }
    .ExperienceImageOpacity{
        opacity: 1;
        transition: 0.4s;
    }
    .HighlightDream{
      animation-name: light;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
    .HighlightPlan{
      animation-name: light;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
    .HighlightExperience{
      animation-name: light;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
    @keyframes light {
      0% {
        opacity: 0.4;
      }
      50% {
       opacity:1;
      }
      100% {
        opacity:0.4;
      }
  }
    .DreamImageGrab{
        cursor:grab;
    }
    .PlanImageGrab{
        cursor:grab;
    }
    .ExperienceImageGrab{
        cursor:grab;
    }
    .DreamText{
      position:absolute;
        z-index: 20;
        display:flex;
        flex-direction: column;
        align-items: center;
       
        color: rgb(34,121,190);
        font-size:5em;
        font-weight: 500;
        background-image: radial-gradient(rgba(255, 255, 255, 0.262) 0%, rgba(255, 255, 255, 0.055) 25%,  rgba(255, 255, 255, 0) 100%);
        border-radius: 50%;
        padding:30px;

    }
  
    .PlanText{
      position:absolute;
      display:flex;
        flex-direction: column;
        align-items: center;
        z-index: 20;
       
        color: rgb(255,119,0);
        font-size:5em;
        font-weight: 500;
        background-image: radial-gradient(rgba(255, 255, 255, 0.262) 0%, rgba(255, 255, 255, 0.055) 25%,  rgba(255, 255, 255, 0) 100%);
        border-radius: 50%;
        padding:30px;
    }
  
    .ExperienceText{
     
      position:absolute;
        display:flex;
        flex-direction: column;
        align-items: center;
        z-index: 20;
      
        color: rgb(34,121,190);
        font-size:5em;
        font-weight: 500;
        background-image: radial-gradient(rgba(255, 255, 255, 0.262) 0%, rgba(255, 255, 255, 0.055) 25%,  rgba(255, 255, 255, 0) 100%);
        border-radius: 50%;
        padding:30px;
    }


    .DreamTextLTR{
      top:0;
      left:20%;
    }

    .PlanTextLTR{
      top:10%;
      right:15%;
    }

    .ExperienceTextLTR{
      direction: ltr;
      bottom:0%;
      right:30%;
    }
    .DreamTextRTL{
      top:0;
      right:20%;
    }

    .PlanTextRTL{
      top:10%;
      left:15%;
    }

    .ExperienceTextRTL{
      bottom:0%;
      left:30%;
    }

    .Icon{
      height:70px;
      margin:5px 0;
    }
    .LogoSection{
      z-index:30;
        position:fixed;
        top: calc(50% - 200px);
        display: block;
    border-radius: 50%;
    padding:50px;
    left:-0.5vw;
        height:300px;
        width:300px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: radial-gradient(rgba(255, 255, 255, 0.362) 0%, rgba(255, 255, 255, 0.15) 40%,  rgba(255, 255, 255, 0) 100%);
    }

    .HighlightLogoSection{
      background-image: radial-gradient(rgba(255, 255, 255, 0.712) 0%, rgba(255, 255, 255, 0.35) 40%,  rgba(255, 255, 255, 0.055) 100%);
    }

    .LogoImage{
        max-height:80%;
        object-fit: contain;
        opacity: 0.4;
    }
    .LogoAnimation{
      animation-name: scale;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
    @keyframes scale {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.3);
      }
      100% {
        transform: scale(1);
      }
  }
    .HighlightLogo{
      opacity: 1;
      cursor: pointer;
    }
    .LogoRotate{
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: 1;
      animation-timing-function: linear; 
    }
    @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
    }
  
    .InnerModal{
       position:fixed;
        width:70vw;
        height:80vh;
        top:10vh;
        left:15vw;
        z-index: 40;
        background-color: white;
        border-radius:20px;
        box-shadow:  0px 11px 8px -10px #ccc;
        overflow-y: auto;
        overflow-x: hidden;
    }
    

    .NextButton{
      padding:20px;
      font-size: 1.2em;
      font-weight: 500;
      color:rgb(34,121,190);
      border-top:1px solid rgb(223,223,223);
      border-bottom: 1px solid rgb(223,223,223);
      background-color: white;
      text-align: center;
      transition: 0.4s;
    }
    .NextButton:hover{
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.4s;
    }
    .ScreenshotScreen{
      margin: 5vh 0;
      height:100%;
  }
  .ScreenshotImage{
    max-height:80%;
    min-height:80%;
    position:relative;
    left:0%;
    object-fit: contain;
}

  
  .ImagineMessageBottom{
    position: relative;
    bottom:20%;
    text-align: center;
    width:90%;
    font-size:1.4em;
    color:rgb(90, 90, 90);
    padding: 2vh 5%;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.808);
    border-top:solid 1px rgb(151, 151, 151);
    border-bottom:solid 1px rgb(151, 151, 151);
}

.CloseIcon{
  position:absolute;
  right:25px;
  height:30px;
  top:25px;
  z-index: 10;
  transition: 0.3s;
}

.CloseIconRTL{
  height:30px;
  position:absolute;
  left:25px;
  top:25px;
  z-index: 10;
  transition: 0.3s;
}
.CloseIcon:hover{
  transition: 0.3s;
  transform:scale(1.1);
  cursor: pointer;
}
.CloseIconRTL:hover{
  transition: 0.3s;
  transform:scale(1.1);
  cursor: pointer;
}


.NextStepsModal{
  position:fixed;
  width:40vw;
  height:45vh;
  padding-top:5vh;
  top:20vh;
  left:30vw;
  z-index: 40;
  background-color: rgba(238, 238, 238, 0.89);
  border-radius:20px;
  box-shadow:  0px 11px 8px -10px #ccc;
}

.GetPersonalizedTrips{
  padding:20px 5%;
  color:white;
  font-size:1.5em;
   margin:5vh 10%;
   width:70%;
   text-align: center;
   border:2px solid white;
   background-color: rgb(255, 146, 51);
   border-radius: 5px;
}
.GetPersonalizedTrips:hover{
  background-color: rgb(255, 160, 77);;
  cursor: pointer;
}

.DiscoverNewPlaces{
  padding:20px 5%;
  font-size:1.5em;
   margin:5vh 10%;
   width:70%;
   color:white;
   text-align: center;
   border:2px solid white;
   background-color: rgb(24, 110, 180);
   border-radius: 5px;
}
.DiscoverNewPlaces:hover{
  background-color: rgb(39, 126, 197);
  cursor: pointer;
}
.DirectMeToHomePage{
  padding:5px 5%;
  font-size:1.2em;
   margin:3vh 20%;
   color:rgb(100,100,100);
   width:50%;
   text-align: center;
}

.DirectMeToHomePage:hover{
  cursor: pointer;

}


.NextStep1{
  width:100%;
  height:30vh;
  margin:0.5% 0;
 /*  background-color: rgb(255, 146, 51); */
  display:flex;
  align-items: center;
}

.NextStep2{
  width:100%;
  height:30vh;
  margin:0.5% 0;
/*   background-color: rgb(24, 110, 180); */
  display:flex;
  align-items: center;
}
.Options{
  display:flex;
}
.SkipButton{
  position: fixed;
  top:5vh;
  background-color: rgba(255, 255, 255, 0.429);
  right:5vw;
  z-index: 30;
  padding:10px 20px;
 cursor: pointer; 
}

.SkipButtonRTL{
  position: fixed;
  top:5vh;
  background-color: rgba(255, 255, 255, 0.429);
  left:5vw;
  z-index: 30;
  padding:10px 20px;
  cursor: pointer;
}
}

@media(max-width:767px){
  .Modal{
    z-index: 200;
    width:100%;
    left:0;
    height:100%;
    top:0;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .Body{
    flex-direction: column;
  }

  .TopLogoSection{
    height:8%;
    padding-top:2%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .TopLogo{
    height:80%;
  }
  .Section{
    position:relative;
    background-color: black;
    height:31%;
    margin:0.5% 0;
    display:flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .DreamImage{
    position: absolute;
    left:0;
    min-width:100%;
    max-width:100%;
    max-height:100%;
    object-fit: cover;
 z-index: 10;
 opacity: 0.4;
 transition: 0.4s;
}

.DreamImageRTL{
/*   transform: rotateY(180deg); */
position: absolute;
left:0;
min-width:100%;
max-width:100%;
max-height:100%;
    object-fit: cover;
 z-index: 10;
 opacity: 0.4;
 transition: 0.4s;
}




.PlanImage{
  position: absolute;
    right:0;
    min-width:100%;
    max-width:100%;
    max-height:100%;
    opacity: 0.4;
    object-fit: cover;
    z-index: 11;
    transition: 0.4s;
}

.PlanImageRTL{
  /* transform: rotateY(180deg); */
  position: absolute;
  right:0;
  min-width:100%;
  max-width:100%;
  max-height:100%;
    opacity: 0.4;
    object-fit: cover;
    z-index: 11;
    transition: 0.4s;
}

.ExperienceImage{
  position: absolute;
  left:0;
  min-width:100%;
 max-width:100%;
 max-height:100%;

    opacity: 0.4;
    object-fit: cover;
    z-index: 12;
    transition: 0.4s;
}

.ExperienceImageRTL{
  position: absolute;
  left:0;
  min-width:100%;
  max-width:100%;
  max-height:100%;

    opacity: 0.4;
    object-fit: cover;
    z-index: 12;
    transition: 0.4s;
}

.DreamImageOpacity{   
    opacity: 1;
    transition: 0.4s;
}
.PlanImageOpacity{
    opacity: 1;
    transition: 0.4s;
}
.ExperienceImageOpacity{
    opacity: 1;
    transition: 0.4s;
}
.HighlightDream{
  animation-name: light;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
.HighlightPlan{
  animation-name: light;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
.HighlightExperience{
  animation-name: light;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
@keyframes light {
  0% {
    opacity: 0.4;
  }
  50% {
   opacity:1;
  }
  100% {
    opacity:0.4;
  }
}
.DreamText{
  position:absolute;
  right:15px;
  bottom:15px;

    color: rgb(34,121,190);
    font-size:1.3em;
    font-weight: 500;
    z-index: 20;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.PlanText{
  position:absolute;
    z-index: 20;
    left:15px;
   bottom:15px;
    color: rgb(255,119,0);
    font-size:1.3em;
    font-weight: 500;
    background-color: white;;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.ExperienceText{
  direction: ltr;
  position:absolute;
    display:flex;
    flex-direction: row;
    z-index: 20;
    right:15px;
    bottom:15px;
    color: rgb(34,121,190);
    font-size:1.3em;
    font-weight: 500;
    background-color: white;;
    display:flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

.RightRectangle{
  position:absolute;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* transform: rotate(270deg); */
  right:0;
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-right: 200px solid white;
  border-bottom: 0 solid transparent;
  z-index:20;
}

.LeftRectangle{
  position:absolute;
  /* transform: rotate(270deg); */
left:0;
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-left: 200px solid white;
  border-bottom: 0 solid transparent;
  z-index:20;
}

.InnerModal{
  position:fixed;
   width:100%;
   height:100%;
   padding:0;
   top:0%;
   z-index: 40;
   background-color: rgba(58, 58, 58, 0.2);
   border-radius:0;
/*    box-shadow:  0px 11px 8px -10px #ccc; */
overflow-y: auto;
overflow-x: hidden;
}

 .ScreenshotScreen{
  padding: 5% 0;
  max-height:90%;
  background-color: white;
  justify-content: space-evenly;
} 
.ScreenshotImage{
top:5%;
max-width:90%;
/* min-height:90%; */
position:relative;
left:0%;
object-fit: contain;
}

.ScreenshotText{
  font-size:1.3em;
 margin-bottom: 15px;
  padding: 5px 0;
  width:100%;
  border-top:1px solid rgb(223,223,223);
  border-bottom: 1px solid rgb(223,223,223);
  background-color: rgb(245,245,245);
  color:rgb(85, 85, 85);
}

.NextButton{
  padding:20px;
  font-size: 1.2em;
  font-weight: 500;
  color:rgb(34,121,190);
  border-top:1px solid rgb(223,223,223);
  border-bottom: 1px solid rgb(223,223,223);
  background-color: white;
  text-align: center;
}

.ImagineMessageBottom{
position: relative;
bottom:20%;
text-align: center;
width:90%;
font-size:1.4em;
color:rgb(90, 90, 90);
padding: 2vh 5%;
font-weight: 500;
background-color: rgba(255, 255, 255, 0.808);
border-top:solid 1px rgb(151, 151, 151);
border-bottom:solid 1px rgb(151, 151, 151);
}

.CloseIcon{
position:absolute;
right:15px;
height:20px;
top:15px;
z-index: 10;
transition: 0.3s;
}

.CloseIconRTL{
height:20px;
position:absolute;
left:15px;
top:15px;
z-index: 10;
transition: 0.3s;
}
.Icon{
  height:25px;
  margin:5px 0;
}
.LogoSection{
  border:1px solid rgb(205,205,205);  
  position:fixed;
  /* top: calc(50% - 200px); */
  display: block;
border-radius: 50%;
padding:50px;
  /* left:calc(50%-200px); */
  height:200px;
  width:200px;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(rgba(255, 255, 255, 0.362) 0%, rgba(255, 255, 255, 0.15) 40%,  rgba(255, 255, 255, 0) 100%);
}

.HighlightLogoSection{
background-image: radial-gradient(rgba(255, 255, 255, 0.712) 0%, rgba(255, 255, 255, 0.35) 40%,  rgba(255, 255, 255, 0.055) 100%);
}

.LogoImage{
  max-height:80%;
  object-fit: contain;
}
.LogoAnimation{
animation-name: scale;
animation-duration: 2s;
animation-iteration-count: infinite;
animation-timing-function: linear; 
}
@keyframes scale {
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.3);
}
100% {
  transform: scale(1);
}
}
.HighlightLogo{
z-index:30;
cursor: pointer;
}
.LogoRotate{
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-timing-function: linear; 
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.NextStepsModal{
  position:fixed;
  width:100vw;

  padding-top:5vh;
  top:20vh;
  left:0vw;
  z-index: 50;
  background-color: rgb(238, 238, 238);
  box-shadow:  0px 11px 8px -10px #ccc;
}

.GetPersonalizedTrips{
  padding:20px 5%;
  color:white;
  font-size:1.5em;
   margin:5vh 20%;
   width:50%;
   text-align: center;
   border:2px solid white;
}
.GetPersonalizedTrips:hover{
  background-color: rgb(255, 160, 77);;
  cursor: pointer;
}

.DiscoverNewPlaces{
  padding:20px 5%;
  font-size:1.5em;
   margin:5vh 20%;
   width:50%;
   color:white;
   text-align: center;
   border:2px solid white;
}
.DiscoverNewPlaces:hover{
  background-color: rgb(39, 126, 197);
  cursor: pointer;
}
.DirectMeToHomePage{
  padding:5px 5%;
  font-size:1.2em;
   margin:3vh 20%;
   color:rgb(100,100,100);
   width:50%;
   text-align: center;
}

.DirectMeToHomePage:hover{
  cursor: pointer;

}


.NextStep1{
  width:100%;
  height:30%;
  margin:0.5% 0;
  background-color: rgb(255, 146, 51);
  display:flex;
  align-items: center;
}

.NextStep2{
  width:100%;
  height:30%;
  margin:0.5% 0;
  background-color: rgb(24, 110, 180);
  display:flex;
  align-items: center;
}
.Options{
  
}
.SkipButton{
  position: fixed;
  top:10vh;
  background-color: rgba(255, 255, 255, 0.429);
  right:10px;
  z-index: 30;
  padding:10px 20px;
  
}

.SkipButtonRTL{
  position: fixed;
  top:10vh;
  background-color: rgba(255, 255, 255, 0.429);
  left:10px;
  z-index: 30;
  padding:10px 20px;
  
}
}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    z-index: 200;
    width:100%;
    left:0;
    height:100%;
    top:0;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .Body{
    flex-direction: column;
  }

  .TopLogoSection{
    height:8%;
    padding-top:2%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .TopLogo{
    height:80%;
  }
  .Section{
    position:relative;
    background-color: black;
    height:31%;
    margin:0.5% 0;
    display:flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .DreamImage{
    position: absolute;
    left:0;
    min-width:100%;
    max-width:100%;
    max-height:100%;
    object-fit: cover;
 z-index: 10;
 opacity: 0.4;
 transition: 0.4s;
}

.DreamImageRTL{
/*   transform: rotateY(180deg); */
position: absolute;
left:0;
  /* position: relative; */
  min-width:100%;
  max-width:100%;
  max-height:100%;
    object-fit: cover;
 z-index: 10;
 opacity: 0.4;
 transition: 0.4s;
}




.PlanImage{
  position: absolute;
    right:0;
    min-width:100%;
 max-width:100%;
 max-height:100%;
    opacity: 0.4;
    object-fit: cover;
    z-index: 11;
    transition: 0.4s;
}

.PlanImageRTL{
  /* transform: rotateY(180deg); */
  position: absolute;
  right:0;
 /*  position: relative; */
 min-width:100%;
 max-width:100%;
 max-height:100%;
    opacity: 0.4;
    object-fit: cover;
    z-index: 11;
    transition: 0.4s;
}

.ExperienceImage{
  position: absolute;
  left:0;
 /*  position: relative; */
 min-width:100%;
 max-width:100%;
 max-height:100%;

    opacity: 0.4;
    object-fit: cover;
    z-index: 12;
    transition: 0.4s;
}

.ExperienceImageRTL{
  position: absolute;
  left:0;
  min-width:100%;
 max-width:100%;
 max-height:100%;

    opacity: 0.4;
    object-fit: cover;
    z-index: 12;
    transition: 0.4s;
}

.DreamImageOpacity{   
    opacity: 1;
    transition: 0.4s;
}
.PlanImageOpacity{
    opacity: 1;
    transition: 0.4s;
}
.ExperienceImageOpacity{
    opacity: 1;
    transition: 0.4s;
}
.HighlightDream{
  animation-name: light;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
.HighlightPlan{
  animation-name: light;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
.HighlightExperience{
  animation-name: light;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
@keyframes light {
  0% {
    opacity: 0.4;
  }
  50% {
   opacity:1;
  }
  100% {
    opacity:0.4;
  }
}

.DreamText{
  position:absolute;
  right:25px;
  bottom:25px;

    color: rgb(34,121,190);
    font-size:2em;
    font-weight: 500;
    z-index: 20;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.PlanText{
  position:absolute;
    z-index: 20;
    left:25px;
   bottom:25px;
    color: rgb(255,119,0);
    font-size:2em;
    font-weight: 500;
    background-color: white;;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.ExperienceText{
  direction: ltr;
  position:absolute;
    display:flex;
    flex-direction: row;
    z-index: 20;
    right:25px;
    bottom:25px;
    color: rgb(34,121,190);
    font-size:2em;
    font-weight: 500;
    background-color: white;;
    display:flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

.RightRectangle{
  position:absolute;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* transform: rotate(270deg); */
  right:0;
  width: 0;
  height: 0;
  border-top: 300px solid transparent;
  border-right: 300px solid white;
  border-bottom: 0 solid transparent;
  z-index:20;
}

.LeftRectangle{
  position:absolute;
  /* transform: rotate(270deg); */
left:0;
  width: 0;
  height: 0;
  border-top: 300px solid transparent;
  border-left: 300px solid white;
  border-bottom: 0 solid transparent;
  z-index:20;
}

.InnerModal{
  position:fixed;
   width:100%;
   height:100%;
   padding:0;
   top:0%;
   z-index: 40;
   background-color: rgba(58, 58, 58, 0.2);
   border-radius:0;
/*    box-shadow:  0px 11px 8px -10px #ccc; */
overflow-y: auto;
overflow-x: hidden;
}

 .ScreenshotScreen{
  padding: 5% 0;
  max-height:90%;
  background-color: white;
  justify-content: space-evenly;
} 
.ScreenshotImage{
top:5%;
max-width:90%;
/* min-height:90%; */
position:relative;
left:0%;
object-fit: contain;
}

.ScreenshotText{
  font-size:1.3em;
 margin-bottom: 15px;
  padding: 5px 0;
  width:100%;
  border-top:1px solid rgb(223,223,223);
  border-bottom: 1px solid rgb(223,223,223);
  background-color: rgb(245,245,245);
  color:rgb(85, 85, 85);
}

.NextButton{
  padding:20px;
  font-size: 1.2em;
  font-weight: 500;
  color:rgb(34,121,190);
  border-top:1px solid rgb(223,223,223);
  border-bottom: 1px solid rgb(223,223,223);
  background-color: white;
  text-align: center;
}


.ImagineMessageBottom{
position: relative;
bottom:20%;
text-align: center;
width:90%;
font-size:1.4em;
color:rgb(90, 90, 90);
padding: 2vh 5%;
font-weight: 500;
background-color: rgba(255, 255, 255, 0.808);
border-top:solid 1px rgb(151, 151, 151);
border-bottom:solid 1px rgb(151, 151, 151);
}

.CloseIcon{
position:absolute;
right:15px;
height:20px;
top:15px;
z-index: 10;
transition: 0.3s;
}

.CloseIconRTL{
height:20px;
position:absolute;
left:15px;
top:15px;
z-index: 10;
transition: 0.3s;
}
.Icon{
  height:50px;
  margin:5px 0;
}
.LogoSection{
  border:1px solid rgb(205,205,205);  
  position:fixed;
  /* top: calc(50% - 200px); */
  display: block;
border-radius: 50%;
padding:50px;
  /* left:calc(50%-200px); */
  height:200px;
  width:200px;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(rgba(255, 255, 255, 0.362) 0%, rgba(255, 255, 255, 0.15) 40%,  rgba(255, 255, 255, 0) 100%);
}

.HighlightLogoSection{
background-image: radial-gradient(rgba(255, 255, 255, 0.712) 0%, rgba(255, 255, 255, 0.35) 40%,  rgba(255, 255, 255, 0.055) 100%);
}

.LogoImage{
  max-height:80%;
  object-fit: contain;
}
.LogoAnimation{
animation-name: scale;
animation-duration: 2s;
animation-iteration-count: infinite;
animation-timing-function: linear; 
}
@keyframes scale {
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.3);
}
100% {
  transform: scale(1);
}
}
.HighlightLogo{
z-index:30;
cursor: pointer;
}
.LogoRotate{
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-timing-function: linear; 
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.NextStepsModal{
  position:fixed;
  width:100vw;

  padding-top:5vh;
  top:20vh;
  left:0vw;
  z-index: 50;
  background-color: rgb(238, 238, 238);
  box-shadow:  0px 11px 8px -10px #ccc;
}

.GetPersonalizedTrips{
  padding:20px 5%;
  color:white;
  font-size:1.5em;
   margin:5vh 20%;
   width:50%;
   text-align: center;
   border:2px solid white;
}
.GetPersonalizedTrips:hover{
  background-color: rgb(255, 160, 77);;
  cursor: pointer;
}

.DiscoverNewPlaces{
  padding:20px 5%;
  font-size:1.5em;
   margin:5vh 20%;
   width:50%;
   color:white;
   text-align: center;
   border:2px solid white;
}
.DiscoverNewPlaces:hover{
  background-color: rgb(39, 126, 197);
  cursor: pointer;
}
.DirectMeToHomePage{
  padding:5px 5%;
  font-size:1.2em;
   margin:3vh 20%;
   color:rgb(100,100,100);
   width:50%;
   text-align: center;
}

.DirectMeToHomePage:hover{
  cursor: pointer;

}


.NextStep1{
  width:100%;
  height:30%;
  margin:0.5% 0;
  background-color: rgb(255, 146, 51);
  display:flex;
  align-items: center;
}

.NextStep2{
  width:100%;
  height:30%;
  margin:0.5% 0;
  background-color: rgb(24, 110, 180);
  display:flex;
  align-items: center;
}
.Options{
  
}
.SkipButton{
  position: fixed;
  top:10vh;
  background-color: rgba(255, 255, 255, 0.429);
  right:10px;
  z-index: 30;
  padding:10px 20px;
  
}

.SkipButtonRTL{
  position: fixed;
  top:10vh;
  background-color: rgba(255, 255, 255, 0.429);
  left:10px;
  z-index: 30;
  padding:10px 20px;
  
}
}