.DiscoverSource{
    display: flex;
    width:auto;
    align-items: center;
}

.Wall{
    width:80%;
    padding: 0 10%;
    display: flex;
}

.PlacesWall{
    width: 90%;
    padding: 0 5%;
    height: 75vh;
    padding-bottom: 10vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.Selection{
    display:flex;

}

.Stats{
    height:85vh;
    overflow-y: auto;
}

.Requests{
    width:80%;
    padding: 0 10%;
}

.Title{
    font-size:1.3em;
    margin-bottom: 20px;
}

.Request{
    padding: 5px 0 ;
    background-color: white;
}

.RequestGrey{
    padding: 5px 0 ;
    background-color: rgb(241, 241, 241);
}

.RequestTopSection{
    padding:0 2.5%;
    width:95%;
    display:flex;
    justify-content: space-between;
}

.RequestMessage{
    width:80%;
}

.ArchiveButton{
    padding: 7px 15px;
    border-radius: 5px;
    border:1px solid rgb(100,100,100);
    cursor: pointer;
    color:white;
    background-color: rgb(88,88,88);
}

.RequestContent{

}

.PlansWall{
    display: flex;
    position: relative;
     flex-wrap: wrap;
     justify-content: flex-start;
     overflow-y: auto;
     overflow-x: hidden;
     width: 80%;
     padding: 0 10%;
     height: 70vh;
     padding-bottom: 10vh;
  }

  .ActivitiesLogExport{
    width: 80%;
    padding: 20px 10%;
  }

  .ActivitiesLogExportTitle{
      padding-bottom: 15px;
      font-size:1.3em;
      font-weight: 500;
  }

  .DateRow{
      display:flex;
      align-items: center;
  }

  .LogDate{
    margin:10px 20px;
  }