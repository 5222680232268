.Body {
  width: 100%;
  max-height: 500px;
  position:relative;
}

.BodyRTL{
  direction: rtl;
}

.InputSection {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 40px;
  background-color: white;
}

.SearchInput {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 0.8px solid rgb(228, 228, 228);
  border-radius: 5px;

}

.ValueRequired{
  border:2px solid #b22222b9;
}

.SearchInputWithSuggestions {
  min-height: 40px;
  width: 100%;
  color: rgb(88, 88, 88);
  display: flex;
  align-items: center;
  border: 0.8px solid rgb(221, 221, 221);

}

.SearchInputRed{
  min-height: 40px;
  width: 100%;
  color: rgb(192, 51, 51);
  display: flex;
  align-items: center;
  border: 2px solid #c43a3acc;
  border-radius: 5px;
}
.InputText {
  color: rgb(100, 100, 100);
  border: none;
  margin: 0 2%;
  font-size: 1em;

  width: 100%;
}
.InputText:focus {
  outline: none;
}

.Suggestions {
  position: relative;
  top: 5px;
  width: 100%;
  height: 200px;
  background-color: white;
  border: 1px solid rgb(233, 227, 227);
  border-radius: 10px;
  z-index: 30;
  overflow-y: auto;
  overflow-x: none; 
  -webkit-overflow-scrolling: touch;
}
.SuggestionsRTL {
  position: relative;
  top: 5px;
  width: 100%;
  height: 200px;
  background-color: white;
  border: 1px solid rgb(233, 227, 227);
  border-radius: 10px;
  z-index: 30;
  overflow-y: auto;
  overflow-x: none; 
  -webkit-overflow-scrolling: touch;
}

.DropdownButton {
  
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding-left: 10px;
  border-left: 1px solid rgb(194, 194, 194);
}
.DropdownButtonRTL{
  
  width: 16px;
  height: 16px;
  margin-left: 10px;
  padding-right: 10px;
  border-right: 1px solid rgb(194, 194, 194);
}

.SearchInputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.ErrorMessage{
  font-size: 0.9em;
  color:#b22222;
  position:relative;
  padding:7px 0;
}

.SelectedOptions{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width:250%;
  padding-bottom:5px;
}


.SearchValue{
  width:90%;
  padding:10px 5%;
  color:rgb(100,100,100);

}

.SearchValueGrey{
  width:90%;
  padding:10px 5%;
  color:rgb(179, 179, 179);
  position: relative;
  top: 5px;
  width: 90%;
  background-color: white;
  border: 1px solid rgb(233, 227, 227);
  border-radius: 10px;
  z-index: 30;
}

.BoldInput{
  border-radius: 2px solid rgb(100,100,100);
  border: 5px solid rgb(100,100,100);
}
@media screen and (min-width: 1024px) {  
  .SearchValue:hover{
    cursor: pointer;
    background-color: rgb(248,248,248);
}
.DropdownButton:hover{
  cursor: pointer;
}
.DropdownButtonRTL:hover{
  cursor: pointer;
}
}

@media (max-width: 767px) {
  .Body {
    max-height: 350px;
    width:98%;
  }
  .InputSection{
    height:30px;
  }
  .SearchInput {
    height: 30px;
  }
  .SearchInputWithSuggestions {
    height: 30px;
  }
  .InputText {
    font-size: 0.9em;
  }
  .DropdownButton {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    padding-left: 5px;
  }
  .RedDot {
    width: 5px;
      height: 5px;
      margin-right: 5px;
  }
  .Suggestions{
    font-size: 1em;
  }
}
