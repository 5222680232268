



@media screen and (min-width: 1024px) {
    .Image{
        width:32%;
        margin : 5px 0.5%;
        object-fit: contain;
        height:40vh;
    }
    
    .ScrollImage{
        transition: 0.3s;
        width:100%;
        object-fit: contain;
    }
    .Image:hover{
        cursor: pointer;
    }
    .ScrollImage:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px){
    .Image{
        width:49%;
        margin : 2px 0.5%;
        object-fit: cover;
        height:30vh;
    }
    .ScrollImage{
        max-height:70%;
        object-fit: cover;
        margin:2px;
    }
}