.Modal{
    animation: scale 0.5s;
}
@keyframes scale {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

.RTL{
    direction: rtl;
}

.Body{
    width:80%;
    padding: 10px 10%;
}

.Body::-webkit-scrollbar
{
  width: 0;
}


.LogoSection{
    padding: 5px 0;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Logo{
    height:8vh;
}

.Title{
    padding:1vh 0;
    width:100%;
    margin:0;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 1.3em;
    font-weight: 500;
    color: #e25b0c;
}

.ExternalIcon{
    height:35px;
    margin-right: 15px;
}

.ExternalIconRTL{
    height:35px;
    margin-left: 15px;
}

.ExternalLoginBox{
    border: 1px solid rgb(233, 233, 233);
    height: 60px;
    width: 80%;
   display: flex;
   align-items: center;
   font-size: 1.2em;
   color :rgb(90, 90, 90);
   box-shadow: 2px 2px 2px #e6e6e6;
   margin: 10px 5%;
   padding: 0 5%;
   font-weight: 500;
}


.EmailOption{
    width: 100%;
    position:relative;
    margin-top: 20px;
    padding-top: 10px;
    border-top:  1px solid rgb(223, 223, 223);
}
.EyeIcon{
    display:none;
    cursor: pointer;
    height: 20px;
    position: relative;
    right:10px;
    z-index: 10;
}

.InputField{
    border-bottom: 1px solid rgba(170, 170, 170, 0.39);
    height: 30px;
    width: 98%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 1em;
   color :rgb(90, 90, 90);
   margin: 20px 0;
   padding: 0 1%;
   font-weight: 300;
}
.InputText{
    border:none;
    font-size:1em;
    width:100%;
    color :rgb(90, 90, 90);
}
.InputText:focus{
    outline: none;
}

.ErrorMessage{
    font-size:0.9em;
    color:#b22222;
}
.Send{
    background-color: rgb(255,119,0);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 50%;
    margin: 10px 25%;
    height: 50px;
   border-radius: 7px;
   font-size: 1.1em;
  
}

.SendFade{
    background-color: rgb(255,119,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 20px;
    width: 50%;
    margin: 20px 25%;
    height: 50px;
   border-radius: 7px;
   font-size: 1.1em;
  
}


.RegisterButton{
    margin-right: 10px;
    font-weight: 600;
    color:rgb(30, 38, 51); 
    cursor: pointer;
}

.ResetPasswordSection{
padding:5px 0;
}

.ResetPassword{
    font-size:1em;
    width:100%;
    color :rgb(90, 90, 90);
}
.Content{
    padding:2vh 0;
}

.OurEmailSection{
    text-align: center;
}

.RequestSection{
    font-style: unset;
    width: 99%;
      margin-top: 25px;
    
      height: auto;
      padding: 0 0.5%;
      padding-bottom: 10px;
      padding-top: 0px;
      min-height: 150px;
      font-size:1em;
      color: rgb(100,100,100);
      font-weight: 450;
      border:1px solid rgb(223,223,223);
      border-radius: 5px;
  }

  .RequestInputField{
    width:95%;
    outline: none;
    border:none;
    resize: vertical; max-height: 150px; min-height: 150px;
  }
  .RequestInputFieldRTL{
    direction: rtl;
  }

  .RequestPlaceName{
      padding: 20px 0;
      font-size: 1.2em;
      color:rgb(100,100,100);
  }

  .ContactOptions{
      
   display:flex;
   justify-content: center;
   align-items: center;
  }

  .FacebookLink{
    margin:0 25px;
  }

  .ContactIcon{
      height:35px;
      padding:5px;

  }

  .TopicHeader{
      font-size:1.1em;
      font-weight: 500;
      padding:5px 0;
      color:rgb(88, 88, 88);
  }
@media screen and (min-width: 1024px) {
    .Modal{
        position: fixed;
        height:90vh;
        top:5vh;
        width:40%;
        left:30%;
        z-index: 700;
        background-color: white;
    }
    
.Body{
    height:85vh;
    width:80%;
    overflow-y: auto;
    padding: 2vh 10%;
}
    .ExternalLoginBox:hover{
        cursor: pointer;
        background-color: rgb(250,250,250);
    }
    

.Send:hover{
    cursor: pointer;
    background-color: rgb(255,119,0,0.8);
}
.ResetPassword:hover{
    cursor: pointer;
}

}

@media screen and (max-width: 767px) {
    .Modal{
        position: fixed;
        height:100vh;
        top:0;
        width:100vw;
        z-index: 700;
        background-color: white;
        padding: 0;
    }
    
    .Body{
        height:80vh;
        width:90%;
        padding: 10px 5%;
        overflow-y: auto;
        overflow-x: hidden;
    }

}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Modal{
        position: fixed;
        height:100vh;
        top:0;
        width:100vw;
        z-index: 700;
        background-color: white;
        padding: 10px 0;
    }

    .Body{
        width:90%;
        padding: 10px 5%;
    }
}