.Body{
    margin:5px 0;
    direction: ltr;
}

.SearchFriends{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 0.7px solid rgb(232,232,232);
    padding: 5px;
}


.UserSection{
    width: 60%;
    display: flex;
    align-items: flex-end;
}

.UserImage{
    object-fit: cover;
    height: 55px;
    width:55px;
    border-radius: 50%;
}

.UserName{
    padding-bottom: 5px;
    font-weight: 500;
    color: rgb(34,50,60);
    padding-left:7px;
}

.DeleteIcon{
    width: 22px;
    height: 22px;
}

.ToolsSection{
    display: flex;
    width:40%;
    justify-content: flex-end;
    align-items: center;
}

.AskRequest{
    border-radius: 5px;
    background-color: rgba(34, 120, 190, 0.185);
    padding: 5px 10px;
    color: white;
    color: rgb(41, 63, 109);
    margin:0 6px;
}

.AskRequest:hover{
    cursor: pointer;
  font-weight: 500;
}

.AskFollow{
    border-radius: 5px;
    background-color: rgba(44, 139, 57, 0.185);
    padding: 5px 10px;
    color: white;
    color: rgb(19, 110, 23);
    margin:0 6px;
}

.AskFollow:hover{
    cursor: pointer;
    font-weight: 500;
}

.RequestSent{
    color: rgb(34,121,190);
}

.Friend{
    color:rgb(8, 109, 8);
    margin:0 7px;
}

.Accept{
    border-radius: 5px;
    background-color: rgba(34, 120, 190, 0.185);
    padding: 5px 10px;
    color: rgb(41, 63, 109);
    margin:0 7px;
}




.Decline{
    border-radius: 5px;
    background-color: rgba(199, 199, 199, 0.185);
    padding: 5px 10px;
    color: rgb(41, 63, 109);
    margin:0 7px;
}


.Decline:hover{
    cursor: pointer;
  font-weight: 500;
}

.Remove{
    border-radius: 5px;
    background-color: rgba(199, 199, 199, 0.185);
    padding: 5px 10px;
    color: white;
    color: rgb(41, 63, 109);
    margin:0 7px;
}




@media screen and (min-width: 1024px) {
    .Body:hover{
        cursor: pointer;
    }
    .Remove:hover{
        cursor: pointer;
      font-weight: 500;
    }
    .Accept:hover{
        cursor: pointer;
      font-weight: 500;
    }
    .SearchFriends:hover{
        -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
        -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
         box-shadow: 5px 5px 7px rgba(33,33,33,.7);
     
    }
    
}


@media screen and (max-width: 767px) {
    .UserImage{
        height: 45px;
        width:45px;
    }
    .UserName{
        padding-bottom: 5px;
        font-weight: 400;
        color: rgb(34,50,60);
        padding-left:5px;
        font-size: 0.8em;;
    }
    .ToolsSection{
        font-size: 0.8em;;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .UserImage{
        height: 55px;
        width:55px;
    }
    .UserName{
        padding-bottom: 5px;
        font-weight: 400;
        color: rgb(34,50,60);
        padding-left:5px;
        font-size: 1em;;
    }
    .ToolsSection{
        font-size: 1em;;
    }
}