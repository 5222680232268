.PlaceCharacteristics{

  padding: 3px 5px;
  background-color: rgb(248,248,248);
    border-radius:5px;
}

.RTLBody{
  direction: rtl;
}

.SectionHeader{
  display: flex;
  align-items: center;
  font-size:1em;
  font-weight: 500;
  color: rgb(66, 66, 66);
  padding:10px 0;
}


.CharRow{

}

.Characteristic{
  padding:5px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.SubCharacteristic{
margin-right:5px;
padding-right: 5px;
}

.Icon{
  height:30px;
  margin-right:25px;
}

.IconRTL{
  height:30px;
  margin-left:25px;
}