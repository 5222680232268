.Body{
    width:100%;
   
}

.RTL{
    direction: rtl;
}

.PlanWall{
    position: relative;
    width:100%;
    overflow: hidden;
    height:80vh;
    margin:0; 
    background-color: rgb(241, 241, 241);
}

.CalendarDays{
  display:flex;
    overflow-x: auto;
    overflow-y: hidden;
    width:100%;
  
    height:100%;
}

 .CalendarDays::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: #F5F5F5;
}

.CalendarDays::-webkit-scrollbar
{
height:5px;
background-color: #F5F5F5;
}

.CalendarDays::-webkit-scrollbar-thumb
{
border-radius: 5px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: rgba(94, 94, 94, 0.562);
} 



.CalendarDay{
  position:relative;
    min-width:32.5vw;
    max-width:32.5vw;
    border:1px solid rgb(230,230,230);
}



.Arrow{
    border-radius: 50%;
    background-color: rgba(235, 235, 235, 0.5);
    box-shadow: 0 0 0 1px #c5c5c58e; 
    width:25px;
    height:25px;
    padding:5px;
    position:absolute;
    z-index: 10;
    top:1.5vh;
    transition: 0.4s;
  }

  .Arrow:hover{
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.9);
      transition: 0.4s;
  }
  
  .ArrowLeft{
    left:0;
  }
  
  .ArrowLeftRTL{
    right:50px;
  }
  
  .ArrowRight{
     right:0; 
  }
  
  .ArrowRightRTL{
    left:10px;
  }
  
  .RotateArrow{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }