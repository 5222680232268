.Body{
    width:99%;
    margin-bottom:25px;
    position:relative;
    display: flex;
    flex-direction: column;

    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
}
.NoBorder{
    border:none;
}

.Title{
    height:5vh;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(223,223,223);
    font-size:1.4em;
    font-weight: 350;
    color:rgb(100,100,100);
    
}

.Title:hover{
    cursor: pointer;
}

.Content{
    position:relative;
    width:100%;
    height:100%;
}

.ToolsSection{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:95%;
    padding: 0 2.5%;
    background-color: rgba(240, 240, 240, 0.54);
}

.ItemTools{
    position:relative;
    right:0;
    height:5vh;
    width:100%;
    padding:0;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    direction: rtl;
    background-color: rgba(243, 243, 243, 0);
}

.ItemToolsRTL{
    position:relative;
    left:0;
    height:5vh;
    width:100%;
    padding:0;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    direction: ltr;
    background-color: rgba(243, 243, 243, 0);
}


.Tools{
    width:auto;
    height:auto;
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    left:5vw;
    top:1vh;
  }
  .ToolsRTL{
    width:auto;
    height:auto;
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    right:5vw;
    top:1vh;
  }
.ToolsIcon{
    height:25px;
    margin-left:15px;
}
.ToolsIconRTL{
    height:25px;
    margin-right:15px;
}

.ToolsIcon:hover{
    cursor: pointer;
}
.ToolsIconRTL:hover{
    cursor: pointer;
}

.CloseIcon{
    height:20px;
    width:20px;
    margin-left:15px;
}
.CloseIconRTL{
    height:20px;
    width:20px;
    margin-right:15px;
}
.CloseIcon:hover{
    cursor: pointer;
}
.CloseIconRTL:hover{
    cursor: pointer;
}

.MarkerIcon{
    min-width:20px;
    max-width: 20px;
    cursor: pointer;
    margin: 10px;
}

.ColorIcon{
    border-radius: 50%;
    min-height:20px;
    max-height: 20px;
    min-width:20px;
    max-width: 20px;
    cursor: pointer;
    margin:5px;
    margin-left:15px;
}
.ColorIconRTL{
    border-radius: 50%;
    min-height:20px;
    max-height: 20px;
    min-width:20px;
    max-width: 20px;
    cursor: pointer;
    margin:5px;
    margin-right:15px;
}

.RedColorIcon{
    background-color: rgb(198,66,66);
}

.BlueColorIcon{
    background-color: rgb(82, 88, 190);
}

.GreenColorIcon{
    background-color: rgb(92,203,93);
}

.YellowColorIcon{
    background-color: rgb(255,225,0);
}

.PurpleColorIcon{
    background-color: rgb(185,65,211);
}

.GreyColorIcon{
    background-color: rgb(153,153,153);
}

.DaysOptions{
    position: absolute;
    min-width: 100px;
    width:auto;
    z-index: 1;
    top:5px;
    background-color: rgb(247, 247, 247);
    padding:5px 10px;
    border:1px solid rgb(223,223,223);
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height:20vh;
    color:rgb(88,88,88);
}

.DaysOptionsLTR{
    right:0;
}
.DaysOptionsRTL{
    left:0
}

.DaysOptions::-webkit-scrollbar
{
width:0;
background-color: #F5F5F5;
}


.DayOption{
    background-color: white;
    white-space: nowrap;
    margin:5px 0;
    padding: 5px 10px;
}

.DayOptionSelected{
    background-color: rgb(34,121,190);
    color:white;
    white-space: nowrap;
    margin:5px 0;
    padding: 5px 10px;
}

.Vote{
    display:flex;
    align-items: center;
    margin: 0 5px;
}
.Vote:hover{
    cursor: pointer;
}
.VoteOptions{
    display: flex;
    align-items: center;
    margin-left:10px;
    padding-right:10px;
    border-right: 1px solid rgb(223,223,223);
}

.VoteOptionsRTL{
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-left:10px;
    border-left: 1px solid rgb(223,223,223);
}

.VoteIcon{
    width:20px;
    margin:0 7px;
    cursor: pointer;
}

.VoteResults{

}

.ColorsPallete{
position: absolute;
display: flex;
align-items: center;
padding: 5px 10px;
width:120px;
flex-wrap: wrap;
top:10px;
background-color: rgb(252, 252, 252);
border: 1px solid rgb(228, 228, 228);
border-radius: 5px;
}

.ColorsPalleteLTR{
    right:0;
}
.ColorsPalleteRTL{
    left:0
}

.VotesList{
    position: absolute;
    z-index: 1;
    background-color: rgba(241, 241, 241, 0.93);
    padding:5px 10px;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height:20vh;
}

.UserVote{
    display:flex;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    margin:5px 0;
    white-space: nowrap;
}

.UserVoteImage{
    border-radius: 50%;
    width:30px;
    margin-right:10px
}

.UserVoteImageRTL{
    border-radius: 50%;
    width:30px;
    margin-right:10px
}

@media screen and (min-width: 1024px) {  
    .DayOption:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 767px) {  
    .Body{
        width:99%;
        margin-bottom:20px;
    }
    .ToolsIcon{
        height:18px;
    }
    .ItemTools{
        
    }
    .Tools{
        width:50vw;
        height:auto;
        position: fixed;
        display: flex;
        justify-content: flex-end;
        z-index: 100;
        background-color: rgb(255, 255, 255);
        right:25vw;
        top:30vh;
      }
      .ToolsRTL{
        width:50vw;
        height:auto;
        position: fixed;
        display: flex;
        justify-content: flex-end;
        z-index: 100;
        background-color: rgb(255, 255, 255);
        opacity: 1;
        left:25vw;
        top:30vh;
      }
    .CloseIcon{
        height:16px;
        width:16px;
    }
    .Vote{
        font-size:0.8em;
    }
    .RedColorIcon{
        max-height:16px;
        min-height: 16px;
        max-width:16px;
        min-width: 16px;;
    }
    
    .BlueColorIcon{
        max-height:16px;
        min-height: 16px;
        max-width:16px;
        min-width: 16px;;
    }
    
    .GreenColorIcon{
        max-height:16px;
        min-height: 16px;
        max-width:16px;
        min-width: 16px;;
    }
    
    .YellowColorIcon{
        max-height:16px;
        min-height: 16px;
        max-width:16px;
        min-width: 16px;;
    }
    
    .PurpleColorIcon{
        max-height:16px;
        min-height: 16px;
        max-width:16px;
        min-width: 16px;;
    }
    
    .GreyColorIcon{
        max-height:16px;
        min-height: 16px;
        max-width:16px;
        min-width: 16px;;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){

}