
.RTLBody{
  direction: rtl;
}


.NewOptions {
  position: fixed;
  z-index: 200;
  left: 30%;
  top: 20vh;
  width: 40%;
  color: grey;
  border: 0.6px solid rgb(180, 180, 180);
  box-shadow: 5px 5px 20px black;
  padding: 3px 0px;
  border-radius:5px;
  background-color: rgb(245, 245, 245);
}

.NewOpt {
  text-align: center;
  padding: 10px 2.5%;
  margin: 5px 0;
  color: rgb(100, 100, 100);
  width: 95%;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.NewOpt:hover {
  cursor: pointer;
  box-shadow: 5px 5px 20px rgba(165, 165, 165, 0.801);
}

.NewOptGrey{
  text-align: center;
  padding: 10px 2.5%;
  margin: 5px 0;
  color: rgb(165, 165, 165);
  width: 95%;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgb(230, 230, 230);
}


.NewOptText {
  margin-left: 15px;
}
.NewOptTextRTL {
  margin-right: 15px;
}

.NewOptTitle {
  font-size: 1.3em;
  text-align: start;
  font-weight: 500;
 
}

.NewOptTitleCenter {
  text-align: center;
  width: 100%;
  font-size: 1.3em;
  font-weight: 500;
}

.NewOptSubtitle {
  text-align: start;
  font-size: 1em;
}

.NewOptImage {
  min-width: 60px;
}

.Icon {
  height: 40px;
}

.CreateTripSelection{
  display:flex;
  position:absolute;
  align-items: center;
  flex-direction: column;
  width:75%;
  left:10%;
  top:25%;
  background-color: rgb(219, 219, 219);
  padding:10px 2.5%;
  border-radius: 10px;
  z-index:100;
}

.SelectionOptions{
  display:flex;
  align-items: center;
  width:100%;
}

.SelectionTitle{
  color:rgb(87, 87, 87);
  font-size:1.2em;
  font-weight: 500;
  padding-bottom: 1vh;
}

.CreationOption{
  background-color: rgb(245,245,245);
  color:rgb(66,66,66);
  border: 1px solid white;
  margin:0 2.5%;
  min-width:35%;
  min-height:40px;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 7px 4%;
  text-align: center;
  border-radius:5px;
}

@media screen and (min-width: 1024px){
  .CreationOption:hover{
    cursor: pointer;
    background-color: white;
    font-weight: 500;
  }
}


@media screen and (max-width: 767px) {

  .NewOptions {
      position: fixed;
      z-index: 200;
      top: 15vh;
      left:0;
      width: 95%;
      color: grey;
      box-shadow: 3px 3px 15px rgb(99, 99, 99);
      padding: 0 2.5%;
      background-color: rgb(255, 255, 255);
      padding-bottom:1px;
    }
    .NewOptText {
      margin-left: 5px;
    }
    .NewOptTextRTL {
      margin-right: 5px;
    }
    .NewOptTitle {
      font-size: 1em;
    }
    .NewOptSubtitle {
      font-size: 0.8em;
    }
    .NewOptImage {
      min-width: 40px;
    }
    .Icon {
      height: 30px;
    }
    .NewOpt {
      height: 10vh;
      width: 95%;
      margin:0;
      padding: 5px 2.5%;
    }
    .NewOpt:hover {
      box-shadow: none;
    }
   
.CreateTripSelection{
  width:90%;
  left:0%;
  top:25%;
  padding:10px 5%;
  border-radius: 0;
}
}


@media screen and (min-width: 768px) and (max-width:1023px){
  .NewOptions {
    position: fixed;
    z-index: 200;
    top: 15vh;
    left:10%;
    width: 75%;
    color: grey;
    box-shadow: 3px 3px 15px rgb(99, 99, 99);
    padding: 0 2.5%;
    background-color: rgb(255, 255, 255);
    padding-bottom:1px;
  }
  .NewOptText {
    margin-left: 15px;
  }
  .NewOptTextRTL {
    margin-right: 15px;
  }
  .NewOptTitle {
    font-size: 1.2em;
  }
  .NewOptSubtitle {
    font-size: 1em;
  }
  .NewOptImage {
    min-width: 40px;
  }
  .Icon {
    height: 30px;
  }
  .NewOpt {
    height: 10vh;
    width: 95%;
    margin:0;
    padding: 5px 2.5%;
  }
  .NewOpt:hover {
    box-shadow: none;
  }
}