.Body{
    display: flex;
    align-items: center;
    width:auto;
}

.BodyRTL{
    display: flex;
    align-items: center;
    direction: rtl;
    width:auto;
}



.LocationFilter{
    border-radius: 25px;
    color: rgba(24, 141, 40, 1);
    border: 1px solid rgba(12, 143, 29, 0.4);   
}

.CategoryFilter{
    border-radius: 25px;
    border: 1px solid rgba(34,121,190, 0.7);
    color: rgba(34,121,190, 1);
}

.DislikeCategory{
    border-radius: 20px;
    border: 1px solid #b22222;
    color:#b22222;
    background-color: #b222223b;
}

.OtherCategoryFilter{
    border-radius: 20px;
    border: 1px solid rgba(89, 80, 212, 0.7);
    color: rgba(89, 80, 212);
}

.CategoryFilterSelected{
    border-radius: 20px;
    background-color: rgba(34,121,190, 0.3);
    border: 1px solid rgba(34,121,190, 0.7);
    color: rgba(34,121,190, 1);
}

.NameFilter{
    border-radius: 20px;
    color: rgb(83, 83, 83);
    border: 1px solid rgba(82, 82, 82, 0.7);   
}

.FavoritePreviewTag{
    font-size: 1em;
    color: rgb(44, 57, 77);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 25px;
  }

  .Characteristic{
    display:flex;
    align-items: center;
    justify-content: center;
    color: rgba(82, 82, 82, 0.8);
    border:1px solid rgba(196, 196, 196, 0.54);
    background-color: rgba(209, 209, 209, 0.038);
    font-weight:400; 
    padding: 5px 12px;
    margin: 5px;
    min-width: 30px;
    border-radius:20px;
  }

  .CharacteristicSelected{
    display:flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: rgba(112, 112, 112, 0.55);
    border:1px solid rgba(160, 160, 160, 0.54);
    font-weight:400; 
    padding: 5px 12px;
    margin: 5px;
    min-width: 30px;
    border-radius:20px;
  }
  .AdditionalTagFilter{
    border-radius: 20px;
    color: rgb(83, 83, 83);
    border: 1px solid rgba(82, 82, 82, 0.7);   
    background-color: white;
    padding: 5px 10px;
    margin: 5px;
  }
  

@media screen and (min-width: 1024px) {
    .ClickableBody{
        cursor: pointer;
    }
    .Icon{
        width:16px;
        margin-left:15px;
        transition: 0.4s;
    }
    .Icon:hover{
        cursor: pointer;
    }
    .IconRTL{
        width:16px;
        margin-right:15px;
    }
    .IconRTL:hover{
        cursor: pointer;
    }
    .LocationFilter{
        padding: 10px 25px;
        margin: 5px;
        transition: 0.5s;
    }
    .LocationFilter:hover{
        background-color:rgba(71, 224, 92, 0.075);
        transition: 0.5s;
        
        box-shadow: rgba(113, 230, 98, 0.04) 0px 3px 5px;
        transform: scale(1.05);
    }
    .ResizeOnHover{
        padding: 10px 10px;
    }
    .CategoryFilter{
        padding: 10px 25px;
        margin: 5px;
        transition: 0.3s;
    }
    .DislikeCategory{
        padding: 10px 25px;
        margin: 5px;
        transition: 0.3s;
    }
    .CategoryFilter:hover{
        background-color: rgba(34,121,190, 0.2);
        transition: 0.5s;
        
        box-shadow: rgba(113, 230, 98, 0.04) 0px 3px 5px;
        transform: scale(1.05);
    }
    .CategoryFilterSelected{
        padding: 10px 25px;
        margin: 5px;
        transition: 0.3s;
    }
    
    .NameFilter{
        padding: 10px 25px;
        margin: 5px;
        transition: 0.3s;
    }
    .NameFilter:hover{
        background-color: rgba(201, 201, 201, 0.2);
        transition: 0.5s;
        
        box-shadow: rgba(113, 230, 98, 0.04) 0px 3px 5px;
        transform: scale(1.05);
    }
    .FavoritePreviewTag{
        padding: 10px 25px;
        margin: 5px;
      }
     /*  .FavoritePreviewTag:hover{
        box-shadow: 0 1px 1px rgba(97, 97, 97, 0.15), 0 2px 2px rgba(83, 83, 83, 0.15),
        0 2px 2px rgba(88, 88, 88, 0.15), 0 4px 4px rgba(90, 90, 90, 0.15);
      } */

      .OtherCategoryFilter{
        padding: 10px 25px;
        margin: 5px;
        transition: 0.3s;
    }
    .OtherCategoryFilter:hover{
        background-color: rgba(46, 146, 204, 0.2);
        transition: 0.5s;
        
        box-shadow: rgba(113, 230, 98, 0.04) 0px 3px 5px;
        transform: scale(1.05);
    }
    .Characteristic{
        padding: 10px 25px;
        border-radius: 25px;
    }
    .CharacteristicSelected{
        padding: 10px 25px;
        border-radius: 25px;
    }
    .Characteristic:hover{
        cursor: pointer;
        color: rgba(100, 100, 100, 0.8);
        background-color: rgba(190, 190, 190, 0.25);
        transition: 0.5s;
        
        box-shadow: rgba(113, 230, 98, 0.04) 0px 3px 5px;
        transform: scale(1.05); 
      }
}

@media screen and (max-width: 767px) {
    .Icon{
        width:16px;
        margin-left:10px;
    }
    .IconRTL{
        width:16px;
        margin-right:10px;
    }
    .LocationFilter{
        padding: 5px 10px;
        margin: 5px;
    }
    .CategoryFilter{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
    }
    .DislikeCategory{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
    }
    .CategoryFilterSelected{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
    }
    .NameFilter{
        padding: 5px 10px;
        margin: 5px;
    }
    .FavoritePreviewTag{
        padding: 5px 10px;
        margin: 5px;
      }
      .Characteristic{
        padding: 5px 10px;
        margin: 5px;
      }
      .OtherCategoryFilter{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
      }
}

@media screen and (min-width: 768px) and (max-width:1023px){
    .Icon{
        width:16px;
        margin-left:10px;
    }
    .IconRTL{
        width:16px;
        margin-right:10px;
    }
    .LocationFilter{
        padding: 5px 10px;
        margin: 5px;
    }
    .CategoryFilter{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
    }
    .DislikeCategory{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
    }
    .CategoryFilterSelected{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
    }
    .NameFilter{
        padding: 5px 10px;
        margin: 5px;
    }
    .FavoritePreviewTag{
        padding: 5px 10px;
        margin: 5px;
      }
      .Characteristic{
        padding: 5px 10px;
        margin: 5px;
      }
      .OtherCategoryFilter{
        padding: 5px 10px;
        margin: 5px;
        transition: 0.3s;
      }
}