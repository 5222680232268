.Body{
    width:100%;
    height:auto;
}

.Wrapper{
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
}

.ImagesSection{
    display:flex;
    align-items: center;
    position:relative;
    min-width:100%;
    max-width:100%;
    min-height:50vh;
    max-height:50vh;
}

.ArrowsSection{
    width:110%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:absolute;
    top:40%;
 
}


.Arrow{
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 0 1px #c5c5c58e;
    width:40px;
    height:40px;
    padding:10px;
    position:relative;
    z-index: 10;
}

.RotateArrow{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}


.Image{
    min-width:100%;
    max-width:100%;
    min-height:48vh;
    max-height:48vh;
    right:-0;
    border-radius: 15px;
    border: solid 1.5em rgba(#000, .03);
    object-fit: cover;
    position: relative;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    z-index: 1;
    cursor: zoom-in;
  
}
.ImageBullets{
    position: relative;
    bottom:8vh;
    z-index: 20;
   height:25px;
   padding: 5px 0;
   width:100%;
   display: flex;
   align-items: center;
   justify-content: center;

}

.Bullet{
width:15px;
height:15px;
margin:0 5px;
}
.Bullet:hover{
    cursor: pointer;
}

.SelectedBullet{
    border-radius:50%;
    background-color: rgb(255,119,0);
    width:15px;
height:15px;
}

.UnselectedBullet{
    border-radius:50%;
    border:0.7px solid rgba(97, 97, 97, 0.527);
    background-color: white;
    width:15px;
height:15px;
}

.CopyrightMessage{
    width:100%;
    position:relative;
    font-size:0.8em;
    display:flex;
    color:rgb(88,88,88);

}

.CopyrightLink{
    color:black;
    font-weight: 500;
    margin:0 10px;
}

@media screen and (min-width: 1024px) {
    .Arrow:hover{
        cursor: pointer;
    }
    .CopyrightLink:hover{
        cursor: pointer;
    }
    
}

@media screen and (max-width: 767px) {
    .Body{width:100%}
    
    .ImagesSection{
        min-width:100vw;
        max-width:100vw;
        min-height:40vh;
        max-height:40vh;
        overflow-x: auto;
    }
.ImageBullets{  
    bottom:8vh;
    left:0;
}
.ArrowsSection{
    width:100%;
}
.Image{
    min-width:100%;
    max-width:100%;
    min-height:40vh;
    max-height:40vh;
    border-radius: 0;
}

    .Arrow{
        padding:0;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 1);
        width:35px;
        padding:5px;
        height:35px;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px){

}