.Modal{
    position:fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    font-size: 1.2em;
    color:rgb(90,90,90);
    top:30vh;
    height:45vh;
    width:25vw;
    left:37.5%;
    z-index: 125;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow:  0px 11px 8px -10px #ccc;
    border:1px solid rgb(223,223,223);
    border-radius: 10px;
}

.Title{
    padding: 1vh 0;
}

.DaysList{
    display: flex;
    flex-direction: column;
    height:30vh;
    overflow-y: auto;
    width:90%;

}

.Day{

}

.CompleteButton{
    position:absolute;
    bottom:2vh;
    padding: 7px 20px;
    background-color: rgb(255,119,0);
    color:white;
    border-radius: 5px;
}

.CompleteButtonFade{
  position:absolute;
  bottom:2vh;
  padding: 7px 20px;
  background-color: rgb(255,119,0,0.5);
  color:white;
  border-radius: 5px;
}

.CheckMarkSection{
    display:flex;
    align-items: center;
    white-space: nowrap;
    color:rgb(80,80,80);
    padding: 1vh 0;
  }

  .CheckIcon{
    margin-right:12px;
    width:26px;
    height:26px;
  }
  
  .CheckIconRTL{
    margin-left:12px;
    width:26px;
    height:26px;
  }
  
  
  .UncheckBox{
    margin-right:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }
  
  .UncheckBoxRTL{
    margin-left:12px;
    width:24px;
    height:24px;
    border-radius:5px;
    border:1px solid rgb(40,40,40);
  }
  


  .TopSection{
    width:100%;
    height:7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
    border-bottom:1px solid rgb(223,223,223);
    position: relative;
  }
  .CloseIcon{
    position:absolute;
    right:10px;
    min-height:22px;
    max-height: 22px;
    min-width:22px;
    max-width: 22px;
    transition: 0.4s;
  
  }

  .CloseIconRTL{
    min-height:22px;
    max-height: 22px;
    min-width:22px;
    max-width: 22px;
    position:absolute;
    left:10px;
    transition: 0.4s;
  
  }

  @media screen and (min-width: 1024px) {  
    .CheckIcon:hover{
        cursor: pointer;
    }
        .CheckIconRTL:hover{
        cursor: pointer;
        }
    
    .UncheckBox:hover{
        cursor: pointer;
        }
    
    .UncheckBoxRTL:hover{
        cursor: pointer;
        }
.CompleteButton:hover{
    background-color: rgb(255,119,0,0.85);
    cursor: pointer;
}
.CloseIcon:hover{
  cursor: pointer;
  transform:scale(1.1);
  transition: 0.4s;
}
.CloseIconRTL:hover{
  cursor: pointer;
  transform:scale(1.1);
  transition: 0.4s;
}
}

@media screen and (max-width: 767px) {  
  .Modal{
    position: fixed;
    left: 10%;
    padding: 10px 2.5%;
    top: 25%;
    width: 75%;
    height: 50%;
    border:none;
}
.DaysList{
  display: flex;
  flex-direction: column;
  height:45vh;
  overflow-y: auto;
  width:90%;

}

}

@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position: fixed;
    left: 20%;
    padding: 10px 5%;
    top: 25%;
    width: 50%;
    height: 50%;
    border:none;
}
.DaysList{
  display: flex;
  flex-direction: column;
  height:45vh;
  overflow-y: auto;
  width:90%;

}
}