.Scroll {
    position: fixed;
    bottom:25px;
    right: 0.5rem;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
    z-index: 1;
  }
  
  .Icon {
    height: 50px;
  }
  
  @media screen and (max-width: 767px) {
    .Scroll{
      bottom:15vh;
      right: 4vw;
    }
  }

  @media screen and (min-width: 768px) and (max-width:1023px){
    .Scroll{
      bottom:12vh;
      right: 5vw;
    }
  }