.Body{
    background-color: white;
    width:100px;
    
    position:absolute;
    top:20px;
    padding:4px 0;
    z-index:20;
    border-radius: 5px;
    border:1px solid rgb(223,223,223);
   

    animation: show 0.35s;
  }@keyframes show{
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .Scrollable{
    overflow-y: auto;
    max-height:20vh;
  }

  .LTR{
    right:0;
  }

.RTL{
  left:0;
  }

  .Body::-webkit-scrollbar-track-piece {
    background-color: white;
    }
    
    .Body::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    }
    
    .Body::-webkit-scrollbar
    {
    width: 5px;
    background-color: #F5F5F5;
    }
    
    .Body::-webkit-scrollbar-thumb
    {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(231, 231, 231, 0.562);
    }


.Style1{
    min-width:150px;
}

.Style2{
  min-width:200px;
}


.ToolOption{
  white-space: nowrap;
  display:flex;
    padding:7px 12px;
    align-items: center;
}
.ToolOption:hover{
    cursor: pointer;
    background-color: rgb(248,248,248);
}

.ToolOptionRTL{
  white-space: nowrap;
  display:flex;
  padding:7px 12px;
  align-items: center;
  direction: rtl;
}
.ToolOptionRTL:hover{
  cursor: pointer;
  background-color: rgb(248,248,248);
}


.Icon{
  height:25px;
  margin-right:15px;
}

.IconRTL{
  height:25px;
  margin-left:15px;
}