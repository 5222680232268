

  .Body {
    position: relative;
    width: 31%;		/* desired width */
    margin: 1%;
  }
  .Body:before {
    content: "";
    float: left;
    padding-top: 100%; 	/* initial ratio of 1:1*/
  }

.FullWidth{
  width:100%;
  object-fit: cover;
}

  
  .ImagePreview {
    position: absolute;
    object-fit: contain;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #333;
  color: #fff;
/*   display: flex;
  align-items: center; */
    height: 100%;
    width: 100%;
  }
  
  .Icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 3px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    transition: 0.5s;
  }
  
  .Icon:hover {
    background-color: rgba(255, 255, 255, 1);
    transition: 0.5s;

  }
  .UpIcon{
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(252, 129, 58);
    font-weight: 500;
    top: 0;
    left: 3px;
    padding: 3px;
    font-size:1.1em;
    z-index: 5;
    transition: 0.5s;

  }
  
  @media (max-width: 600px) {
    .Body {
      width: 48%;
    }
    .ImagePreview {
    }
    .ImageWraper {
      margin: 3px;
    }
    .Icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      z-index:10;
      right: 2px;
    }
    .UpIcon{
      cursor: pointer;
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 2px;
      right: 2px;
      padding: 2px;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.705);
      border-radius: 50%;
      transition: 0.5s;
      z-index:10;
    }
  }
  