.Modal{
    position:fixed;
    z-index:100;
    width:50vw;
    left:25vw;
    height:90vh;
    top:5vh;
    background-color: white;
    border-radius: 10px;
    background-color: white;
}

.Body{
    width:90%;
    padding :1vh 5%;
    height:80vh;
}


.AccommodationSelection{
    width: 60%;;
  }
  
  .PlaceNotExist{
    color:rgb(255,119,0);
    width:90%;
    padding: 10px 0;
    text-align: center;
  }

.AccommodationCategories{
    padding: 10px 0;
    width:100%;
  }
  
  .SelectedCategories{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
  }
  
  
  .CategoriesSelectionList{
    width:100%;
  }

  .CountrySelection{
      padding:2vh 0;
        border-bottom: 1px solid rgb(223,223,223);
  }

  .ResetPlace{
    margin: 5px 0;
  width:auto;
  padding:5px 15px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: rgb(248,248,248);
  border:1px solid rgb(230,230,230);
  font-size:1.1em;
  color:rgb(88,88,88);
  }
  

  @media screen and (min-width: 1024px) {  
    .PlaceNotExist:hover{
        cursor: pointer;
      }
      .ResetPlace:hover{
        cursor: pointer;
        background-color: white;
        
      }
  }

  @media screen and (max-width: 767px) {  
    .Modal{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
     border-radius: 0;;
      border:none;
  }

  .Body{
    width:96%;
    padding: 20px 2%;
    height:80vh;
    padding-bottom: 5vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.AccommodationSelection{
  width: 100%;
}
  }

  
@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
   border-radius: 0;;
    border:none;
}

.Body{
  width:96%;
  padding: 20px 2%;
  height:80vh;
  padding-bottom: 5vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.AccommodationSelection{
width: 60%;
}
}