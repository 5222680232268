.Modal{
    position: fixed;
    left: 25%;
    top: 20vh;
    width: 50%;
    min-height: 55vh;
    max-height: 55vh;
    border: 0.6px solid rgb(180, 180, 180);
    box-shadow: 5px 5px 10px rgb(172, 172, 172);
    border-radius: 15px;
    z-index: 880;
    background-color: rgb(255, 255, 255);
    display: flex;
  flex-direction: column;
  }
  
  .Body{
    width:90%;
    padding: 20px 5%;
    height:46vh;
    overflow-x: hidden;
    overflow-y: auto;
    color: rgb(95, 95, 95);
  }
  

  .NoteSection{
      width:90%;
      padding: 0 2.5%;
      margin:15px 0;
      border-radius: 5px;
      border:1px solid rgb(230,230,230);
  }

  
.Content{
    width:100%;
    height:auto;
    padding: 15px 0 ;
    display:flex;
   align-items: center;
   direction: ltr;
}   

.Ticket{
 
    border-radius:7px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:80%;
    padding:5px 0;
    height:110px;
    font-size: 0.9em;
}
.Ticket:hover{

}

.TicketPart{
    background-color: white;
    width:45%;
    padding:10px 2.5%;
    height:80%;
    font-size:1em;    
    position: relative;
    color:rgb(100,100,100);
   
}

.RightClass{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
}

.Time{
    position: absolute;
    bottom:1vh;
}
.TicketPartHover{
    background-color: white;
    width:45%;
    padding:0 2.5%;
    height:100%;
    font-size:1em;    
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(100,100,100);
   
}
.TicketBorder{
    width:0px;
    height:100%;
 
  
}

.Type{
 
    width:20%;
   
    display:flex;
    align-items: center;
    justify-content: center;
    height:90px;
    margin: 10px 0;
}
.TypeHover{
    width:20%;
    height:90px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

}

.LeftClass{
   /*  height:90px;
    margin: 10px 0;
    border-right:1px solid rgb(223,223,223); */
}

.TransportIcon{
    width:40px;
}

.Arrow{
    width:30px;
}

  @media screen and (min-width: 1024px) {  
  }
  
  
  @media(max-width:767px){
    .Modal{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 100vh;
      max-height: 100vh;
      border:none;
  }
  
  .Body{
      width:96%;
      padding: 20px 2%;
      height:76vh;
      overflow-x: hidden;
      overflow-y: auto;
  }
  
  }
  
      
@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    border:none;
}

.Body{
    width:90%;
    padding: 20px 5%;
    height:76vh;
    overflow-x: hidden;
    overflow-y: auto;
}

}