.RTL{
  direction: rtl;
}

.UserProfile{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.SettingsSelection{
  display: flex;
}

.UserName{
  display: flex;
  align-items: center;
  justify-content: center;
}

.country{
  display: flex;
  align-items: center;
  justify-content: center;
}

  
.UploadSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  cursor: pointer;
  position:absolute;
  top:10px;
  right:10px;
}

.FileInput {
  width: 45px;
  height: 45px;
  cursor: pointer;
  position:absolute;
  right:0;
  top:20px;
}

.ImageUploadWrapper {
  cursor: pointer;
  max-width: 45px;
  max-height: 45px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  color: rgb(87, 87, 87);

}

.ImageUploadWrapper > input[type="file"] {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0;
  cursor: pointer;
}

.ImageUploadWrapper > .UploadButton {
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

.UploadButton {
  cursor: pointer;
  border-radius: 50%;
  padding: 3px;
  padding-top: 0;
  
}

.ImageIcon{
  cursor: pointer;
  width: 30px;
  height: 30px;
  
  background-color: white;
}

.ImageSection{
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.Data{
  margin:10px 0;
  padding: 10px 0;
}

.MobileSeperator{
  min-height:3vh;
  border-right:1px solid rgb(194, 194, 194);
  width:2px;
}
@media screen and (min-width: 1024px) {
  .Modal{
    height: 100vh;
    position:fixed;
    width: 100vw;
    left:0;
    top:0;
    z-index:200;
    background-color: white;
  }
  .Body{
    width: 100%;
    padding: 0;
    height: 92.8vh;
    display:flex;
  }

  .UserProfile{
    width:25%;
    padding:2vh 0;
    height:88.8vh;
    background-color: rgb(210,210,210);
  }

  .SettingsContent{
    width:70%;
    padding:2vh 2.5%;
    padding-bottom: 10vh;
    height:80vh;
  }

  .SelectedSettings{
    width:90%;
    display:flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    overflow-y: auto;
  }

  .InfoSection{
    width:500px;


  }

  .InterestsSettings{
    width:60%;
  }

  .UserName{
    height:40px;
    font-size:1.5em;
    margin-top:15px;
    color:rgb(88,88,88);
  }

  .Country{
    height:40px;
    font-size:1.2em;
    margin-top:15px;
    color:rgb(78, 78, 78);
  }

  
  .CropIcon {
    cursor: pointer;
    height: 30px;
    margin-right: 20px;
    position:absolute;
    padding:5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.671);
    right:0;
    top:5px;
  }

  .CropIcon:hover{
    cursor: pointer;
  }
  
  .Image {
    object-fit: cover;
    width: 300px;
  height: 300px;
    border-radius: 50%;
  }
  .Clickable:hover{
    cursor: pointer;
  }

  .ProfileImage{
    object-fit: cover;
    width: 250px;
    height:250px;
    border-radius: 50%;
  }

  .CropImage{
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;
    min-height: 300px;
  }

  .CropButtons{
    display: flex;
    align-items: center;
  }

  .DoneButton{
    margin:0 20px;
      position:relative;
      top:55px;
    border-radius: 6px;
      height:35px;
      width:auto;
      padding:5px 10px;
      display:flex;
      align-items: center;
      color:white;
      background-color: rgb(41, 129, 41);
  }
  .DoneButton:hover{
    background-color: rgb(41, 129, 41,0.75);
    cursor: pointer;
  }

  .DiscardButton{
    margin:0 20px;
    position:relative;
    top:55px;
  border-radius: 6px;
    height:35px;
    width:auto;
    padding:5px 10px;
    display:flex;
    align-items: center;
    color:white;
    background-color: rgb(182, 182, 182);
  }

  .DiscardButton:hover{
    background-color: rgba(211, 211, 211, 0.75);
    cursor: pointer;
  }
  
  .ProfileImageSection {
    width:400px;
    margin-top: 30px;
    margin-bottom:60px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }

  

  .LanguageSection{
    position: relative;
    width:60%;
    padding: 10px 0;
    padding-bottom: 50px;
  }

  .LanguageMessage{
    padding: 10px 0;
    font-size: 1.1em;
    color: rgb(100,100,100);
  }

  .LanguageOptions{
    position: relative;
    width:100%;
  }


 
.ProfileStats{
  margin-top: 30px;
  width:80%;
  padding:0 10%;
}

.DatesStats{
  margin-bottom: 40px;
}

.Stat{
  color:rgb(88,88,88);
  display: flex;
  justify-content: space-between;
  padding:5px 10px;
  margin:5px 0;
  border-radius: 5px;
  background-color: rgb(220,220,220);
  font-size: 1.2em;
}

.StatTitle{
 
}

.StatValue{

}

.UserDataSection{
  margin-top:30px;
  color:rgb(100,100,100);
  width:60%;
}

.UserData{
  display:flex;
  align-items: center;
  font-size: 1.2em;
  margin:25px 0;
 width:100%;
}




.DataTitle{
  font-size: 1.1em;
  color: rgb(100,100,100);
  width:auto;
  padding: 10px 0;
}

.DataInput{
  height:40px;
  border:none;
  border-bottom:1px solid rgb(223,223,223);
  padding:0 10px;
  width:100%;
  font-size: 1em;
  color:rgb(100,100,100);
}

.DataInput:focus{
  outline: none;
}

.Interests{
  margin-top:30px;
}

.InterestsHeader{
  margin:15px 0;
  font-size:1.4em;
}

.InterestsSection{
  width:100%;
  border-top:1px solid rgb(223,223,223);
  padding-bottom: 20px;
}

.InterestsSectionHeader{
  padding:10px 0;
  font-size:1.2em;
  font-weight: 500;
  margin-bottom:10px;
}

.DislikesSection{
  padding-bottom: 20vh;
}

.CategoryIcon{
  position: relative;
  top:10px;
  height:50px;
  margin-right:15px;
}

.CategoryIconRTL{
  position: relative;
  top:10px;
  height:50px;
  margin-left:15px;
}

.CategoriesTags{
  width:70%;
  display: flex;
  flex-wrap: wrap;
  padding:10px 0;
}

.WelcomeMessage{
  position:fixed;
  height:55vh;
  padding: 2.5vh 2.5vw;
  top:20vh;
  width:25vw;
  left:35vw;
  z-index: 10;
  background-color: white;
  border:1px solid rgb(223,223,223);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  align-items: center;
}


.MessageTitle{
  margin: 4vh 0;
  font-size: 2.2em;;
  color:rgb(255,119,0);
}

.MessageBody{
  font-size:1.4em;
}

.MessageButton{
  position: relative;;
  bottom:-10vh;
  padding: 5px 10px;
  border:1px solid rgb(223,223,223);
  border-radius: 5px;
  font-size: 1.2em;
}

.MessageButton:hover{
  cursor: pointer;
  background-color: rgb(245,245,245);
}


}

@media screen and (max-width: 767px) {
  .Modal{
    height: 100vh;
    position:fixed;
    width: 100vw;
    left:0;
    top:0;
    z-index:200;
    background-color: white;
  }
  .Body{
    width: 100%;
    padding: 0;
    height: 82vh;
   
  }
  .SettingsContent{
    width:100%;
    padding:2vh 0;
    height:75vh;
    padding-bottom: 5vh;
  }

  .UserProfile{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    padding:2vh 0;
    height:80%;
    background-color: rgb(248,248,248);
  }
  .ProfileImage{
    object-fit: cover;
    width: 50%;
    border-radius: 50%;
  }

  .Stat{
    color:rgb(88,88,88);
    display: flex;
    justify-content: space-between;
    padding:5px 10px;
    margin:5px 0;
    border-radius: 5px;
    border-bottom:1px solid rgb(223,223,223);
    font-size: 1em;
  }

  .SelectedSettingsProfile{
    height:100%;
    width:100%;
    padding: 0;
  }
  
  .SelectedSettings{
     
    height:100%;
    width:90%;
    padding: 0 5%;
    margin-bottom:15vh;
  }

  .InterestsSettings{
    height:100%;
    width:90%;
    padding: 0 5%;
  }
  
  .ProfileImageSection{
    width:80%;
    margin:0 10%;
      margin-top: 20px;
      margin-bottom:40px;
    
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  }
  
  .Image {
    object-fit: cover;
    width: 80%;
  height:auto;
    border-radius: 50%;
  }
  
  
  .LanguageSection{
    position: relative;
    width:100%;
    padding: 10px 0;
  }
  
  .LanguageMessage{
    padding: 10px 0;
    font-size: 1em;
    color: rgb(100,100,100);
  }
  
  .LanguageOptions{
    position: relative;
    width: 90%;
  }
  
  
  .UserDataSection{
    margin-top:30px;
    color:rgb(100,100,100);
  }
  
  .UserData{
    display:flex;
    align-items: center;
    font-size: 1em;
    margin:15px 0;
  }
  
  .DataTitle{
    font-size: 1.1em;
    color: rgb(100,100,100);
    width:auto;
    padding: 10px 0;
  }
  
  .DataInput{
    height:30px;
    border:none;
    border-bottom:1px solid rgb(223,223,223);
    width:250px;
    padding:0 10px;
    margin:3px 0;
    font-size: 1em;
    color:rgb(100,100,100);
  }
  .DataInput:focus{
    outline: none;
  }
  
  .WelcomeMessage{
    position:fixed;
    height:60vh;
    padding: 2.5vh 2.5vw;
    top:10vh;
    width:85vw;
    left:5vw;
    z-index: 10;
    background-color: white;
    border:1px solid rgb(223,223,223);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .MessageTitle{
    margin: 4vh 0;
    font-size: 1.8em;;
    color:rgb(255,119,0);
  }
  
  .MessageBody{
    font-size:1.2em;
  }
  
  .MessageButton{
    position: relative;
    bottom:-20vh;
    right:2vw;
    padding: 5px 15px;
    border:1px solid rgb(223,223,223);
    border-radius: 5px;
    font-size: 1.1em;
  }
  
.Interests{
  margin-top:30px;
  padding-bottom:50px;
}

.InterestsHeader{
  margin:15px 0;
  font-size:1.2em;
}

.InterestsSection{
  width:100%;
  border-top:1px solid rgb(223,223,223);
  padding-bottom: 20px;
}

.InterestsSectionHeader{
  padding:10px 0;
  font-size:1.1em;
  font-weight: 500;
  margin-bottom:10px;
}

.CategoryIcon{
  position: relative;
  top:5px;
  height:40px;
  margin-right:15px;
}

.CategoryIconRTL{
  position: relative;
  top:5px;
  height:40px;
  margin-left:15px;
}

.CategoriesTags{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  padding:10px 0;
}
}


@media screen and (min-width: 768px) and (max-width:1023px){
  .Modal{
    height: 100vh;
    position:fixed;
    width: 100vw;
    left:0;
    top:0;
    z-index:200;
    background-color: white;
  }
  .Body{
    width: 100%;
    padding: 0;
    height: 92.8vh;
    display:flex;
  }

  .UserProfile{
    width:35%;
    padding:2vh 0;
    height:88.8vh;
    background-color: rgb(210,210,210);
  }

  .SettingsContent{
    width:90%;
    padding:2vh 2.5%;
    padding-bottom: 10vh;
    height:80vh;
   
  }

  .SelectedSettings{
    width:80%;
  }

  
  .InterestsSettings{
    height:100%;
    width:90%;
    padding: 0 5%;
  }

  .UserName{
    height:40px;
    font-size:1.5em;
    margin-top:15px;
    color:rgb(88,88,88);
  }

  .Country{
    height:40px;
    font-size:1.5em;
    margin-top:15px;
    color:rgb(88,88,88);
  }

  
  .CropIcon {
    cursor: pointer;
    height: 30px;
    margin-right: 20px;
    position:absolute;
    padding:5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.671);
    right:0;
    top:5px;
  }

  .CropIcon:hover{
    cursor: pointer;
  }
  
  .Image {
    object-fit: cover;
    width: 300px;
  height: 300px;
    border-radius: 50%;
  }

  .ProfileImage{
    object-fit: cover;
    width: 70%;
    border-radius: 50%;
  }

  .CropImage{
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;
    min-height: 300px;
  }

  .CropButtons{
    display: flex;
    align-items: center;
  }

  .DoneButton{
    margin:0 20px;
      position:relative;
      top:55px;
    border-radius: 6px;
      height:35px;
      width:auto;
      padding:5px 10px;
      display:flex;
      align-items: center;
      color:white;
      background-color: rgb(41, 129, 41);
  }
  .DoneButton:hover{
    background-color: rgb(41, 129, 41,0.75);
    cursor: pointer;
  }

  .DiscardButton{
    margin:0 20px;
    position:relative;
    top:55px;
  border-radius: 6px;
    height:35px;
    width:auto;
    padding:5px 10px;
    display:flex;
    align-items: center;
    color:white;
    background-color: rgb(182, 182, 182);
  }

  .DiscardButton:hover{
    background-color: rgba(211, 211, 211, 0.75);
    cursor: pointer;
  }
  
  .ProfileImageSection {
    width:400px;
    margin-top: 30px;
    margin-bottom:60px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }

  

  .LanguageSection{
    position: relative;
    width:90%;
    padding: 10px 0;
    padding-bottom: 50px;
  }

  .LanguageMessage{
    padding: 10px 0;
    font-size: 1.1em;
    color: rgb(100,100,100);
  }

  .LanguageOptions{
    position: relative;
    width:100%;
    font-size: 1.1em;
  }


 
.ProfileStats{
  margin-top: 30px;
  width:80%;
  padding:0 10%;
}

.DatesStats{
  margin-bottom: 40px;
}

.Stat{
  color:rgb(88,88,88);
  display: flex;
  justify-content: space-between;
  padding:5px 10px;
  margin:5px 0;
  border-radius: 5px;
  background-color: rgb(220,220,220);
  font-size: 1.2em;
}

.StatTitle{
 
}

.StatValue{

}

.UserDataSection{
  margin-top:30px;
  color:rgb(100,100,100);
  width:90%;
}

.UserData{
  display:flex;
  align-items: center;
  font-size: 1.2em;
  margin:15px 0;
 width:100%;
}


.DataTitle{
  width:auto;
}

.DataInput{
  height:40px;
  border:none;
  border-bottom:1px solid rgb(223,223,223);
  padding:0 10px;
  width:100%;
  font-size: 1em;
  color:rgb(100,100,100);
}

.DataInput:focus{
  outline: none;
}

.Interests{
  margin-top:30px;
  padding-bottom: 20vh;
}

.InterestsHeader{
  margin:15px 0;
  font-size:1.4em;
}

.InterestsSection{
  width:100%;
  border-top:1px solid rgb(223,223,223);
  padding-bottom: 20px;
}

.InterestsSectionHeader{
  padding:10px 0;
  font-size:1.2em;
  font-weight: 500;
  margin-bottom:10px;
}

.CategoryIcon{
  position: relative;
  top:10px;
  height:50px;
  margin-right:15px;
}

.CategoryIconRTL{
  position: relative;
  top:10px;
  height:50px;
  margin-left:15px;
}

.CategoriesTags{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  padding:10px 0;
}

.WelcomeMessage{
  position:fixed;
  height:55vh;
  padding: 2.5vh 2.5vw;
  top:20vh;
  width:25vw;
  left:35vw;
  z-index: 10;
  background-color: white;
  border:1px solid rgb(223,223,223);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  align-items: center;
}


.MessageTitle{
  margin: 4vh 0;
  font-size: 2.2em;;
  color:rgb(255,119,0);
}

.MessageBody{
  font-size:1.4em;
}

.MessageButton{
  position: relative;;
  bottom:-10vh;
  padding: 5px 10px;
  border:1px solid rgb(223,223,223);
  border-radius: 5px;
  font-size: 1.2em;
}

.MessageButton:hover{
  cursor: pointer;
  background-color: rgb(245,245,245);
}

}